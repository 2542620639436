import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Skeleton from 'react-skeleton-loader';

import { IReservationVehicle } from '../interfaces/IReservation';
import HTMLRichText from './HTMLRichText';
import { Bed, Seat, Door, Fuel, Bags, Transmission, Tire } from '../icons';

interface IProps {
  vehicle: IReservationVehicle;
  loading: boolean;
}

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
  title: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
    fontWeight: 600,
    display: 'flex',
    justifyContent: 'flex-end',
    letterSpacing: 2,
  },
  text: {
    color: theme.palette.text.primary,
  },
  iconGridItem: {
    margin: theme.spacing(1.5, 0),
    display: 'flex',
    alignItems: 'center',
  },
  iconContainer: {
    width: 35,
    height: 35,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    marginRight: 5,
    flexShrink: 0,
  },
  imageFit: {
    height: '100%',
    width: '100%',
  },
  imageContainer: {
    maxWidth: 400,
    display: 'flex',
    margin: 'auto',
  },
  missingImage: {
    width: 400,
    height: 300,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inlineText: {
    fontWeight: 500,
    paddingLeft: 5,
  },
  skeletonImageContainer: {
    maxWidth: 400,
    display: 'flex',
    margin: '0 auto',
  },
  skeletonLabel: {
    margin: '10px 0',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  skeletonIcon: {
    marginRight: 5,
  },
  descriptionGrid: {
    margin: theme.spacing(2.5, 0),
  },
  descriptionTitle: {
    margin: theme.spacing(1.25, 0),
  },
  descriptionSkeleton: {
    width: '100%',
    maxWidth: 500,
  },
  skeletonImage: {
    padding: theme.spacing(2, 0),
  },
}));

const allowedVehicleInfo = ['doors', 'seats', 'beds', 'largeBags', 'smallBags'];
const vehicleMap: { [key: string]: { label: string; icon: JSX.Element } } = {
  doors: {
    label: 'Doors',
    icon: <img src={Door} alt="Door" width="25px" />,
  },
  seats: {
    label: 'Seats',
    icon: <img src={Seat} alt="Seat" width="25px" />,
  },
  beds: {
    label: 'Beds',
    icon: <img src={Bed} alt="Bed" />,
  },
  largeBags: {
    label: 'Large bags',
    icon: <img src={Bags} alt="Bag" />,
  },
  smallBags: {
    label: 'Small bags',
    icon: <img src={Bags} alt="Bag" />,
  },
};

const ReservationCarInfo: React.FC<IProps> = ({ vehicle, loading }) => {
  const classes = useStyles();

  if (loading) {
    return <ReservationCarSkeleton />;
  }

  return (
    <div className={classes.container}>
      {renderImage()}
      <Grid container direction="row">
        <Grid item xs={12}>
          <Grid container>
            {vehicle.transmission
              ? renderClassItem(
                  vehicle.transmission,
                  <img src={Transmission} alt="Transmission" width="18px" />
                )
              : null}
            {vehicle.fuel ? renderClassItem(vehicle.fuel, <img src={Fuel} alt="Fuel" />) : null}
            {vehicle.drive ? renderClassItem(vehicle.drive, <img src={Tire} alt="Drive" />) : null}
            {renderVehicleItems()}
          </Grid>
        </Grid>
        <Grid item xs={12} className={classes.descriptionGrid}>
          <Typography variant="h5" className={classes.descriptionTitle}>
            Description
          </Typography>
          <HTMLRichText text={vehicle.summary} />
        </Grid>
      </Grid>
    </div>
  );

  function renderImage() {
    if (!vehicle.image) {
      return (
        <div className={classes.missingImage}>
          <Typography variant="h5"> Missing image</Typography>
        </div>
      );
    }

    return (
      <div className={classes.imageContainer}>
        <img
          alt={vehicle.class ? vehicle.class : 'Vehicle image'}
          src={vehicle.image}
          className={classes.imageFit}
        />
      </div>
    );
  }

  function renderVehicleItems() {
    return allowedVehicleInfo.map(key => {
      if (!vehicle[key]) {
        return null;
      }

      return renderClassItem(
        `${vehicle[key]}x ${vehicleMap[key].label}`,
        vehicleMap[key].icon,
        key
      );
    });
  }

  function renderClassItem(label: string, icon: React.ReactNode, key?: string) {
    return (
      <Grid key={key} item className={classes.iconGridItem} xs={4}>
        <div className={classes.iconContainer}>{icon}</div>
        <Typography variant="body1" display="inline" className={classes.inlineText}>
          {label}
        </Typography>
      </Grid>
    );
  }
};

const ReservationCarSkeleton: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const icons: JSX.Element[] = [];
  for (let i = 0; i <= 6; i++) {
    icons.push(renderClassItem(i));
  }
  return (
    <div className={classes.container}>
      <div className={classes.skeletonImageContainer}>
        <div className={classes.imageFit}>
          <div className={classes.skeletonImage}>
            <Skeleton
              color={theme.customPalette.skeleton}
              width="100%"
              height="300px"
              widthRandomness={0}
              heightRandomness={0}
            />
          </div>
        </div>
      </div>
      <Grid container direction="row">
        <Grid item>
          <Grid container>{icons}</Grid>
        </Grid>
        <Grid item className={classes.descriptionSkeleton}>
          <Skeleton
            height="200px"
            width="100%"
            widthRandomness={0}
            color={theme.customPalette.skeleton}
          />
        </Grid>
      </Grid>
    </div>
  );

  function renderClassItem(key: number) {
    return (
      <Grid item className={classes.iconGridItem} key={key} xs={4}>
        <div className={classes.skeletonIcon}>
          <Skeleton
            color={theme.customPalette.skeleton}
            height="30px"
            width="30px"
            borderRadius="50%"
            widthRandomness={0}
            heightRandomness={0}
          />
        </div>

        <Skeleton
          color={theme.customPalette.skeleton}
          width="100px"
          height="25px"
          widthRandomness={0}
          heightRandomness={0}
        />
      </Grid>
    );
  }
};

export default ReservationCarInfo;

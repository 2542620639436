import { Field, FieldProps, Formik, FormikActions, FormikProps, getIn } from 'formik';
import React, { useRef, useState } from 'react';
import { IReservationDriver } from '../interfaces/IReservation';
import * as Yup from 'yup';
import {
  Grid,
  Input,
  Typography,
  makeStyles,
  Button,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';
import DatePicker from './DatePicker';
import moment, { Moment } from 'moment';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { selectOnlineCheckIn } from '../store/selectors';
import useNotifier from '../hooks/useNotifier';

interface IProps {
  driver: IReservationDriver;
  editable?: boolean;
  onEdited: (driver: IReservationDriver) => Promise<any>;
  onUpdating: (change: boolean) => void;
  onRemoveExtraDriver: (id: number) => void;
}

const schema = Yup.object().shape({
  name: Yup.string()
    .required('Required')
    .test('name', 'Field cannot be empty', value => {
      return !value || value.length !== 0;
    }),
  licenseNumber: Yup.string()
    .required('Required')
    .test('licence', 'Field cannot be empty', value => {
      return !value || value.length !== 0;
    }),
  dateOfBirth: Yup.string().required('Required'),
  mainDriver: Yup.bool().required('Required'),
  images: Yup.mixed()
    .required('Required')
    .test(
      'fileRequired',
      'Required',
      value => value.DriverLicenseFront_S !== null || value.DriverLicenseBack_S !== null
    )
    .test('fileFormat', 'You have to upload images', value => {
      if (value.length > 0) {
        const fileFrontType = value[0].type;
        const fileBackType = value[1].type;
        const array = ['image/png', 'image/jpeg', 'image/jpg'];
        const result1 = array.indexOf(fileFrontType);
        const result2 = array.indexOf(fileBackType);

        if (result1 > -1 && result2 > -1) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }),
});

const useStyle = makeStyles(theme => ({
  label: {
    flex: 1,
  },
  fieldContainer: {
    display: 'flex',
    width: '100%',
  },
  input: {
    background: 'white',
    flex: 1,
  },
  inputError: {
    border: '1px solid !important',
    borderColor: '#f44336 !important',
  },
  disabled: {
    color: 'rgba(0, 0, 0, 0.38)',
  },
  inputText: {
    width: '100%',
    border: 0,
    height: 32,
    margin: 0,
    padding: '6px 0 7px',
    background: 'white',
  },
  browseBtn: {
    width: 'calc(100% - 89%)',
    marginLeft: 25,
    color: 'white',
    height: 32,
    [theme.breakpoints.down('sm')]: {
      marginLeft: 15,
    },
  },
  imageText: {
    display: 'flex',
    flexDirection: 'column',
  },
  customPicker: {
    flex: 1,
    backgroundColor: 'white',
    border: 'none',
    '& label': {
      display: 'none',
    },
    '& div': {
      height: '32px',
      '& fieldset': {
        border: 0,
      },
    },
  },
}));

let bla: any[] = [];

const OnlineCheckInDriverList = ({
  driver,
  onEdited,
  onUpdating,
  editable = true,
  onRemoveExtraDriver,
}: IProps) => {
  const classes = useStyle();
  const [calendarOpen, setCalendarOpen] = useState(false);
  const fileRef = useRef<HTMLInputElement>(null);
  const [selectedFiles, setSelectedFiles] = useState<string[]>([]);
  const isFormValid = schema.isValidSync(driver);
  const [clicked, setClicked] = useState(false);
  const [clicked2, setClicked2] = useState(false);
  const notifierSuccess = useNotifier({ dismissable: true }, { variant: 'success' });
  const notifierWarning = useNotifier({ dismissable: true }, { variant: 'warning' });

  const { driverLicenceAge, driverLicenceMessage } = useSelector(selectOnlineCheckIn) || {
    driverLicenceAge: 20,
    driverLicenceMessage: '',
  };

  if (onUpdating !== undefined) {
    isFormValid ? onUpdating(true) : onUpdating(false);
  }

  const fileHandler = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  const fileUploadHandler = (event: any, formActions: FormikProps<IReservationDriver>) => {
    // if (event.target.files.length !== 2) {
    //   setSelectedFiles([]);
    //   notifier.notify('You have to select 2 images for driver license');
    //   formActions.setFieldTouched('images', true);
    //   return null;
    const files = event.target.files[0];

    if (bla.length === 2) {
      bla = [];
    }

    if (bla.length === 0) {
      bla.push(files);
      notifierSuccess.notify(
        'You have successfully uploaded front image. You can now upload the back licence photo.'
      );
      setClicked(true);
      const fileNames = bla.map((singleFile: File) => {
        return singleFile.name;
      });
      setSelectedFiles(() => fileNames);
      return null;
    } else {
      bla.push(files);
      const fileNames = bla.map((singleFile: File) => {
        return singleFile.name;
      });

      setClicked2(true);
      setSelectedFiles(() => fileNames);
      formActions.setFieldValue('images', bla);
      formActions.setFieldTouched('images', true);
    }
  };

  const removeExtraDriver = (id: number | undefined) => {
    if (id) {
      onRemoveExtraDriver(id);
    }
  };

  return (
    <Formik
      initialValues={driver}
      validationSchema={schema}
      isInitialValid={isFormValid}
      onSubmit={onSubmit}
      enableReinitialize
    >
      {form => {
        return (
          <Grid
            container
            justify="flex-end"
            style={{ backgroundColor: '#f5f5f5', marginBottom: 20, padding: 10, borderRadius: 10 }}
          >
            {driver !== null && driver.mainDriver !== null && !driver.mainDriver && editable && (
              <Grid style={{ marginBottom: '10px' }}>
                <Button
                  onClick={() => removeExtraDriver(driver.id)}
                  title="Remove extra driver"
                  style={{ fontSize: '13px', fontWeight: 'bold' }}
                >
                  X
                </Button>
              </Grid>
            )}
            <Grid item style={{ width: '100%', marginBottom: 10 }}>
              <Field name="name">
                {({ field }: FieldProps<IReservationDriver>) => (
                  <div className={classes.fieldContainer}>
                    <Typography className={classes.label} variant="body1">
                      Name*
                    </Typography>
                    <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                      <Input
                        readOnly={!editable}
                        className={cx(
                          classes.input,
                          form.errors.name ? classes.inputError : null,
                          !editable ? classes.disabled : null
                        )}
                        disableUnderline
                        {...field}
                      />
                      {getIn(form.errors, 'name') && getIn(form.touched, 'name') && (
                        <span style={{ color: '#f44336' }}>{form.errors.name}</span>
                      )}
                    </div>
                  </div>
                )}
              </Field>
            </Grid>
            <Grid item style={{ width: '100%', marginBottom: 10 }}>
              <Field name="licenseNumber">
                {({ field }: FieldProps<IReservationDriver>) => (
                  <div className={classes.fieldContainer}>
                    <Typography className={classes.label} variant="body1">
                      Licence Number*
                    </Typography>
                    <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                      <Input
                        readOnly={!editable}
                        className={cx(
                          classes.input,
                          form.errors.licenseNumber ? classes.inputError : null
                        )}
                        disableUnderline
                        {...field}
                      />
                      {getIn(form.errors, 'licenseNumber') &&
                        getIn(form.touched, 'licenseNumber') && (
                          <span style={{ color: '#f44336' }}>{form.errors.licenseNumber}</span>
                        )}
                    </div>
                  </div>
                )}
              </Field>
            </Grid>

            <Grid item style={{ width: '100%', marginBottom: 10 }}>
              <Field name="dateOfBirth">
                {({ field }: FieldProps<IReservationDriver>) => (
                  <div className={classes.fieldContainer}>
                    <Typography className={classes.label} variant="body1">
                      Date of Birth*
                    </Typography>
                    <div style={{ flex: '1', display: 'flex', flexDirection: 'column' }}>
                      <DatePicker
                        disabled={!editable}
                        open={calendarOpen}
                        onOpen={handleCalendarOpened(form)}
                        className={cx(
                          classes.customPicker,
                          form.errors.dateOfBirth ? classes.inputError : null
                        )}
                        onClose={handleCalendarClosed}
                        value={moment(field.value)}
                        onChange={handleDateOfBirthChanged(form)}
                        dateFormat={'MMM DD, YYYY'}
                        showToolbar
                        label={'Date of Birth'}
                      />
                      {getIn(form.errors, 'dateOfBirth') && getIn(form.touched, 'dateOfBirth') && (
                        <span style={{ color: '#f44336' }}>{form.errors.dateOfBirth}</span>
                      )}
                    </div>
                  </div>
                )}
              </Field>
            </Grid>
            <Grid item style={{ width: '100%' }}>
              <Field name="images">
                {({ field }: FieldProps<IReservationDriver>) => (
                  <div className={classes.fieldContainer}>
                    <Typography
                      className={classes.label}
                      variant="body1"
                      style={{
                        flex:
                          !field.value.DriverLicenseFront_S && !field.value.DriverLicenseBack_S
                            ? '2'
                            : '1',
                        alignSelf:
                          !field.value.DriverLicenseFront_S && !field.value.DriverLicenseBack_S
                            ? 'flex-start'
                            : 'center',
                      }}
                    >
                      Driver Licence Photos*{' '}
                      {!field.value.DriverLicenseFront_S &&
                        !field.value.DriverLicenseBack_S &&
                        '(Front and Back side)'}
                    </Typography>

                    {!field.value.DriverLicenseFront_S && !field.value.DriverLicenseBack_S ? (
                      <div style={{ display: 'flex', flexDirection: 'column', flex: '2' }}>
                        {selectedFiles && (
                          <div style={{ display: 'flex', flexDirection: 'column', flex: '1.04' }}>
                            <input
                              className={cx(
                                classes.inputText,
                                form.errors.images ? classes.inputError : null
                              )}
                              style={{ outline: 'none' }}
                              type="text"
                              readOnly
                              value={selectedFiles}
                            />
                            {getIn(form.errors, 'images') &&
                              (getIn(form.touched, 'images') && (
                                <span style={{ color: '#f44336' }}>{form.errors.images}</span>
                              ))}
                          </div>
                        )}
                        <div style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                          <span style={{ fontSize: '12px' }}>
                            Max size of photos shouldn't be larger then 10MB combined
                          </span>
                          <Tooltip title="Upload Front Licence Image (JPEG or PNG)">
                            <Button
                              disabled={!editable || clicked}
                              variant="contained"
                              color="primary"
                              className={classes.browseBtn}
                              style={{
                                width: 'calc(100% - 89%)',
                                marginLeft: 10,
                                color: 'white',
                                height: 32,
                              }}
                              onClick={fileHandler}
                            >
                              Browse
                            </Button>
                          </Tooltip>

                          <Tooltip title="Upload Back Licence Image (JPEG or PNG)">
                            <Button
                              disabled={!editable || clicked2}
                              variant="contained"
                              color="primary"
                              className={classes.browseBtn}
                              style={{
                                width: 'calc(100% - 89%)',
                                marginLeft: 10,
                                color: 'white',
                                height: 32,
                              }}
                              onClick={fileHandler}
                            >
                              Browse
                            </Button>
                          </Tooltip>

                          <input
                            accept="image/jpeg, image/png"
                            ref={fileRef}
                            type="file"
                            onChange={e => fileUploadHandler(e, form)}
                            style={{ display: 'none' }}
                          />
                        </div>
                      </div>
                    ) : (
                      <div style={{ flex: 1 }}>
                        <img
                          src={field.value.DriverLicenseFront_S}
                          alt="front"
                          style={{ marginRight: 10 }}
                        />
                        <img src={field.value.DriverLicenseBack_S} alt="back" />
                      </div>
                    )}
                  </div>
                )}
              </Field>
            </Grid>
            {!!editable && (
              <Button
                variant="contained"
                disabled={form.isSubmitting || !form.dirty || !form.isValid || !form.errors}
                style={{
                  marginTop: 15,
                  backgroundColor:
                    !form.isValid || !form.dirty || form.isSubmitting ? 'lightgray' : '#45C461',
                  cursor:
                    !form.isValid || !form.dirty || form.isSubmitting ? 'not-allowed' : 'pointer',
                  color: 'white',
                }}
                onClick={() => form.submitForm()}
              >
                {form.isSubmitting ? <CircularProgress size={24} color="inherit" /> : 'Save'}
              </Button>
            )}
          </Grid>
        );
      }}
    </Formik>
  );

  async function onSubmit(data: IReservationDriver, formik: FormikActions<IReservationDriver>) {
    try {
      await onEdited(data);
      onUpdating(true);
    } catch (error) {
      console.error('An error occured while saving the data');
      onUpdating(false);
    }
  }

  function handleCalendarOpened(formActions: FormikProps<IReservationDriver>) {
    return () => {
      setCalendarOpen(true);
      formActions.setFieldTouched('dateOfBirth', true);
    };
  }

  function handleCalendarClosed() {
    setCalendarOpen(false);
  }

  function handleDateOfBirthChanged(formActions: FormikProps<IReservationDriver>) {
    return (momentValue: Moment | null) => {
      if (momentValue !== null) {
        if (moment().diff(moment(momentValue), 'years') < driverLicenceAge) {
          // alert(driverLicenceMessage);
          notifierWarning.notify(driverLicenceMessage);
        }
        formActions.setFieldValue(
          'dateOfBirth',
          momentValue
            .utc()
            .startOf('day')
            .format()
        );
      }
    };
  }
};

export default OnlineCheckInDriverList;

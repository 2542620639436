import createReducer from '../createReducer';
import createTheme, { createDefaultTheme } from '../../utils/theme';
import { TThemeState } from './types';
import { TENANT_RESOLVE } from './../tenant/actions';

const initialState: TThemeState = createDefaultTheme();

const themeReducer = createReducer(initialState, {
  [TENANT_RESOLVE]: (_, action) => createTheme(action.payload),
});

export default themeReducer;

import React from 'react';
import env from '../env';

const HiddenEnvironmentInfo: React.FC = () => (
  <div style={{ display: 'none' }}>
    <span>build type {env.nodeEnv}</span>
    <span>env {env.appEnv}</span>
  </div>
);

export default HiddenEnvironmentInfo;

import React from 'react';
import useAuthActions from '../hooks/useAuthActions';
import useAuthState from '../hooks/useAuthState';
import useMount from '../hooks/useMount';
import { useHistory } from 'react-router-dom';

export default function DirectLogin() {
  const { login } = useAuthActions();
  const { user } = useAuthState();
  const history = useHistory();

  useMount(() => {
    if (user) {
      history.replace('/dashboard');
    }
    if (!user) {
      login();
    }
  });

  return <></>;
}

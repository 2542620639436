import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface IProps {
  title?: string;
  items: Array<{
    label: string;
    value: string;
  }>;
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(2, 3),
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 10,
  },
  title: {
    marginBottom: theme.spacing(1),
  },
  item: {
    marginTop: theme.spacing(1),
  },
}));

const InfoCard: React.FC<IProps> = ({ title, items }) => {
  const classes = useStyles();

  return (
    <div className={classes.container}>
      {title && (
        <Typography variant="h5" className={classes.title}>
          {title}
        </Typography>
      )}
      <Grid container>
        {items.map((item, index) => (
          <Grid container justify="space-between" key={index} className={classes.item}>
            <Typography>{item.label}</Typography>
            <Typography>{item.value}</Typography>
          </Grid>
        ))}
      </Grid>
    </div>
  );
};

export default InfoCard;

import { ActionType, getType } from 'typesafe-actions';
import { put, takeLatest, call } from 'redux-saga/effects';

import * as A from './actions';
import { getApiService } from '../sagaUtils';
import { captureException } from '../../sentry';

function* searchPlaces(action: ActionType<typeof A.searchPlaces>) {
  const api = yield getApiService();

  try {
    const places = yield call(() => api.searchForPlaces(action.payload));
    yield put(A.searchPlacesSuccess(places));
  } catch (error) {
    yield put(A.searchPlacesError(error));
    captureException(error);
  }
}

function* getMorePlaces(action: ActionType<typeof A.getMorePlaces>) {
  const api = yield getApiService();
  const { query, from } = action.payload;

  try {
    const places = yield call(() => api.searchForPlaces(query, from));
    yield put(A.getMorePlacesSuccess(places));
  } catch (error) {
    yield put(A.getMorePlacesError(error));
    captureException(error);
  }
}

const sagas = [
  takeLatest(getType(A.searchPlaces), searchPlaces),
  takeLatest(getType(A.getMorePlaces), getMorePlaces),
];

export default sagas;

import React from 'react';
import { useSelector } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Skeleton from 'react-skeleton-loader';
import Typography from '@material-ui/core/Typography';

import IReservationLite from '../interfaces/IReservationLite';
import { selectUserEmail } from '../store/selectors';
import ReservationCard from '../components/ReservationCard';
import Section from '../components/Section';

interface IProps {
  reservations: IReservationLite[];
  title?: string;
  loading?: boolean;
  error?: string;
  link?: string;
  linkText?: string;
  className?: string;
}

const useStyles = makeStyles(theme => ({
  reservationItem: {
    width: '100%',
  },
  emptyText: {
    fontSize: 18,
    marginBottom: theme.spacing(4),
  },
}));

const ReservationList: React.FC<IProps> = ({
  reservations,
  title,
  loading,
  error,
  link,
  linkText,
  className,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const userEmail = useSelector(selectUserEmail);

  if (loading) {
    return renderContent(
      <Grid container spacing={4} justify="flex-start">
        {renderSkeletonItem()}
        {renderSkeletonItem()}
      </Grid>
    );
  }

  if (error) {
    return renderContent(
      <Typography variant="h6" color="textSecondary">
        Error: {error}
      </Typography>
    );
  }

  if (reservations.length === 0) {
    return renderContent(
      <Typography className={classes.emptyText} color="textSecondary">
        No bookings available
      </Typography>
    );
  }

  return renderContent(
    <Grid container spacing={4} justify="flex-start">
      {reservations.map((reservation, index) => (
        <Grid key={index} item sm={12} md={6} className={classes.reservationItem}>
          <ReservationCard
            reservation={reservation}
            userEmail={userEmail}
            status={reservation.status}
          />
        </Grid>
      ))}
    </Grid>
  );

  function renderContent(content: React.ReactNode) {
    return (
      <Section
        title={title}
        loading={loading}
        link={link}
        linkText={linkText}
        className={className}
      >
        {content}
      </Section>
    );
  }

  function renderSkeletonItem() {
    return (
      <Grid item sm={12} md={6} className={classes.reservationItem}>
        <Skeleton
          color={theme.customPalette.skeleton}
          height="250px"
          width="100%"
          widthRandomness={0}
          heightRandomness={0}
        />
      </Grid>
    );
  }
};

export default ReservationList;

import React, { useEffect } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

const ScrollToTop: React.FC<RouteComponentProps> = ({ location, history }) => {
  useEffect(() => {
    if (history.action === 'PUSH') {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, history.action]);
  return null;
};

export default withRouter(ScrollToTop);

import React from 'react';
import cx from 'classnames';
import Grid from '@material-ui/core/Grid';
import Arrow from '@material-ui/icons/ArrowForward';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import ReservationDateCard, { IProps as IReservationCardProps } from './ReservationDateCard';

interface IProps {
  from: IReservationCardProps;
  to: IReservationCardProps;
  loading?: boolean;
  hideLabels?: boolean;
}

const arrowSize = 55;
const useStyles = makeStyles(theme => ({
  container: {
    flexWrap: 'nowrap',
    marginBottom: 50,
  },
  card: {
    flex: 1,
    marginRight: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      margin: 0,
      width: '85%',
    },
  },
  cardRight: {
    marginRight: 0,
    marginLeft: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      alignSelf: 'flex-end',
    },
  },
  arrowContainer: {
    position: 'relative',
  },
  arrowCircle: {
    zIndex: 1,
    position: 'absolute',
    top: '50%',
    left: `-${arrowSize / 2}px`,
    transform: 'translate(0,-20%)',
    width: arrowSize,
    height: arrowSize,
    backgroundColor: theme.palette.primary.main,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: arrowSize / 2,
    [theme.breakpoints.down('xs')]: {
      left: `calc(15% -  ${arrowSize / 2}px)`,
    },
  },
  arrow: {
    color: 'white',
  },
}));

const ReservationDateRange: React.FC<IProps> = ({ from, to, loading, hideLabels }) => {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <Grid container justify="center" className={classes.container}>
      <Grid item className={classes.card}>
        <ReservationDateCard
          {...from}
          alignLabel={xs ? 'left' : 'center'}
          loading={loading}
          hideLabel={hideLabels}
        />
      </Grid>
      <div className={classes.arrowContainer}>
        <div className={classes.arrowCircle}>
          <Arrow fontSize="large" className={classes.arrow} />
        </div>
      </div>
      <Grid item className={cx(classes.card, classes.cardRight)}>
        <ReservationDateCard
          {...to}
          alignLabel={xs ? 'right' : 'center'}
          loading={loading}
          hideLabel={hideLabels}
        />
      </Grid>
    </Grid>
  );
};

export default ReservationDateRange;

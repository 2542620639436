import React from 'react';
import { useSelector } from 'react-redux';
import useAuthState from '../hooks/useAuthState';
import { selectTenantFetched, selectTenantError } from '../store/selectors';
import FullPageLoader from '../components/FullPageLoader';

function withSplashScreen<P extends object>(WrappedComponent: React.ComponentType<P>) {
  const WithSplash: React.FC<P> = props => {
    const authState = useAuthState();
    const { tenantFetched, tenantError } = useSelector((state: any) => ({
      tenantFetched: selectTenantFetched(state),
      tenantError: selectTenantError(state),
    }));

    if (tenantError) {
      return <h1>Tenant error</h1>;
    }

    return !tenantFetched || !authState.isReady ? (
      <FullPageLoader />
    ) : (
      <WrappedComponent {...props} />
    );
  };

  return WithSplash;
}

export default withSplashScreen;

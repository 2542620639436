import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useState, useLayoutEffect } from 'react';

interface IProps {
  updateError: (unitId: number | null, damage: any) => void;
  unitId: number | null;
  damageErrors: any[];
  addDamage: (e: any, partOfCar: string) => void;
  setIsOpen: (open: boolean) => void;
  setPartOfCar: (partOfCar: any) => void;
  blueprint: string;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    maxWidth: '100%',
    width: '100%',
  },
  mobileImage: {
    width: '390px',
    height: '207px',
  },
  webImage: {
    width: '779px',
    height: '413px',
  },
  circle: {
    opacity: 1,
    pointerEvents: 'none',
    position: 'absolute',
    width: '100%',
    height: '100%',
    '&:hover': {
      pointerEvents: 'click',
    },
  },
  circleChild: {
    pointerEvents: 'auto',
    '&:hover': {
      cursor: 'pointer',
      fill: 'green !important',
      [theme.breakpoints.down('xs')]: {
        r: '12',
      },
      r: '16',
    },
  },
  test22: {
    '& area': {
      cursor: 'pointer',
      '&:hover + $svg': {
        opacity: 1,
      },
    },
  },
  test: {
    opacity: 0,
    pointerEvents: 'none',
    position: 'absolute',
    width: '1000px',
    height: '1000px',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  colorClass: {
    fill: theme.palette.primary.main + '80',
    stroke: theme.palette.primary.main,
    strokeWidth: 2,
    strokeDasharray: 7,
  },
}));

const BluePrintSV = ({
  unitId,
  damageErrors,
  updateError,
  addDamage,
  setIsOpen,
  setPartOfCar,
  blueprint,
}: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const responsiveAreas: any[] = [];

  const originalCoordinates = () => {
    const areas: any = document.getElementsByTagName('area');

    for (const area of areas) {
      responsiveAreas.push({ element: area, originalCords: area.coords.split(',') });
    }
  };

  const isMobile = () => {
    if (window.outerWidth < 768 && responsiveAreas.length === 0) {
      originalCoordinates();
      resize();
    }
  };

  const resize = () => {
    const ratio = 0.5;
    for (const area of responsiveAreas) {
      const newCoords = [];
      for (const originalCoord of area.originalCords) {
        newCoords.push(Math.round(originalCoord * ratio));
      }
      area.element.coords = newCoords.join(',');
    }

    return true;
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', isMobile);
    window.addEventListener('load', isMobile);
    isMobile();

    return () => {
      window.removeEventListener('resize', isMobile);
      window.removeEventListener('load', isMobile);
    };
  }, []);

  return (
    <div className={classes.container}>
      <map id="map" className={classes.test22} name="image-map">
        {damageErrors.map((damage: any) => (
          <svg key={damage.id} id="circle" className={classes.circle}>
            <g>
              <circle
                id={`circle${damage.customId}`}
                className={classes.circleChild}
                onClick={() => updateError(unitId, damage)}
                cx={damage.xCoordinate}
                cy={damage.yCoordinate}
                r={xs === true ? 10 : 12}
                fill={damage.isAddedByCustomer ? '#FFA500' : '#D2042D'}
              />
              <text
                style={xs ? { fontSize: '11px' } : undefined}
                x={damage.xCoordinate}
                y={damage.yCoordinate}
                stroke="white"
                strokeWidth="1"
                textAnchor="middle"
                alignmentBaseline="middle"
              >
                {damage.customId}
              </text>
              <title>{damage.description}</title>
            </g>
          </svg>
        ))}
        <area
          alt="area"
          title="Front part"
          onClick={e => {
            addDamage(e, 'Front Part');
          }}
          onTouchStart={e => {
            addDamage(e, 'Front Part');
          }}
          coords="30,110,87,94,132,95,133,163,117,169,97,183,78,175,54,162,28,156,24,132"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M30 110 L 87 94 L 132 95 L 133 163 L 117 169 L 97 183 L 78 175 L 54 162 L 28 156 L 24 132Z"
          />
        </svg>

        <area
          alt="area"
          title="Front door"
          onClick={e => {
            addDamage(e, 'Front Door');
          }}
          onTouchStart={e => {
            addDamage(e, 'Front Door');
          }}
          coords="133,92,182,66,217,64,213,162,133,163"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path className={classes.colorClass} d="M133 92 L 182 66 L 217 64 L 213 162 L 133 163Z" />
        </svg>

        <area
          alt="area"
          title="Back door"
          onClick={e => {
            addDamage(e, 'Back Door');
          }}
          onTouchStart={e => {
            addDamage(e, 'Back Door');
          }}
          coords="212,162,275,161,295,120,292,85,277,64,220,63"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 212 162 L 275 161 L 295 120 L 292 85 L 277 64 L 220 63Z"
          />
        </svg>

        <area
          alt="area"
          title="Back Part"
          onClick={e => {
            addDamage(e, 'Back Part');
          }}
          onTouchStart={e => {
            addDamage(e, 'Back Part');
          }}
          coords="281,66,343,70,365,95,365,121,373,127,366,154,336,158,320,183,294,183,278,165,288,137,294,133,296,112"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 281 66 L 343 70 L 365 95 L 365 121 L 373 127 L 366 154 L 336 158 L 320 183 L 294 183 L 278 165 L 288 137 L 294 133 L 296 112Z"
          />
        </svg>

        <area
          alt="area"
          title="Front view"
          onClick={e => {
            addDamage(e, 'Front view');
          }}
          onTouchStart={e => {
            addDamage(e, 'Front view');
          }}
          coords="418,183,438,181,439,160,538,160,539,182,563,183,562,153,560,109,542,59,439,58,414,109"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M418 183 L 438 181 L 439 160 L 538 160 L 539 182 L 563 183 L 562 153 L 560 109 L 542 59 L 439 58 L 414 109Z"
          />
        </svg>

        <area
          alt="area"
          title="Top view"
          onClick={e => {
            addDamage(e, 'Top View');
          }}
          onTouchStart={e => {
            addDamage(e, 'Top View');
          }}
          coords="614,370,609,249,609,126,610,77,651,42,716,44,755,74,756,157,755,267,754,362,728,386,663,390,637,385"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 614 370 L 609 249 L 609 126 L 610 77 L 651 42 L 716 44 L 755 74 L 756 157 L 755 267 L 754 362 L 728 386 L 663 390 L 637,385Z"
          />
        </svg>

        <area
          alt="area"
          title="Back view"
          onClick={e => {
            addDamage(e, 'Back View');
          }}
          onTouchStart={e => {
            addDamage(e, 'Back View');
          }}
          coords="417,368,436,367,439,347,537,348,540,370,562,369,562,292,545,249,533,242,489,242,438,242,415,285"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 417 368 L 436 367 L 439 347 L 537 348 L 540 370 L 562 369 L 562 292 L 545 249 L 533 242 L 489 242 L 438 242 L 415 285Z"
          />
        </svg>

        <area
          alt="area"
          title="Front part"
          coords="272,351,277,288,298,284,314,283,372,299,380,322,378,345,341,350,324,370,298,366"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Front Part');
          }}
          onTouchStart={e => {
            addDamage(e, 'Front Part');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 272 351 L 277 288 L 298 284 L 314 283 L 372 299 L 380 322 L 378 345 L341 350 L 324 370 L 298 366Z"
          />
        </svg>
        <area
          alt=""
          title="Front door"
          coords="191,350,187,250,229,256,276,286,271,351"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Front Door');
          }}
          onTouchStart={e => {
            addDamage(e, 'Front Door');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 191 350 L 187 250 L 229 256 L 276 286 L 271 351Z"
          />
        </svg>
        <area
          alt=""
          title="Back door"
          coords="190,348,128,347,125,315,110,307,112,275,126,252,185,250"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Back Door');
          }}
          onTouchStart={e => {
            addDamage(e, 'Back Door');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 190 348 L 128 347 L 125 315 L 110 307 L 112 275 L 126 252 L 185 250Z"
          />
        </svg>
        <area
          alt=""
          title="Back Part"
          coords="42,342,33,325,33,310,40,278,61,254,126,252,110,307,127,347,120,362,98,370,80,362,70,343"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Back Part');
          }}
          onTouchStart={e => {
            addDamage(e, 'Back Part');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 42 342 L 33 325 L 33 310 L 40 278 L 61 254 L 126 252 L 110 307 L 127 347 L 120 362 L 98 370 L 80 362 L 70 343Z"
          />
        </svg>
      </map>

      <img
        id="img"
        className={xs ? classes.mobileImage : classes.webImage}
        src={blueprint}
        alt="mising"
        useMap="#image-map"
      />
    </div>
  );
};

export default BluePrintSV;

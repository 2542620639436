import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import {
  extendReservation,
  fetchExtensionPrice,
  clearExtensionPrice,
  updateSelfService,
} from '../store/reservations/actions';
import {
  getReservationExtensionState,
  getLoadedReservationGuid,
  getLoadedReservationPrices,
  getLoadingStates,
  selectOnlineCheckIn,
  selectGracePeriods,
} from '../store/selectors';

import ReservationDateRange from './ReservationDateRange';
import DateTimePicker from './DateTimePicker';
import { IProps as IReservationCardProps } from './ReservationDateCard';
import { TIME_FORMAT, DATE_AND_TIME_FORMAT } from '../constants';
import formatPrice from '../utils/priceFormatter';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';

interface IProps {
  initialTo: IReservationCardProps;
  initialFrom: IReservationCardProps;
  initialPickup: boolean;
  initialDropOff: boolean;
  onUpdating: (change: boolean) => void;
}

const useStyles = makeStyles(theme => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(4, 0, 2, 0),
    },
  },
  difference: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  error: {
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.error.main,
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  prices: {
    marginTop: theme.spacing(6),
  },
  priceLine: {
    padding: `2px 0px`,
    textTransform: 'uppercase',
  },
  bold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  totalPrice: {
    margin: theme.spacing(1, 0),
    paddingBottom: 4,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    textTransform: 'uppercase',
  },
  btnContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: '6.3rem',
  },
  confirmBtn: {
    alignSelf: 'flex-end',
    backgroundColor: '#45C461',
    color: 'white',
    '&:hover': {
      backgroundColor: '#45C461',
      color: 'white',
    },
  },
  discardBtn: {
    alignSelf: 'flex-end',
    fontWeight: 'bold',
    marginRight: 10,
  },
}));

const OnlineCheckInExtendReservation = ({
  initialTo,
  initialFrom,
  onUpdating,
  initialPickup,
  initialDropOff,
}: IProps) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [fromDate, setFromDate] = useState<Moment>(initialFrom.date || moment.utc());
  const [toDate, setToDate] = useState<Moment>(initialTo.date || moment.utc().add(1, 'day'));
  const extension = useSelector(getReservationExtensionState);
  const reservationGuid = useSelector(getLoadedReservationGuid);
  const reservationPrices = useSelector(getLoadedReservationPrices);
  const { isFetchingExtensionPrice, isExtending, isExtendingSuccess } = useSelector(
    getLoadingStates
  );
  const { timeLimitStart, timeLimitEnd } = useSelector(selectGracePeriods) || {
    timeLimitStart: null,
    timeLimitEnd: null,
  };
  const fromDiff = initialFrom.date && fromDate ? initialFrom.date.diff(fromDate, 'minutes') : 0;
  const toDiff = initialTo.date && toDate ? toDate.diff(initialTo.date, 'minutes') : 0;
  const hasError = extension.errorMessage !== null;
  const [pickUp, setPickUp] = useState(initialPickup);
  const [dropOff, setDropOff] = useState(initialDropOff);
  const [isClicked, setIsClicked] = useState(false);

  useEffect(() => {
    if (isExtendingSuccess && isClicked) {
      setIsClicked(false);
      onUpdating(true);
    }
  }, [isExtendingSuccess, onUpdating, isClicked]);

  useEffect(() => {
    if (fromDiff === 0 && toDiff === 0 && !isClicked) {
      onUpdating(true);
    }
  }, [onUpdating, fromDiff, toDiff, isClicked]);

  const changePickUp = () => {
    setPickUp(last => !last);
    setIsClicked(true);
    onUpdating(false);
  };

  const changeDropOff = () => {
    setDropOff(last => !last);
    setIsClicked(true);
    onUpdating(false);
  };

  const updateExtensionRange = (extendFrom: Moment, extendTo: Moment) => {
    if (!reservationGuid) {
      return;
    }

    setFromDate(extendFrom);
    setToDate(extendTo);
    onUpdating(false);

    if (
      initialFrom.date &&
      initialFrom.date.isSame(extendFrom) &&
      initialTo.date &&
      initialTo.date.isSame(extendTo)
    ) {
      dispatch(clearExtensionPrice());
      onUpdating(true);
      return;
    }

    dispatch(
      fetchExtensionPrice(reservationGuid, extendFrom.utc().format(), extendTo.utc().format())
    );
  };

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        <ReservationDateRange from={{ ...initialFrom }} to={{ ...initialTo }} hideLabels />
        <Grid container spacing={4}>
          <DateTimePicker
            label="New Pick-Up"
            value={fromDate || moment()}
            disabled={isFetchingExtensionPrice}
            maxDate={undefined}
            timeOptions={getTimeOptions(fromDate, {}, { start: timeLimitStart, end: timeLimitEnd })}
            onChange={val => updateExtensionRange(val, toDate)}
          />
          <DateTimePicker
            label="New Drop-Off"
            value={toDate || moment()}
            disabled={isFetchingExtensionPrice}
            minDate={undefined}
            timeOptions={getTimeOptions(toDate, {}, { start: timeLimitStart, end: timeLimitEnd })}
            onChange={val => updateExtensionRange(fromDate, val)}
          />
        </Grid>
        <Grid container className={classes.difference}>
          {hasError ? (
            <Typography className={classes.error}>
              Not available: {extension.errorMessage}
            </Typography>
          ) : (
            <React.Fragment>
              {renderDifference(fromDiff, initialFrom.date, fromDate, 'before')}
              {renderDifference(toDiff, initialTo.date, toDate, 'after')}
            </React.Fragment>
          )}
        </Grid>
        <Grid
          style={{ margin: '20px 0 0 0', display: 'flex', flexDirection: 'column' }}
          container
          spacing={4}
        >
          <div>
            <Typography variant="body2">
              The Self Check-in process is designed for your convenience, you can finish the self
              check-in process to speed up the pick-up process at the counter, or opt to pickup the
              keys from a keybox and skip the queue.
            </Typography>
          </div>

          <div style={{ marginTop: '15px' }}>
            <input
              style={{ cursor: 'pointer', transform: 'scale(1.5)', marginRight: 10 }}
              id="pickUp"
              checked={pickUp}
              type="checkbox"
              onChange={changePickUp}
            />
            <label style={{ fontSize: '17px' }} htmlFor="pickUp">
              Pick-Up
            </label>
          </div>
          <div>
            <input
              style={{ cursor: 'pointer', transform: 'scale(1.5)', marginRight: 10 }}
              id="dropOff"
              checked={dropOff}
              type="checkbox"
              onChange={changeDropOff}
            />
            <label style={{ fontSize: '17px' }} htmlFor="dropOff">
              Drop-Off
            </label>
          </div>
        </Grid>
        {renderPrice()}
        {(fromDiff !== 0 || toDiff !== 0 || isClicked) && (
          <div className={classes.btnContainer}>
            <Button
              className={classes.discardBtn}
              variant="outlined"
              disabled={hasError || isFetchingExtensionPrice || isExtending}
              onClick={discardChanges}
            >
              Discard changes
            </Button>

            <Button
              className={classes.confirmBtn}
              variant="contained"
              disabled={hasError || isFetchingExtensionPrice || isExtending}
              onClick={confirmExtension}
            >
              {isExtending && <CircularProgress size={24} color="inherit" />}
              <span style={isExtending ? { marginLeft: '0.5rem' } : {}}>
                {isClicked ? 'Update Booking' : 'Extend Booking'}
              </span>
            </Button>
          </div>
        )}
      </div>
    </div>
  );

  function getTimeOptions(
    date: Moment | null,
    boundaries: { minDate?: Moment | null; maxDate?: Moment | null },
    limit: { start?: number | null; end?: number | null }
  ) {
    if (!date) {
      return [];
    }

    const timeOptions = [];
    const minHour = limit.start || 0;
    const maxHour = limit.end || 23;
    const { minDate, maxDate } = boundaries;

    // Temporary solution because Campeasy has no registered times
    for (let i = minHour; i <= maxHour; i++) {
      const timeOpt = moment(date)
        .set('hours', i)
        .set('minutes', 0);

      if (
        (!minDate || timeOpt.isSameOrAfter(minDate)) &&
        (!maxDate || timeOpt.isSameOrBefore(maxDate))
      ) {
        timeOptions.push({
          label: timeOpt.format(TIME_FORMAT),
          value: timeOpt.format(DATE_AND_TIME_FORMAT),
        });
      }

      if (i < maxHour) {
        timeOpt.set('minutes', 30);
        if (
          (!minDate || timeOpt.isSameOrAfter(minDate)) &&
          (!maxDate || timeOpt.isSameOrBefore(maxDate))
        ) {
          timeOptions.push({
            label: timeOpt.format(TIME_FORMAT),
            value: timeOpt.format(DATE_AND_TIME_FORMAT),
          });
        }
      }
    }

    return timeOptions;
  }

  function renderDifference(
    diff: number,
    currentDate: Moment | null,
    newDate: Moment | null,
    when: 'before' | 'after'
  ) {
    let days = 0;

    if (currentDate && newDate && !currentDate.isSame(newDate, 'day')) {
      const dayInMinutes = 24 * 60;
      days += Math.floor(diff / dayInMinutes);

      const addBefore =
        when === 'before' &&
        !moment(currentDate)
          .subtract(days, 'days')
          .isSame(newDate, 'day');

      const addAfter =
        when === 'after' &&
        !moment(currentDate)
          .add(days, 'days')
          .isSame(newDate, 'day');

      if (addBefore || addAfter) {
        days++;
      }
    }

    const visible = !!diff && !isFetchingExtensionPrice;
    const dayStr = days === 1 ? 'day' : 'days';
    const type = when === 'before' ? 'Pickup' : 'Dropoff';

    return (
      <Grid item xs={12} sm={6}>
        {/* {visible &&
          (!!days ? (
            <div>
              <Typography display="inline">
                {days} {dayStr} added{' '}
              </Typography>
              <Typography display="inline" className={classes.bold}>
                {when}
              </Typography>
            </div>
          ) : (
            <Typography display="inline">{type} time changed</Typography>
          ))} */}
        {visible && <Typography display="inline">{type} time changed</Typography>}
      </Grid>
    );
  }

  function renderPrice() {
    if (!reservationPrices) {
      return null;
    }

    const { totalPrice, currency, balance } = reservationPrices;
    const addedPrice = extension ? extension.totalExtensionPrice : 0;

    return (
      <div className={classes.prices}>
        <Grid container justify="space-between" className={classes.priceLine}>
          <Typography>Original price</Typography>
          <Typography>{formatPrice(totalPrice, currency)}</Typography>
        </Grid>
        <Grid container justify="space-between" className={classes.priceLine}>
          <Typography>Price for extension</Typography>
          <Typography>{formatPrice(addedPrice, currency)}</Typography>
        </Grid>
        <Grid container justify="space-between" className={classes.totalPrice}>
          <Typography variant="h5">Total price</Typography>
          <Typography variant="h5">{formatPrice(totalPrice + addedPrice, currency)}</Typography>
        </Grid>
        <Grid container justify="space-between" className={classes.priceLine}>
          <Typography className={classes.bold}>Balance</Typography>
          <Typography className={classes.bold}>
            {formatPrice(balance + addedPrice, currency)}
          </Typography>
        </Grid>
      </div>
    );
  }

  function confirmExtension() {
    if (reservationGuid && fromDate && toDate && (fromDiff || toDiff) && isClicked) {
      dispatch(extendReservation(reservationGuid, fromDate.utc().format(), toDate.utc().format()));
      dispatch(updateSelfService(reservationGuid, pickUp, dropOff, false));
      return;
    }
    if (reservationGuid && fromDate && toDate && !isClicked && (fromDiff || toDiff)) {
      dispatch(extendReservation(reservationGuid, fromDate.utc().format(), toDate.utc().format()));
      return;
    }
    if (reservationGuid && isClicked && (fromDiff === 0 && toDiff === 0)) {
      dispatch(updateSelfService(reservationGuid, pickUp, dropOff, true));
      return;
    }
  }

  function discardChanges() {
    setFromDate(initialFrom.date || moment.utc());
    setToDate(initialTo.date || moment.utc());
    setPickUp(initialPickup);
    setDropOff(initialDropOff);
    onUpdating(true);
    setIsClicked(false);
  }
};

export default OnlineCheckInExtendReservation;

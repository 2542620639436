import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment, { Moment } from 'moment';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import {
  extendReservation,
  fetchExtensionPrice,
  clearExtensionPrice,
  closeExtendReservationDialog,
} from '../store/reservations/actions';
import {
  getReservationExtensionState,
  getLoadedReservationGuid,
  getLoadedReservationPrices,
  getLoadingStates,
  selectOnlineCheckIn,
  selectGracePeriods,
} from '../store/selectors';

import ReservationDateRange from './ReservationDateRange';
import DateTimePicker from './DateTimePicker';
import { IProps as IReservationCardProps } from './ReservationDateCard';
import { TIME_FORMAT, DATE_AND_TIME_FORMAT } from '../constants';
import formatPrice from '../utils/priceFormatter';
import useTheme from '../hooks/useTheme';

interface IProps {
  initialTo: IReservationCardProps;
  initialFrom: IReservationCardProps;
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4, 6),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
    },
  },
  content: {
    margin: theme.spacing(6, 8, 2, 8),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(4, 0, 2, 0),
    },
  },
  difference: {
    textAlign: 'center',
    marginTop: theme.spacing(2),
  },
  error: {
    width: '100%',
    fontWeight: theme.typography.fontWeightMedium,
    color: theme.palette.error.main,
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  prices: {
    marginTop: theme.spacing(6),
  },
  priceLine: {
    padding: `2px 0px`,
    textTransform: 'uppercase',
  },
  bold: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  totalPrice: {
    margin: theme.spacing(1, 0),
    paddingBottom: 4,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
    textTransform: 'uppercase',
  },
}));

const ExtendReservationDialog: React.FC<IProps> = ({ initialTo, initialFrom }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const [fromDate, setFromDate] = useState<Moment>(initialFrom.date || moment.utc());
  const [toDate, setToDate] = useState<Moment>(initialTo.date || moment.utc().add(1, 'day'));
  const extension = useSelector(getReservationExtensionState);
  const reservationGuid = useSelector(getLoadedReservationGuid);
  const reservationPrices = useSelector(getLoadedReservationPrices);
  const { isFetchingExtensionPrice, isExtending } = useSelector(getLoadingStates);
  const { timeLimitStart, timeLimitEnd } = useSelector(selectGracePeriods) || {
    timeLimitStart: null,
    timeLimitEnd: null,
  };

  const fromDiff = initialFrom.date && fromDate ? initialFrom.date.diff(fromDate, 'minutes') : 0;
  const toDiff = initialTo.date && toDate ? toDate.diff(initialTo.date, 'minutes') : 0;

  const hasError = extension.errorMessage !== null;

  return (
    <Dialog
      open={extension.isDialogOpen}
      onClose={quitExtensionDialog}
      maxWidth="md"
      fullWidth
      fullScreen={xs}
    >
      <DialogContent className={classes.container} dividers>
        <Typography
          style={{ display: 'flex', justifyContent: 'center', fontSize: xs ? '0.73rem' : '1.5rem' }}
          variant="h5"
        >
          Extend your booking with earlier pick-up or later drop-off
        </Typography>
        <div className={classes.content}>
          <ReservationDateRange from={{ ...initialFrom }} to={{ ...initialTo }} hideLabels />
          <Grid container spacing={4}>
            <DateTimePicker
              label="New pickup"
              value={fromDate || moment()}
              disabled={isFetchingExtensionPrice}
              maxDate={undefined}
              timeOptions={getTimeOptions(
                fromDate,
                {},
                { start: timeLimitStart, end: timeLimitEnd }
              )}
              onChange={val => updateExtensionRange(val, toDate)}
            />
            <DateTimePicker
              label="New dropoff"
              value={toDate || moment()}
              disabled={isFetchingExtensionPrice}
              minDate={undefined}
              timeOptions={getTimeOptions(toDate, {}, { start: timeLimitStart, end: timeLimitEnd })}
              onChange={val => updateExtensionRange(fromDate, val)}
            />
          </Grid>
          <Grid container className={classes.difference}>
            {hasError ? (
              <Typography className={classes.error}>
                Not available: {extension.errorMessage}
              </Typography>
            ) : (
              <React.Fragment>
                {renderDifference(fromDiff, initialFrom.date, fromDate, 'before')}
                {renderDifference(toDiff, initialTo.date, toDate, 'after')}
              </React.Fragment>
            )}
          </Grid>
          {renderPrice()}
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={quitExtensionDialog}
          className={classes.cancelButton}
          disabled={isFetchingExtensionPrice || isExtending}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={hasError || isFetchingExtensionPrice || isExtending}
          onClick={confirmExtension}
        >
          {isExtending ? <CircularProgress size={24} color="inherit" /> : 'Confirm'}
        </Button>
      </DialogActions>
    </Dialog>
  );

  function getTimeOptions(
    date: Moment | null,
    boundaries: { minDate?: Moment | null; maxDate?: Moment | null },
    limit: { start?: number | null; end?: number | null }
  ) {
    if (!date) {
      return [];
    }

    const timeOptions = [];
    const minHour = limit.start || 0;
    const maxHour = limit.end || 23;
    const { minDate, maxDate } = boundaries;

    // Temporary solution because Campeasy has no registered times
    for (let i = minHour; i <= maxHour; i++) {
      const timeOpt = moment(date)
        .set('hours', i)
        .set('minutes', 0);

      if (
        (!minDate || timeOpt.isSameOrAfter(minDate)) &&
        (!maxDate || timeOpt.isSameOrBefore(maxDate))
      ) {
        timeOptions.push({
          label: timeOpt.format(TIME_FORMAT),
          value: timeOpt.format(DATE_AND_TIME_FORMAT),
        });
      }

      if (i < maxHour) {
        timeOpt.set('minutes', 30);
        if (
          (!minDate || timeOpt.isSameOrAfter(minDate)) &&
          (!maxDate || timeOpt.isSameOrBefore(maxDate))
        ) {
          timeOptions.push({
            label: timeOpt.format(TIME_FORMAT),
            value: timeOpt.format(DATE_AND_TIME_FORMAT),
          });
        }
      }
    }

    return timeOptions;
  }

  function updateExtensionRange(extendFrom: Moment, extendTo: Moment) {
    if (!reservationGuid) {
      return;
    }

    setFromDate(extendFrom);
    setToDate(extendTo);

    if (
      initialFrom.date &&
      initialFrom.date.isSame(extendFrom) &&
      initialTo.date &&
      initialTo.date.isSame(extendTo)
    ) {
      dispatch(clearExtensionPrice());
      return;
    }

    dispatch(
      fetchExtensionPrice(reservationGuid, extendFrom.utc().format(), extendTo.utc().format())
    );
  }

  function renderDifference(
    diff: number,
    currentDate: Moment | null,
    newDate: Moment | null,
    when: 'before' | 'after'
  ) {
    let days = 0;
    if (currentDate && newDate && !currentDate.isSame(newDate, 'day')) {
      const dayInMinutes = 24 * 60;
      days += Math.floor(diff / dayInMinutes);

      const addBefore =
        when === 'before' &&
        !moment(currentDate)
          .subtract(days, 'days')
          .isSame(newDate, 'day');

      const addAfter =
        when === 'after' &&
        !moment(currentDate)
          .add(days, 'days')
          .isSame(newDate, 'day');

      if (addBefore || addAfter) {
        days++;
      }
    }

    const visible = !!diff && !isFetchingExtensionPrice;
    const dayStr = days === 1 ? 'day' : 'days';
    const type = when === 'before' ? 'Pickup' : 'Dropoff';

    return (
      <Grid item xs={12} sm={6}>
        {visible &&
          (!!days ? (
            <div>
              <Typography display="inline">
                {days} {dayStr} added{' '}
              </Typography>
              <Typography display="inline" className={classes.bold}>
                {when}
              </Typography>
            </div>
          ) : (
            <Typography display="inline">{type} time changed</Typography>
          ))}
      </Grid>
    );
  }

  function renderPrice() {
    if (!reservationPrices) {
      return null;
    }

    const { totalPrice, currency, balance } = reservationPrices;
    const addedPrice = extension ? extension.totalExtensionPrice : 0;

    return (
      <div className={classes.prices}>
        <Grid container justify="space-between" className={classes.priceLine}>
          <Typography>Original price</Typography>
          <Typography>{formatPrice(totalPrice, currency)}</Typography>
        </Grid>
        <Grid container justify="space-between" className={classes.priceLine}>
          <Typography>Price for extension</Typography>
          <Typography>{formatPrice(addedPrice, currency)}</Typography>
        </Grid>
        <Grid container justify="space-between" className={classes.totalPrice}>
          <Typography variant="h5">Total price</Typography>
          <Typography variant="h5">{formatPrice(totalPrice + addedPrice, currency)}</Typography>
        </Grid>
        <Grid container justify="space-between" className={classes.priceLine}>
          <Typography className={classes.bold}>Balance</Typography>
          <Typography className={classes.bold}>
            {formatPrice(balance + addedPrice, currency)}
          </Typography>
        </Grid>
      </div>
    );
  }

  function confirmExtension() {
    if (reservationGuid && fromDate && toDate && (fromDiff || toDiff)) {
      dispatch(extendReservation(reservationGuid, fromDate.utc().format(), toDate.utc().format()));
    }
  }

  function quitExtensionDialog() {
    dispatch(closeExtendReservationDialog());
    dispatch(clearExtensionPrice());
  }
};

export default ExtendReservationDialog;

import { updateCustomer } from '../store/reservations/actions';
import IReservation from '../interfaces/IReservation';
import { Dispatch } from 'redux';

export async function performUpdateCustomer(
  reservation: IReservation | undefined,
  dispatch: Dispatch,
  values: { [name: string]: string }
) {
  if (reservation && values.firstName && values.lastName) {
    dispatch(
      updateCustomer(reservation.guid, {
        firstName: values.firstName,
        lastName: values.lastName,
        phone: values.phone || null,
        mobile: values.mobile || null,
        passport: values.passport || null,
        address: values.address || null,
        city: values.city || null,
        zipCode: values.zipCode || null,
        countryId: values.countryId || null,
        flightNumber: values.flightNumber || null,
      })
    );
  }
}

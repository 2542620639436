import { all, takeLatest } from 'redux-saga/effects';
import { LOCATION_CHANGE, CALL_HISTORY_METHOD } from 'connected-react-router';

function* navigationSaga() {
  yield all([
    takeLatest(LOCATION_CHANGE, function* locationChange(arg) {
      // console.log('Location Change', arg);
      yield true;
    }),
    takeLatest(CALL_HISTORY_METHOD, function* callHistoryMethod(arg) {
      // console.log('Call Hisotry Method', arg);
      yield true;
    }),
  ]);
}

export default navigationSaga;

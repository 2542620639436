import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SortableContainer, SortEnd } from 'react-sortable-hoc';
import { v4 as uuidv4 } from 'uuid';

import { searchPlaces } from '../store/places/actions';
import { ISearchPlace } from '../services/ApiService';
import { placeIsWaypoint } from '../utils/trips';
import { IDraggableDay } from './TripDetails';
import ConfirmDialog from './ConfirmDialog';
import AddTripPlaceDialog from './AddTripPlaceDialog';
import { enqueueNotification } from '../store/notifier/actions';
import TripDayPlaces from './TripDayPlaces';
import TripPlaceDetailsDialog from './TripPlaceDetailsDialog';
import HTMLRichText from './HTMLRichText';

interface IProps {
  day: IDraggableDay;
  dayIndex: number;
  canEditTrip: boolean;
  distance?: number;
  updateDay: (index: number, day: IDraggableDay) => void;
}

const useStyles = makeStyles(theme => ({
  description: {
    marginTop: theme.spacing(-2),
    marginBottom: theme.spacing(5),
    '& p': {
      margin: 0,
    },
  },
}));

const PlaceList = SortableContainer(TripDayPlaces);

const TripDay: React.FC<IProps> = ({ day, dayIndex, canEditTrip, updateDay }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  const [isDragging, setIsDragging] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [detailsDialogPlaceId, setDetailsDialogPlaceId] = useState<string | null>(null);

  return (
    <div>
      {day.description && (
        <div className={classes.description}>
          <HTMLRichText text={day.description} />
        </div>
      )}
      <PlaceList
        places={day.places}
        canEditTrip={canEditTrip}
        isDragging={isDragging}
        axis="xy"
        onSortStart={handleDragStart}
        onSortEnd={handleDragEnd}
        setDeleteIndex={setDeleteIndex}
        setAddDialogOpen={handleSetAddPlaceDialogState}
        setDetailsDialogPlaceId={setDetailsDialogPlaceId}
        pressDelay={sm ? 200 : 100}
      />
      {deleteIndex !== null && (
        <ConfirmDialog
          open={deleteIndex !== null}
          title={`Delete ${day.places[deleteIndex].name}`}
          text="Are you sure you want to remove this place from the trip?"
          onConfirm={() => removePlace(deleteIndex)}
          onCancel={() => setDeleteIndex(null)}
        />
      )}
      {canEditTrip && (
        <AddTripPlaceDialog
          open={addDialogOpen}
          onClose={() => handleSetAddPlaceDialogState(false)}
          addPlace={addPlace}
        />
      )}
      {detailsDialogPlaceId && (
        <TripPlaceDetailsDialog
          open
          onClose={() => setDetailsDialogPlaceId(null)}
          placeId={detailsDialogPlaceId}
        />
      )}
    </div>
  );

  function handleDragStart() {
    setIsDragging(true);
    document.body.style.cursor = 'grabbing';
  }

  function handleDragEnd({ newIndex, oldIndex }: SortEnd) {
    setIsDragging(false);
    document.body.style.cursor = 'auto';

    if (newIndex === oldIndex) {
      return;
    }

    const updatedDay = { ...day, places: [...day.places] };
    const draggedPlace = updatedDay.places[oldIndex];
    updatedDay.places.splice(oldIndex, 1);
    updatedDay.places.splice(newIndex, 0, draggedPlace);
    const updatedPlaces = updatedDay.places;

    if (
      placeIsWaypoint(updatedPlaces[0]) ||
      placeIsWaypoint(updatedPlaces[updatedPlaces.length - 1])
    ) {
      dispatch(
        enqueueNotification('Waypoint not allowed at start or end of day', { variant: 'error' })
      );
      return;
    }

    updateDay(dayIndex, updatedDay);
  }

  function addPlace(place: ISearchPlace) {
    const updatedDay = { ...day };
    updatedDay.places.push({
      ...place,
      type: 'stop',
      instanceId: uuidv4(),
    });
    updateDay(dayIndex, updatedDay);
  }

  function removePlace(index: number) {
    const updatedDay = { ...day };
    updatedDay.places.splice(index, 1);
    updateDay(dayIndex, updatedDay);
    setDeleteIndex(null);
  }

  function handleSetAddPlaceDialogState(open: boolean) {
    setAddDialogOpen(open);
    if (open) {
      dispatch(searchPlaces(''));
    }
  }
};

export default TripDay;

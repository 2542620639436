import React, { useState, useCallback, useEffect } from 'react';
import Popover from '@material-ui/core/Popover';
import Grid from '@material-ui/core/Grid';
import Skeleton from 'react-skeleton-loader';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import CheckIcon from '@material-ui/icons/Check';
import Hidden from '@material-ui/core/Hidden';
import Checkbox from '@material-ui/core/Checkbox';
import useTheme from '@material-ui/core/styles/useTheme';
import formatPrice from '../utils/priceFormatter';
import { IReservationExtra, IExtra, IAddExtra } from '../interfaces/IReservation';
import InformationButton from './InformationButton';
import ExtrasPrice from './ExtrasPrice';
import HTMLRichText from './HTMLRichText';
import CircularIconButton from './CircularIconButton';

interface IExtraStatus {
  id: number;
  quantity: number;
  currentQuantity: number;
  anchor: HTMLDivElement | null;
}

interface IProps {
  label: string;
  extras: IReservationExtra[];
  available: IExtra[];
  loading: boolean;
  error: string | null;
  busy: boolean;
  currency: string;
  days: number;
  addExtras: (extras: IAddExtra[]) => void;
  onUpdating: (changes: boolean) => void;
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4, 6, 0, 6),
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 960,
  },
  stickyFooter: {
    margin: theme.spacing(0, 1),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
    },
  },
  addedPrice: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  stickyItem: {
    [theme.breakpoints.down('xs')]: {
      margin: 'auto',
      textAlign: 'center',
    },
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  error: {
    margin: theme.spacing(2, 0),
  },
  itemsContainer: {
    padding: theme.spacing(3, 0),
  },
  freeTitle: {
    marginTop: theme.spacing(3),
  },
  extraItem: {
    border: `1px solid ${theme.palette.grey[200]}`,
    boxShadow: `1px 2px 6px 0 ${theme.palette.grey[200]}`,
    borderRadius: 3,
    width: '100%',
    textAlign: 'center',
    minHeight: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  extraTitle: {
    fontWeight: 'bold',
    margin: 5,
  },
  extraImg: {
    height: 100,
    width: '100%',
    objectFit: 'contain',
    padding: theme.spacing(3),
    paddingBottom: theme.spacing(1),
  },
  infoButton: {
    position: 'absolute',
    top: 15,
    right: 15,
  },
  infoText: {
    width: 250,
    margin: theme.spacing(2),
  },
  counter: {
    padding: theme.spacing(1),
  },
  counterNumber: {
    fontSize: 20,
    margin: theme.spacing(0, 2),
  },
  bannerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  bookedBanner: {
    backgroundColor: theme.customPalette.success,
    padding: '1px 6px',
    borderRadius: 5,
    display: 'flex',
    alignItems: 'center',
    textTransform: 'uppercase',
  },
  addBtn: {
    backgroundColor: '#45C461',
    color: 'white',
    '&:hover': {
      backgroundColor: '#45C461',
      color: 'white',
    },
  },
  discardBtn: {
    fontWeight: 'bold',
    marginRight: 10,
  },
}));

const OnlineCheckInInsurances: React.FC<IProps> = ({
  label,
  extras,
  available,
  loading,
  error,
  busy,
  addExtras,
  currency,
  days,
  onUpdating,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [extraStatus, setExtraStatus] = useState<{ [id: number]: IExtraStatus }>({});
  const [changes, setChanges] = useState(false);
  const newExtras = getNewExtras();

  let addedPrice = 0;
  newExtras.forEach(extra => (addedPrice += extra.price));

  const initializeExtras = useCallback(() => {
    const status: { [id: number]: IExtraStatus } = {};

    available.forEach(extra => {
      status[extra.id] = {
        id: extra.id,
        quantity: 0,
        currentQuantity: 0,
        anchor: null,
      };
    });

    extras.forEach(extra => {
      status[extra.id] = {
        id: extra.id,
        quantity: extra.quantity,
        currentQuantity: extra.quantity,
        anchor: null,
      };
    });

    setExtraStatus(status);
  }, [available, extras]);

  useEffect(() => {
    initializeExtras();
  }, [initializeExtras]);

  return (
    <div className={classes.container}>
      {renderContent(days)}
      <Grid container justify="space-between" alignItems="center" spacing={2}>
        {/* <Grid item className={classes.stickyItem}>
          <Typography className={classes.addedPrice}>
            Additional price per day: {formatPrice(Math.round(addedPrice / days), currency)}
          </Typography>
          <Typography className={classes.addedPrice}>
            Total additional price: {formatPrice(addedPrice, currency)}
          </Typography>
        </Grid> */}
        <Grid
          item
          className={classes.stickyItem}
          style={{ display: 'flex', marginTop: '3rem', justifyContent: 'center', width: '100%' }}
        >
          {(changes || busy) && (
            <div>
              <Button
                variant="outlined"
                className={classes.discardBtn}
                disabled={busy}
                onClick={discardChanges}
              >
                Discard changes
              </Button>
              <Button
                variant="contained"
                className={classes.addBtn}
                disabled={busy}
                onClick={confirmChanges}
              >
                {busy && <CircularProgress size={24} color="inherit" />}
                <span style={busy ? { marginLeft: '0.5rem' } : {}}>Add {label}</span>
              </Button>
            </div>
          )}
        </Grid>
      </Grid>
    </div>
  );

  function renderContent(tripDays: number) {
    if (loading) {
      return renderLoadingSkeleton();
    }

    if (error) {
      return <Typography className={classes.error}>{error}</Typography>;
    }

    const regularExtras = available
      .filter(e => e.price > 0)
      .sort(sortExtras)
      .map(renderExtraItem)
      .filter(e => !!e);
    const freeExtras = available
      .filter(e => e.price === 0)
      .sort(sortExtras)
      .map(renderExtraItem)
      .filter(e => !!e);

    if (regularExtras.length + freeExtras.length === 0) {
      return <Typography className={classes.error}>No {label.toLowerCase()} available</Typography>;
    }

    return (
      <React.Fragment>
        {regularExtras.length > 0 && (
          <Grid container spacing={1} className={classes.itemsContainer}>
            {regularExtras}
          </Grid>
        )}
        {freeExtras.length > 0 && regularExtras.length > 0 && (
          <Typography variant="h5" className={classes.freeTitle}>
            Free
          </Typography>
        )}
        {freeExtras.length > 0 && (
          <Grid container spacing={1} className={classes.itemsContainer}>
            {freeExtras}
          </Grid>
        )}
        <Grid container spacing={2} style={{ marginBottom: theme.spacing(2) }}>
          <Grid item xs={12} md={6}>
            <ExtrasPrice
              title="Previously booked"
              extras={extras}
              emptyText={`No ${label.toLowerCase()}`}
              currency={currency}
              disabled
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <ExtrasPrice
              title="Booking now"
              extras={newExtras}
              emptyText="Nothing selected"
              currency={currency}
              days={tripDays}
            />
          </Grid>
        </Grid>
      </React.Fragment>
    );
  }

  function renderExtraItem(extra: IExtra) {
    const { id, name, image, price, maximumQuantity, description, pricePerDay } = extra;
    const status = extraStatus[id];

    if (!status) {
      return null;
    }

    const { quantity, currentQuantity, anchor } = status;
    const canAdd = !maximumQuantity || quantity < maximumQuantity;
    const canRemove = quantity > 0;
    const canCheck = quantity === 0 || canRemove;

    return (
      <Grid item key={id} xs={12} sm={4} md={3} style={{ position: 'relative' }}>
        <div className={classes.extraItem}>
          {image ? (
            <img src={image} className={classes.extraImg} alt={name} />
          ) : (
            <div className={classes.extraImg} />
          )}
          <div style={{ flex: 1 }}>
            <Typography className={classes.extraTitle} variant="body1">
              {name}
            </Typography>
            <Typography style={{ fontWeight: 'bold' }} variant="body2">
              {formatPrice(price, currency)} {pricePerDay && ' per day'}
            </Typography>
          </div>
          <Grid container justify="center" alignItems="center" className={classes.counter}>
            {maximumQuantity === 1 ? (
              <Checkbox
                checked={quantity === 1}
                disabled={!canCheck}
                onClick={() => selectCheckbox(id, canCheck)}
              />
            ) : (
              <React.Fragment>
                <CircularIconButton
                  onClick={() => updateExtraQuantity(id, 'remove', canRemove)}
                  disabled={!canRemove}
                >
                  <RemoveIcon fontSize="small" style={{ padding: 1 }} />
                </CircularIconButton>
                <Typography variant="body1" className={classes.counterNumber}>
                  {status.quantity}
                </Typography>
                <CircularIconButton
                  onClick={() => updateExtraQuantity(id, 'add', canAdd)}
                  disabled={!canAdd}
                >
                  <AddIcon fontSize="small" style={{ padding: 1 }} />
                </CircularIconButton>
              </React.Fragment>
            )}
          </Grid>
        </div>
        {description && (
          <React.Fragment>
            <InformationButton onClick={e => openInfo(id, e)} className={classes.infoButton} />
            <Popover
              open={!!anchor}
              anchorEl={anchor}
              onClose={() => closeInfo(id)}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className={classes.infoText}>
                <HTMLRichText text={description} />
              </div>
            </Popover>
          </React.Fragment>
        )}
        {currentQuantity > 0 && (
          <div className={classes.bannerContainer}>
            <div className={classes.bookedBanner}>
              <CheckIcon
                fontSize="small"
                htmlColor="white"
                style={{ marginRight: 3, paddingBottom: 1 }}
              />
              <Typography style={{ color: 'white' }} variant="subtitle2">
                Booked
              </Typography>
            </div>
          </div>
        )}
      </Grid>
    );
  }

  function renderLoadingSkeleton() {
    const skeletonItem = () => {
      return (
        <Grid item xs={12} sm={4} md={3}>
          <Skeleton
            height="200px"
            width="100%"
            color={theme.customPalette.skeleton}
            heightRandomness={0}
            widthRandomness={0}
          />
        </Grid>
      );
    };

    return (
      <Grid container spacing={1} className={classes.itemsContainer}>
        {skeletonItem()}
        {skeletonItem()}
        <Hidden smDown>{skeletonItem()}</Hidden>
        <Hidden xsDown> {skeletonItem()}</Hidden>
      </Grid>
    );
  }

  function selectCheckbox(id: number, valid: boolean) {
    if (valid) {
      const status = { ...extraStatus };
      status[id].quantity = status[id].quantity === 1 ? 0 : 1;
      setExtraStatus(status);
      setChanges(true);
      onUpdating(false);
    }
  }

  function updateExtraQuantity(id: number, type: 'add' | 'remove', valid: boolean) {
    if (valid) {
      const status = { ...extraStatus };
      const change = type === 'remove' ? -1 : 1;
      status[id].quantity = status[id].quantity + change;
      setExtraStatus(status);
      setChanges(true);
      onUpdating(false);
    }
  }

  function openInfo(id: number, event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const status = { ...extraStatus };
    status[id].anchor = event.currentTarget;
    setExtraStatus(status);
  }

  function closeInfo(id: number) {
    const status = { ...extraStatus };
    status[id].anchor = null;
    setExtraStatus(status);
  }

  function discardChanges() {
    const statusCopy = { ...extraStatus };
    const statusArray = Object.entries(statusCopy);

    statusArray.forEach(singleStatus => {
      singleStatus[1].quantity = singleStatus[1].currentQuantity;
    });

    const result = statusArray.reduce((acc: any, [k, v]) => {
      acc[k] = v;

      return acc;
    }, {});

    setExtraStatus(result);
    setChanges(false);
    onUpdating(true);
  }

  async function confirmChanges() {
    let hasChanged = false;
    for (const extra of Object.values(extraStatus)) {
      if (extra.currentQuantity !== extra.quantity) {
        hasChanged = true;
        break;
      }
    }

    if (hasChanged) {
      // const validExtras = Object.values(extraStatus).filter(extra => extra.quantity > 0);
      const validExtras = Object.values(extraStatus).filter(
        extra => extra.quantity > 0 || extra.currentQuantity !== extra.quantity
      );
      addExtras(validExtras);
      setChanges(false);
      onUpdating(true);
    }
  }

  function sortExtras(a: IExtra, b: IExtra) {
    const aStatus = extraStatus[a.id];
    const bStatus = extraStatus[b.id];

    if (!bStatus || !aStatus) {
      return 1;
    }

    const aQty = aStatus.currentQuantity;
    const bQty = bStatus.currentQuantity;

    return aQty > bQty ? -1 : bQty > aQty ? 1 : 0;
  }

  function getNewExtras() {
    const filtered = available.filter(extra => {
      const status = extraStatus[extra.id];
      if (!status) {
        return false;
      }
      return status.currentQuantity !== status.quantity;
    });

    return filtered.map(extra => {
      const status = extraStatus[extra.id];
      const added = status.quantity - status.currentQuantity;
      const price = extra.price * added * (extra.pricePerDay ? days : 1);

      return { ...extra, quantity: added, price };
    });
  }
};

export default OnlineCheckInInsurances;

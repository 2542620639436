import React, { useState, useEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import Skeleton from 'react-skeleton-loader';
import Hidden from '@material-ui/core/Hidden';
import Slider from '@material-ui/core/Slider';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import makeStyles from '@material-ui/core/styles/makeStyles';
import SearchIcon from '@material-ui/icons/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import TripCard from '../components/TripCard';
import Section from '../components/Section';
import { ITripLite } from '../services/ApiService';

const defaultFilters = {
  days: [0, 20],
  stops: [0, 100],
  distance: [0, 5000],
};

interface IProps {
  trips: ITripLite[];
  title?: string;
  loading?: boolean;
  error?: string;
  link?: string;
  linkText?: string;
  emptyText?: string;
  className?: string;
  email?: string;
  isLoggedIn: boolean;
  isCustomerTrips: boolean;
  showFilters?: boolean;
  addToTrips?: (templateId: string) => Promise<void>;
  deleteCustomerTrip?: (instanceId: string) => Promise<void>;
}

const useStyles = makeStyles(theme => ({
  emptyText: {
    fontSize: 18,
    marginBottom: theme.spacing(4),
  },
  filters: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(5),
      padding: theme.spacing(0, 2),
    },
  },
  slider: {
    margin: theme.spacing(0, 1),
  },
  search: {
    marginLeft: 'auto',
    [theme.breakpoints.only('sm')]: {
      paddingTop: '0px !important',
    },
  },
  mediumText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
}));

const TripList: React.FC<IProps> = ({
  trips,
  title,
  loading,
  error,
  link,
  linkText,
  emptyText,
  className,
  email,
  isLoggedIn,
  isCustomerTrips,
  showFilters,
  addToTrips,
  deleteCustomerTrip,
}) => {
  const theme = useTheme();
  const classes = useStyles();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  const [daysFilter, setDaysFilter] = useState(defaultFilters.days);
  const [stopsFilter, setStopsFilter] = useState(defaultFilters.stops);
  const [distanceFilter, setDistanceFilter] = useState(defaultFilters.distance);
  const [filterRange, setFilterRange] = useState(defaultFilters);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    if (trips.length && showFilters) {
      const range = getFilterRange(trips);
      setDaysFilter(range.days);
      setStopsFilter(range.stops);
      setDistanceFilter(range.distance);
      setFilterRange(range);
    }
  }, [trips, showFilters]);

  if (loading) {
    return renderContent(
      <Grid container spacing={4} justify="flex-start">
        {renderSkeletonItem()}
        {renderSkeletonItem()}
        <Hidden mdDown>{renderSkeletonItem()}</Hidden>
      </Grid>
    );
  }

  if (error) {
    return renderContent(
      <Typography variant="h6" color="textSecondary">
        {error}
      </Typography>
    );
  }

  if (trips.length === 0) {
    return renderContent(
      <Typography className={classes.emptyText} color="textSecondary">
        {emptyText || 'No trips available'}
      </Typography>
    );
  }

  // Only filter if the filter panel is visible
  const tripsToDisplay = showFilters ? trips.filter(filterTrips) : trips;

  return renderContent(
    tripsToDisplay.length ? (
      <Grid container spacing={4} justify="flex-start">
        {tripsToDisplay.map((trip, index) => (
          <Grid key={index} item xs={12} sm={6} lg={4}>
            <TripCard
              trip={trip}
              isCustomerTrip={isCustomerTrips}
              addToTrips={addToTrips}
              email={email}
              isLoggedIn={isLoggedIn}
              deleteCustomerTrip={deleteCustomerTrip}
            />
          </Grid>
        ))}
      </Grid>
    ) : (
      <Typography className={classes.emptyText} color="textSecondary">
        No trips found
      </Typography>
    )
  );

  function renderContent(content: React.ReactNode) {
    return (
      <Section
        title={title}
        loading={loading}
        link={link}
        linkText={linkText}
        className={className}
      >
        {showFilters && renderFilters()}
        {content}
      </Section>
    );
  }

  function renderSkeletonItem() {
    return (
      <Grid item xs={12} sm={6} lg={4}>
        <Skeleton
          color={theme.customPalette.skeleton}
          height="300px"
          width="100%"
          widthRandomness={0}
          heightRandomness={0}
        />
      </Grid>
    );
  }

  function filterTrips({ dayCount, stopCount, distanceKm, name }: ITripLite) {
    if (dayCount < daysFilter[0] || dayCount > daysFilter[1]) {
      return false;
    }
    if (stopCount < stopsFilter[0] || stopCount > stopsFilter[1]) {
      return false;
    }
    if (distanceKm < distanceFilter[0] || distanceKm > distanceFilter[1]) {
      return false;
    }
    return name.toLowerCase().includes(searchText.toLowerCase());
  }

  function renderFilters() {
    return (
      <Grid
        container
        direction="row"
        spacing={xs ? 2 : 4}
        className={classes.filters}
        justify="flex-start"
        alignItems="center"
      >
        {renderSlider({
          title: 'Days',
          value: daysFilter,
          range: filterRange.days,
          onChange: setDaysFilter,
        })}
        {renderSlider({
          title: 'Stops',
          value: stopsFilter,
          range: filterRange.stops,
          onChange: setStopsFilter,
        })}
        {renderSlider({
          title: 'km',
          value: distanceFilter,
          range: filterRange.distance,
          onChange: setDistanceFilter,
          step: 100,
        })}
        <Grid item xs={12} md={3} className={classes.search}>
          <OutlinedInput
            value={searchText}
            onChange={e => setSearchText(e.target.value)}
            style={{ width: '100%' }}
            placeholder="Search"
            color="secondary"
            startAdornment={
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            }
          />
        </Grid>
      </Grid>
    );
  }

  function renderSlider(options: {
    title: string;
    value: number[];
    range: number[];
    onChange: (val: number[]) => void;
    step?: number;
  }) {
    return (
      <Grid item xs={12} sm={4} md={3} lg={2}>
        <Grid container direction="row" justify="space-between">
          <Typography gutterBottom className={classes.mediumText}>
            {options.title}
          </Typography>
          <Typography gutterBottom>{options.value.join(' - ')}</Typography>
        </Grid>
        <div className={classes.slider}>
          <Slider
            value={options.value}
            min={options.range[0]}
            max={options.range[1]}
            aria-labelledby="range-slider"
            valueLabelDisplay="auto"
            color="secondary"
            step={options.step}
            onChange={(_, val) => options.onChange(val as number[])}
          />
        </div>
      </Grid>
    );
  }
};

function getFilterRange(trips: ITripLite[]) {
  let maxDays = 0;
  let maxStops = 0;
  let maxDistance = 0;

  trips.forEach(trip => {
    if (trip.dayCount > maxDays) {
      maxDays = trip.dayCount;
    }
    if (trip.stopCount > maxStops) {
      maxStops = trip.stopCount;
    }
    if (trip.distanceKm > maxDistance) {
      maxDistance = trip.distanceKm;
    }
  });

  return {
    days: [0, maxDays],
    stops: [0, maxStops],
    distance: [0, maxDistance],
  };
}

export default TripList;

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Layout from '../components/Layout';
import OnlineCheckInStepper from '../components/OnlineCheckInStepper';
import { StringParam, useQueryParam } from 'use-query-params';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReservationByEmail, fetchReservationByGuid } from '../store/reservations/actions';
import { getLoadedReservation } from '../store/selectors';

const useStyle = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

const OnlineCheckIn = () => {
  const classes = useStyle();
  const [bookingCode] = useQueryParam('bookingCode', StringParam);
  const [email] = useQueryParam('email', StringParam);
  const [guid] = useQueryParam('guid', StringParam);
  const [success] = useQueryParam('success', StringParam);
  const reservation = useSelector(getLoadedReservation);
  const dispatch = useDispatch();

  useEffect(() => {
    if (email && bookingCode) {
      if (reservation.bookingCode === '') {
        dispatch(fetchReservationByEmail(email, bookingCode));
      }
    } else if (guid) {
      if (reservation.guid === '') {
        dispatch(fetchReservationByGuid(guid));
      }
    }
  }, [dispatch, email, bookingCode, guid, reservation]);

  return (
    <Layout useDefaultSpacing>
      <div className={classes.container}>
        <OnlineCheckInStepper guid={guid} success={success} />
      </div>
    </Layout>
  );
};

export default OnlineCheckIn;

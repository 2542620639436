import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Popover from '@material-ui/core/Popover';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Button from '@material-ui/core/Button';
import Skeleton from 'react-skeleton-loader';
import ReservationPaymentDialog from './ReservationPaymentDialog';
import { getReservationPayment, getLoadedReservationGuid } from '../store/selectors';
import { payBalance, resetPaymentState } from '../store/reservations/actions';
import formatPrice from '../utils/priceFormatter';
import { IReservationPrices, ICardInfo } from '../interfaces/IReservation';
import InformationButton from './InformationButton';
import InfoCard from './InfoCard';
import BorgunSecureForm from './BorgunSecureForm';
import useApi from '../hooks/useApi';
import useMount from '../hooks/useMount';

interface IProps {
  priceInfo: IReservationPrices;
  days: number;
  loading: boolean;
  canPayBalance: boolean;
  shouldBeValitor?: boolean;
  isFromOnlineCheckIn?: boolean;
  shouldContinue: (value: boolean) => void;
}

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(4, 0),
  },
  infoLine: {
    padding: `2px 0px`,
  },
  upperCase: {
    textTransform: 'uppercase',
  },
  fullPrice: {
    textDecoration: 'line-through',
  },
  totalPrice: {
    margin: theme.spacing(1, 0),
    paddingBottom: 4,
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  infoButton: {
    marginLeft: theme.spacing(1),
  },
  balance: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  skeletonLine: {
    padding: `5px 0px`,
  },
  payNowButton: {
    margin: theme.spacing(0, 1),
    padding: theme.spacing(0.125, 1),
  },
}));

const ReservationPriceSummary: React.FC<IProps> = ({
  priceInfo,
  days,
  loading,
  canPayBalance,
  shouldBeValitor = false,
  isFromOnlineCheckIn = false,
  shouldContinue,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
  const [isPaymentDialogOpen, setPaymentOpen] = useState(false);
  const [infoType, setInfoType] = useState<'price' | 'discount'>('price');
  const dispatch = useDispatch();
  const paymentState = useSelector(getReservationPayment);
  const reservationGuid = useSelector(getLoadedReservationGuid);
  const api = useApi();
  const [valitorData, setValitor] = useState('');

  useEffect(() => {
    const getValitor = async (successUrl: string, cancelUrl: string) => {
      try {
        if (reservationGuid !== '') {
          const res = await api.valitorPayment(reservationGuid!, successUrl, cancelUrl);
          setValitor(res.form);
        }
      } catch (error) {
        console.error(error);
      }
    };

    if (shouldBeValitor) {
      let successUrl = 'bookings/view/?success=true';
      let cancelUrl = 'bookings/view/?success=false';

      if (isFromOnlineCheckIn) {
        successUrl = 'bookings/onlinecheck/?success=true';
        cancelUrl = 'bookings/onlinecheck/?success=false';
      }
      getValitor(successUrl, cancelUrl);
    }
  }, [api, reservationGuid, shouldBeValitor, isFromOnlineCheckIn]);

  useMount(() => {
    if (canPayBalance === true) {
      shouldContinue(false);
    } else {
      shouldContinue(true);
    }
  });

  // Feature flag, disable payments until 3D Secure migration is complete
  const disablePayments = false;

  const {
    price,
    totalPrice,
    discountPrice,
    currency,
    paymentsAmount,
    extrasTotalPrice,
    insurancesTotalPrice,
    extrasDiscountPrice,
    insurancesDiscountPrice,
    vehicleDiscountPrice,
    balance,
  } = priceInfo;

  if (loading) {
    return <ReservationPriceSummarySkeleton />;
  }

  return (
    <Grid container direction="column" className={classes.container}>
      <BorgunSecureForm />
      {discountPrice > 0 && (
        <React.Fragment>
          <Grid container justify="space-between" className={classes.infoLine}>
            <div style={{ display: 'flex' }}>
              <Typography className={classes.upperCase}>Discount</Typography>
              <InformationButton
                onClick={e => openPopover('discount', e.currentTarget)}
                className={classes.infoButton}
              />
            </div>
            <Typography>{formatWithCurrency(-discountPrice)}</Typography>
          </Grid>
          <Grid container justify="space-between" className={classes.infoLine}>
            <Typography className={classes.upperCase}>Price</Typography>
            <Typography className={classes.fullPrice}>{formatWithCurrency(price)}</Typography>
          </Grid>
        </React.Fragment>
      )}
      <Grid container justify="space-between" className={classes.totalPrice}>
        <div style={{ display: 'flex' }}>
          <Typography variant="h5" className={classes.upperCase}>
            Total price
          </Typography>
          <InformationButton
            onClick={e => openPopover('price', e.currentTarget)}
            className={classes.infoButton}
          />
        </div>
        <Typography variant="h5">{formatWithCurrency(totalPrice)}</Typography>
      </Grid>
      <Grid container justify="space-between" className={classes.infoLine}>
        <Typography className={classes.upperCase}>Amount paid</Typography>
        <Typography>{formatWithCurrency(paymentsAmount)}</Typography>
      </Grid>
      <Grid container justify="space-between" className={classes.infoLine}>
        <Typography className={cx(classes.balance, classes.upperCase)}>
          Balance{' '}
          {!disablePayments && canPayBalance && (
            <Button
              variant="contained"
              color="secondary"
              size="small"
              onClick={togglePaymentDialog}
              className={classes.payNowButton}
            >
              Pay now
            </Button>
          )}
        </Typography>
        <Typography className={classes.balance}>{formatWithCurrency(balance)}</Typography>
      </Grid>
      {renderInfoPopover()}
      <ReservationPaymentDialog
        open={isPaymentDialogOpen}
        onClose={togglePaymentDialog}
        balance={formatWithCurrency(balance)}
        payBalance={handlePayBalance}
        paymentLoading={paymentState.isPerformingPayment}
        paymentError={paymentState.error || ''}
        paymentSuccess={paymentState.wasSuccessful}
        resetPayBalance={handleResetPayBalance}
        shouldBeValitor={shouldBeValitor}
        valitor={valitorData}
      />
    </Grid>
  );

  function togglePaymentDialog() {
    setPaymentOpen(state => !state);
  }

  function renderInfoPopover() {
    const items =
      infoType === 'price'
        ? [
            { label: 'Extras Total', value: formatWithCurrency(extrasTotalPrice) },
            {
              label: 'Insurance Total',
              value: formatWithCurrency(insurancesTotalPrice),
            },
            {
              label: 'Daily rate',
              value: formatWithCurrency(Math.round(totalPrice / days)),
            },
          ]
        : [
            {
              label: 'Vehicle Discount',
              value: formatWithCurrency(-vehicleDiscountPrice),
            },
            { label: 'Extras Discount', value: formatWithCurrency(-extrasDiscountPrice) },
            {
              label: 'Insurance Discount',
              value: formatWithCurrency(-insurancesDiscountPrice),
            },
          ];

    return (
      <Popover
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          style: { borderRadius: 10, maxWidth: 400 },
        }}
        style={{ marginLeft: 5 }}
      >
        <InfoCard items={items} />
      </Popover>
    );
  }

  function openPopover(type: 'price' | 'discount', anchor: HTMLDivElement | null) {
    setInfoType(type);
    setAnchorEl(anchor);
  }

  function formatWithCurrency(priceVal: number) {
    return `${formatPrice(priceVal, currency)}`;
  }

  function handlePayBalance(cardInfo: ICardInfo) {
    if (reservationGuid) {
      let successUrl = 'bookings/view/?success=true';
      let cancelUrl = 'bookings/view/?success=false';

      if (isFromOnlineCheckIn) {
        successUrl = 'bookings/onlinecheck/?success=true';
        cancelUrl = 'bookings/onlinecheck/?success=false';
      }
      dispatch(payBalance(reservationGuid, cardInfo, successUrl, cancelUrl));
      shouldContinue(true);
    }
  }

  function handleResetPayBalance() {
    dispatch(resetPaymentState());
  }
};

const ReservationPriceSummarySkeleton: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Grid container direction="column" className={classes.container}>
      <Grid container justify="space-between" className={classes.skeletonLine}>
        <Skeleton color={theme.customPalette.skeleton} />
        <Skeleton color={theme.customPalette.skeleton} />
      </Grid>
      <Grid container justify="space-between" className={classes.skeletonLine}>
        <Skeleton height="50px" width="200px" color={theme.customPalette.skeleton} />
        <Skeleton height="50px" width="200px" color={theme.customPalette.skeleton} />
      </Grid>
      <Grid container justify="space-between" className={classes.skeletonLine}>
        <Skeleton color={theme.customPalette.skeleton} />
        <Skeleton color={theme.customPalette.skeleton} />
      </Grid>
    </Grid>
  );
};

export default ReservationPriceSummary;

import axios, { AxiosInstance } from 'axios';
import { Moment } from 'moment';

import get from 'lodash.get';
import env from '../env';
import {
  IReservation,
  IUpdateCustomer,
  IExtra,
  IAddExtra,
  IInsurance,
  ICardInfo,
  IReservationDriver,
  ITerms,
} from '../interfaces/IReservation';
import IReservationLite from '../interfaces/IReservationLite';
import ITenantConfig from '../interfaces/ITenantConfig';
import { convertToLatLngShape } from '../utils/map';
import { captureException } from '../sentry';
import { PLACES_RESPONSE_SIZE } from '../constants';
import ApiError from '../errors/ApiError';

export interface ILocation {
  latitude: number;
  longitude: number;
}

export interface ITripLite {
  id: string;
  name: string;
  description: string;
  photoUrl: string;
  dayCount: number;
  stopCount: number;
  distanceKm: number;
  modifiedAt: number;
  templateId?: string;
}

export interface ITrip extends ITripLite {
  slug?: string;
  customId?: string;
  shareId?: string;
  customerEmail?: string;
  days: ITripDay[];
}

export interface ITripDay {
  name: string;
  description: string;
  distance: number;
  photo: string;
  places: ITripPlace[];
}

export interface IUpdateTripPlace {
  id: string;
  type: string;
  location: ILocation;
}

export interface ITripPlace extends IUpdateTripPlace {
  name: string;
  description: string;
  photo: string | null;
  categories: string[];
  externalProduct?: IExternalProduct | null;
}

export interface IUpdateCustomerTrip {
  days: Array<{
    name: string;
    description: string;
    photo: string;
    places: IUpdateTripPlace[];
  }>;
}

export interface ISearchPlace {
  id: string;
  name: string;
  categories: string[];
  description: string;
  photo: string | null;
  location: ILocation;
}

export interface IExternalProduct {
  id: string;
  placeId: string;
  productId: string;
  providerId: string;
}

export interface IPlaceDetails extends ISearchPlace {
  photos?: string[];
  externalProduct: IExternalProduct | null;
}

export interface ITripRoute {
  waypoint: Array<{ shapeIndex: number }>;
  shape: Array<{ lat: number; lng: number }>;
  boundingBox: { topLeft: ILocation; bottomRight: ILocation } | null;
  legs?: Array<{ distance: number; duration: number }>;
}

export interface IExtensionPrice {
  price: number;
  totalPrice: number;
  discountPrice: number;
  currency: string;
}

interface IApiHeaders {
  Authorization?: string;
  'tenant-id'?: string;
  'Content-Type'?: string;
}

interface IApiServiceConfiguration {
  accessToken: string | null;
  tenantId: string | null;
}

class ApiService {
  private client: AxiosInstance;
  private clientWithPhotos: AxiosInstance;

  constructor(config?: IApiServiceConfiguration) {
    const headers: IApiHeaders = {};
    const headersForPhotos: IApiHeaders = {};
    const accessToken = get(config, 'accessToken', null);
    const tenantId = get(config, 'tenantId', null);

    if (accessToken) {
      headers.Authorization = `Bearer ${accessToken}`;
      headersForPhotos.Authorization = `Bearer ${accessToken}`;
    }

    if (tenantId) {
      headers['tenant-id'] = tenantId;
      headersForPhotos['tenant-id'] = tenantId;
      // headersForPhotos['Content-Type'] = 'multipart/form-data';
    }

    this.client = axios.create({
      baseURL: env.api.baseUrl,
      headers,
    });

    this.clientWithPhotos = axios.create({
      baseURL: env.api.baseUrl,
      headers: headersForPhotos,
    });

    this.client.interceptors.response.use(
      response => response,
      err => {
        if (err.response) {
          const { data, status } = err.response;

          if (status >= 500) {
            captureException(new Error(`API threw internal error`), data);
          }

          // If there is some data returned, it's an API error
          if (data) {
            throw new ApiError(data);
          }
        }

        // If it was not an API error, re-throw the error
        throw err;
      }
    );
  }

  async fetchTenantConfig(): Promise<ITenantConfig> {
    const response = await this.client.get('/tenant');

    return response.data;
  }

  async fetchReservation(bookingCode: string, email: string): Promise<IReservation> {
    const params = {
      email,
      bookingCode,
    };

    const config = {
      params,
    };

    const response = await this.client.get('/reservations/find', config);

    return response.data;
  }

  async fetchReservationByGuid(guid: string): Promise<IReservation> {
    const response = await this.client.get(`/reservations/${guid}`);

    return response.data;
  }

  async fetchTrips({ days }: { days: number[] }): Promise<ITripLite[]> {
    const response = await this.client.get('/trips', {
      params: { days },
    });

    if (response.status === 401) {
      return [];
    }

    return response.data;
  }

  async fetchTripById(tripId: string): Promise<ITrip> {
    const response = await this.client.get(`/trips/${tripId}`);

    return response.data || null;
  }

  async fetchTripRoute(tripId: string): Promise<ITripRoute> {
    const { data } = await this.client.get(`/trips/${tripId}/route`);
    const route = { ...data, shape: convertToLatLngShape(data.shape) };

    return route;
  }

  async fetchReservations(includeCancelled?: boolean): Promise<IReservationLite[]> {
    const response = await this.client.get('/reservations', { params: { includeCancelled } });

    if (response.status === 401) {
      return [];
    }

    return response.data;
  }

  async cancelReservation(guid: string) {
    const response = await this.client.post(`/reservations/${guid}/cancel`);

    return response.status;
  }

  async updateCustomer(guid: string, customer: IUpdateCustomer) {
    const { data } = await this.client.patch(`/reservations/${guid}/customer`, customer);

    return data;
  }

  async updateFlightNumber(guid: string, flightNumber: string | null) {
    const flightData = { flightNumber };
    const { data } = await this.client.patch(`/reservations/${guid}/flightNumber`, flightData);

    return data;
  }

  async sendVerificationEmail() {
    const { data } = await this.client.post('/user/send-verification-email');

    return data;
  }

  async setCompleteProfileSkippedFlag() {
    const { data } = await this.client.patch('/user/metadata', {
      didSkipProfile: true,
    });

    return data;
  }

  async setCompleteProfileFilledFlag() {
    const { data } = await this.client.patch('/user/metadata', {
      didFillProfile: true,
    });

    return data;
  }

  async signUp(email: string, password: string, firstName: string, lastName: string) {
    try {
      const response = await this.client.post('/user/signup', {
        email,
        password,
        givenName: firstName,
        familyName: lastName,
      });

      return response.data;
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 409:
            throw new Error('This email is already registered');
          default:
            throw new Error('An error occured');
        }
      }
      throw error;
    }
  }

  async fetchExtras(guid: string): Promise<IExtra[]> {
    try {
      const response = await this.client.get(`/reservations/${guid}/available-extras`);
      return response.data;
    } catch (error) {
      throw new Error('Error loading extras');
    }
  }

  async addExtras(guid: string, extras: IAddExtra[]) {
    const { data } = await this.client.post(`/reservations/${guid}/extras`, extras);

    return data;
  }

  async fetchInsurances(guid: string): Promise<IInsurance[]> {
    try {
      const response = await this.client.get(`/reservations/${guid}/available-insurances`);
      return response.data;
    } catch (error) {
      throw new Error('Error loading insurances');
    }
  }

  async addInsurances(guid: string, insurances: any[]) {
    const { data } = await this.client.post(`/reservations/${guid}/insurances`, insurances);

    return data;
  }

  async extendReservation(
    guid: string,
    dateFrom: Moment,
    dateTo: Moment,
    onlyCheckPrices: boolean
  ): Promise<IExtensionPrice> {
    const { data } = await this.client.post(`/reservations/${guid}/extend`, {
      dateFrom,
      dateTo,
      onlyCheckPrices,
    });

    return data;
  }

  async payBalance(guid: string, cardInfo: ICardInfo, successUrl: string, cancelUrl: string) {
    const { data } = await this.client.post(`/payment/${guid}/securePayment`, {
      cardInfo,
      successUrl,
      cancelUrl,
    });
    return data;
  }

  async createFromTripTemplate(templateId: string): Promise<ITrip> {
    const { data } = await this.client.post('/trips/createFromTemplate', { templateId });
    return data;
  }

  async getCustomerTrips() {
    const { data } = await this.client.get(`/trips/myTrips`);
    return data;
  }

  async fetchCustomerTripById(id: string) {
    const { data } = await this.client.get(`/trips/myTrips/${id}`);
    return data;
  }

  async updateCustomerTrip(id: string, trip: IUpdateCustomerTrip) {
    const { data } = await this.client.patch(`/trips/myTrips/${id}`, trip);
    return data;
  }

  async deleteCustomerTrip(id: string) {
    const response = await this.client.delete(`/trips/myTrips/${id}`);

    // Temporary solution
    // TODO: Look into ApiService error handling
    if (!response) {
      throw new Error('Unable to delete trip');
    }
  }

  async calculateRoute(waypoints: ILocation[]): Promise<ITripRoute> {
    const { data } = await this.client.get('/trips/calculateRoute', { params: { waypoints } });
    const route = { ...data, shape: convertToLatLngShape(data.shape) };

    return route;
  }

  async searchForPlaces(query: string, from?: number): Promise<ISearchPlace[]> {
    const { data } = await this.client.get('/places/search', {
      params: { query, from, size: PLACES_RESPONSE_SIZE },
    });
    return data;
  }

  async getPlaceDetails(id: string): Promise<IPlaceDetails> {
    const { data } = await this.client.get(`/places/${id}`);
    return data;
  }

  async updateDrivers(guid: string, drivers: IReservationDriver[]): Promise<IReservationDriver[]> {
    const { data } = await this.client.patch(`/reservations/${guid}/drivers`, { drivers });

    return data.drivers;
  }

  async updateDriver(guid: string, driver: any) {
    const { data } = await this.client.patch(`/reservations/${guid}/driver`, { driver });

    return data.driver;
  }

  async removeExtraDriver(guid: string, id: number) {
    const { data } = await this.client.patch(`/reservations/${guid}/removeDriver`, { id });

    return data;
  }

  async updateSelfService(guid: string, selfServicePickup: boolean, selfServiceDropoff: boolean) {
    const { data } = await this.client.post(`/reservations/${guid}/selfService`, {
      selfServicePickup,
      selfServiceDropoff,
    });

    return data;
  }

  async getTerms(): Promise<ITerms> {
    const { data } = await this.client.post('/payment/terms');

    return data;
  }

  async getRentalAgreement(guid: string): Promise<ITerms> {
    const { data } = await this.client.get(`/reservations/${guid}/rentalAgreement`);

    return data;
  }

  async getUnitId(guid: string): Promise<any> {
    const { data } = await this.client.get(`/reservations/${guid}/unitId`);

    return data;
  }

  async getVehicleBluePrint(unitId: number): Promise<any> {
    const { data } = await this.client.get(`/reservations/${unitId}/blueprint`);

    return data;
  }

  async getVehicleDamages(unitId: number): Promise<any> {
    const { data } = await this.client.get(`/reservations/${unitId}/vehicleDamages`);

    return data;
  }

  async getSnapshot(reservationId: number): Promise<any> {
    const { data } = await this.client.get(`/reservations/${reservationId}/snapshot`);

    return data;
  }

  async getVehicleDamagesPost(unitId: number): Promise<any> {
    const { data } = await this.client.post(`/reservations/getRentalDamages`, { unitId });

    return data;
  }

  async deleteVehicleDamage(unitId: number, id: number): Promise<any> {
    const { data } = await this.client.post(`/reservations/${unitId}/deleteVehicleDamage/${id}`);

    return data;
  }

  async getCountries(): Promise<any> {
    const { data } = await this.client.get('/reservations/get/countries');

    return data;
  }

  async valitorPayment(guid: string, successUrl: string, cancelUrl: string): Promise<any> {
    const { data } = await this.client.post(`/payment/${guid}/valitorPayment`, {
      successUrl,
      cancelUrl,
    });

    return data;
  }

  async setReservationCheckIn(guid: string) {
    const { data } = await this.client.post(`/reservations/${guid}/checkIn`);

    return data;
  }

  async acceptTermsAndConditions(guid: string, signaturePhoto: any): Promise<any> {
    const { data } = await this.client.post(`/reservations/${guid}/acceptTerms`, {
      signaturePhoto,
    });

    return data;
  }

  async acceptRentalAgreement(guid: string, signaturePhoto: any): Promise<any> {
    const { data } = await this.client.post(`/reservations/${guid}/acceptRentalAgreement`, {
      signaturePhoto,
    });

    return data;
  }

  async acceptCarCondition(
    id: number,
    guid: string,
    unitId: string,
    signaturePhoto: any
  ): Promise<any> {
    const { data } = await this.client.post(`/reservations/${guid}/acceptCarCondition`, {
      id,
      unitId,
      signaturePhoto,
    });

    return data;
  }

  async addVehicleDamage(
    guid: string,
    vehicleDamagePhotos: any[],
    xCoordinate: string,
    yCoordinate: string,
    description: string
  ): Promise<any> {
    const formData = new FormData();

    vehicleDamagePhotos.map(photo => {
      formData.append(`photos`, photo);
    });

    formData.append('description', description);

    const { data } = await this.clientWithPhotos.post(
      `/reservations/${guid}/addVehicleDamage/${xCoordinate}/${yCoordinate}`,
      formData,
      {
        headers: { 'content-type': 'multipart/form-data' },
      }
    );

    return data;
  }

  async updateVehicleDamage(
    unitId: number,
    damageId: string,
    damagePhotoUrl: string,
    description: string,
    xCoordinate: string,
    yCoordinate: string
  ): Promise<any> {
    const damageData = {
      unitId,
      damageId,
      damagePhotoUrl,
      description,
      xCoordinate,
      yCoordinate,
    };

    const { data } = await this.clientWithPhotos.post(
      `reservations/${unitId}/updateVehicleDamage`,
      damageData
    );

    return data;
  }

  async uploadLicencePhotos(guid: string, driverId: number, licencePhotos: File[]): Promise<any> {
    const formData = new FormData();
    const uploadedPhotos = [...licencePhotos];

    uploadedPhotos.forEach(photo => {
      formData.append(`photos`, photo);
    });

    const { data } = await this.clientWithPhotos.post(
      `reservations/${guid}/licencePhotos/${driverId}`,
      formData,
      {
        headers: { 'content-type': 'multipart/form-data' },
      }
    );

    return data;
  }
}

export default ApiService;

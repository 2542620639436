import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import BookIcon from '@material-ui/icons/Book';

interface IProps {
  checkListInformation: string;
  displayCompleteBtn: boolean;
  navigate?: () => void;
  bookingCode: string;
  vehicleName: string;
  customImage: string;
  showImages: boolean[];
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    marginBottom: 10,
    '& h5': {
      fontWeight: 'bold',
      alignSelf: 'center',
      flex: 1,
    },
  },
  completeBtn: {
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  images: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: `1px solid ${theme.palette.primary.light}`,
    borderRadius: '6px',
    boxSizing: 'border-box',
    boxShadow: '1px 1px 4px rgba(0, 0, 0, 0.25)',
    width: '172px',
    height: '136px',
    justifyContent: 'center',
    marginRight: 50,
  },
  imagesContainer: {
    display: 'flex',
    justifyContent: 'center',
    '& div:last-child': {
      marginRight: 0,
    },
  },
  header: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    padding: '0 15px',
    '& span:first-child': {
      fontSize: 12,
      marginBottom: 5,
    },
    '& span:last-child': {
      fontWeight: 'bold',
    },
  },
  checkListInfo: {
    textAlign: 'center',
    margin: '100px 40px 50px 40px',
    fontWeight: 'bold',
    fontSize: 18,
  },
  greyLine: {
    height: 5,
    width: 1000,
    backgroundColor: theme.palette.grey[100],
  },
}));

const OnlinePrecheckIn = ({
  checkListInformation,
  displayCompleteBtn,
  navigate,
  bookingCode,
  vehicleName,
  showImages,
  customImage,
}: IProps) => {
  const classes = useStyles();

  return (
    <div>
      {!displayCompleteBtn && (
        <>
          <div className={classes.container}>
            <div className={classes.header}>
              <span>Booking code</span>
              <span>{bookingCode}</span>
            </div>
            <Typography variant="h4">Welcome to online Check-In</Typography>
            <div className={classes.header} style={{ alignItems: 'flex-end' }}>
              <span>Vehicle type</span>
              <span>{vehicleName}</span>
            </div>
          </div>
          <div className={classes.greyLine} />
        </>
      )}
      <Grid container direction="column" alignItems="center">
        <p className={classes.checkListInfo}>{checkListInformation}</p>
        {!displayCompleteBtn && (
          <div className={classes.imagesContainer}>
            {showImages[0] === true && (
              <div className={classes.images}>
                <RecentActorsIcon
                  color="secondary"
                  style={{ alignSelf: 'center', fontSize: '50px' }}
                />
                <label>Driver Licence</label>
              </div>
            )}
            {showImages[1] === true && (
              <div className={classes.images}>
                <BookIcon color="secondary" style={{ alignSelf: 'center', fontSize: '50px' }} />
                <label>Passport</label>
              </div>
            )}
            {showImages[2] === true && (
              <div className={classes.images}>
                <CreditCardIcon
                  color="secondary"
                  style={{ alignSelf: 'center', fontSize: '50px' }}
                />
                <label>Credit Card</label>
              </div>
            )}
          </div>
        )}
        {displayCompleteBtn && (
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {customImage && (
              <img
                style={{ width: '400px', height: '400px' }}
                src={customImage}
                alt="customImage"
              />
            )}

            <Button
              className={classes.completeBtn}
              style={{ width: 'fit-content', marginTop: 20, alignSelf: 'center' }}
              onClick={navigate}
            >
              View booking
            </Button>
          </div>
        )}
      </Grid>
    </div>
  );
};

export default OnlinePrecheckIn;

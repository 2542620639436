import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Skeleton from 'react-skeleton-loader';
import cx from 'classnames';

interface IProps {
  bookingCode?: string;
  vehicleClass?: string;
  loading: boolean;
}

const useStyles = makeStyles(theme => ({
  container: {
    margin: theme.spacing(1.5, 0),
  },
  label: {
    textTransform: 'uppercase',
    fontWeight: 'normal',
    [theme.breakpoints.down('xs')]: {
      fontSize: 15,
    },
  },
  mainText: {
    textTransform: 'uppercase',
    fontWeight: 'bolder',
    [theme.breakpoints.down('xs')]: {
      fontSize: 13,
    },
  },
  alignRight: {
    textAlign: 'right',
  },
}));

const ReservationInfoHeader: React.FC<IProps> = ({ bookingCode, vehicleClass, loading }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.container}>
      <Grid item xs={5}>
        <Typography variant="h6" color="inherit" className={classes.label}>
          Booking summary
        </Typography>
        <Typography variant="h4" className={classes.mainText}>
          {loading ? <Skeleton /> : bookingCode}
        </Typography>
      </Grid>
      <Grid item xs={7}>
        <Typography variant="h6" color="inherit" className={cx(classes.label, classes.alignRight)}>
          Vehicle type
        </Typography>
        <Typography variant="h4" className={cx(classes.mainText, classes.alignRight)}>
          {loading ? <Skeleton /> : vehicleClass}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default ReservationInfoHeader;

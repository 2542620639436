import React, { useEffect, useRef, useState } from 'react';
import Layout from '../components/Layout';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import SignaturePad from 'react-signature-canvas';
import {
  Button,
  DialogContent,
  DialogTitle,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { useSelector } from 'react-redux';
import { StringParam, useQueryParam } from 'use-query-params';
import {
  getLoadedReservationGuid,
  getLoadedReservationId,
  getLoadedUnitId,
} from '../store/selectors';
import useApi from '../hooks/useApi';
import _ from 'lodash';
import DamageTable from '../components/DamageTable';
import DamageDialog from '../components/DamageDialog';
import BluePrintSV from '../components/BluePrintSV';
import BluePrintToyota from '../components/BluePrintToyota';
import BluePrintPolo from '../components/BluePrintPolo';
import BluePrintKangoo from '../components/BluePrintKangoo';
import BluePrintXl from '../components/BluePrintXl';
import useNotifier from '../hooks/useNotifier';

interface ImageInput {
  id: string;
  element: JSX.Element;
}

const useStyles = makeStyles(theme => ({
  bodyWithoutScroll: {
    overflowX: 'hidden',
    width: '100%',
  },
  signaturePad: {
    border: '1px solid black',
    width: '100%',
    [theme.breakpoints.down('xs')]: {
      height: '150px',
    },
  },
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  },
  removeScroll: {
    maxWidth: '100%',
    overflowX: 'hidden',
    position: 'relative',
  },
}));

const DamageControl = () => {
  const classes = useStyles();
  const [isOpen, setIsOpen] = useState(false);
  const [partOfCar, setPartOfCar] = useState<any>({
    id: '',
    name: '',
    image: null,
    images: [],
    description: '',
    xCoordinate: '',
    yCoordinate: '',
    isCreating: false,
  });
  const [selectedImage2, setSelectedImage2] = useState(null);
  const [arrayOfImages, setArrayOfImages] = useState<any[]>([]);
  const [description, setDescription] = useState('');
  const fileRef = useRef<HTMLInputElement>(null);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const [damageCordinate, setDamageCordinate] = useState({ x: '', y: '' });
  const [guid] = useQueryParam('guid', StringParam);
  const unitId = useSelector(getLoadedUnitId);
  const api = useApi();
  const [bluePrint, setBluePrint] = useState('');
  const [bluePrintName, setBluePrintName] = useState('');
  const [snapshotData, setSnapshot] = useState<any>({});
  const [damageErrors, setDamageErrors] = useState<any>([]);
  const [isSignatureDialogOpen, setIsSignatureDialogOpen] = useState(false);
  const sigCanvas = useRef({}) as React.MutableRefObject<SignaturePad>;
  const reservationGuid = useSelector(getLoadedReservationGuid);
  const reservationId = useSelector(getLoadedReservationId);
  const [descriptionError, setDescriptionError] = useState('');
  const [imageError, setImageError] = useState('');
  const [imageInputs, setImageInputs] = useState<ImageInput[]>([]);
  const notifierSuccess = useNotifier({ dismissable: true }, { variant: 'success' });

  useEffect(() => {
    async function getDanages() {
      if (unitId !== null && reservationId !== null) {
        const { bluePrintUrl, name } = await api.getVehicleBluePrint(unitId);
        const damages = await api.getVehicleDamagesPost(unitId);
        const snapshot = await api.getSnapshot(reservationId);
        const copyOfDamages = [...damages];
        const removedFixedDamages = copyOfDamages.filter(damage => damage.repairDate === null);
        const sorted = _.orderBy(removedFixedDamages, 'id', 'asc');
        const sortedWithCustomId = sorted.map((data, index) => ({ ...data, customId: ++index }));

        if (window.outerWidth < 768) {
          for (const damage of sortedWithCustomId) {
            damage.xCoordinate = damage.xCoordinate / 2;
            damage.yCoordinate = damage.yCoordinate / 2;
          }
        }

        setBluePrintName(name);
        setDamageErrors(sortedWithCustomId);
        setBluePrint(bluePrintUrl);
        setSnapshot(snapshot);
      }
    }

    getDanages();
  }, [unitId, api, reservationId]);

  useEffect(() => {
    document.body.classList.add(classes.bodyWithoutScroll);
    const bla = document.getElementById('root');
    if (bla !== null) {
      bla.classList.add(classes.bodyWithoutScroll);
    }

    return () => {
      const bla2 = document.getElementById('root');
      if (bla2 !== null) {
        bla2.classList.remove(classes.bodyWithoutScroll);
      }
      document.body.classList.remove(classes.bodyWithoutScroll);
    };
  });

  const generateId = () => {
    return `input-${new Date().getTime()}`; // Generate a unique ID based on the timestamp
  };

  const removeImageInput = (id: string) => {
    setImageError('');
    setImageInputs(prevInputs => prevInputs.filter(input => input.id !== id));
  };

  const addImageInput = () => {
    const id = generateId();
    const element = (
      <div key={id}>
        <input
          accept="image/jpeg, image/png"
          ref={fileRef}
          type="file"
          style={{ marginBottom: '5px' }}
          onChange={fileUploadHandler}
        />
        <button onClick={() => removeImageInput(id)}>X</button>
      </div>
    );

    setImageInputs(prevInputs => [...prevInputs, { id, element }]);
  };

  // useEffect(() => {
  //   let firstMove = false;

  //   window.addEventListener('touchstart', e => {
  //     firstMove = true;
  //   });

  //   window.addEventListener('touchmove', e => {
  //     if (firstMove) {
  //       e.preventDefault();

  //       firstMove = false;
  //     }
  //   });
  // }, []);

  const onClose = () => {
    setPartOfCar({ name: '', description: '', image: null, images: [] });
    setDescriptionError('');
    setImageError('');
    setIsOpen(false);
    setSelectedImage2(null);
    setArrayOfImages([]);
    setImageInputs([]);
    setDescription('');
  };

  const createDamage = async () => {
    if (guid !== undefined && unitId !== null) {
      if (window.outerWidth < 768) {
        damageCordinate.x = (parseInt(damageCordinate.x, 10) * 2).toString();
        damageCordinate.y = (parseInt(damageCordinate.y, 10) * 2).toString();
      }

      await api.addVehicleDamage(
        guid,
        arrayOfImages,
        damageCordinate.x,
        damageCordinate.y,
        description
      );

      const damages = await api.getVehicleDamages(unitId);
      const copyOfDamages = [...damages];
      const removedFixedDamages = copyOfDamages.filter(damage => damage.repairDate === null);
      const sorted = _.orderBy(removedFixedDamages, 'id', 'asc');
      const sortedWithCustomId = sorted.map((data, index) => ({ ...data, customId: ++index }));

      if (window.outerWidth < 768) {
        for (const damage of sortedWithCustomId) {
          damage.xCoordinate = damage.xCoordinate / 2;
          damage.yCoordinate = damage.yCoordinate / 2;
        }
      }

      setDamageErrors(sortedWithCustomId);
      notifierSuccess.notify('You have successfully added a damage.');
    }
  };

  const updateDamage = async () => {
    if (unitId !== null) {
      if (window.outerWidth < 768) {
        partOfCar.xCoordinate = (parseInt(partOfCar.xCoordinate, 10) * 2).toString();
        partOfCar.yCoordinate = (parseInt(partOfCar.yCoordinate, 10) * 2).toString();
      }

      await api.updateVehicleDamage(
        unitId,
        partOfCar.id,
        partOfCar.image,
        description,
        partOfCar.xCoordinate,
        partOfCar.yCoordinate
      );

      const damages = await api.getVehicleDamages(unitId);
      const copyOfDamages = [...damages];
      const removedFixedDamages = copyOfDamages.filter(damage => damage.repairDate === null);
      const sorted = _.orderBy(removedFixedDamages, 'id', 'asc');
      const sortedWithCustomId = sorted.map((data, index) => ({ ...data, customId: ++index }));

      if (window.outerWidth < 768) {
        for (const damage of sortedWithCustomId) {
          damage.xCoordinate = damage.xCoordinate / 2;
          damage.yCoordinate = damage.yCoordinate / 2;
        }
      }

      setDamageErrors(sortedWithCustomId);
      notifierSuccess.notify('You have successfully updated a damage.');
    }
  };

  const onSubmit = (isCreating: boolean) => {
    if (description.trim() === '') {
      setDescriptionError('Please enter a description.');
      return;
    }

    if (isCreating) {
      if (arrayOfImages.length === 0) {
        setImageError('Please upload at least one image.');
        return;
      }

      if (arrayOfImages.length <= imageInputs.length) {
        setImageError('Please upload optional image that you selected');
        return;
      }

      createDamage();
    } else {
      updateDamage();
    }
    setIsOpen(false);
    setArrayOfImages([]);
    setImageInputs([]);
    setDescription('');
  };

  const fileUploadHandler = (event: any) => {
    const images = [...event.target.files];
    setSelectedImage2(images[0]);
    setArrayOfImages(prev => [...prev, images[0]]);
    setImageError('');
  };

  const updateError = (id: number | null, damage: any) => {
    setPartOfCar({
      name: damage.description,
      description: damage.description,
      images: damage.damageImages,
      image: damage.damageUrl,
      id: damage.id,
      xCoordinate: damage.xCoordinate,
      yCoordinate: damage.yCoordinate,
      isCreating: false,
    });
    setIsOpen(true);
  };

  const textAreaChange = (e: any) => {
    setDescription(e.target.value);
    setDescriptionError('');
  };

  const addDamage = (e: any, carName: string) => {
    const { pageX, pageY, currentTarget, clientX, clientY } = e;
    const { left, top } = currentTarget.getBoundingClientRect();
    const image = document.getElementById('img');

    let x = 0;
    let y = 0;

    // if (window.outerWidth < 768) {
    //   if (image !== null) {
    //     x = e.touches[0].pageX - image.offsetLeft;
    //     y = e.touches[0].pageY - image.offsetTop;
    //   }
    // } else {
    //   x = clientX - left;
    //   y = clientY - top;
    // }

    if (e.touches !== undefined && image !== null) {
      x = e.touches[0].pageX - image.offsetLeft;
      y = e.touches[0].pageY - image.offsetTop;
    } else {
      x = clientX - left;
      y = clientY - top;
    }

    // const x = clientX - left;
    // const y = clientY - top;
    const xString = x.toString();
    const yString = y.toString();

    setPartOfCar({ name: carName, description: '', image: null, isCreating: true });
    setDamageCordinate({ x: xString, y: yString });
    setIsOpen(true);
  };

  const signCarCondition = async () => {
    if (unitId !== null && reservationGuid !== null && reservationId !== null) {
      const signature = sigCanvas.current.getTrimmedCanvas().toDataURL('image/png');
      await api.acceptCarCondition(reservationId, reservationGuid, unitId.toString(), signature);

      notifierSuccess.notify('You have successfully signed car condition.');
      const snapshot = await api.getSnapshot(reservationId);
      setSnapshot(snapshot);
    }
  };

  return (
    <Layout useDefaultSpacing>
      <DamageDialog
        isOpen={isOpen}
        fileRef={fileRef}
        partOfCar={partOfCar}
        onClose={onClose}
        onSubmit={isCreating => onSubmit(isCreating)}
        textAreaChange={textAreaChange}
        fileUploadHandler={fileUploadHandler}
        descriptionError={descriptionError}
        imageError={imageError}
        addImageInput={addImageInput}
        imageInputs={imageInputs}
      />

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            justifySelf: 'center',
            alignSelf: 'center',
            fontWeight: 'normal',
            textTransform: 'uppercase',
            fontSize: xs ? '1rem' : '1.25rem',
            marginBottom: '15px',
          }}
        >
          Damage Report
        </div>
        <div
          style={{
            display: 'block',
            width: '100%',
            maxWidth: '100%',
            boxSizing: 'border-box',
          }}
        >
          {bluePrintName === 'SUV' ? (
            <BluePrintSV
              unitId={unitId}
              updateError={updateError}
              addDamage={addDamage}
              blueprint={bluePrint}
              setPartOfCar={setPartOfCar}
              setIsOpen={setIsOpen}
              damageErrors={damageErrors}
            />
          ) : bluePrintName === 'Compact' ? (
            <BluePrintPolo
              unitId={unitId}
              updateError={updateError}
              addDamage={addDamage}
              blueprint={bluePrint}
              setPartOfCar={setPartOfCar}
              setIsOpen={setIsOpen}
              damageErrors={damageErrors}
            />
          ) : bluePrintName === 'Small Camper' ? (
            <BluePrintKangoo
              unitId={unitId}
              updateError={updateError}
              addDamage={addDamage}
              blueprint={bluePrint}
              setPartOfCar={setPartOfCar}
              setIsOpen={setIsOpen}
              damageErrors={damageErrors}
            />
          ) : bluePrintName === 'Large Camper' ? (
            <BluePrintXl
              unitId={unitId}
              updateError={updateError}
              addDamage={addDamage}
              blueprint={bluePrint}
              setPartOfCar={setPartOfCar}
              setIsOpen={setIsOpen}
              damageErrors={damageErrors}
            />
          ) : (
            <BluePrintToyota
              unitId={unitId}
              updateError={updateError}
              addDamage={addDamage}
              blueprint={bluePrint}
              setPartOfCar={setPartOfCar}
              setIsOpen={setIsOpen}
              damageErrors={damageErrors}
            />
          )}
        </div>

        {/* Table preview of errors */}
        <div
          style={{
            display: 'block',
            width: '100%',
            maxWidth: '100%',
            boxSizing: 'border-box',
          }}
        >
          <DamageTable damageErrors={damageErrors} unitId={unitId} onUpdate={updateError} />
        </div>

        {/* Button to open signature */}
        {snapshotData !== null && snapshotData.IsStartRentalCustomerSigned !== true && (
          <Button
            style={{ alignSelf: 'center', color: 'white' }}
            variant="contained"
            color="primary"
            onClick={() => {
              setIsSignatureDialogOpen(true);
            }}
          >
            Sign
          </Button>
        )}
      </div>

      {/* Dialog for signing condition */}
      <Dialog
        onClose={() => {
          return;
        }}
        open={isSignatureDialogOpen}
      >
        <DialogTitle>Sign Damage Report</DialogTitle>

        <DialogContent dividers>
          <Typography gutterBottom>By signing you accept condition of the car.</Typography>
          <SignaturePad
            ref={sigCanvas}
            clearOnResize={false}
            canvasProps={{ className: classes.signaturePad }}
          />

          <Button
            onClick={() => {
              setIsSignatureDialogOpen(false);
            }}
          >
            Cancel
          </Button>

          <Button
            variant="outlined"
            style={{ borderColor: 'green', color: 'green' }}
            onClick={() => {
              signCarCondition();
              setIsSignatureDialogOpen(false);
            }}
          >
            Sign the condition of the car
          </Button>
        </DialogContent>
      </Dialog>
    </Layout>
  );
};

export default DamageControl;

import * as tenantSelectors from './tenant/selectors';
import * as themeSelectors from './theme/selectors';
import * as authSelectors from './auth/selectors';
import {
  selectLoadedReservation,
  selectLoadedReservationPrices,
  selectLoadedReservationDates,
  selectReservationLoading,
  selectAvailableExtras,
  selectAvailableInsurances,
  selectExtensionState,
  selectLoadedGuid,
  selectLoadingStates,
  selectErrors,
  selectPaymentState,
  selectExtrasState,
  selectInsurancesState,
  selectSecurePaymentForm,
  selectReservationCheckedInStatus,
  selectReservationTermsAndConditions,
  selectLoadedReservationSelfService,
  selectIsRentalSigned,
  selectUnitId,
  selectLoadedId,
} from './reservations/selectors';
import { IApplicationState } from './types';
export const getTenantConfig = (state: IApplicationState) =>
  tenantSelectors.getTenantConfig(state.tenant);
export const getLoginStatus = (state: IApplicationState) => authSelectors.isLoggedIn(state.auth);
export const getAuthState = (state: IApplicationState) => state.auth;
export const getRouterState = (state: IApplicationState) => state.router;
export const selectApiToken = (state: IApplicationState) =>
  authSelectors.getAccessToken(state.auth);
export const getTokenExpiryTimestamp = (state: IApplicationState) =>
  authSelectors.getTokenExpiryTimestamp(state.auth);

export function selectTheme(state: IApplicationState) {
  return themeSelectors.getTheme(state.theme);
}

export function selectTenantValues(state: IApplicationState) {
  return tenantSelectors.getTenantConfig(state.tenant);
}

export function selectTenantId(state: IApplicationState) {
  return tenantSelectors.getTenantId(state.tenant);
}

export function selectTenantFetched(state: IApplicationState) {
  return tenantSelectors.getTenantFetched(state.tenant);
}

export function selectTenantSitewideMessage(state: IApplicationState) {
  return tenantSelectors.getSitewideMessage(state.tenant);
}

export function selectBokunChannelUUID(state: IApplicationState) {
  return tenantSelectors.getBokunUUID(state.tenant);
}

export function selectOnlineCheckIn(state: IApplicationState) {
  return tenantSelectors.getOnlineCheckIn(state.tenant);
}

export function selectGracePeriods(state: IApplicationState) {
  return tenantSelectors.getGracePeriods(state.tenant);
}

export function selectPlaceProductsButtonLabel(state: IApplicationState) {
  return tenantSelectors.getPlaceProductsButtonLabel(state.tenant);
}

export function selectTenantError(state: IApplicationState) {
  return tenantSelectors.getTenantError(state.tenant);
}

export function selectAppMetadata(state: IApplicationState) {
  return authSelectors.selectAppMetadata(state.auth);
}

export function selectUserMetadata(state: IApplicationState) {
  return authSelectors.selectUserMetadata(state.auth);
}

export function selectEmailVerified(state: IApplicationState) {
  return authSelectors.selectEmailVerified(state.auth);
}

export function selectUserEmail(state: IApplicationState) {
  return authSelectors.selectUserEmail(state.auth);
}

export function selectCompleteProfileState(state: IApplicationState) {
  return state.completeProfile;
}

export function selectRouterState(state: IApplicationState) {
  return state.router;
}

export function selectCurrentPathname(state: IApplicationState) {
  return selectRouterState(state).location.pathname;
}

export function selectPlacesState(state: IApplicationState) {
  return state.places;
}

export function selectCustomerTripState(state: IApplicationState) {
  return state.customerTrip;
}

export function getReservationsState(state: IApplicationState) {
  return state.reservations;
}

export function getLoadedReservation(state: IApplicationState) {
  return selectLoadedReservation(state.reservations);
}

export function getLoadedUnitId(state: IApplicationState) {
  return selectUnitId(state.reservations);
}

export function getLoadedReservationSelfService(state: IApplicationState) {
  return selectLoadedReservationSelfService(state.reservations);
}

export function getLoadedReservationPrices(state: IApplicationState) {
  return selectLoadedReservationPrices(state.reservations);
}

export function getLoadedReservationDates(state: IApplicationState) {
  return selectLoadedReservationDates(state.reservations);
}

export function getReservationLoading(state: IApplicationState) {
  return selectReservationLoading(state.reservations);
}

export function getReservationCheckedInStatus(state: IApplicationState) {
  return selectReservationCheckedInStatus(state.reservations);
}

export function getIsRentalSigned(state: IApplicationState) {
  return selectIsRentalSigned(state.reservations);
}

export function getReservationsTermsAndConditionsStatus(state: IApplicationState) {
  return selectReservationTermsAndConditions(state.reservations);
}

export function getExtrasState(state: IApplicationState) {
  return selectExtrasState(state.reservations);
}

export function getAvailableExtras(state: IApplicationState) {
  return selectAvailableExtras(state.reservations);
}

export function getInsurancesState(state: IApplicationState) {
  return selectInsurancesState(state.reservations);
}

export function getAvailableInsurances(state: IApplicationState) {
  return selectAvailableInsurances(state.reservations);
}

export function getReservationExtensionState(state: IApplicationState) {
  return selectExtensionState(state.reservations);
}

export function getLoadedReservationGuid(state: IApplicationState) {
  return selectLoadedGuid(state.reservations);
}

export function getLoadedReservationId(state: IApplicationState) {
  return selectLoadedId(state.reservations);
}

export function getLoadingStates(state: IApplicationState) {
  return selectLoadingStates(state.reservations);
}

export function getReservationErrors(state: IApplicationState) {
  return selectErrors(state.reservations);
}

export function getReservationPayment(state: IApplicationState) {
  return selectPaymentState(state.reservations);
}

export function get3dSecurePaymentForm(state: IApplicationState) {
  return selectSecurePaymentForm(state.reservations);
}

import React, { useState, useEffect } from 'react';
import TagManager, { TagManagerArgs } from 'react-gtm-module';
import useRouterState from '../hooks/useRouterState';

interface IProps {
  gtmId: string | null;
}

const Gtag: React.FC<IProps> = ({ gtmId }) => {
  const routes = useRouterState();
  const [isInitialized, setInitialized] = useState(false);

  useEffect(() => {
    if (gtmId) {
      const tagManagerArgs: TagManagerArgs = {
        gtmId,
      };
      TagManager.initialize(tagManagerArgs);
      setInitialized(true);
    }
  }, [gtmId]);

  useEffect(() => {
    if (isInitialized) {
      TagManager.dataLayer({
        dataLayer: {
          event: 'HistoryChange',
          hitType: 'pageview',
        },
      });
    }
  }, [routes.location.pathname, isInitialized]);
  return null;
};

export default Gtag;

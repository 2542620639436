/* global google */
import { createContext } from 'react';

export interface IMapsContext {
  map: google.maps.Map | null;
  maps: typeof google.maps | null;
  isLoaded: boolean;
}

export default createContext<IMapsContext>({ map: null, maps: null, isLoaded: false });

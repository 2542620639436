import React from 'react';
import { History, UnregisterCallback } from 'history';

import BasicPage from './BasicPage';
import { captureException } from '../sentry';

interface IProps {
  history: History;
}

interface IState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<IProps, IState> {
  static getDerivedStateFromError() {
    return { hasError: true };
  }

  readonly state = { hasError: false };
  unlisten: UnregisterCallback | null = null;

  componentDidMount() {
    this.unlisten = this.props.history.listen(() => {
      if (this.state.hasError) {
        this.setState({ hasError: false });
      }
    });
  }

  componentWillUnmount() {
    if (this.unlisten) {
      this.unlisten();
    }
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    captureException(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <BasicPage title="Something went wrong" />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { selectTenantSitewideMessage } from '../store/selectors';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.primary.light,
    textAlign: 'center',
  },
  content: {
    padding: theme.spacing(2),
  },
  contrastText: {
    color: theme.palette.primary.contrastText,
  },
  text: {
    padding: theme.spacing(0, 5),
  },
  dismiss: {
    position: 'absolute',
    top: 0,
    right: 0,
  },
}));

const SitewideBanner: React.FC = () => {
  const [isDismissed, setIsDismissed] = useState(false);
  const classes = useStyles();
  const message = useSelector(selectTenantSitewideMessage);

  if (!message || isDismissed) {
    return null;
  }

  return (
    <Paper elevation={1} variant="outlined" square className={classes.root}>
      <div className={classes.content}>
        <Typography className={cx(classes.text, classes.contrastText)}>{message}</Typography>
        <IconButton
          className={cx(classes.dismiss, classes.contrastText)}
          onClick={() => setIsDismissed(true)}
          aria-label="dismiss"
        >
          <CloseIcon className={classes.contrastText} />
        </IconButton>
      </div>
    </Paper>
  );
};

export default SitewideBanner;

import createReducer from '../createReducer';
import * as A from './actions';
import * as T from './types';
import defaultTenantConfig from './defaultTenantConfig';

const initialState: T.ITenantState = {
  didFetch: false,
  error: '',
  values: defaultTenantConfig,
};

const tenantReducer = createReducer(initialState, {
  [A.TENANT_RESOLVE]: (state, action) => ({
    ...state,
    values: action.payload,
    error: '',
    didFetch: true,
  }),
  [A.TENANT_REJECT]: (state, action) => ({
    ...state,
    didFetch: false,
    error: action.payload,
  }),
});

export default tenantReducer;

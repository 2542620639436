import React from 'react';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Layout from '../components/Layout';

interface IProps {
  title: string;
  text?: string;
}

const useStyles = makeStyles(theme => ({
  container: {
    maxWidth: theme.dimensions.maxLayoutWidth,
    margin: 'auto',
    marginBottom: 200,
  },
  text: {
    marginTop: theme.spacing(3),
  },
}));

const BasicPage: React.FC<IProps> = ({ title, text, children }) => {
  const classes = useStyles();

  return (
    <Layout useDefaultSpacing>
      <div className={classes.container}>
        <Typography variant="h4">{title}</Typography>
        {text && (
          <Typography variant="body1" className={classes.text}>
            {text}
          </Typography>
        )}
        {children}
      </div>
    </Layout>
  );
};

export default BasicPage;

import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Fade from '@material-ui/core/Fade/Fade';
import Grow from '@material-ui/core/Grow/Grow';
import Typography from '@material-ui/core/Typography';
import cx from 'classnames';

interface IProps {
  placeholder: string;
  question?: string;
  cancelPlaceholder: string;
  confirmPlaceholder: string;
  confirm(): void;
}

const useStyles = makeStyles(theme => ({
  cancelButton: {
    border: `1px solid ${theme.palette.secondary.main}`,
  },
  marginTop: { marginTop: 33 },
  marginTextBottom: { marginBottom: 10, fontWeight: 'bold' },
  buttonSpace: {
    marginRight: 10,
  },
  redButton: {
    backgroundColor: theme.palette.error.main,
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.error.light,
    },
  },
  moveRight: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

const ConfirmButton: React.FC<IProps> = props => {
  const classes = useStyles();
  const [pressed, togglePressed] = useState(false);
  const { placeholder, question, cancelPlaceholder, confirmPlaceholder, confirm } = props;
  return (
    <React.Fragment>
      {!pressed ? (
        <div className={classes.marginTop}>
          <Fade in={!pressed} timeout={500}>
            <Button
              className={classes.cancelButton}
              color="secondary"
              onClick={() => togglePressed(true)}
            >
              {placeholder}
            </Button>
          </Fade>
        </div>
      ) : (
        <React.Fragment>
          {!!question && (
            <Grow in={pressed} timeout={500}>
              <Typography variant="body1" className={classes.marginTextBottom}>
                {question}
              </Typography>
            </Grow>
          )}
          <div className={classes.moveRight}>
            <Grow in={pressed} timeout={500}>
              <Button
                className={cx(classes.cancelButton, classes.buttonSpace)}
                color="secondary"
                onClick={() => togglePressed(false)}
              >
                {cancelPlaceholder}
              </Button>
            </Grow>
            <Grow in={pressed} timeout={500}>
              <Button
                className={classes.redButton}
                variant="contained"
                onClick={() => {
                  confirm();
                  togglePressed(false);
                }}
              >
                {confirmPlaceholder}
              </Button>
            </Grow>
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ConfirmButton;

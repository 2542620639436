import React from 'react';
import moment from 'moment';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import RouterLinkForwardRef from './RouterLinkForwardRef';
import IReservationLite from '../interfaces/IReservationLite';
import { DATE_AND_TIME_FORMAT } from '../constants';

interface IProps {
  reservation: IReservationLite;
  userEmail: string;
  status: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: 0,
  },
  title: {
    fontSize: 14,
    marginBottom: 0,
  },
  bookingCode: {
    fontSize: 18,
  },
  pos: {
    marginBottom: theme.spacing(1.5),
  },
  imageSize: {
    maxWidth: '100%',
    objectFit: 'contain',
    margin: theme.spacing(1.25),
  },
  location: {
    backgroundColor: theme.customPalette.infoBox.default,
    padding: theme.spacing(1.25, 2),
  },
  bookingPadding: {
    padding: theme.spacing(2, 2, 0, 2),
  },
  actionsPadding: {
    padding: theme.spacing(2),
  },
  viewButton: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  status: {
    color: theme.palette.secondary.main,
  },
  vehicleMargin: {
    margin: theme.spacing(2.5, 0),
  },
}));

const ReservationCard: React.FC<IProps> = ({ reservation, userEmail, status }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { dateFrom, dateTo, vehicle, location } = reservation;

  return (
    <Card>
      <CardContent className={classes.root}>
        <Grid container direction="row" justify="space-between" className={classes.bookingPadding}>
          <Grid item xs={7}>
            <Typography className={classes.title} color="textSecondary" gutterBottom>
              Booking reference
            </Typography>
            <Typography className={classes.bookingCode} color="textSecondary" gutterBottom>
              {reservation.bookingCode}
            </Typography>
            <div className={classes.vehicleMargin}>
              <Typography variant="h4">{vehicle.class}</Typography>
            </div>
          </Grid>
          <Grid item xs={5} style={{ display: 'flex', justifyContent: 'center' }}>
            {vehicle.image && (
              <img src={vehicle.image} alt="vehicle" className={classes.imageSize} />
            )}
          </Grid>
        </Grid>
        <Grid container direction="row" justify="space-between" className={classes.location}>
          <Grid item xs={6}>
            <Typography variant="body1">Pick up</Typography>
            <Typography variant="body2">{location.pickupName}</Typography>
            <Typography variant="body2">
              {dateFrom
                ? moment(dateFrom)
                    .utc()
                    .format(DATE_AND_TIME_FORMAT)
                : null}
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant="body1">Drop off</Typography>
            <Typography variant="body2">{location.dropoffName}</Typography>
            <Typography variant="body2">
              {dateTo
                ? moment(dateTo)
                    .utc()
                    .format(DATE_AND_TIME_FORMAT)
                : null}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions className={classes.actionsPadding}>
        <Grid container direction="row" justify="space-between">
          <Grid item xs={6}>
            <Typography>Booking Status:{getStatus()}</Typography>
          </Grid>
          <Grid item xs={6} className={classes.viewButton}>
            <Button
              size="small"
              component={RouterLinkForwardRef}
              variant="contained"
              color="secondary"
              to={`/bookings/view?email=${userEmail}&bookingCode=${reservation.bookingCode}`}
            >
              Manage Booking
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  );

  function getStatus() {
    let fontColor = '';
    switch (status) {
      case 'Cancelled':
        fontColor = theme.palette.error.light;
        break;
      case 'Completed':
        fontColor = theme.palette.grey[600];
        break;
      case 'Upcoming':
        fontColor = theme.palette.secondary.main;
        break;
      case 'Ongoing':
        fontColor = 'green';
        break;
    }
    return (
      <strong className={classes.status} style={{ color: fontColor }}>
        {' '}
        {status}
      </strong>
    );
  }
};

export default ReservationCard;

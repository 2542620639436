import { getType, ActionType } from 'typesafe-actions';
import { put, delay, select, takeLatest, call } from 'redux-saga/effects';
import ApiService from '../../services/ApiService';
import { LOGGED_IN } from '../auth/actions';
import { showDialog, skip, submitProfile, profileUpdated, profileError } from './actions';
import { enqueueNotification } from '../notifier/actions';
import {
  selectUserMetadata,
  selectApiToken,
  selectEmailVerified,
  selectTenantId,
} from '../selectors';
import { renderDismissButton } from '../../components/Notifier';

function* showWelcomeDialogIfRequired() {
  const emailVerified: ReturnType<typeof selectEmailVerified> = yield select(selectEmailVerified);
  const userMetadata: ReturnType<typeof selectUserMetadata> = yield select(selectUserMetadata);

  if (!userMetadata) {
    return;
  }

  const { didFillProfile, didSkipProfile } = userMetadata;
  if (emailVerified && !didFillProfile && !didSkipProfile) {
    yield delay(1300);
    yield put(showDialog());
  }
}

function* handleSkip(action: ActionType<typeof skip>) {
  const { skipPermanently } = action.payload;

  if (skipPermanently) {
    const apiToken: ReturnType<typeof selectApiToken> = yield select(selectApiToken);
    const tenantId: ReturnType<typeof selectTenantId> = yield select(selectTenantId);

    const api = getApiService(apiToken, tenantId);
    try {
      yield call(() => api.setCompleteProfileSkippedFlag());
      yield put(
        enqueueNotification("OK, we won't bother you with this again", {
          variant: 'info',
          autoHideDuration: 2500,
        })
      );
    } catch (error) {
      yield put({ type: 'WHOA' });
    }
  }
}

function* handleSubmit(action: ActionType<typeof submitProfile>) {
  // const apiToken: ReturnType<typeof selectApiToken> = yield select(selectApiToken);
  // const api = getApiService(apiToken);

  try {
    yield delay(3000);
    yield put(profileUpdated());
  } catch (error) {
    yield put(profileError());
    yield put(
      enqueueNotification('An error occured, please try again', {
        variant: 'error',
        persist: true,
        key: new Date().getTime() + Math.random(),
        action: key => renderDismissButton('OK', key),
      })
    );
  }
}

function* handleProfileUpdated() {
  const apiToken: ReturnType<typeof selectApiToken> = yield select(selectApiToken);
  const tenantId: ReturnType<typeof selectTenantId> = yield select(selectTenantId);

  const api = getApiService(apiToken, tenantId);

  try {
    yield call(() => api.setCompleteProfileFilledFlag());
    yield put(
      enqueueNotification('Profile updated. Thanks!', {
        variant: 'success',
        autoHideDuration: 2500,
      })
    );
  } catch (error) {
    yield put({ type: 'SET_PROFILE_FILLED_FLAG_FAILED' });
  }
}

const sagas = [
  takeLatest(LOGGED_IN, showWelcomeDialogIfRequired),
  takeLatest(getType(skip), handleSkip),
  takeLatest(getType(submitProfile), handleSubmit),
  takeLatest(getType(profileUpdated), handleProfileUpdated),
];

export default sagas;

function getApiService(accessToken: string | null, tenantId: string | null) {
  if (!accessToken) {
    throw new Error('API Token not present');
  }

  return new ApiService({ accessToken, tenantId });
}

export const LOGIN_ROUTE = '/login';
export const SIGNUP_ROUTE = '/signup';
export const CALLBACK_ROUTE = '/callback';
export const EMAIL_VERIFIED_ROUTE = '/email_verified';
export const CUSTOM_CLAIM_NAMESPACE = 'https://my-caren.com/';
export const DEFAULT_PALETTE_PRIMARY = '#0db8d5';
export const DEFAULT_PALETTE_SECONDARY = '#0067c5';
export const NAVBAR_HEIGHT = 64;
export const DATE_FORMAT = 'dddd, MMM DD, YYYY';
export const TIME_FORMAT = 'HH:mm';
export const DATE_AND_TIME_FORMAT = 'dddd, MMM DD, YYYY HH:mm';
export const PLACES_RESPONSE_SIZE = 10;

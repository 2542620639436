import { ActionType, getType } from 'typesafe-actions';
import { put, takeLatest, call, actionChannel, fork, take } from 'redux-saga/effects';
import { buffers } from 'redux-saga';

import * as A from './actions';
import { enqueueNotification } from '../notifier/actions';
import { getApiService } from '../sagaUtils';
import { captureException } from '../../sentry';

function* fetchCustomerTrip(action: ActionType<typeof A.fetchCustomerTrip>) {
  const api = yield getApiService();

  try {
    const trip = yield call(() => api.fetchCustomerTripById(action.payload));
    yield put(A.fetchCustomerTripSuccess(trip));
  } catch (error) {
    yield put(A.fetchCustomerTripError(error));
    captureException(error);
  }
}

function* calculateCustomerTripRoute(action: ActionType<typeof A.calculateCustomerTripRoute>) {
  const api = yield getApiService();

  try {
    let route = null;
    if (action.payload.length > 1) {
      route = yield call(() => api.calculateRoute(action.payload));
    }
    yield put(A.calculateCustomerTripRouteSuccess(route));
  } catch (error) {
    // TODO: Create an ApiError class for the ApiService to throw
    const errorWithMessage = (error.response && error.response.data) || error;
    yield put(A.calculateCustomerTripRouteError(errorWithMessage));
  }
}

function* routeError(action: ActionType<typeof A.calculateCustomerTripRouteError>) {
  const notifierAction = enqueueNotification(
    'Unable to calculate route, one or more destinations are unreachable by vehicle.',
    {
      variant: 'warning',
      persist: false,
      preventDuplicate: false,
    }
  );

  yield put(notifierAction);
  captureException(action.payload);
}

function* callUpdateCustomerTrip(action: ActionType<typeof A.updateCustomerTrip>) {
  yield put(A.callUpdateCustomerTrip());
  const api = yield getApiService();
  const { id, trip } = action.payload;

  try {
    const updated = yield call(() => api.updateCustomerTrip(id, trip));
    yield put(A.updateCustomerTripSuccess(updated));
  } catch (error) {
    yield put(A.updateCustomerTripError(error));
    captureException(error);
  }
}

const updateCustomerTripQueue = () =>
  fork(function*() {
    const requestChannel = yield actionChannel(getType(A.updateCustomerTrip), buffers.sliding(1));

    while (true) {
      const action = yield take(requestChannel);
      yield call(callUpdateCustomerTrip, action);
    }
  });

const sagas = [
  takeLatest(getType(A.fetchCustomerTrip), fetchCustomerTrip),
  takeLatest(getType(A.calculateCustomerTripRoute), calculateCustomerTripRoute),
  takeLatest(getType(A.calculateCustomerTripRouteError), routeError),
  updateCustomerTripQueue(),
];

export default sagas;

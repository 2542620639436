// ReservationPaymentDialog

import React, { useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import DoneOutlineIcon from '@material-ui/icons/DoneOutline';
import 'react-credit-cards/es/styles-compiled.css';
import { ICardInfo } from '../interfaces/IReservation';
import Cards, { CallbackArgument } from 'react-credit-cards';
import cx from 'classnames';
import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  Grid,
  TextField,
} from '@material-ui/core';
import moment from 'moment';
import { IFormCard, IValidCard, TFocused } from '../interfaces/IPayment';
import { formatCreditCardNumber, formatExpirationDate, formatCVC } from '../utils/creditCard';

interface IProps {
  open: boolean;
  onClose: () => void;
  balance: string;
  payBalance: (cardInfo: ICardInfo) => void;
  paymentLoading: boolean;
  paymentError: string;
  paymentSuccess: boolean;
  resetPayBalance: () => void;
  valitor: string;
  shouldBeValitor: boolean;
}

const useStyles = makeStyles(theme => ({
  cardItem: {
    display: 'flex',
    alignItems: 'center',
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 1,
      padding: 10,
    },
  },
  formItem: {
    display: 'flex',
    alignItems: 'center',
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  dialogActionsButton: {
    margin: theme.spacing(1),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
    height: '50%',
  },
  alignCenter: {
    alignItems: 'center',
  },
  loadingSpinner: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  dialogHeight: {
    minHeight: 250,
    height: 400,
    display: 'flex',
  },
  mobileDialogHeight: {
    [theme.breakpoints.down('sm')]: {
      maxHeight: 150,
    },
  },
  enlargeIcons: {
    transform: 'scale(2)',
  },
  valitor: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
}));

const ReservationPaymentDialog: React.FC<IProps> = props => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();

  const {
    open,
    onClose,
    balance,
    valitor,
    payBalance,
    paymentSuccess,
    paymentError,
    paymentLoading,
    resetPayBalance,
    shouldBeValitor,
  } = props;

  const initialCardState: IFormCard = {
    name: '',
    number: '',
    cvc: '',
    issuer: '',
    expiry: '',
  };
  const initialCardValidState: IValidCard = {
    validNumber: false,
    validExpiry: true,
    validCvc: true,
    validName: true,
  };

  const [cardState, setCardState] = useState<IFormCard>(initialCardState);
  const [validCard, setCardValid] = useState<IValidCard>(initialCardValidState);
  const [focused, setFocus] = useState<TFocused>('name');
  const [validForm, setValidForm] = useState(true);

  // useEffect(() => {
  //   setTimeout(() => {
  //     const script = document.getElementById('carenPaymentsCreditCardFormScriptsTag');
  //     if (open === true && script !== null && script.innerHTML !== '') {

  //       /* tslint-disable */
  //       // eval(script.innerHTML);
  //     }
  //   }, 0);
  // }, [open, valitor]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth fullScreen={xs}>
      <DialogTitle>
        <Typography variant="body1">Pay remaining balance: {balance}</Typography>
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
        <div style={{ display: 'flex', justifyContent: 'center', alignContent: 'center' }} />
      </DialogTitle>
      {shouldBeValitor ? (
        <>
          <div
            id="valitor"
            className={classes.valitor}
            dangerouslySetInnerHTML={{ __html: valitor }}
          />
        </>
      ) : (
        <>
          <DialogContent dividers className={classes.dialogHeight}>
            {paymentLoading ? (
              <Grid container>
                <Grid item xs={12} className={classes.loadingSpinner}>
                  <CircularProgress color="primary" className={classes.enlargeIcons} />
                </Grid>
              </Grid>
            ) : paymentSuccess ? (
              <Grid container alignItems="center" className={classes.mobileDialogHeight}>
                <Grid item xs={12} className={cx(classes.centerContent, classes.alignCenter)}>
                  <DoneOutlineIcon
                    fontSize="large"
                    color="primary"
                    className={classes.enlargeIcons}
                  />
                </Grid>
                <Grid item xs={12} className={classes.centerContent}>
                  <Typography variant="h4">Payment Successful!</Typography>
                </Grid>
              </Grid>
            ) : paymentError ? (
              <React.Fragment>
                <Grid container alignItems="center" className={classes.mobileDialogHeight}>
                  <Grid item xs={12} className={cx(classes.centerContent, classes.alignCenter)}>
                    <ErrorOutlineIcon
                      fontSize="large"
                      color="error"
                      className={classes.enlargeIcons}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.centerContent}>
                    <Typography variant="h4">{paymentError}</Typography>
                  </Grid>
                </Grid>
              </React.Fragment>
            ) : (
              renderCardForm()
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={closeCardDialog} disabled={paymentLoading} hidden={paymentSuccess}>
              {paymentSuccess ? 'close' : 'cancel'}
            </Button>
            {!paymentError && !paymentLoading && !paymentSuccess && (
              <Button
                variant="contained"
                color="secondary"
                onClick={() => confirmPayment()}
                className={classes.dialogActionsButton}
              >
                Confirm
              </Button>
            )}
            {paymentError && (
              <Button
                variant="outlined"
                onClick={resetPayBalance}
                className={classes.dialogActionsButton}
              >
                Try again
              </Button>
            )}
            {paymentLoading && (
              <Button variant="contained" disabled className={classes.dialogActionsButton}>
                <CircularProgress size={24} color="inherit" />
              </Button>
            )}
          </DialogActions>
        </>
      )}
    </Dialog>
  );

  function renderCardForm() {
    return (
      <Grid container direction="row" justify="center" spacing={2}>
        <Grid item xs={12} md={6} className={classes.formItem}>
          <Grid container justify="flex-end" spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Card holder"
                fullWidth
                type="text"
                name="name"
                color="secondary"
                required
                error={!validForm && !validCard.validName}
                onChange={handleInputChange}
                onFocus={() => setFocus('name')}
                value={cardState.name}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                label="Credit card number"
                fullWidth
                type="text"
                name="number"
                color="secondary"
                required
                error={!validForm && !validCard.validNumber}
                value={cardState.number}
                onChange={handleInputChange}
                onFocus={() => setFocus('number')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                label="Expiry date"
                fullWidth
                type="tel"
                name="expiry"
                color="secondary"
                required
                error={!validForm && !validCard.validExpiry}
                helperText={'MM/YY'}
                value={cardState.expiry}
                onChange={handleInputChange}
                onFocus={() => setFocus('expiry')}
              />
            </Grid>
            <Grid item xs={6} style={{ paddingLeft: 10 }}>
              <TextField
                label="Security code"
                fullWidth
                type="tel"
                name="cvc"
                color="secondary"
                required
                error={!validForm && !validCard.validCvc}
                value={cardState.cvc}
                onChange={handleInputChange}
                onFocus={() => setFocus('cvc')}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} md={6} className={classes.cardItem}>
          <Cards
            number={cardState.number}
            name={cardState.name}
            expiry={cardState.expiry}
            cvc={cardState.cvc}
            focused={focused}
            callback={handleCreditCardCallback}
            preview
          />
        </Grid>
      </Grid>
    );
  }

  function closeCardDialog() {
    onClose();
    setCardState(initialCardState);
    setCardValid(initialCardValidState);
    setValidForm(true);
    resetPayBalance();
  }

  function handleCreditCardCallback(type: CallbackArgument, isValid: boolean) {
    if (isValid) {
      setCardState(prev => ({ ...prev, issuer: type.issuer }));
    }
    if (isValid !== validCard.validNumber) {
      setCardValid(prev => ({ ...prev, validNumber: isValid }));
    }
  }

  function handleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    if (event) {
      const fieldName = event.target.name as any;
      let fieldValue = event.target.value as any;
      if (fieldName === 'number') {
        fieldValue = formatCreditCardNumber(fieldValue);
      } else if (fieldName === 'expiry') {
        fieldValue = formatExpirationDate(fieldValue);
      } else if (fieldName === 'cvc') {
        fieldValue = formatCVC(fieldValue, cardState);
      }
      setValidForm(true);
      setCardState(prev => ({ ...prev, [fieldName]: fieldValue }));
    }
  }

  function validateCreditCard() {
    const { issuer, expiry, cvc } = cardState;
    const { validNumber } = validCard;
    const validName = !!cardState.name;
    const splitExpiry = expiry.split('/');
    const thisMonth = parseInt(moment(new Date()).format('MM'), 10);
    const thisYear = parseInt(moment(new Date()).format('YY'), 10);
    const splitMonth = parseInt(splitExpiry[0], 10);
    const splitYear = parseInt(splitExpiry[1], 10);
    const validExpiry =
      splitMonth <= 12 &&
      splitMonth > 0 &&
      (splitYear > thisYear || (splitYear === thisYear && splitMonth >= thisMonth));
    const validCvc =
      (issuer === 'amex' && cvc.length === 4) || (issuer !== 'amex' && cvc.length === 3);
    setCardValid(prev => ({ ...prev, validExpiry, validCvc, validName }));
    setValidForm(validNumber && validExpiry && validCvc && validName);
    return validNumber && validExpiry && validCvc && validName;
  }

  function confirmPayment() {
    if (validateCreditCard() && !paymentLoading) {
      const expirationMonth = parseInt(cardState.expiry.split('/')[0], 10);
      const expirationYear = parseInt(cardState.expiry.split('/')[1], 10);
      const cardInfo: ICardInfo = {
        cardNumber: cardState.number.replace(/\D/g, ''),
        cardHolder: cardState.name,
        cardType: cardState.issuer,
        cvc: cardState.cvc,
        expirationMonth,
        expirationYear,
      };
      payBalance(cardInfo);
    }
  }
};

export default ReservationPaymentDialog;

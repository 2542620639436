import React from 'react';
import cx from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';

import Footer from './Footer';
import Navbar from './Navbar';

interface IProps {
  contentClassName?: string;
  useDefaultSpacing?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    minHeight: '100vh',
    overflowX: 'hidden',
    maxWidth: '100%',
    width: '100%',
  },
  content: {
    flex: 1,
  },
  defaultSpacing: {
    marginTop: theme.spacing(6),
    marginBottom: theme.spacing(10),
    padding: theme.spacing(0, 3),
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(4),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(0, 2),
    },
  },
}));

const Layout: React.FC<IProps> = ({ children, contentClassName, useDefaultSpacing }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Navbar />
      <section
        className={cx(
          classes.content,
          { [classes.defaultSpacing]: useDefaultSpacing },
          contentClassName
        )}
      >
        {children}
      </section>
      <Footer />
    </div>
  );
};

export default Layout;

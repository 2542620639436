import React, { useState } from 'react';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';

import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Formik, Form, Field, FieldProps, FormikActions, FormikProps } from 'formik';
import * as Yup from 'yup';

import InfoBox from './InfoBox';
import CircularIconButton from './CircularIconButton';
import Input from './Input';
import { useSelector } from 'react-redux';
import { getLoadingStates } from '../store/selectors';
import Dropdown from './Dropdown';
export interface IItem {
  label: string;
  value: string | null;
  name?: string;
  required?: boolean;
  disableEdit?: boolean;
}

interface IProps {
  title: string | null;
  items: IItem[];
  countriesItems: any[];
  editable?: boolean;
  isFromCheckIn?: boolean;
  onUpdate?: (values: { [name: string]: string }) => void;
  onUpdatingCustomerInfo: (change: boolean) => void;
}

const schema = Yup.object().shape({
  firstName: Yup.string().required('Required'),
  lastName: Yup.string().required('Required'),
  mobile: Yup.string().required('Required'),
  city: Yup.string().required('Required'),
  zipCode: Yup.string().required('Required'),
  countryId: Yup.string()
    .required('Required')
    .test('selectCountry', 'Required', value => value !== '0'),
});

const useStyles = makeStyles(theme => ({
  container: {
    width: '60%',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  editButton: {
    margin: theme.spacing(0, 1),
  },
  text: {
    fontWeight: 'bold',
  },
  error: {
    borderColor: '#f44336',
  },
  buttonContainer: {
    marginTop: theme.spacing(2),
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  dropDown: {
    height: '34px',
    width: '60%',
    backgroundColor: 'white',
    '& div': {
      height: '34px',
      '& fieldset': {
        borderColor: '#e0e0e0',
      },
      '& div': {
        padding: '0 0 0 10px',
        display: 'flex',
        alignItems: 'center',
      },
    },
  },
}));

const InformationItems: React.FC<IProps> = ({
  title,
  items,
  editable = false,
  onUpdate,
  isFromCheckIn = false,
  onUpdatingCustomerInfo,
  countriesItems,
}) => {
  const [isEditing, setEditing] = useState(false);
  const classes = useStyles();
  const { isUpdatingCustomer } = useSelector(getLoadingStates);
  const [openDropdown, setOpenDropdown] = useState(false);
  const columnUnits = items.length <= 6 ? 12 : 6;

  return (
    <InfoBox className={classes.container}>
      <div className={classes.titleContainer}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h5">{title}</Typography>
          {isEditing ||
            (isFromCheckIn && <Typography variant="body2">*Required Information</Typography>)}
        </div>
        {editable && !isFromCheckIn && !isEditing && (
          <CircularIconButton onClick={() => setEditing(true)} className={classes.editButton}>
            <EditIcon fontSize="small" style={{ padding: 2 }} />
          </CircularIconButton>
        )}
      </div>
      {isEditing || isFromCheckIn ? renderEditForm(isFromCheckIn) : renderItems()}
    </InfoBox>
  );

  function renderItems() {
    return (
      <Grid container spacing={1} justify="flex-start">
        {items.map(
          ({ label, value, name, disableEdit }) =>
            (!disableEdit || value) && (
              <Grid item container key={name || label} xs={12} sm={columnUnits} alignItems="center">
                <Grid item xs={4}>
                  <Typography variant="body1">{label}</Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography variant="body1" className={classes.text}>
                    {name === 'countryId' ? displayCountryName(value) : value}
                  </Typography>
                </Grid>
              </Grid>
            )
        )}
      </Grid>
    );
  }

  function displayCountryName(value: any) {
    if (countriesItems !== undefined && countriesItems.length > 0) {
      const selectedCountry = countriesItems.find(country => country.value === parseInt(value, 10));
      return selectedCountry.label;
    }
  }

  function renderEditForm(isFromCheckInBoolean: boolean = false) {
    const itemsObj: { [name: string]: string } = {};
    items.forEach(({ label, value, name, disableEdit }) => {
      if (!disableEdit) {
        itemsObj[name || label] = value || '';
      }
    });

    const isFormValid = schema.isValidSync(itemsObj);

    if (onUpdatingCustomerInfo !== undefined) {
      if (isFormValid) {
        onUpdatingCustomerInfo(true);
      } else {
        onUpdatingCustomerInfo(false);
      }
    }

    return (
      <Formik
        initialValues={itemsObj}
        isInitialValid={isFormValid}
        validationSchema={schema}
        onSubmit={onSubmit}
        enableReinitialize
      >
        {form => (
          <Form>
            <Grid direction="column" container spacing={1} justify="flex-start">
              {items.map(item => {
                const id = item.name || item.label;
                return (
                  (!item.disableEdit || item.value) && (
                    <Grid
                      item
                      container
                      key={id}
                      style={{ maxWidth: '100%' }}
                      xs={12}
                      sm={columnUnits}
                      alignItems="center"
                    >
                      <Grid item xs={4}>
                        <Typography variant="body1">
                          {item.label}
                          {item.required ? '*' : ''}
                        </Typography>
                      </Grid>
                      {renderInput(
                        item,
                        itemsObj[id] === undefined,
                        !!form.errors[id],
                        form.errors[id],
                        form
                      )}
                    </Grid>
                  )
                );
              })}
            </Grid>
            {renderSaveButton(form, isFromCheckInBoolean)}
          </Form>
        )}
      </Formik>
    );
  }

  function renderInput(
    item: IItem,
    disabled: boolean,
    error: boolean,
    errorMessage: string | undefined,
    form: FormikProps<{
      [name: string]: string;
    }>
  ) {
    const { label, value, name, required } = item;

    return (
      <Grid item xs={8}>
        {disabled ? (
          <Typography variant="body1" className={classes.text}>
            {value}
          </Typography>
        ) : (
          <Field
            style={{ width: '60%' }}
            name={name || label}
            validate={(v: string) => (!v && required ? 'Required' : undefined)}
          >
            {({ field }: FieldProps) => (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                {name === 'countryId' ? (
                  <Dropdown
                    label={''}
                    className={classes.dropDown}
                    value={field.value}
                    open={openDropdown}
                    onChange={country => {
                      form.setFieldValue('countryId', country);
                    }}
                    onOpen={() => setOpenDropdown(true)}
                    onClose={() => setOpenDropdown(false)}
                    items={countriesItems}
                  />
                ) : (
                  <Input className={cx({ [classes.error]: error })} disableUnderline {...field} />
                )}
                <span style={{ color: '#f44336' }}>{errorMessage}</span>
              </div>
            )}
          </Field>
        )}
      </Grid>
    );
  }

  function renderSaveButton(
    {
      errors,
      submitForm,
      isValid,
      dirty,
    }: FormikProps<{
      [name: string]: string;
    }>,
    isCheckIn: boolean = false
  ) {
    return (
      <Grid alignItems="center" justify="flex-end" container className={classes.buttonContainer}>
        {!isCheckIn && (
          <Button className={classes.cancelButton} onClick={() => setEditing(false)}>
            Cancel
          </Button>
        )}
        <Button
          variant="contained"
          style={{
            backgroundColor: !isValid || !dirty || isUpdatingCustomer ? 'lightgray' : '#45C461',
            cursor: !isValid || !dirty || isUpdatingCustomer ? 'not-allowed' : 'pointer',
            color: 'white',
          }}
          disabled={isUpdatingCustomer || (Object.keys(errors).length > 0 || !isValid || !dirty)}
          onClick={submitForm}
        >
          {isUpdatingCustomer ? <CircularProgress size={24} color="inherit" /> : 'Save'}
        </Button>
      </Grid>
    );
  }

  function onSubmit(
    values: { [name: string]: string },
    actions: FormikActions<{ [name: string]: string }>
  ) {
    onUpdatingCustomerInfo(true);

    if (onUpdate) {
      onUpdate(values);
    }
    actions.setSubmitting(false);
    setEditing(false);
  }
};

export default InformationItems;

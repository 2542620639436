import { createAction } from 'typesafe-actions';

export const showDialog = createAction('completeProfile/SHOW_DIALOG', resolve => () => resolve());
export const hideDialog = createAction('completeProfile/HIDE_DIALOG', resolve => () => resolve());
export const skip = createAction('completeProfile/SKIP', resolve => (permaskip: boolean) =>
  resolve({ skipPermanently: permaskip })
);

export const openForm = createAction('completeProfile/OPEN_FORM');

export const submitProfile = createAction(
  'completeProfile/SUBMIT',
  resolve => (newProfile: object) => resolve(newProfile)
);

export const profileUpdated = createAction('completeProfile/UPDATED');

export const profileError = createAction('completeProfile/ERROR');

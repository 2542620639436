import React, { useState } from 'react';
import { AxiosError } from 'axios';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import BasicPage from '../components/BasicPage';
import useApi from '../hooks/useApi';
import { captureException } from '../sentry';
import ApiError from '../errors/ApiError';

const useStyles = makeStyles(theme => ({
  buttonContainer: {
    marginTop: theme.spacing(4),
  },
  buttonInfo: {
    margin: theme.spacing(1, 0),
  },
}));

const EmailVerificationRequired: React.FC = () => {
  const classes = useStyles();
  const api = useApi();

  const [isSending, setSending] = useState(false);
  const [response, setResponse] = useState('');

  return (
    <BasicPage
      title="Email verification required"
      text="You must verify your email to view this page. Please click the link in the verification email you got when signing up."
    >
      <div className={classes.buttonContainer}>
        <Typography variant="body2" className={classes.buttonInfo}>
          Did the email get lost?
        </Typography>
        <Button
          disabled={isSending || !!response}
          onClick={handleSendClick}
          color="secondary"
          variant="contained"
        >
          Re-Send verification email
        </Button>
        {response && (
          <Typography variant="body2" className={classes.buttonInfo}>
            {response}
          </Typography>
        )}
      </div>
    </BasicPage>
  );

  async function handleSendClick() {
    setSending(true);
    try {
      const res = await api.sendVerificationEmail();
      setResponse(res.message);
    } catch (err) {
      handleErrorResponse(err);
    } finally {
      setSending(false);
    }
  }

  function handleErrorResponse(error: ApiError | AxiosError) {
    if (error instanceof ApiError) {
      setResponse(error.message);
      captureException(error);
      return;
    }

    setResponse('An error occured, please try again');
    if (error.response) {
      captureException(new Error(error.response.data.message));
    } else {
      captureException(new Error('No response from API'));
    }
  }
};

export default EmailVerificationRequired;

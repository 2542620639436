/* global google */
import React, { useState, useEffect } from 'react';
import GoogleMapReact, { ChangeEventValue } from 'google-map-react';

import MapsContext, { IMapsContext } from './Context';
import env from '../../env';

interface IMapProps {
  initialCenter?: {
    lat: number;
    lng: number;
  };
  initialZoom?: number;
  maxZoom?: number;
  bounds?: google.maps.LatLngBoundsLiteral | null;
  onChange?: (value: ChangeEventValue) => void;
  onLoaded?: () => void;
}

const defaultInitialCenter = { lat: 64, lng: -21 };
const defaultInitialZoom = 6;
const defaultMaxZoom = 15;

const GoogleMap: React.FC<IMapProps> = ({
  children,
  initialCenter = defaultInitialCenter,
  initialZoom = defaultInitialZoom,
  maxZoom = defaultMaxZoom,
  bounds,
  onChange,
  onLoaded,
}) => {
  const [state, setState] = useState<IMapsContext>({ map: null, maps: null, isLoaded: false });

  useEffect(() => {
    if (state.map) {
      if (bounds) {
        state.map.fitBounds(bounds);
      } else {
        state.map.setCenter(initialCenter);
        state.map.setZoom(initialZoom);
      }
    }
  }, [state.map, bounds, initialCenter, initialZoom]);

  if (!env.googleMaps.apiKey) {
    return <div>Error loading map: Missing API Key</div>;
  }

  return (
    <MapsContext.Provider value={state}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: env.googleMaps.apiKey }}
        defaultCenter={initialCenter}
        defaultZoom={initialZoom}
        yesIWantToUseGoogleMapApiInternals
        onGoogleApiLoaded={onMapLoaded}
        onChange={onChange}
        options={{ maxZoom }}
      >
        {children}
      </GoogleMapReact>
    </MapsContext.Provider>
  );

  function onMapLoaded({ map, maps }: { map: google.maps.Map; maps: typeof google.maps }) {
    setState({
      map,
      maps,
      isLoaded: true,
    });

    if (onLoaded) {
      onLoaded();
    }
  }
};

export default GoogleMap;

import { getType, ActionType } from 'typesafe-actions';

import createReducer from '../createReducer';
import * as A from './actions';
import { INotifierState } from './types';

const initialState: INotifierState = {
  notifications: [],
};

const notifierReducer = createReducer(initialState, {
  [getType(A.enqueueNotification)]: (
    state,
    { payload }: ActionType<typeof A.enqueueNotification>
  ) => {
    return {
      ...state,
      notifications: [...state.notifications, payload],
    };
  },
  [getType(A.closeNotification)]: (state, { payload }: ActionType<typeof A.closeNotification>) => {
    return {
      ...state,
      notifications: state.notifications.map(notification =>
        payload.dismissAll || notification.key === payload.key
          ? { ...notification, dismissed: true }
          : { ...notification }
      ),
    };
  },
  [getType(A.removeNotification)]: (
    state,
    { payload }: ActionType<typeof A.removeNotification>
  ) => {
    return {
      ...state,
      notifications: state.notifications.filter(notification => notification.key !== payload.key),
    };
  },
});

export default notifierReducer;

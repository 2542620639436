import React, { useLayoutEffect, useState } from 'react';
import { useTheme, useMediaQuery, makeStyles } from '@material-ui/core';

interface IProps {
  updateError: (unitId: number | null, damage: any) => void;
  unitId: number | null;
  damageErrors: any[];
  addDamage: (e: any, partOfCar: string) => void;
  setIsOpen: (open: boolean) => void;
  setPartOfCar: (partOfCar: any) => void;
  blueprint: string;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    maxWidth: '100%',
    width: '100%',
  },
  mobileImage: {
    width: '390px',
    height: '207px',
  },
  webImage: {
    width: '779px',
    height: '413px',
  },
  circle: {
    opacity: 1,
    pointerEvents: 'none',
    position: 'absolute',
    width: '1000px',
    height: '1000px',
    '&:hover': {
      pointerEvents: 'click',
    },
  },
  circleChild: {
    pointerEvents: 'auto',
    '&:hover': {
      cursor: 'pointer',
      fill: 'green !important',
      [theme.breakpoints.down('xs')]: {
        r: '12',
      },
      r: '16',
    },
  },
  test22: {
    '& area': {
      cursor: 'pointer',
      '&:hover + $svg': {
        opacity: 1,
      },
    },
  },
  test: {
    opacity: 0,
    pointerEvents: 'none',
    position: 'absolute',
    width: '1000px',
    height: '1000px',
  },
  colorClass: {
    fill: theme.palette.primary.main + '80',
    stroke: theme.palette.primary.main,
    strokeWidth: 2,
    strokeDasharray: 7,
  },
}));

const BluePrintToyota = ({
  unitId,
  damageErrors,
  updateError,
  addDamage,
  setIsOpen,
  setPartOfCar,
  blueprint,
}: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const responsiveAreas: any[] = [];
  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobileScreen = width <= 768;

  const originalCoordinates = () => {
    const areas: any = document.getElementsByTagName('area');

    for (const area of areas) {
      responsiveAreas.push({ element: area, originalCords: area.coords.split(',') });
    }
  };

  const isMobile = () => {
    setWidth(window.innerWidth);

    if (isMobileScreen && responsiveAreas.length === 0) {
      originalCoordinates();
      resize();
    }
  };

  const resize = () => {
    const ratio = 0.5;
    for (const area of responsiveAreas) {
      const newCoords = [];
      for (const originalCoord of area.originalCords) {
        newCoords.push(Math.round(originalCoord * ratio));
      }
      area.element.coords = newCoords.join(',');
    }

    return true;
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', isMobile);
    window.addEventListener('load', isMobile);
    isMobile();

    return () => {
      window.removeEventListener('resize', isMobile);
      window.removeEventListener('load', isMobile);
    };
  }, []);
  return (
    <div className={classes.container}>
      <map id="map" className={classes.test22} name="image-map">
        {damageErrors.map((damage: any) => (
          <svg key={damage.id} id="circle" className={classes.circle}>
            <g>
              <circle
                id={`circle${damage.customId}`}
                className={classes.circleChild}
                onClick={() => updateError(unitId, damage)}
                cx={damage.xCoordinate}
                cy={damage.yCoordinate}
                r={xs === true ? 10 : 12}
                fill="#D2042D"
              />
              <text
                style={xs ? { fontSize: '11px' } : undefined}
                x={damage.xCoordinate}
                y={damage.yCoordinate}
                stroke="white"
                strokeWidth="1"
                textAnchor="middle"
                alignmentBaseline="middle"
              >
                {damage.customId}
              </text>
              <title>{damage.description}</title>
            </g>
          </svg>
        ))}
        <area
          alt="area"
          title="Front part"
          coords="24,120,27,154,65,159,89,176,110,161,125,160,123,102,90,97,48,107"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Front Part');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M24 120 L 27 154 L 65 159 L 89 176 L 110 161 L 125 160 L 123 102 L 90 97 L 48 107Z"
          />
        </svg>

        <area
          title="Front Door"
          alt="area"
          coords="207,73,201,160,126,158,123,100,169,76"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Front Door');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M207 73 L 201 160 L 126 158 L 123 100 L 169 76Z"
          />
        </svg>

        <area
          alt="area"
          title="Back Door"
          coords="211,71,278,80,280,107,267,137,267,159,203,160,204,114"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Back Door');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M211 71 L 278 80 L 280 107 L 267 137 L 267 159 L 203 160 L 204 114Z"
          />
        </svg>
        <area
          alt="area"
          title="Back Part"
          coords="280,77,354,98,361,125,361,149,339,156,318,156,301,171,282,173,266,157,273,126,280,108"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Back Part');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 280 77 L 354 98 L 361 125 L 361 149 L 339 156 L 318 156 L 301 171 L 282 173 L 266 157 L 273 126 L 280 108Z"
          />
        </svg>
        <area
          alt="area"
          title="Front view"
          coords="424,69,399,117,402,174,417,175,421,158,512,160,517,174,532,173,534,118,511,70,470,66"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Front View');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 424 69 L 399 117 L 402 174 L 417 175 L 421 158 L 512 160 L 517 174 L 532 173 L 534 118 L 511 70 L 470 66Z"
          />
        </svg>
        <area
          alt="area"
          title="Top view"
          coords="638,36,612,55,602,79,603,319,604,343,634,378,706,378,732,344,733,320,733,82,722,55,698,36"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Top view');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 638 36 L 612 55 L 602 79 L 603 319 L 604 343 L 634 378 L 706 378 L 732 344 L 733 320 L 733 82 L 722 55 L 698 36Z"
          />
        </svg>
        <area
          alt="area"
          title="Back view"
          coords="425,249,401,293,402,351,418,353,420,340,513,340,517,353,533,353,533,295,511,250,470,247"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Back view');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 425 249 L 401 293 L 402 351 L 418 353 L 420 340 L 513 340 L 517 353 L 533 353 L 533 295 L 511 250 L 470 247Z"
          />
        </svg>
        <area
          alt="area"
          title="Front part"
          coords="270,281,266,343,287,345,304,356,324,348,335,340,366,335,368,301,329,284,285,278"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Front part');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 270 281 L 266 343 L 287 345 L 304 356 L 324 348 L 335 340 L 366 335 L 368 301 L 329 284 L 285 278Z"
          />
        </svg>
        <area
          alt="area"
          title="Front door"
          coords="181,253,191,341,266,340,267,280,220,260"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Front door');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 181 253 L 191 341 L 266 340 L 267 280 L 220 260Z"
          />
        </svg>
        <area
          alt="area"
          title="Back door"
          coords="181,253,129,260,111,288,131,341,190,340"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Back door');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 181 253 L 129 260 L 111 288 L 131 341 L 190 340Z"
          />
        </svg>
        <area
          alt="area"
          title="Back part"
          coords="125,262,37,277,38,293,32,303,31,315,30,330,56,340,77,337,87,352,113,354,126,339,117,304,109,286"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Back part');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 125 262 L 37 277 L 38 293 L 32 303 L 31 315 L 30 330 L 56 340 L 77 337 L 87 352 L 113 354 L 126 339 L 117 304 L 109 286Z"
          />
        </svg>
      </map>

      <img
        id="img"
        className={xs ? classes.mobileImage : classes.webImage}
        src={blueprint}
        alt="mising"
        useMap="#image-map"
      />
    </div>
  );
};

export default BluePrintToyota;

import { Reducer } from 'redux';

interface IFnMap<S> {
  [actionType: string]: (state: S, action: any) => S;
}

export default function createReducer<S>(initialState: S, fnMap: IFnMap<S>): Reducer<S> {
  return (state = initialState, action) => {
    const handler = fnMap[action.type];

    return handler ? handler(state, action) : state;
  };
}

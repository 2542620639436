import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button, useMediaQuery, useTheme } from '@material-ui/core';
import { useSelector } from 'react-redux';
import {
  getIsRentalSigned,
  getLoadedReservationDates,
  getReservationCheckedInStatus,
  selectGracePeriods,
  selectOnlineCheckIn,
} from '../store/selectors';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  onlineCheckInContainer: {
    display: 'flex',
  },
  onlineCheckInButtonContainer: {
    display: 'flex',
    alignItems: 'center',
    flex: 1,
    flexDirection: 'column',
    padding: '10px 10px 0 0',
  },
  onlineCheckInButton: {
    height: '40px',
    width: '30%',
    alignSelf: 'flex-end',
    cursor: 'pointer',
    backgroundColor: theme.palette.primary.main,
    color: 'white',
    border: 'none',
    '&:hover': {
      backgroundColor: theme.palette.primary.light,
    },
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.4rem',
      height: '35px',
    },
    [theme.breakpoints.up(1820)]: {
      fontSize: '0.65rem',
    },
  },
  greyLine: {
    height: 3,
    width: theme.dimensions.maxContentWidth,
    backgroundColor: theme.palette.grey[100],
  },
  paragraphMargin: {
    margin: theme.spacing(1, 0),
  },
  onlineCheckInText: {
    color: 'black',
    fontWeight: 500,
    marginBottom: 0,
    alignSelf: 'flex-end',
    [theme.breakpoints.down('lg')]: {
      fontSize: '11px ',
    },
    [theme.breakpoints.up(1820)]: {
      fontSize: '12px',
    },
  },
  bold: {
    fontWeight: 500,
    color: 'black',
  },
  timeColor: {
    color: theme.palette.primary.main,
  },
  disabled: {
    backgroundColor: theme.palette.primary.main + '36',
    color: 'white !important',
  },
}));

interface IProps {
  unitId: number | null;
  onNavigateHandler: () => void;
  downloadRentalAgreementHandler: () => void;
  damageNavigateHandler: () => void;
}

const OnlineCheckInInformation = ({
  onNavigateHandler,
  damageNavigateHandler,
  downloadRentalAgreementHandler,
  unitId,
}: IProps) => {
  const reservationDates = useSelector(getLoadedReservationDates);
  const isCheckedIn = useSelector(getReservationCheckedInStatus);
  const isRentalSigned = useSelector(getIsRentalSigned);
  const classes = useStyles();
  const { hours: checkInHours, contactEmail: contact } = useSelector(selectOnlineCheckIn) || {
    hours: 0,
    contactEmail: '',
  };
  const { editable } = useSelector(selectGracePeriods) || { editable: 0 };
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [showButton, setShowButton] = useState(false);
  const [isDateInPast, setIsDateInPast] = useState(false);
  const [showOnlineCheckIn, setShowOnlineCheckIn] = useState(false);
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  useEffect(() => {
    if (reservationDates.dateFrom !== null) {
      const today = moment().utc();
      const reservationDateClone = moment(reservationDates.dateFrom.utc());
      if (editable && editable > 0) {
        const openUntilDate = reservationDateClone.subtract(editable, 'h');
        if (
          today.isBefore(openUntilDate, 'hour') ||
          (today.isSame(openUntilDate, 'hour') && today.isBefore(openUntilDate, 'minute'))
        ) {
          setShowOnlineCheckIn(true);
        } else {
          setShowOnlineCheckIn(false);
          return;
        }
      }
      const difference = moment.duration(reservationDates.dateFrom.utc().diff(today));

      if (reservationDates.dateFrom.utc().isBefore(today)) {
        setIsDateInPast(true);
        return;
      }

      const inDays = Math.round(difference.asDays());

      if (moment.duration(reservationDates.dateFrom.utc().diff(today)).asHours() <= checkInHours!) {
        setShowButton(true);
      }

      if (moment.duration(reservationDates.dateFrom.utc().diff(today)).asHours() > checkInHours!) {
        setShowButton(false);
      }

      if (inDays < 1) {
        setHours(parseInt(difference.asHours().toString(), 10));
        setMinutes(parseInt(difference.asMinutes().toString(), 10) % 60);
      } else {
        setDays(parseInt(inDays.toString(), 10));
      }

      setIsDateInPast(false);
      setShowOnlineCheckIn(true);
    }
  }, [reservationDates.dateFrom, checkInHours, editable]);

  return (
    <>
      <div className={classes.greyLine} />

      {!showOnlineCheckIn && !isCheckedIn ? (
        <Grid className={classes.onlineCheckInContainer}>
          <Grid>
            <p
              style={{ fontSize: xs ? '0.7rem' : '1.2rem' }}
              className={`${classes.paragraphMargin} ${classes.bold}`}
            >
              <span>You cannot edit this reservation anymore.</span>{' '}
              <span>If you have any questions please contact us at {contact}.</span>
            </p>
          </Grid>
        </Grid>
      ) : (
        // <span>test</span>
        <Grid className={classes.onlineCheckInContainer}>
          {!isCheckedIn ? (
            <Grid style={{ flex: 3, display: 'flex', flexDirection: 'column', padding: '10px, 0' }}>
              <p className={`${classes.paragraphMargin} ${classes.bold}`}>
                {days > 1 && (
                  <span style={{ fontSize: xs ? '0.7rem' : '1.2rem' }}>
                    Your trip starts in <span className={classes.timeColor}>{days}</span> days
                  </span>
                )}
                {days === 1 && (
                  <span style={{ fontSize: xs ? '0.7rem' : '1.2rem' }}>
                    Your trip starts in <span className={classes.timeColor}>{days}</span> day
                  </span>
                )}
                {days < 1 && (
                  <>
                    {reservationDates.dateFrom !== null && isDateInPast && (
                      <span>
                        Your trip started at
                        {' ' + reservationDates.dateFrom.utc().format('dddd, MMM DD, YYYY HH:mm')}
                      </span>
                    )}
                    {reservationDates.dateFrom !== null && !isDateInPast && (
                      <span style={{ fontSize: xs ? '0.7rem' : '1.2rem', marginTop: 15 }}>
                        Your trip starts in <span className={classes.timeColor}>{hours}</span>{' '}
                        hours, <span className={classes.timeColor}>{minutes}</span> minutes
                      </span>
                    )}
                  </>
                )}
              </p>
              {!isDateInPast && (
                <p
                  style={{ fontSize: xs ? '0.7rem' : '1.2rem' }}
                  className={`${classes.paragraphMargin} ${classes.bold}`}
                >
                  You can modify your booking below up until{' '}
                  {editable === 0 ? (
                    <span>reservation starts</span>
                  ) : (
                    <>
                      <span className={classes.timeColor}>{editable}</span>{' '}
                      <span>hours before reservation starts </span>.
                    </>
                  )}
                </p>
              )}
            </Grid>
          ) : (
            <Grid
              style={{
                flex: 3,
                display: 'flex',
                flexDirection: 'column',
                padding: '10px, 0',
                justifyContent: 'center',
              }}
            >
              <p
                style={{ fontSize: xs ? '0.7rem' : '1.2rem' }}
                className={`${classes.paragraphMargin} ${classes.bold}`}
              >
                <span>
                  If you need to make changes to your booking please contact us at {contact}.
                </span>
              </p>
            </Grid>
          )}

          {!isDateInPast && (
            <Grid
              className={classes.onlineCheckInButtonContainer}
              style={showButton && !isCheckedIn ? { padding: '15px 10px 15px 0' } : {}}
            >
              <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                {isCheckedIn && isRentalSigned && (
                  <Button
                    style={{ marginRight: '10px' }}
                    onClick={downloadRentalAgreementHandler}
                    className={classes.onlineCheckInButton}
                  >
                    Rental Agreement
                  </Button>
                )}
                {isCheckedIn && unitId !== null && (
                  <Button
                    style={
                      showButton && !isCheckedIn
                        ? { height: 45, marginRight: '10px' }
                        : { marginRight: '10px' }
                    }
                    onClick={damageNavigateHandler}
                    className={classes.onlineCheckInButton}
                  >
                    Damage Report
                  </Button>
                )}
                <Button
                  disabled={!showButton || isCheckedIn}
                  onClick={onNavigateHandler}
                  style={showButton && !isCheckedIn ? { height: 45 } : {}}
                  className={`${classes.onlineCheckInButton} ${
                    isCheckedIn || !showButton ? classes.disabled : null
                  }`}
                >
                  Online Check-In
                </Button>
              </div>
              {isCheckedIn && (
                <p className={`${classes.onlineCheckInText}`}>
                  <span>You have checked In</span>
                </p>
              )}
              {!isCheckedIn && !showButton && (
                <p className={`${classes.onlineCheckInText}`}>
                  Online checkin will be available {checkInHours} hours before pick-up.
                </p>
              )}
            </Grid>
          )}
        </Grid>
      )}
    </>
  );
};

export default OnlineCheckInInformation;

import accounting from 'accounting';

const formatPrice = (price: number, currency: string) => {
  switch (currency) {
    case 'ISK':
      return accounting.formatMoney(price, currency, 0, '.', ',', '%v %s');
    case 'EUR':
      return accounting.formatMoney(price, currency, 2, '.', ',', '%v %s');
    case 'USD':
      return accounting.formatMoney(price, currency, 2, ',', '.', '%s %v');
    default:
      return accounting.formatMoney(price, currency, 2, '.', ',', '%v %s');
  }
};
export default formatPrice;

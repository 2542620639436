import React from 'react';
import { RouteComponentProps } from 'react-router';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import FindReservationForm from '../components/FindReservationForm';
import Layout from '../components/Layout';
import useTenant from '../hooks/useTenant';
import { NAVBAR_HEIGHT } from '../constants';

type TProps = RouteComponentProps;

const useStyles = makeStyles(theme => ({
  loginImage: {
    width: '100%',
    height: '100vh',
    minHeight: 800,
    marginTop: -NAVBAR_HEIGHT,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  loginForm: {
    marginTop: NAVBAR_HEIGHT,
  },
}));

const Index: React.FC<TProps> = () => {
  const classes = useStyles();
  const tenant = useTenant();
  const theme = useTheme();
  const { heroImage } = tenant;
  const mdUp = useMediaQuery(theme.breakpoints.up('md'));

  const loginImageClass = mdUp ? classes.loginImage : undefined;
  const loginImageStyle = mdUp ? { backgroundImage: `url("${heroImage}")` } : undefined;
  const loginFormClass = mdUp ? classes.loginForm : undefined;

  return (
    <Layout>
      <div className={loginImageClass} style={loginImageStyle}>
        <div className={loginFormClass}>
          <FindReservationForm />
        </div>
      </div>
    </Layout>
  );
};

export default Index;

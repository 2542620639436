import * as Sentry from '@sentry/browser';
import { Auth0DecodedHash } from 'auth0-js';
import env from './env';

export function initializeSentry() {
  if (!env.isLocal) {
    Sentry.init({ dsn: env.sentry.dsn, environment: env.appEnv });
  }
}

export function captureException(error: Error, errorInfo?: { [key: string]: any }) {
  if (!env.isLocal) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo || {});
      Sentry.captureException(error);
    });
  }
}

function recordBreadcrumb(
  level: Sentry.Severity,
  category: string,
  message: string,
  data?: { [key: string]: any }
) {
  if (!env.isLocal) {
    Sentry.addBreadcrumb({
      category,
      message,
      data,
      level,
    });
  }
}

export function recordInfo(category: string, message: string, data?: { [key: string]: any }) {
  recordBreadcrumb(Sentry.Severity.Info, category, message, data);
}

export function recordError(category: string, message: string, data?: { [key: string]: any }) {
  recordBreadcrumb(Sentry.Severity.Error, category, message, data);
}

export function setUserContext(decodedHash: Auth0DecodedHash) {
  if (!env.isLocal) {
    const { idTokenPayload } = decodedHash;

    Sentry.configureScope(scope => {
      scope.setUser({
        id: idTokenPayload.email,
        email: idTokenPayload.email,
        emailVerified: idTokenPayload.email_verified,
        exp: idTokenPayload.exp,
        aud: idTokenPayload.aud,
        accessToken: decodedHash.accessToken,
      });
    });
  }
}

export function unsetUserContext() {
  if (!env.isLocal) {
    Sentry.configureScope(scope => {
      scope.setUser(null);
    });
  }
}

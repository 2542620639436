import moment from 'moment';
import { IReservationsState } from './types';

export const selectLoadedReservation = (state: IReservationsState) => state.loadedReservation;

export const selectLoadedReservationSelfService = (state: IReservationsState) =>
  state.loadedReservation.selfService;

export const selectLoadedReservationPrices = (state: IReservationsState) => {
  const loadedReservation = selectLoadedReservation(state);
  return loadedReservation ? loadedReservation.prices : null;
};

export const selectLoadedReservationDates = (state: IReservationsState) => {
  const loadedReservation = selectLoadedReservation(state);
  return {
    dateFrom: loadedReservation ? moment(loadedReservation.dateFrom) : null,
    dateTo: loadedReservation ? moment(loadedReservation.dateTo) : null,
  };
};

export const selectReservationLoading = (state: IReservationsState) => {
  return state.isFetching;
};

export const selectAvailableExtras = (state: IReservationsState) => {
  return state.extras.available;
};

export const selectAvailableInsurances = (state: IReservationsState) => {
  return state.insurances.available;
};

export const selectExtensionState = (state: IReservationsState) => {
  return state.extension;
};

export const selectReservationCheckedInStatus = (state: IReservationsState) => {
  return state.loadedReservation.checkedIn;
};

export const selectIsRentalSigned = (state: IReservationsState) => {
  return state.loadedReservation.rentalAgreementSigned;
};

export const selectReservationTermsAndConditions = (state: IReservationsState) => {
  return state.loadedReservation.termsAndConditions;
};

export const selectUnitId = (state: IReservationsState) => {
  return state.unit.unitId;
};

export const selectLoadedGuid = (state: IReservationsState) => {
  const loadedReservation = selectLoadedReservation(state);
  return loadedReservation ? loadedReservation.guid : null;
};

export const selectLoadedId = (state: IReservationsState) => {
  const loadedReservation = selectLoadedReservation(state);
  return loadedReservation ? loadedReservation.id : null;
};

export const selectSecurePaymentForm = (state: IReservationsState) => {
  return state.payment._3dsForm;
};

export const selectLoadingStates = ({
  insurances,
  extras,
  extension,
  isFetching,
  isFetchingList,
  isUpdatingCustomer,
  isUpdatingDrivers,
  isCancelling,
}: IReservationsState) => ({
  isFetching,
  isFetchingList,
  isFetchingAvailableExtras: extras.isFetching,
  isFetchingAvailableInsurances: insurances.isFetching,
  isFetchingExtensionPrice: extension.isLoading,
  isExtending: extension.isExtending,
  isAddingExtras: extras.isAdding,
  isAddingInsurances: insurances.isAdding,
  isUpdatingCustomer,
  isUpdatingDrivers,
  isCancelling,
  isExtendingSuccess: extension.isExtendedSuccess,
});

export const selectErrors = (state: IReservationsState) => state.errors;
export const selectPaymentState = (state: IReservationsState) => state.payment;
export const selectExtrasState = (state: IReservationsState) => state.extras;
export const selectInsurancesState = (state: IReservationsState) => state.insurances;

import React, { useEffect } from 'react';
import Layout from '../components/Layout';

declare global {
  interface Window {
    __CarenWidget: (elementId: string, init: any, overwriteConfig?: any) => any;
  }
}

const Widget = () => {
  const cwg = async () => {
    {
      window.__CarenWidget('CarenWidget', {
        baseUrl: 'https://carendevwidgetserver.azurewebsites.net/api',
        widgetId: '633c4961ef0cd64fcec0a988',
      });
    }
  };

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://carendevwidgetserver.azurewebsites.net/cdn/v2/widget.js';
    script.defer = true;
    script.onload = () => {
      cwg();
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <Layout useDefaultSpacing>
      <div id="CarenWidget" />
    </Layout>
  );
};

export default Widget;

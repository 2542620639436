import { getType } from 'typesafe-actions';

import createReducer from '../createReducer';
import * as A from './actions';
import { IAuthState, IUser } from './types';
import { CUSTOM_CLAIM_NAMESPACE } from '../../constants';

const initialState: IAuthState = {
  isReady: false,
  isLoggedIn: false,
  idToken: null,
  accessToken: null,
  expiresAt: 0,
  user: null,
};

function mapIdTokenPayloadToUserModel(idTokenPayload: any): IUser {
  return {
    name: idTokenPayload.name,
    nickname: idTokenPayload.nickname,
    email: idTokenPayload.email,
    email_verified: idTokenPayload.email_verified,
    picture: idTokenPayload.picture,
    userMetadata: idTokenPayload[CUSTOM_CLAIM_NAMESPACE + 'user_metadata'] || {},
    appMetadata: idTokenPayload[CUSTOM_CLAIM_NAMESPACE + 'app_metadata'] || {},
  };
}

const authReducer = createReducer(initialState, {
  [getType(A.loggedIn)]: (state, { payload }: A.LoggedInAction) => ({
    ...state,
    isReady: true,
    isLoggedIn: true,
    idToken: payload.idToken || null,
    accessToken: payload.accessToken || null,
    expiresAt: (payload.expiresIn || 0) * 1000 + new Date().getTime(),
    user: mapIdTokenPayloadToUserModel(payload.idTokenPayload),
  }),
  [getType(A.loggedOut)]: state => ({
    ...state,
    isReady: true,
    isLoggedIn: false,
    idToken: null,
    accessToken: null,
    expiresAt: 0,
    user: null,
  }),
});

export default authReducer;

import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';

import securePage from '../hocs/securePage';
import useReservations from '../hooks/useReservations';
import Layout from '../components/Layout';
import ReservationList from '../components/ReservationList';

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    maxWidth: theme.dimensions.maxContentWidth,
    margin: 'auto',
    marginBottom: theme.spacing(5),
  },
  bold: {
    fontWeight: 'bold',
    textTransform: 'capitalize',
  },
  regular: {
    fontweight: 'normal',
    textTransform: 'capitalize',
  },
  borderRight: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(0, 1.25),
  },
  filterTitle: {
    display: 'flex',
    alignItems: 'center',
  },
}));

const Reservations: React.FC = () => {
  const { reservations, isLoading, error } = useReservations(true);
  const [filterState, setFilterState] = useState('Upcoming');
  const classes = useStyles();
  const theme = useTheme();

  const filteredReservations = reservations
    .filter(res => res.status === filterState || filterState === 'All')
    .sort((a, b) => new Date(a.dateFrom).getTime() - new Date(b.dateFrom).getTime());

  return (
    <Layout useDefaultSpacing>
      <Grid container direction="row" justify="flex-end" className={classes.container}>
        <Grid item>{renderFilters()}</Grid>
      </Grid>
      <ReservationList reservations={filteredReservations} loading={isLoading} error={error} />
    </Layout>
  );

  function renderFilters() {
    const filters = ['Upcoming', 'Ongoing', 'Completed', 'Cancelled', 'All'];
    return (
      <Grid container direction="row">
        <Grid item className={classes.filterTitle} xs={12} sm="auto">
          <Typography>Show me:</Typography>
        </Grid>
        {filters.map((filter, index) => {
          return (
            <Grid
              item
              xs={6}
              sm="auto"
              key={filter}
              className={classes.borderRight}
              style={{
                borderRight:
                  index !== filters.length - 1 ? `2px solid ${theme.palette.primary.main}` : '',
              }}
            >
              <Button disableTouchRipple onClick={() => setFilterState(filter)} variant="text">
                <Typography
                  variant="body1"
                  className={filter === filterState ? classes.bold : classes.regular}
                >
                  {filter}
                </Typography>
              </Button>
            </Grid>
          );
        })}
      </Grid>
    );
  }
};

export default securePage(Reservations);

import { getType } from 'typesafe-actions';
import createReducer from '../createReducer';
import * as A from './actions';
import * as T from './types';

const initialState: T.ICompleteProfileState = {
  isDialogVisible: false,
  isFormVisible: false,
  isSubmitting: false,
  error: '',
};

const tenantReducer = createReducer(initialState, {
  [getType(A.showDialog)]: state => ({
    ...state,
    isDialogVisible: true,
  }),
  [getType(A.hideDialog)]: state => ({
    ...state,
    isDialogVisible: false,
  }),
  [getType(A.skip)]: state => ({
    ...state,
    isDialogVisible: false,
  }),
  [getType(A.openForm)]: state => ({
    ...state,
    isFormVisible: true,
  }),
  [getType(A.submitProfile)]: state => ({
    ...state,
    isSubmitting: true,
  }),
  [getType(A.profileUpdated)]: state => ({
    ...state,
    isDialogVisible: false,
    isSubmitting: false,
  }),
  [getType(A.profileError)]: state => ({
    ...state,
    isSubmitting: false,
    error: 'Something went wrong',
  }),
});

export default tenantReducer;

import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Layout from '../components/Layout';

const useStyles = makeStyles(theme => ({
  contentHeight: {
    height: '100%',
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      minHeight: '70vh',
    },
  },
}));

const LoadingPage: React.FC = () => {
  const classes = useStyles();
  return (
    <Layout contentClassName={classes.contentHeight}>
      <Grid container direction="column" justify="center" alignItems="center">
        <CircularProgress size={50} />
      </Grid>
    </Layout>
  );
};

export default LoadingPage;

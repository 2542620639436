import { ActionType, getType } from 'typesafe-actions';
import { put, takeLatest, call, select } from 'redux-saga/effects';
import moment from 'moment';
import * as A from './actions';
import { enqueueNotification } from '../notifier/actions';
import { getApiService } from '../sagaUtils';
import ApiService, { IExtensionPrice } from '../../services/ApiService';
import IReservation, {
  IExtra,
  IInsurance,
  IReservationPaymentsResponse,
  IUpdateCustomer,
} from '../../interfaces/IReservation';
import IReservationLite from '../../interfaces/IReservationLite';
import { getLoadedReservation } from '../selectors';
import { IFetchExtensionPriceSuccessPayload } from './types';
import ApiError from '../../errors/ApiError';

function* fetchByEmail(action: ActionType<typeof A.fetchReservationByEmail>) {
  const { email, bookingCode } = action.payload;
  const api: ApiService = yield getApiService();

  try {
    const reservation: IReservation = yield call(() => api.fetchReservation(bookingCode, email));
    yield put(A.fetchSuccess(reservation));
  } catch (error) {
    if (isApiError(error) && error.statusCode === 404) {
      yield put(A.fetchReservationError('Booking Not Found'));
    } else {
      yield put(A.fetchReservationError('An error occured when fetching the booking.', true));
    }
  }
}

function* fetchByGuid(action: ActionType<typeof A.fetchReservationByGuid>) {
  const { guid } = action.payload;
  const api: ApiService = yield getApiService();

  try {
    const reservation: IReservation = yield call(() => api.fetchReservationByGuid(guid));
    yield put(A.fetchSuccess(reservation));
  } catch (error) {
    if (isApiError(error) && error.statusCode === 404) {
      yield put(A.fetchReservationError('Booking Not Found'));
    } else {
      yield put(A.fetchReservationError('An error occured when fetching the booking.', true));
    }
  }
}

function* getUnitIdByGuid(action: ActionType<typeof A.getUnitIdByGuid>) {
  const { guid } = action.payload;
  const api: ApiService = yield getApiService();

  const unit: any = yield call(() => api.getUnitId(guid));

  yield put(A.getUnitIdByGuidSuccess(unit));
}

function* fetchError(action: ActionType<typeof A.fetchReservationError>) {
  const { message, shouldNotify } = action.payload;

  if (shouldNotify) {
    yield put(enqueueNotification(message, { variant: 'error' }));
  }
}

function* updateDamage(action: ActionType<typeof A.updateDamage>) {
  const {
    unitId,
    damageId,
    damagePhotoUrl,
    description,
    xCoordinate,
    yCoordinate,
  } = action.payload;
  const api: ApiService = yield getApiService();
  try {
    yield call(() =>
      api.updateVehicleDamage(
        unitId,
        damageId,
        damagePhotoUrl,
        description,
        xCoordinate,
        yCoordinate
      )
    );
    yield call(() => api.getVehicleDamages(unitId));
    yield put(
      enqueueNotification('You have successfully updated the vehicle damage', {
        variant: 'success',
      })
    );
  } catch (err) {
    yield call(() => api.getVehicleDamages(unitId));
    yield put(
      enqueueNotification(
        'Something went wrong with updating the vehicle damage. Please try again later',
        {
          variant: 'error',
        }
      )
    );
  }
}

function* fetchList(action: ActionType<typeof A.fetchReservationsList>) {
  const api: ApiService = yield getApiService();

  try {
    const reservations: IReservationLite[] = yield call(() => api.fetchReservations(false));

    yield put(A.fetchListSuccess(reservations));
  } catch (error) {
    yield put(A.fetchListError(error.message));
  }
}

function* fetchExtras(action: ActionType<typeof A.fetchAvailableExtras>) {
  const { guid } = action.payload;
  const api: ApiService = yield getApiService();

  try {
    const extras: IExtra[] = yield call(() => api.fetchExtras(guid));
    yield put(A.fetchAvailableExtrasSuccess(guid, extras));
  } catch (error) {
    yield put(A.fetchAvailableExtrasError(error.message));
    yield put(
      enqueueNotification('An error occured when fetching the available extras', {
        variant: 'error',
      })
    );
  }
}

function* fetchInsurances(action: ActionType<typeof A.fetchAvailableInsurances>) {
  const { guid } = action.payload;
  const api: ApiService = yield getApiService();

  try {
    const insurances: IInsurance[] = yield call(() => api.fetchInsurances(guid));
    yield put(A.fetchAvailableInsurancesSuccess(guid, insurances));
  } catch (error) {
    yield put(A.fetchAvailableInsurancesError(error.message));
    yield put(
      enqueueNotification('An error occured when fetching the available insurancess', {
        variant: 'error',
      })
    );
  }
}

function* fetchExtensionPrice(action: ActionType<typeof A.fetchExtensionPrice>) {
  const { guid, from, to } = action.payload;
  const api: ApiService = yield getApiService();

  const fromDate = moment(from);
  const toDate = moment(to);

  try {
    const extensionPrice: IExtensionPrice = yield call(() =>
      api.extendReservation(guid, fromDate, toDate, true)
    );
    const loadedReservation: ReturnType<typeof getLoadedReservation> = yield select(
      getLoadedReservation
    );

    if (!loadedReservation || loadedReservation.guid !== guid) {
      throw new Error('No reservation loaded');
    }

    const originalPrices = loadedReservation.prices;

    const payload: IFetchExtensionPriceSuccessPayload = {
      currency: extensionPrice.currency,
      totalExtensionPrice: extensionPrice.totalPrice - originalPrices.totalPrice,
      toDate: to,
      fromDate: from,
    };

    yield put(A.fetchExtensionPriceSuccess(payload));
  } catch (error) {
    yield put(A.fetchExtensionPriceError(error.message));
    yield put(
      enqueueNotification(`Failed to load extension prices: ${error.message}`, { variant: 'error' })
    );
  }
}

function* extendReservation(action: ActionType<typeof A.extendReservation>) {
  const { guid, from, to } = action.payload;
  const api: ApiService = yield getApiService();

  const fromDate = moment(from);
  const toDate = moment(to);

  try {
    yield call(() => api.extendReservation(guid, fromDate, toDate, false));

    // TODO: Instead of doing a refetch, figure out how to correctly calculate
    // the prices manually and dispatch an updatePrices action
    // OR.... Make the API return the new prices ;)
    yield refetchReservationIfLoaded(guid);
    yield put(A.extendReservationSuccess(guid));
    yield put(A.clearExtensionPrice());
    yield put(A.closeExtendReservationDialog());
    yield put(
      enqueueNotification('You have successfully extend reservation', { variant: 'success' })
    );
  } catch (error) {
    yield put(A.extendReservationError(error.message));
    yield put(
      enqueueNotification(`Failed to extend booking: ${error.message}`, { variant: 'error' })
    );
  }
}

function* addExtras(action: ActionType<typeof A.addExtras>) {
  const { guid, extras } = action.payload;
  const api: ApiService = yield getApiService();

  try {
    yield call(() => api.addExtras(guid, extras));

    yield refetchReservationIfLoaded(guid);
    yield put(A.addExtrasSuccess(guid));
    yield put(enqueueNotification('You have successfully updated extras', { variant: 'success' }));
  } catch (error) {
    yield put(A.addExtrasError(error.message));
    yield enqueueNotification(`Failed to add extras: ${error.message}`, { variant: 'error' });
  }
}

function* addInsurances(action: ActionType<typeof A.addInsurances>) {
  const { guid, insuranceIds } = action.payload;
  const api: ApiService = yield getApiService();

  try {
    yield call(() => api.addInsurances(guid, insuranceIds));

    yield refetchReservationIfLoaded(guid);
    yield put(A.addInsurancesSuccess(guid));
    yield put(
      enqueueNotification('You have successfully updated insurances', { variant: 'success' })
    );
  } catch (error) {
    yield put(A.addInsurancesError(error.message));
    yield enqueueNotification(`Failed to add insurances: ${error.message}`, { variant: 'error' });
  }
}

function* updateCustomer(action: ActionType<typeof A.updateCustomer>) {
  const { guid, customer } = action.payload;
  const { flightNumber, ...restCustomer } = customer;
  const api: ApiService = yield getApiService();

  try {
    if (flightNumber) {
      yield call(() => api.updateFlightNumber(guid, flightNumber));
    }
    yield call(() => api.updateCustomer(guid, restCustomer as IUpdateCustomer));
    yield put(A.updateCustomerSuccess(guid));
    yield refetchReservationIfLoaded(guid);
    yield put(
      enqueueNotification('You have successfully updated a customer', { variant: 'success' })
    );
  } catch (error) {
    yield put(A.updateCustomerError(error.message));
    yield put(
      enqueueNotification(`Failed to update customer information: ${error.message}`, {
        variant: 'error',
      })
    );
  }
}

function* updateDrivers(action: ActionType<typeof A.updateDrivers>) {
  const { guid, drivers } = action.payload;
  const api: ApiService = yield getApiService();

  try {
    yield call(() => api.updateDrivers(guid, drivers));
    yield put(A.patchLocalReservation({ drivers }));
    yield put(A.updateDriversSuccess(guid));
    yield refetchReservationIfLoaded(guid);
  } catch (error) {
    yield put(A.updateDriversError(error.message));
    yield put(
      enqueueNotification(`Failed to update drivers: ${error.message}`, { variant: 'error' })
    );
  }
}

function* updateDriver(action: ActionType<typeof A.updateDriver>) {
  const { guid, driver } = action.payload;
  const api: ApiService = yield getApiService();

  try {
    yield call(() => api.updateDriver(guid, driver));
    yield put(A.patchDriver(driver));
    yield put(A.updateDriversSuccess(guid));
    yield put(
      enqueueNotification('You have successfully updated a driver', { variant: 'success' })
    );
  } catch (error) {
    yield put(A.updateDriversError(error.message));
    yield put(
      enqueueNotification(`Failed to update driver: ${error.message}`, { variant: 'error' })
    );
  }
}

function* removeDriver(action: ActionType<typeof A.removeDriver>) {
  const { guid, id } = action.payload;
  const api: ApiService = yield getApiService();

  try {
    yield call(() => api.removeExtraDriver(guid, id));
    // yield put(A.patchDriver(driver));
    yield put(A.removeDriverSuccess(guid));
    yield refetchReservationIfLoaded(guid);
    yield put(
      enqueueNotification('You have successfully removed a driver', { variant: 'success' })
    );
  } catch (error) {
    yield put(A.removeDriverError(error.message));
    yield put(
      enqueueNotification(`Failed to update driver: ${error.message}`, { variant: 'error' })
    );
  }
}

function* updateSelfService(action: ActionType<typeof A.updateSelfService>) {
  const { guid, selfServicePickup, selfServiceDropoff, shouldFinishReservation } = action.payload;
  const api: ApiService = yield getApiService();

  try {
    yield call(() => api.updateSelfService(guid, selfServicePickup, selfServiceDropoff));
    yield refetchReservationIfLoaded(guid);
    if (shouldFinishReservation) {
      yield put(A.extendReservationSuccess(guid));
    }
    yield put(
      enqueueNotification('You have successfully updated self service', { variant: 'success' })
    );
  } catch (error) {
    yield put(
      enqueueNotification(`Failed to update self service: ${(error as any).message}`, {
        variant: 'error',
      })
    );
  }
}

function* uploadLicencePhotos(action: ActionType<typeof A.uploadLicencePhotos>) {
  const { guid, driverId, licensePhotos } = action.payload;
  const api: ApiService = yield getApiService();

  try {
    yield call(() => api.uploadLicencePhotos(guid, driverId, licensePhotos));
    yield refetchReservationIfLoaded(guid);
  } catch (error) {
    yield put(
      enqueueNotification(`Failed to upload photos: ${(error as any).message}`, {
        variant: 'error',
      })
    );
  }
}

function* acceptTermsAndConditions(action: ActionType<typeof A.acceptTermsAndConditions>) {
  const { guid, signaturePhoto } = action.payload;
  const api: ApiService = yield getApiService();

  try {
    yield call(() => api.acceptTermsAndConditions(guid, signaturePhoto));
  } catch (error) {
    enqueueNotification(`Failed to accept terms and conditions ${(error as any).message}`, {
      variant: 'error',
    });
  }
}

function* acceptRentalAgreement(action: ActionType<typeof A.acceptRentalAgreement>) {
  const { guid, signaturePhoto } = action.payload;
  const api: ApiService = yield getApiService();

  try {
    yield call(() => api.acceptRentalAgreement(guid, signaturePhoto));
  } catch (error) {
    enqueueNotification(`Failed to accept rental agreement ${(error as any).message}`, {
      variant: 'error',
    });
  }
}

function* setReservationCheckIn(action: ActionType<typeof A.setReservationCheckIn>) {
  const { guid } = action.payload;
  const api: ApiService = yield getApiService();

  try {
    yield call(() => api.setReservationCheckIn(guid));
    yield put(
      enqueueNotification('You have successfully finished online check-in', { variant: 'success' })
    );
    yield refetchReservationIfLoaded(guid);
  } catch (error) {
    enqueueNotification(`Error with completing online check-in.${(error as any).message}`, {
      variant: 'error',
    });
  }
}

function* cancelReservation(action: ActionType<typeof A.cancelReservation>) {
  const { guid } = action.payload;
  const api: ApiService = yield getApiService();

  try {
    yield call(() => api.cancelReservation(guid));

    yield put(A.patchLocalReservation({ status: 'Cancelled' }));
    yield put(A.cancelReservationSuccess(guid));
  } catch (error) {
    yield put(A.cancelReservationError(error.message));
    yield put(
      enqueueNotification(`Failed to cancel booking: ${error.message}`, {
        variant: 'error',
      })
    );
  }
}

function* payBalance(action: ActionType<typeof A.payBalance>) {
  const { guid, cardInfo, successUrl, cancelUrl } = action.payload;
  const api: ApiService = yield getApiService();

  try {
    const paymentResponse: IReservationPaymentsResponse = yield call(() =>
      api.payBalance(guid, cardInfo, successUrl, cancelUrl)
    );
    if (paymentResponse._3dSecure && paymentResponse.form) {
      yield put(A.receiveSecurePaymentForm(guid, paymentResponse.form));
    } else {
      yield refetchReservationIfLoaded(guid);
      yield put(A.payBalanceSuccess(guid));
    }
  } catch (error) {
    yield put(A.payBalanceError(error.message));
    yield put(enqueueNotification(`Payment failed: ${error.message}`, { variant: 'error' }));
  }
}

function* refetchReservationIfLoaded(guid: string) {
  const api: ApiService = yield getApiService();

  const loadedReservation: ReturnType<typeof getLoadedReservation> = yield select(
    getLoadedReservation
  );

  if (loadedReservation && loadedReservation.guid === guid) {
    // If we are still looking at the involved reservation, we re-fetch it
    // to get the new, correct data.
    const reloadedReservation: IReservation = yield call(() => api.fetchReservationByGuid(guid));
    yield put(
      A.patchLocalReservation({
        ...reloadedReservation,
      })
    );
  }
}

function isApiError(error: any): error is ApiError {
  return error instanceof ApiError;
}

const sagas = [
  takeLatest(getType(A.fetchReservationByEmail), fetchByEmail),
  takeLatest(getType(A.fetchReservationByGuid), fetchByGuid),
  takeLatest(getType(A.getUnitIdByGuid), getUnitIdByGuid),
  takeLatest(getType(A.fetchReservationsList), fetchList),
  takeLatest(getType(A.fetchReservationError), fetchError),
  takeLatest(getType(A.fetchAvailableExtras), fetchExtras),
  takeLatest(getType(A.fetchAvailableInsurances), fetchInsurances),
  takeLatest(getType(A.fetchExtensionPrice), fetchExtensionPrice),
  takeLatest(getType(A.extendReservation), extendReservation),
  takeLatest(getType(A.addExtras), addExtras),
  takeLatest(getType(A.addInsurances), addInsurances),
  takeLatest(getType(A.updateCustomer), updateCustomer),
  takeLatest(getType(A.updateDrivers), updateDrivers),
  takeLatest(getType(A.updateDriver), updateDriver),
  takeLatest(getType(A.cancelReservation), cancelReservation),
  takeLatest(getType(A.payBalance), payBalance),
  takeLatest(getType(A.uploadLicencePhotos), uploadLicencePhotos),
  takeLatest(getType(A.setReservationCheckIn), setReservationCheckIn),
  takeLatest(getType(A.acceptTermsAndConditions), acceptTermsAndConditions),
  takeLatest(getType(A.acceptRentalAgreement), acceptRentalAgreement),
  takeLatest(getType(A.updateSelfService), updateSelfService),
  takeLatest(getType(A.removeDriver), removeDriver),
];

export default sagas;

import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  primary: {
    fill: theme.palette.primary.main,
  },
}));

const Marker = () => {
  const classes = useStyles();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="25.5" height="34" viewBox="0 0 25.5 34">
      <path
        id="map-marker-alt-regular_1_"
        d="M12.75 0A12.75 12.75 0 0 0 0 12.75c0 4.723 1.593 6.2 10.045 19.751a3.189 3.189 0 0 0 5.409 0C23.911 18.941 25.5 17.472 25.5 12.75A12.75 12.75 0 0 0 12.75 0zm0 30.813C4.288 17.245 3.188 16.388 3.188 12.75a9.563 9.563 0 0 1 19.125 0c0 3.623-1.008 4.345-9.563 18.063zM7.438 12.75a5.313 5.313 0 1 1 5.313 5.313 5.312 5.312 0 0 1-5.313-5.313z"
        className={classes.primary}
        data-name="map-marker-alt-regular (1)"
      />
    </svg>
  );
};

export default Marker;

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { HeightProperty } from 'csstype';
import useTheme from '@material-ui/core/styles/useTheme';
import CircularProgress from '@material-ui/core/CircularProgress';
import cx from 'classnames';

import GoogleMap, { Anchor, Polyline } from '../GoogleMap';
import InfoWindow from './InfoWindow';
import { ITripRoute, ILocation } from '../../services/ApiService';
import { placeIsStop, placeIsWaypoint } from '../../utils/trips';
import { IChunkedRoute, IDraggableDay, IDraggablePlace } from '../TripDetails';
import { calculateCustomerTripRoute } from '../../store/customerTrip/actions';

interface IProps {
  days: IDraggableDay[];
  dayIndex: number;
  route: ITripRoute | null;
  chunkedRoute: IChunkedRoute;
  height?: HeightProperty<string | number>;
  isCustomerTrip: boolean;
  routeLoading?: boolean;
}

const useStyles = makeStyles(theme => ({
  markerIcon: {
    position: 'absolute',
    top: -8,
    left: -8,
    cursor: 'pointer',
    transition: 'transform 0.1s ease-in-out',
    width: 16,
    height: 16,
    backgroundColor: 'white',
    borderRadius: '50%',
    border: `4px solid ${theme.palette.secondary.dark}`,
    boxSizing: 'content-box',
  },
  waypointMarkerIcon: {
    borderWidth: 0,
    backgroundColor: theme.palette.secondary.dark,
  },
  loading: {
    position: 'absolute',
    top: 15,
    left: 20,
  },
}));

const initialCenter = { lat: 64.9631, lng: -19.0208 };
const initialZoom = 6;

const TripRouteMap: React.FC<IProps> = ({
  days,
  dayIndex,
  route,
  chunkedRoute,
  height,
  isCustomerTrip,
  routeLoading,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();

  const [hoveredPlace, setHoveredPlace] = useState<IDraggablePlace | null>(null);
  const [currentChunks, setCurrentChunks] = useState<IChunkedRoute>([]);
  const [bounds, setBounds] = useState<google.maps.LatLngBoundsLiteral | null>(null);

  const hoveredId = hoveredPlace ? hoveredPlace.id : '';
  const startPlace = getStartPlace(days, dayIndex);

  useEffect(() => {
    const chunks = getCurrentChunks(dayIndex, route, chunkedRoute);
    const newBounds = getBounds(dayIndex, route, chunks, startPlace);
    setCurrentChunks(chunks);
    setBounds(newBounds);
  }, [dayIndex, route, chunkedRoute, startPlace]);

  useEffect(() => {
    if (isCustomerTrip) {
      const waypoints: ILocation[] = [];
      days.forEach(day => day.places.forEach(place => waypoints.push(place.location)));
      if (waypoints.length) {
        dispatch(calculateCustomerTripRoute(waypoints));
      }
    }
  }, [days, isCustomerTrip, dispatch]);

  return (
    <div style={{ height: height || '450px', position: 'relative' }}>
      <GoogleMap initialCenter={initialCenter} initialZoom={initialZoom} bounds={bounds}>
        {startPlace && renderMarker(startPlace)}
        {days
          .filter((_, index) => dayIndex < 0 || dayIndex === index)
          .map(day => day.places.map(renderMarker))}
        {currentChunks.map((dayRoute, index) => (
          <Polyline
            key={index}
            coords={dayRoute}
            strokeColor={theme.palette.secondary.light}
            strokeWeight={4}
          />
        ))}
        {renderInfoWindow()}
      </GoogleMap>
      {routeLoading && <CircularProgress size={30} className={classes.loading} />}
    </div>
  );

  function renderMarker(place: IDraggablePlace) {
    return (
      <Anchor key={place.instanceId} lat={place.location.latitude} lng={place.location.longitude}>
        <div
          onMouseEnter={() => setHoveredPlace(place)}
          onMouseLeave={() => setHoveredPlace(null)}
          className={cx(classes.markerIcon, {
            [classes.waypointMarkerIcon]: placeIsWaypoint(place),
          })}
          style={{ transform: hoveredId === place.id ? 'scale(1.2)' : undefined }}
        />
      </Anchor>
    );
  }

  function renderInfoWindow() {
    if (!hoveredPlace) {
      return null;
    }

    const { location, name } = hoveredPlace;

    return (
      <Anchor lat={location.latitude} lng={location.longitude}>
        <InfoWindow title={name} description="" padding={placeIsStop(hoveredPlace) ? 4 : 0} />
      </Anchor>
    );
  }
};

function getStartPlace(days: IDraggableDay[], dayIndex: number) {
  let startPlace: IDraggablePlace | null = null;
  if (dayIndex > 0) {
    days.slice(0, dayIndex).forEach(day => {
      day.places.forEach(place => (startPlace = place));
    });
  }
  return startPlace;
}

function getCurrentChunks(dayIndex: number, route: ITripRoute | null, chunkedRoute: IChunkedRoute) {
  let chunks = chunkedRoute;
  if (!chunks.length) {
    chunks = route ? [route.shape] : [];
  } else if (dayIndex >= 0) {
    chunks = dayIndex < chunkedRoute.length ? [chunkedRoute[dayIndex]] : [];
  }
  return chunks;
}

function getBounds(
  dayIndex: number,
  route: ITripRoute | null,
  currentChunks: IChunkedRoute,
  startPlace: IDraggablePlace | null
) {
  if (dayIndex < 0 && route && route.boundingBox) {
    return {
      north: route.boundingBox.topLeft.latitude,
      south: route.boundingBox.bottomRight.latitude,
      east: route.boundingBox.bottomRight.longitude,
      west: route.boundingBox.topLeft.longitude,
    };
  }

  const startLat = startPlace ? startPlace.location.latitude : null;
  const startLng = startPlace ? startPlace.location.longitude : null;

  let maxLat: number | null = startLat;
  let minLat: number | null = startLat;
  let maxLng: number | null = startLng;
  let minLng: number | null = startLng;

  currentChunks.forEach(chunk => {
    chunk.forEach(coord => {
      if (!maxLat || coord.lat > maxLat) {
        maxLat = coord.lat;
      }
      if (!minLat || coord.lat < minLat) {
        minLat = coord.lat;
      }
      if (!maxLng || coord.lng > maxLng) {
        maxLng = coord.lng;
      }
      if (!minLng || coord.lng < minLng) {
        minLng = coord.lng;
      }
    });
  });

  if (maxLat && minLat && maxLng && minLng) {
    return {
      north: maxLat,
      south: minLat,
      east: maxLng,
      west: minLng,
    };
  }

  return null;
}

export default TripRouteMap;

import React, { useEffect } from 'react';
import { Formik, Form, Field, FieldProps } from 'formik';
import { RouteComponentProps, withRouter } from 'react-router';
import * as Yup from 'yup';
import cx from 'classnames';
import qs from 'qs';

import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

import useAuthActions from '../hooks/useAuthActions';
import useAuthState from '../hooks/useAuthState';
import useTenant from '../hooks/useTenant';
import { ErrorIcon } from '../icons';

type TProps = RouteComponentProps;

interface IProps {
  error?: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
  },
  paper: {
    width: 800,
    margin: 'auto',
    backgroundColor: 'rgba(255,255,255,0.95)',
    padding: theme.spacing(5, 6),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      boxShadow: 'none',
      backgroundColor: 'white',
      padding: theme.spacing(2, 4, 6, 4),
    },
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
      paddingBottom: theme.spacing(6),
    },
  },
  tenantLogo: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(6),
    maxWidth: 400,
    maxHeight: 300,
  },
  errorIcon: {
    width: 70,
    height: 70,
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
    },
  },
  title: {
    width: '100%',
    color: theme.palette.grey[900],
    fontSize: 35,
    lineHeight: 1.2,
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      fontSize: 33,
      textTransform: 'none',
      marginTop: theme.spacing(3),
    },
  },
  errorTitle: {
    fontSize: 30,
    marginTop: 0,
    marginBottom: theme.spacing(6),
  },
  errorForm: {
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      paddingRight: theme.spacing(0),
      paddingBottom: theme.spacing(4),
    },
  },
  errorLogin: {
    border: `0px solid ${theme.palette.grey[400]}`,
    borderLeftWidth: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      borderLeftWidth: 0,
      borderTopWidth: 1,
      paddingLeft: theme.spacing(0),
      paddingTop: theme.spacing(4),
    },
  },
  errorBoldText: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 18,
    lineHeight: 1.4,
    width: '100%',
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  errorLightText: {
    color: theme.palette.grey[600],
    width: '100%',
    textAlign: 'center',
    marginBottom: theme.spacing(2),
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: '0 auto',
    width: '100%',
  },
  regularForm: {
    [theme.breakpoints.up('md')]: {
      width: 400,
    },
  },
  form: {
    width: '100%', // Fix IE 11 issue.
  },
  fieldMargin: {
    marginBottom: theme.spacing(4),
  },
  formButton: {
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
  },
  button: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    width: '70%',
    whiteSpace: 'nowrap',
  },
  orLineContainer: {
    display: 'flex',
    justifyContent: 'center',
    margin: '0px auto',
    width: 90,
  },
  orLine: {
    flex: 1,
    height: 1,
    margin: 15,
    backgroundColor: theme.palette.grey[400],
  },
  boldText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  lightText: {
    fontSize: 14,
    color: theme.palette.grey[500],
  },
  buttonGrid: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
  loginText: {
    textAlign: 'center',
    padding: theme.spacing(1.25),
  },
  loginButton: {
    justifyContent: 'flex-end',
    paddingRight: 5,
    marginBottom: 0,
  },
  signupButton: {
    justifyContent: 'flex-start',
    paddingLeft: 5,
    marginBottom: 0,
  },
}));

export interface IFormValues {
  email: string;
  bookingCode: string;
}

const findReservationSchema = Yup.object().shape({
  bookingCode: Yup.string().required('Required'),
  email: Yup.string()
    .email('Invalid email')
    .required('Required'),
});

const FindReservationForm: React.FC<IProps & TProps> = ({ error, history }) => {
  const classes = useStyles();
  const { logoUrl, hideLogin } = useTenant();
  const { login } = useAuthActions();
  const { user } = useAuthState();
  const { signup } = useAuthActions();

  const initialFormValues: IFormValues = {
    email: '',
    bookingCode: '',
  };

  useEffect(() => {
    if (user) {
      history.replace('/dashboard');
    }
  }, [history, user]);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Grid container direction="column" alignItems="center">
          {error ? (
            <img src={ErrorIcon} alt="Error" className={classes.errorIcon} />
          ) : (
            <Hidden smDown>
              <img src={logoUrl} alt="logo" className={classes.tenantLogo} />
            </Hidden>
          )}
          <Typography
            component="h1"
            variant="h5"
            className={cx(classes.title, { [classes.errorTitle]: error })}
          >
            {error ? 'Your booking was not found' : 'Find your Booking'}
          </Typography>
        </Grid>

        {error ? (
          <Grid container>
            <Grid item xs={12} sm={6} className={classes.errorForm}>
              <Typography className={classes.boldText}>
                Please check your entry and re-enter your details:
              </Typography>
              {renderForm()}
            </Grid>
            <Grid item xs={12} sm={6} className={classes.errorLogin}>
              {renderErrorLogin()}
            </Grid>
          </Grid>
        ) : (
          renderForm()
        )}
      </Paper>
    </div>
  );

  function renderForm() {
    return (
      <div className={cx(classes.formContainer, { [classes.regularForm]: !error })}>
        <Formik
          initialValues={initialFormValues}
          validationSchema={findReservationSchema}
          onSubmit={handleSubmit}
        >
          {({ errors, touched }) => {
            const emailErrored = Boolean(touched.email && errors.email);
            const bookingCodeErrored = Boolean(touched.bookingCode && errors.bookingCode);

            return (
              <Form className={classes.form}>
                <div className={classes.fieldMargin}>
                  <Field
                    name="email"
                    render={({ field }: FieldProps<IFormValues>) => (
                      <FormControl margin="normal" required fullWidth error={emailErrored}>
                        <InputLabel htmlFor="email">Email Address</InputLabel>
                        <Input
                          id="email"
                          aria-describedby="email-error-text"
                          autoComplete="email"
                          autoFocus
                          {...field}
                        />
                        {emailErrored && (
                          <FormHelperText id="email-error-text">{errors.email}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                  <Field
                    name="bookingCode"
                    render={({ field }: FieldProps<IFormValues>) => (
                      <FormControl margin="normal" required fullWidth error={bookingCodeErrored}>
                        <InputLabel htmlFor="bookingCode">Booking Code</InputLabel>
                        <Input id="bookingCode" aria-describedby="book-error-text" {...field} />
                        {bookingCodeErrored && (
                          <FormHelperText id="book-error-text">{errors.bookingCode}</FormHelperText>
                        )}
                      </FormControl>
                    )}
                  />
                </div>

                <div>
                  <div className={classes.formButton}>
                    <Button
                      color="secondary"
                      variant="contained"
                      className={classes.button}
                      type="submit"
                    >
                      {error ? 'Try again' : 'Open Booking'}
                    </Button>
                  </div>
                  {!error && !hideLogin && (
                    <div className={classes.orLineContainer}>
                      <span className={classes.orLine} />
                      <Typography
                        variant="body1"
                        className={classes.lightText}
                        style={{ alignSelf: 'center' }}
                      >
                        OR
                      </Typography>
                      <span className={classes.orLine} />
                    </div>
                  )}
                  {!error && !hideLogin && (
                    <div className={classes.formButton}>
                      <Grid container direction="row" alignItems="center">
                        <Grid item xs={6} className={cx(classes.buttonGrid, classes.loginButton)}>
                          <Button
                            color="secondary"
                            variant="outlined"
                            href="#"
                            onClick={login}
                            className={classes.button}
                          >
                            Login
                          </Button>
                        </Grid>
                        <Grid item xs={6} className={cx(classes.buttonGrid, classes.signupButton)}>
                          <Button
                            color="secondary"
                            variant="outlined"
                            href="#"
                            onClick={signup}
                            className={classes.button}
                          >
                            Sign up
                          </Button>
                        </Grid>
                        <Grid item className={classes.buttonGrid}>
                          <Typography
                            variant="body1"
                            className={cx(classes.lightText, classes.loginText)}
                          >
                            You can view all your bookings by logging in
                          </Typography>
                        </Grid>
                      </Grid>
                    </div>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    );
  }

  function renderErrorLogin() {
    return (
      <React.Fragment>
        <Typography className={classes.errorBoldText}>
          Log in to get easy access to your bookings
        </Typography>
        <Typography className={classes.errorLightText}>
          You can extend bookings, add extras and insurances, edit your personal details
        </Typography>
        <Typography className={classes.errorLightText}>
          Simple login with your e-mail address
        </Typography>
        <Button
          color="secondary"
          variant="contained"
          href="#"
          onClick={login}
          className={classes.button}
        >
          Login
        </Button>
      </React.Fragment>
    );
  }

  function handleSubmit(values: IFormValues) {
    const query = qs.stringify({
      email: values.email,
      bookingCode: values.bookingCode,
    });

    // To reset state when input is submitted unchanged
    history.push('/');
    history.replace(`/bookings/view?${query}`);
  }
};

export default withRouter(FindReservationForm);

import auth0, { AuthorizeOptions, Auth0DecodedHash } from 'auth0-js';
import store from 'store';
import ITenantConfig from '../interfaces/ITenantConfig';
import { getFullUrlPath } from './path';
import { CALLBACK_ROUTE } from '../constants';
import env from '../env';
import { captureException, recordInfo, recordError } from '../sentry';

const LOGGED_IN_KEY = 'isLoggedIn';
const RETURN_TO_KEY = 'returnTo';

const getBaseUrl = () => `${window.location.protocol}//${window.location.host}`;
const getOptions = (showSignup: boolean = false): AuthorizeOptions => ({
  responseType: 'token id_token',
  redirectUri: `${getBaseUrl() + CALLBACK_ROUTE}`,
  scope: 'openid profile email',
  audience: env.api.audience,
  mode: showSignup ? 'signUp' : 'login',
});

export function webAuth({ auth0ClientId, auth0Domain }: ITenantConfig) {
  return new auth0.WebAuth({ clientID: auth0ClientId, domain: auth0Domain });
}

export function parseHash(tenantConfig: ITenantConfig): Promise<Auth0DecodedHash> {
  return new Promise((resolve, reject) => {
    recordInfo('auth', 'parseHash helper called', { baseUrl: getBaseUrl() });
    webAuth(tenantConfig).parseHash((err, result) => {
      if (err) {
        console.error('Something happened with the Sign In request', err);
        captureException(new Error('parseHash errored out'), err);
        reject(err);
        return;
      }

      if (!result || !result.idToken) {
        reject(err);
        return;
      }

      resolve(result);
    });
  });
}

export function renewSession(tenantConfig: ITenantConfig) {
  return new Promise((resolve, reject) => {
    recordInfo('auth', 'renewSession helper called', getOptions());
    webAuth(tenantConfig).checkSession(getOptions(), async (err, authResult) => {
      if (err) {
        recordError('auth', 'checkSession call failed in renewSession', err);
        reject(err);
        return;
      }

      if (authResult && authResult.accessToken && authResult.idToken) {
        resolve(authResult);
      } else {
        reject(new Error('Unknown error while renewing session'));
      }
    });
  });
}

export function login(tenantConfig: ITenantConfig, showSignup: boolean = false) {
  webAuth(tenantConfig).authorize(getOptions(showSignup));
}

export function logout(tenantConfig: ITenantConfig) {
  webAuth(tenantConfig).logout({ returnTo: getBaseUrl() });
}

export function setSession() {
  store.set(LOGGED_IN_KEY, true);
}

export function unsetSession() {
  store.remove(LOGGED_IN_KEY);
}

export function isLoggedIn() {
  return Boolean(store.get(LOGGED_IN_KEY));
}

export function setReturnTo() {
  store.set(RETURN_TO_KEY, getFullUrlPath());
}

export function unsetReturnTo() {
  store.remove(RETURN_TO_KEY);
}

export function getReturnTo() {
  return store.get(RETURN_TO_KEY);
}

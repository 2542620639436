import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';

import themeReducer from './theme/reducer';
import authReducer from './auth/reducer';
import tenantReducer from './tenant/reducer';
import completeProfileReducer from './completeProfile/reducer';
import notifierReducer from './notifier/reducer';
import placesReducer from './places/reducer';
import customerTripReducer from './customerTrip/reducer';
import reservationsReducer from './reservations/reducer';
import { IApplicationState } from './types';

export default (history: History) =>
  combineReducers<IApplicationState>({
    router: connectRouter(history),
    theme: themeReducer,
    auth: authReducer,
    tenant: tenantReducer,
    completeProfile: completeProfileReducer,
    notifier: notifierReducer,
    places: placesReducer,
    customerTrip: customerTripReducer,
    reservations: reservationsReducer,
  });

import React, { useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import CircularProgress from '@material-ui/core/CircularProgress';
import cx from 'classnames';

import BookActivityButton from './BookActivityButton/BookActivityButton';

import { IPlaceDetails, IExternalProduct } from '../services/ApiService';
import HTMLRichText from './HTMLRichText';
import useApi from '../hooks/useApi';

interface IProps {
  open: boolean;
  onClose: () => void;
  placeId: string;
}

interface IBookingButtonProps {
  label: string;
  baseUrl: string;
  product: IExternalProduct;
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3),
      paddingTop: theme.spacing(0),
    },
  },
  backgroundImage: {
    backgroundColor: theme.palette.grey[300],
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 4,
  },
  mainImage: {
    height: 300,
    width: '100%',
  },
  titleContainer: {
    margin: theme.spacing(2, 0),
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  hidden: {
    display: 'none',
  },
}));

const TripPlaceDetailsDialog: React.FC<IProps> = ({ open, onClose, placeId }) => {
  const api = useApi();
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const [place, setPlace] = useState<IPlaceDetails | null>(null);
  const [loading, setLoading] = useState(true);
  const [showHide, setShowHide] = useState(false);

  useEffect(() => {
    async function getPlaceDetails() {
      setLoading(true);
      try {
        const res = await api.getPlaceDetails(placeId);
        setPlace(res);
      } catch (err) {
        setPlace(null);
      }
      setLoading(false);
    }

    getPlaceDetails();
  }, [api, placeId]);

  return (
    <Dialog
      className={showHide ? classes.hidden : ''}
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      fullScreen={xs}
    >
      <Grid container justify="flex-end" style={{ padding: theme.spacing(1) }}>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </Grid>
      <DialogContent className={classes.container}>
        {!place ? (
          renderLoading()
        ) : (
          <React.Fragment>
            <div
              style={{ backgroundImage: `url(${place.photo})` }}
              className={cx(classes.backgroundImage, classes.mainImage)}
            />
            <div className={classes.titleContainer}>
              <Typography className={classes.title} variant="h5">
                {place.name}
              </Typography>
              <Typography variant="body2" color="textSecondary">
                {place.categories.join(', ')}
              </Typography>
              {place.externalProduct && (
                <BookActivityButton
                  productId={place.externalProduct.productId}
                  dialogTitle={`Book Activity for ${place.name}`}
                  onShowHide={() => setShowHide(prev => !prev)}
                />
              )}
            </div>
            <HTMLRichText text={place.description} type="body2" />
          </React.Fragment>
        )}
      </DialogContent>
    </Dialog>
  );

  function renderLoading() {
    if (loading) {
      return (
        <Grid container justify="center" alignItems="center" style={{ height: xs ? '100%' : 300 }}>
          <CircularProgress size={40} />
        </Grid>
      );
    }

    return <Typography style={{ height: 100 }}>Place not found</Typography>;
  }
};

export default TripPlaceDetailsDialog;

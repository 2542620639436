import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface IProps {
  width: string;
  height: string;
}

const useStyles = makeStyles(theme => ({
  primary: {
    fill: theme.palette.primary.main,
  },
}));

const Camera: React.FC<IProps> = ({ height, width }) => {
  const classes = useStyles();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="camera_alt-24px"
      width={width}
      height={height}
      viewBox="0 0 49.38 49.38"
    >
      <path
        id="Path_67"
        d="M6.584 0A6.584 6.584 0 1 1 0 6.584 6.584 6.584 0 0 1 6.584 0z"
        className={classes.primary}
        data-name="Path 67"
        transform="translate(18.106 18.106)"
      />
      <path
        id="Path_65"
        d="M16.4 2l-3.763 4.115H6.115A4.127 4.127 0 0 0 2 10.23v24.69a4.127 4.127 0 0 0 4.115 4.115h32.92a4.127 4.127 0 0 0 4.115-4.115V10.23a4.127 4.127 0 0 0-4.115-4.115h-6.522L28.748 2zm6.173 30.863a10.288 10.288 0 1 1 10.29-10.288 10.291 10.291 0 0 1-10.288 10.288z"
        className={classes.primary}
        data-name="Path 65"
        transform="translate(2.115 2.115)"
      />
    </svg>
  );
};

export default Camera;

import React from 'react';
import { RouteComponentProps } from 'react-router';

import useTripDetails from '../hooks/useTripDetails';
import Layout from '../components/Layout';
import BasicPage from '../components/BasicPage';
import LoadingPage from '../components/LoadingPage';
import TripDetailsComponent from '../components/TripDetails';

const TripDetails: React.FC<RouteComponentProps<{ id: string }>> = ({ match }) => {
  const tripId = match.params.id;
  const {
    trip,
    route,
    tripLoading,
    routeLoading,
    tripError,
    addToTrips,
    addingToMyTrips,
  } = useTripDetails(tripId);
  const isLoading = tripLoading || addingToMyTrips;

  if (isLoading) {
    return <LoadingPage />;
  }

  if (!trip || tripError) {
    return <BasicPage title={tripError ? `Error: ${tripError}` : 'Error loading trips'} />;
  }

  return (
    <Layout>
      <TripDetailsComponent
        trip={trip}
        route={route}
        routeLoading={routeLoading}
        isCustomerTrip={false}
        addToTrips={addToTrips}
      />
    </Layout>
  );
};

export default TripDetails;

import React from 'react';
import get from 'lodash/get';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import useAuthState from '../hooks/useAuthState';
import { unsetReturnTo, getReturnTo } from '../utils/auth';
import FullPageLoader from '../components/FullPageLoader';

const Callback: React.FC<RouteComponentProps> = ({ location }) => {
  const { isLoggedIn, user } = useAuthState();

  if (!isLoggedIn) {
    return <FullPageLoader />;
  }

  const returnTo = getReturnTo() || '/';
  unsetReturnTo();

  const isEmailVerified = get(user, 'email_verified', false);

  if (!isEmailVerified) {
    return <Redirect to={`/welcome?returnTo=${returnTo}`} />;
  }

  return <Redirect to={returnTo} />;
};

export default Callback;

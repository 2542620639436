import React from 'react';
import parse from 'html-react-parser';
import makeStyles from '@material-ui/core/styles/makeStyles';

import removeHtmlStyles from '../utils/removeHtmlStyles';

interface IProps {
  text: string | null;
  type?: 'body1' | 'body2';
}

const useStyles = makeStyles(theme => ({
  body1: theme.typography.body1,
  body2: theme.typography.body2,
}));

const HTMLRichText: React.FC<IProps> = ({ text, type = 'body1' }) => {
  const classes = useStyles();

  if (!text) {
    return null;
  }

  const parsedText = parse(removeHtmlStyles(text));
  const styling = classes[type];

  if (typeof parsedText === 'string') {
    return <p className={styling}>{parsedText}</p>;
  }

  return <div className={styling}>{parsedText}</div>;
};

export default HTMLRichText;

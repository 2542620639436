import React from 'react';
import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import EditIcon from '@material-ui/icons/Edit';
import { ImagePlaceHolder } from '../icons';

const useStyles = makeStyles(theme => ({
  table: {
    width: '50%',
    border: '1px solid rgba(224, 224, 224, 1)',
  },
  tableRow: {
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}80 !important`,
    },

    '&:hover + $circle': {
      fill: 'purple',
    },
  },
  tableHead: {
    backgroundColor: 'black',
  },
}));

interface IProps {
  damageErrors: any[];
  unitId: number | null;
  onUpdate: (unitId: number | null, damage: any) => void;
}

const DamageTable = ({ damageErrors, unitId, onUpdate }: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  let previousColor: string | null = '';

  return (
    <TableContainer
      style={{
        maxHeight: 350,
        display: 'flex',
        justifyContent: 'center',
        marginBottom: '15px',
        maxWidth: '100%',
      }}
    >
      <Table className={classes.table} stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow style={{ backgroundColor: 'black' }}>
            <TableCell style={{ color: 'white', backgroundColor: 'black' }}>#</TableCell>
            <TableCell style={{ color: 'white', backgroundColor: 'black' }}>Image</TableCell>
            <TableCell style={{ color: 'white', backgroundColor: 'black' }}>Description</TableCell>
            <TableCell style={{ color: 'white', backgroundColor: 'black' }} align="center">
              Options
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {damageErrors.map((damage: any, index: number) => (
            <TableRow
              style={index % 2 ? { backgroundColor: '#0000000a' } : { backgroundColor: 'white' }}
              className={classes.tableRow}
              onMouseOver={() => {
                const element = document.getElementById(`circle${damage.customId}`);
                if (element !== null) {
                  previousColor = element.style.fill;

                  element.style.fill = '#18A558';
                  element.setAttribute('r', xs ? '12' : '16');
                }
              }}
              onMouseOut={() => {
                const element = document.getElementById(`circle${damage.customId}`);
                if (element !== null) {
                  if (previousColor !== null) {
                    element.style.fill = previousColor;
                  }
                  element.setAttribute('r', xs ? '10' : '12');
                }
              }}
              hover
              key={damage.id}
            >
              <TableCell>{damage.customId}</TableCell>
              <TableCell>
                <img
                  width={xs ? '70px' : '100px'}
                  height="100px"
                  alt="damage"
                  src={damage.damageUrl || ImagePlaceHolder}
                />
              </TableCell>
              <TableCell>{damage.description}</TableCell>
              <TableCell align="center">
                <div>
                  <IconButton
                    title="Update a damage"
                    onClick={() => {
                      onUpdate(unitId, damage);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DamageTable;

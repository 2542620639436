import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { composeWithDevTools } from 'redux-devtools-extension';
import { applyMiddleware, createStore, DeepPartial } from 'redux';
import thunk from 'redux-thunk';
import createSagaMiddleware from 'redux-saga';

// import { appStart } from './actions';
import { IApplicationState } from './types';
import createRootReducer from './rootReducer';
import rootSaga from './rootSaga';

export const history = createBrowserHistory();

export default function configureStore(preloadedState: DeepPartial<IApplicationState> = {}) {
  const sagaMiddleware = createSagaMiddleware();
  const storeEnhancer = composeWithDevTools(
    applyMiddleware(routerMiddleware(history), sagaMiddleware, thunk)
  );
  const store = createStore(createRootReducer(history), preloadedState, storeEnhancer);

  sagaMiddleware.run(rootSaga);

  // store.dispatch(appStart());

  return store;
}

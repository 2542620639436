import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface IProps {
  width: string;
  height: string;
}

const useStyles = makeStyles(theme => ({
  primary: {
    fill: theme.palette.primary.main,
  },
}));

const Distance: React.FC<IProps> = ({ width, height }) => {
  const classes = useStyles();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 46 40.685">
      <g id="Group_183" data-name="Group 183" transform="translate(-914 -671.633)">
        <path
          id="map-marker-alt-regular_1_"
          data-name="map-marker-alt-regular (1)"
          d="M8.85,0C3.959,0,0,4.284,0,9.564c0,3.542,1.106,4.649,6.972,14.815a2.129,2.129,0,0,0,3.754,0C16.6,14.208,17.7,13.105,17.7,9.564,17.7,4.279,13.735,0,8.85,0Zm0,23.112C2.976,12.935,2.212,12.293,2.212,9.564A6.921,6.921,0,0,1,8.85,2.391a6.921,6.921,0,0,1,6.637,7.173C15.487,12.281,14.788,12.822,8.85,23.112ZM5.162,9.564A3.845,3.845,0,0,1,8.85,5.579a3.845,3.845,0,0,1,3.687,3.985A3.845,3.845,0,0,1,8.85,13.548,3.845,3.845,0,0,1,5.162,9.564Z"
          transform="translate(914 671.633)"
          /* fill="#ff5a0f" */ className={classes.primary}
        />
        <path
          id="map-marker-alt-regular_1_2"
          data-name="map-marker-alt-regular (1)"
          d="M12.75,0A12.75,12.75,0,0,0,0,12.75c0,4.723,1.593,6.2,10.045,19.751a3.189,3.189,0,0,0,5.409,0C23.911,18.941,25.5,17.472,25.5,12.75A12.75,12.75,0,0,0,12.75,0Zm0,30.813C4.288,17.245,3.188,16.388,3.188,12.75a9.563,9.563,0,0,1,19.125,0C22.313,16.373,21.305,17.095,12.75,30.813ZM7.438,12.75a5.313,5.313,0,1,1,5.313,5.313A5.312,5.312,0,0,1,7.438,12.75Z"
          transform="translate(934.5 678.318)"
          /* fill="#ff5a0f" */ className={classes.primary}
        />
      </g>
    </svg>
  );
};

export default Distance;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import BokunWidgetDialog from '../../components/BokunWidgetDialog';
import Button from '@material-ui/core/Button';
import { IProps } from './BookActivityButton.types';
import { selectBokunChannelUUID, selectPlaceProductsButtonLabel } from '../../store/selectors';
import ReactDOM from 'react-dom';

const BookActivityButton: React.FC<IProps> = ({
  className,
  productId,
  dialogTitle = 'Book Activity',
  label,
  onShowHide,
}) => {
  const bokunUUID = useSelector(selectBokunChannelUUID);
  const defaultButtonLabel = useSelector(selectPlaceProductsButtonLabel);
  const [dialogOpen, setDialogOpen] = useState(false);

  if (!bokunUUID) {
    return null;
  }

  return (
    <React.Fragment>
      <Button
        className={className}
        variant="contained"
        color="primary"
        size="small"
        onClick={() => {
          handleClick();
          if (onShowHide !== undefined) {
            onShowHide();
          }
        }}
      >
        {label || defaultButtonLabel || 'Book Experience'}
      </Button>
      {dialogOpen &&
        ReactDOM.createPortal(
          <BokunWidgetDialog
            url={getWidgetSrc()}
            title={dialogTitle}
            closeHandler={() => {
              handleClose();
              if (onShowHide !== undefined) {
                onShowHide();
              }
            }}
          />,
          document.getElementById('bokunWidget')!
        )}
    </React.Fragment>
  );

  function getWidgetSrc() {
    return `https://widgets.bokun.io/online-sales/${bokunUUID}/${productId}`;
  }

  function handleClick() {
    setDialogOpen(true);
  }

  function handleClose() {
    setDialogOpen(false);
  }
};

export default BookActivityButton;

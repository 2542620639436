import React from 'react';
import cx from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';

import RouterLinkForwardRef from '../components/RouterLinkForwardRef';

interface IProps {
  title?: string;
  loading?: boolean;
  link?: string;
  linkText?: string;
  className?: string;
}

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    maxWidth: theme.dimensions.maxContentWidth,
    margin: '0 auto',
  },
  titleArea: {
    marginBottom: theme.spacing(4),
  },
  title: {
    textTransform: 'uppercase',
  },
  button: {
    textTransform: 'none',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.body1.fontSize,
  },
}));

const Section: React.FC<IProps> = ({ title, children, loading, link, linkText, className }) => {
  const classes = useStyles();
  const hasTitleArea = title || (link && linkText);

  return (
    <div className={cx(classes.container, className)}>
      {hasTitleArea && (
        <Grid
          container
          justify="space-between"
          alignItems="flex-start"
          className={classes.titleArea}
        >
          <Typography variant="h4" className={classes.title}>
            {title}
          </Typography>
          {link && linkText && !loading && (
            <Button to={link} component={RouterLinkForwardRef} className={classes.button}>
              {linkText}
            </Button>
          )}
        </Grid>
      )}
      {children}
    </div>
  );
};

export default Section;

import { ActionType } from 'typesafe-actions';
import { call, put, select, takeLatest, delay } from 'redux-saga/effects';
import { setUserContext, unsetUserContext } from '../../sentry';
import { getTenantConfig, getRouterState, getTokenExpiryTimestamp } from '../selectors';
import * as A from './actions';
import { LOGIN_ROUTE, EMAIL_VERIFIED_ROUTE } from '../../constants';
import {
  login,
  logout,
  renewSession,
  setSession,
  unsetSession,
  setReturnTo,
} from '../../utils/auth';

function* handleRenewSession() {
  const tenantConfig = yield select(getTenantConfig);

  try {
    const result = yield call(renewSession, tenantConfig);
    yield put(A.loggedIn(result));
  } catch (err) {
    yield put(A.renewSessionError(err));
    yield put(A.loggedOut());
  }
}

function* handleLoginRequest(action: ActionType<typeof A.login>) {
  const tenantConfig: ReturnType<typeof getTenantConfig> = yield select(getTenantConfig);
  const routerState: ReturnType<typeof getRouterState> = yield select(getRouterState);

  // Unset any previous logged_in session flag
  yield call(unsetSession);
  // Don't set the returnTo flag if we are on the login or email verified route
  if (
    routerState.location.pathname !== LOGIN_ROUTE &&
    routerState.location.pathname !== EMAIL_VERIFIED_ROUTE
  ) {
    yield call(setReturnTo);
  }

  yield call(login, tenantConfig, action.payload.showSignup);
}

function* handleLogoutRequest() {
  const tenantConfig = yield select(getTenantConfig);
  yield call(unsetSession);
  yield call(logout, tenantConfig);
}

function* handleLoggedIn({ payload }: ActionType<typeof A.loggedIn>) {
  yield call(setSession);
  yield call(setUserContext, payload);
  yield put(A.scheduleRenewal());
}

function* handleLoggedOut() {
  yield call(unsetUserContext);
  yield call(unsetSession);
}

function* handleScheduleRenewal() {
  const expiresAt = yield select(getTokenExpiryTimestamp);

  if (expiresAt > 0) {
    const delayMs = expiresAt - Date.now();
    yield delay(delayMs);
    yield put(A.renewSession());
  }
}

const authSagas = [
  takeLatest(A.RENEW_SESSION, handleRenewSession),
  takeLatest(A.SCHEDULE_RENEWAL, handleScheduleRenewal),
  takeLatest(A.LOGGED_IN, handleLoggedIn),
  takeLatest(A.LOGGED_OUT, handleLoggedOut),
  takeLatest(A.LOGIN, handleLoginRequest),
  takeLatest(A.LOGOUT, handleLogoutRequest),
];

export default authSagas;

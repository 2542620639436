import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface IProps {
  width: string;
  height: string;
}

const useStyles = makeStyles(theme => ({
  none: {
    fill: 'none',
  },
  primary: {
    fill: theme.palette.primary.main,
    transform: 'translate(2.155 2.155)',
  },
}));

const Duration: React.FC<IProps> = ({ width, height }) => {
  const classes = useStyles();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="_24px_11_"
      width={width}
      height={height}
      data-name="24px (11)"
      viewBox="0 0 49.864 49.864"
    >
      <path id="Path_54" d="M0 0h49.864v49.864H0z" className={classes.none} data-name="Path 54" />
      <path
        id="Path_55"
        d="M31.586 13.967a12.413 12.413 0 0 0-8.809-3.657v12.467l-8.809 8.809a12.458 12.458 0 1 0 17.618-17.619zM22.777 2a20.777 20.777 0 1 0 20.777 20.777A20.784 20.784 0 0 0 22.777 2zm0 37.4A16.621 16.621 0 1 1 39.4 22.777 16.617 16.617 0 0 1 22.777 39.4z"
        className={classes.primary}
        data-name="Path 55"
      />
    </svg>
  );
};

export default Duration;

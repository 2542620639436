import { getType } from 'typesafe-actions';
import createReducer from '../createReducer';

import * as A from './actions';
import { IPlacesState } from './types';
import { PLACES_RESPONSE_SIZE } from '../../constants';

const initialState: IPlacesState = {
  places: [],
  placesError: '',
  placesLoading: false,
  getMoreLoading: false,
  noMoreResults: false,
};

const placesReducer = createReducer(initialState, {
  [getType(A.searchPlaces)]: state => ({
    ...state,
    placesLoading: true,
    noMoreResults: false,
  }),
  [getType(A.searchPlacesSuccess)]: (state, { payload }) => ({
    ...state,
    places: payload,
    placesError: '',
    placesLoading: false,
    noMoreResults: payload.length < PLACES_RESPONSE_SIZE,
  }),
  [getType(A.searchPlacesError)]: (state, { payload }) => ({
    ...state,
    placesError: payload.message,
    placesLoading: false,
  }),
  [getType(A.getMorePlaces)]: state => ({
    ...state,
    getMoreLoading: true,
  }),
  [getType(A.getMorePlacesSuccess)]: (state, { payload }) => ({
    ...state,
    places: [...state.places, ...payload],
    getMoreLoading: false,
    noMoreResults: payload.length < PLACES_RESPONSE_SIZE,
  }),
  [getType(A.getMorePlacesError)]: state => ({
    ...state,
    getMoreLoading: false,
  }),
});

export default placesReducer;

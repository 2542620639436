import React from 'react';
import Typography from '@material-ui/core/Typography';
import { Link, withRouter, RouteComponentProps } from 'react-router-dom';
import qs from 'qs';
import makeStyles from '@material-ui/core/styles/makeStyles';
import BasicPage from '../components/BasicPage';

type TProps = RouteComponentProps;

const useStyles = makeStyles(theme => ({
  content: {
    marginTop: theme.spacing(3),
  },
  spacing: {
    marginBottom: theme.spacing(2),
  },
  link: {
    paddingTop: theme.spacing(2),
  },
}));

const Welcome: React.FC<TProps> = ({ location }) => {
  const classes = useStyles();
  const returnToPath = qs.parse(location.search).returnTo || '/';

  return (
    <BasicPage title="Thank you for signing up!">
      <div className={classes.content}>
        <Typography variant="body1" className={classes.spacing}>
          To gain full access to all of your bookings, you need to verify your email address.
        </Typography>
        <Typography variant="body1" className={classes.spacing}>
          Please follow the link in the email we have sent you to complete the verification process.
          Please note that it can take a few minutes for the email to arrive.
        </Typography>
        <Typography variant="body1" className={classes.spacing}>
          In the meantime, you can look up specific bookings and browse trips.
        </Typography>
        <Typography variant="body1" className={classes.link}>
          <Link to={returnToPath}>Return to previous location</Link>
        </Typography>
      </div>
    </BasicPage>
  );
};

export default withRouter(Welcome);

import React, { useEffect, useRef, useState } from 'react';
import { Button, makeStyles, Tooltip } from '@material-ui/core';
import SignaturePad from 'react-signature-canvas';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  signaturePad: {
    border: '1px solid black',
    width: '100%',
  },
  signatureContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& Button': {
      alignSelf: 'flex-end',
    },
  },
  terms: {
    height: '400px',
    overflow: 'auto',
  },
  marginBottom: {
    marginBottom: '10px',
  },
  noMargin: {
    margin: 0,
  },
}));

interface IProps {
  acceptTerms: (image: string, isChecked: boolean) => void;
  label: string;
  data: string;
}

const OnlineCheckInApproveRentalTerms = ({ acceptTerms, data, label }: IProps) => {
  const classes = useStyles();
  const sigCanvas = useRef({}) as React.MutableRefObject<SignaturePad>;
  const [image, setImage] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [terms, setTerms] = useState('');

  const clear = () => {
    sigCanvas.current.clear();
    setImage('');
  };

  const save = () => {
    setImage(sigCanvas.current.getTrimmedCanvas().toDataURL('image/png'));
  };

  const changeCheckBox = () => {
    setIsChecked(prev => !prev);
  };

  const acceptTermsHandler = () => {
    acceptTerms(image, isChecked);
    setIsChecked(prev => !prev);
    setImage('');
  };

  useEffect(() => {
    setTerms(data);
  }, [data]);

  return (
    <div className={classes.container}>
      <div className={classes.marginBottom} />
      {terms && (
        <div
          className={cx(classes.marginBottom, classes.terms)}
          dangerouslySetInnerHTML={{ __html: terms }}
        />
      )}

      <div className={classes.marginBottom}>
        <input id="checkbox" type="checkbox" defaultChecked={isChecked} onChange={changeCheckBox} />
        <label htmlFor="checkbox">I have read and understood the rental {label}</label>
      </div>

      {terms && (
        <div className={cx(classes.signatureContainer, classes.marginBottom)}>
          <label>Your signature:</label>
          {image === '' && (
            <>
              <SignaturePad
                ref={sigCanvas}
                canvasProps={{ className: classes.signaturePad, height: 150 }}
              />
              <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                <Button onClick={clear}>Clear</Button>
                <Button
                  style={{ backgroundColor: '#45C461', color: 'white', marginLeft: '0.5rem' }}
                  onClick={save}
                >
                  Save
                </Button>
              </div>
            </>
          )}
          {image !== '' && (
            <img
              alt="signature"
              src={image}
              style={{ width: '300px', height: '150px', alignSelf: 'center' }}
            />
          )}
        </div>
      )}

      {image === '' || !isChecked ? (
        <Tooltip title="Please accept terms and conditions">
          <span style={{ justifyContent: 'center', display: 'flex' }}>
            <Button
              variant="contained"
              color="primary"
              style={{ color: 'white', marginTop: '1.5rem' }}
              onClick={acceptTermsHandler}
              disabled={image === '' || !isChecked}
            >
              Accept
            </Button>
          </span>
        </Tooltip>
      ) : (
        <span style={{ justifyContent: 'center', display: 'flex' }}>
          <Button
            variant="contained"
            color="primary"
            style={{ color: 'white', marginTop: '2rem' }}
            onClick={acceptTermsHandler}
            disabled={image === '' || !isChecked}
          >
            Accept
          </Button>
        </span>
      )}
    </div>
  );
};

export default OnlineCheckInApproveRentalTerms;

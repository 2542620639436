import createMuiTheme, { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';
import { Breakpoint } from '@material-ui/core/styles/createBreakpoints';
import grey from '@material-ui/core/colors/grey';
import ITenantConfig from '../interfaces/ITenantConfig';
import defaultTenantConfig from '../store/tenant/defaultTenantConfig';
import { DEFAULT_PALETTE_PRIMARY, DEFAULT_PALETTE_SECONDARY } from '../constants';

declare module '@material-ui/core/styles/createMuiTheme' {
  interface Theme {
    appDrawer: {
      width: React.CSSProperties['width'];
      breakpoint: Breakpoint;
    };
    dimensions: {
      maxContentWidth?: React.CSSProperties['width'];
      maxLayoutWidth?: React.CSSProperties['width'];
    };
    customPalette: {
      infoBox: {
        default: string;
      };
      skeleton: string;
      success: string;
    };
  }
  // allow configuration using `createMuiTheme`
  interface ThemeOptions {
    appDrawer?: {
      width?: React.CSSProperties['width'];
      breakpoint?: Breakpoint;
    };
    dimensions?: {
      maxContentWidth?: React.CSSProperties['width'];
      maxLayoutWidth?: React.CSSProperties['width'];
    };
    customPalette?: {
      infoBox: {
        default: string;
      };
      skeleton: string;
      success: string;
    };
  }
}

const defaultPalette = {
  primary: {
    main: DEFAULT_PALETTE_PRIMARY,
  },
  secondary: {
    main: DEFAULT_PALETTE_SECONDARY,
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.65)',
  },
  background: {
    default: '#fff',
  },
};

export default function createThemeFromTenantConfig(
  config: ITenantConfig = defaultTenantConfig,
  options: ThemeOptions = {}
) {
  return createMuiTheme({
    palette: {
      ...defaultPalette,
      primary: { main: config.baseColor },
    },
    typography: {
      body1: {
        color: defaultPalette.text.primary,
      },
      h4: {
        color: defaultPalette.text.primary,
      },
      h5: {
        color: defaultPalette.text.primary,
        fontWeight: 'bold',
        letterSpacing: 0.8,
      },
      button: {
        fontWeight: 400,
      },
    },
    customPalette: {
      infoBox: {
        default: grey[100],
      },
      skeleton: grey[200],
      success: '#00ab47',
    },
    dimensions: {
      maxContentWidth: '1440px',
      maxLayoutWidth: '1440px',
    },
    ...options,
  });
}

export function createDefaultTheme() {
  return createThemeFromTenantConfig();
}

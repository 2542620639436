import React from 'react';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import useTenant from '../hooks/useTenant';

interface IProps {
  pickupExtra: string | null;
  bookingCode: string;
  email?: string;
}

const PickupInfo: React.FC<IProps> = ({ pickupExtra, bookingCode, email }) => {
  const { extraPickupInfo } = useTenant();

  if (!extraPickupInfo || !extraPickupInfo.active) {
    return null;
  }

  const {
    infoLabel,
    buttonLabel,
    linkUrl,
    bookingCodeParameterName,
    emailParameterName,
  } = extraPickupInfo;

  if (pickupExtra) {
    return (
      <Typography>
        {infoLabel ? `${infoLabel}: ` : ''}
        {pickupExtra}
      </Typography>
    );
  }

  if (!buttonLabel || !linkUrl) {
    return null;
  }

  let buttonLink = linkUrl;
  buttonLink += `?${bookingCodeParameterName || 'booking-code'}=${bookingCode}`;
  buttonLink += email ? `&${emailParameterName || 'email'}=${email}` : '';

  return (
    <a
      href={buttonLink}
      target="_blank"
      rel="noopener noreferrer"
      style={{ textDecoration: 'none' }}
    >
      <Button color="secondary" variant="contained" size="small">
        {buttonLabel}
      </Button>
    </a>
  );
};

export default PickupInfo;

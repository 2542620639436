/* global google */
import React, { memo, useContext } from 'react';
import MapsContext from './Context';

type EventHandler = (event: google.maps.MouseEvent) => void;

interface IProps {
  coords: Array<{ lat: number; lng: number }>;
  strokeColor?: string;
  strokeOpacity?: number;
  strokeWeight?: number;
  zIndex?: number;
  hoverAreaSize?: number;
  onMouseOver?: EventHandler;
  onMouseOut?: EventHandler;
  onClick?: EventHandler;
}

interface IPolylineRendererProps extends IProps {
  map: google.maps.Map;
  maps: typeof google.maps;
}

interface IState {
  renderedPolyline: google.maps.Polyline | null;
  polylineHoverArea: google.maps.Polyline | null;
}

class PolylineRenderer extends React.PureComponent<IPolylineRendererProps, IState> {
  state: IState = {
    renderedPolyline: null,
    polylineHoverArea: null,
  };

  componentDidMount() {
    const { map, maps, onMouseOut, onMouseOver, onClick } = this.props;

    const renderedPolyline = new maps.Polyline(this.createPolylineOptions());
    const polylineHoverArea = new maps.Polyline(this.createPolylineOptions(true));

    if (onMouseOver) {
      polylineHoverArea.addListener('mouseover', onMouseOver);
    }

    if (onMouseOut) {
      polylineHoverArea.addListener('mouseout', onMouseOut);
    }

    if (onClick) {
      polylineHoverArea.addListener('click', onClick);
    }

    renderedPolyline.setMap(map);
    polylineHoverArea.setMap(map);
    this.setState({ renderedPolyline, polylineHoverArea });
  }

  componentDidUpdate() {
    const { renderedPolyline, polylineHoverArea } = this.state;

    if (renderedPolyline) {
      renderedPolyline.setOptions(this.createPolylineOptions());
    }

    if (polylineHoverArea) {
      polylineHoverArea.setOptions(this.createPolylineOptions(true));
    }
  }

  componentWillUnmount() {
    const { maps } = this.props;
    const { renderedPolyline, polylineHoverArea } = this.state;

    if (renderedPolyline) {
      maps.event.clearInstanceListeners(renderedPolyline);
      renderedPolyline.setMap(null);
    }

    if (polylineHoverArea) {
      maps.event.clearInstanceListeners(polylineHoverArea);
      polylineHoverArea.setMap(null);
    }
  }

  render() {
    return null;
  }

  createPolylineOptions(isHoverPolyline: boolean = false) {
    const {
      coords,
      strokeColor = '#00a1e1',
      strokeOpacity = 1.0,
      strokeWeight = 2,
      zIndex = 1,
      hoverAreaSize,
    } = this.props;

    const resolvedHoverArea = hoverAreaSize || strokeWeight * 4;

    return {
      path: coords,
      geodesic: false,
      strokeOpacity: isHoverPolyline ? 0 : strokeOpacity,
      strokeWeight: isHoverPolyline ? resolvedHoverArea : strokeWeight,
      strokeColor,
      zIndex,
    };
  }
}

const Polyline: React.FC<IProps> = props => {
  const { map, maps, isLoaded } = useContext(MapsContext);

  if (!isLoaded || !map || !maps) {
    return null;
  }

  return <PolylineRenderer map={map} maps={maps} {...props} />;
};

export default memo(Polyline);

import { useReducer, useEffect } from 'react';
import useApi from './useApi';
import { ITripLite } from '../services/ApiService';
import useTenant from './useTenant';

interface IState {
  trips: ITripLite[];
  isLoading: boolean;
  error: string;
}

interface IInitAction {
  type: 'FETCH_INIT';
}

interface ISuccessAction {
  type: 'FETCH_SUCCESS';
  payload: ITripLite[];
}

interface IErrorAction {
  type: 'FETCH_ERROR';
  payload: Error;
}

type Action = IInitAction | ISuccessAction | IErrorAction;

const initialState: IState = {
  trips: [],
  isLoading: true,
  error: '',
};

function reducer(state: IState, action: Action) {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, isLoading: true };
    case 'FETCH_SUCCESS':
      return { ...state, trips: action.payload || [], isLoading: false, error: '' };
    case 'FETCH_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload.message || 'An error occured',
      };
    default:
      return state;
  }
}

export default function useTrips(days?: number) {
  const api = useApi();
  const { hasDriverGuide } = useTenant();

  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    let didCancel = false;

    const dayArray = days ? [days] : [];
    if (days) {
      for (let i = days - 1; i > 0 && i > days - 4; i--) {
        dayArray.push(i);
      }
    }

    const fetchData = async (canFetch: boolean) => {
      if (canFetch) {
        dispatch({ type: 'FETCH_INIT' });
        try {
          const data = await api.fetchTrips({ days: dayArray });
          if (!didCancel) {
            dispatch({ type: 'FETCH_SUCCESS', payload: data });
          }
        } catch (error) {
          if (!didCancel) {
            dispatch({ type: 'FETCH_ERROR', payload: error });
          }
        }
      }
    };

    fetchData(hasDriverGuide);

    return () => {
      didCancel = true;
    };
  }, [api, days, hasDriverGuide]);

  return {
    trips: state.trips,
    isLoading: state.isLoading,
    error: state.error,
  };
}

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { hot } from 'react-hot-loader';
import withSplashScreen from './hocs/withSplash';

import useTenant from './hooks/useTenant';
import Welcome from './pages/Welcome';
import EmailVerificationComplete from './pages/EmailVerificationComplete';
import Reservations from './pages/Reservations';
import ViewReservation from './pages/ViewReservation';
import Dashboard from './pages/Dashboard';
import Trips from './pages/Trips';
import TripDetails from './pages/TripDetails';
import CustomerTripDetails from './pages/CustomerTripDetails';
import PageNotFound from './pages/PageNotFound';
import Callback from './pages/Callback';
import Login from './pages/Login';
import Index from './pages/Index';
import Signup from './pages/Signup';
import OnlineCheckIn from './pages/OnlineCheckIn';

import { CALLBACK_ROUTE, LOGIN_ROUTE, SIGNUP_ROUTE, EMAIL_VERIFIED_ROUTE } from './constants';
import DamageControl from './pages/DamageControl';
import DirectLogin from './pages/DirectLogin';
import Widget from './pages/Widget';

const Routes = withSplashScreen(() => {
  const { hasDriverGuide } = useTenant();

  return (
    <Switch>
      <Route path="/" exact component={Index} />
      <Route path="/directLogin" exact component={DirectLogin} />
      <Route path="/welcome" exact component={Welcome} />
      <Route path={EMAIL_VERIFIED_ROUTE} exact component={EmailVerificationComplete} />
      <Route path="/dashboard" exact component={Dashboard} />
      <Route path="/bookings" exact component={Reservations} />
      <Route path="/bookings/view" exact component={ViewReservation} />
      <Route path="/bookings/onlinecheck" exact component={OnlineCheckIn} />
      <Route path="/bookings/damageHandler" exact component={DamageControl} />
      <Route path="/widget" exact component={Widget} />
      {hasDriverGuide && <Route path="/trips" exact component={Trips} />}
      {hasDriverGuide && <Route path="/trips/:id" exact component={TripDetails} />}
      {hasDriverGuide && (
        <Route path="/trips/:id/instance/:instanceId/" exact component={CustomerTripDetails} />
      )}
      <Route path={LOGIN_ROUTE} exact component={Login} />
      <Route path={SIGNUP_ROUTE} exact component={Signup} />
      <Route path={CALLBACK_ROUTE} exact component={Callback} />
      <Route component={PageNotFound} />
    </Switch>
  );
});

export default (process.env.NODE_ENV === 'development' ? hot(module)(Routes) : Routes);

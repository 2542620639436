import React from 'react';
import cx from 'classnames';
import MaterialInput, { InputProps } from '@material-ui/core/Input';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  input: {
    backgroundColor: 'white',
    borderRadius: 4,
    width: '60%',
    border: `1px solid ${theme.palette.grey[300]}`,
    padding: theme.spacing(0, 1),
  },
}));

const Input: React.FC<InputProps> = props => {
  const classes = useStyles();
  return <MaterialInput {...props} className={cx(classes.input, props.className)} />;
};

export default Input;

import React from 'react';
import { bindActionCreators, Dispatch } from 'redux';
import { connect, useDispatch } from 'react-redux';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import Button from '@material-ui/core/Button';

import { removeNotification, closeNotification } from '../store/notifier/actions';
import { INotificationItem } from '../store/notifier/types';
import { IApplicationState } from '../store/types';

interface IStateProps {
  notifications: INotificationItem[];
}

interface IDispatchProps {
  removeNotification: typeof removeNotification;
}

type TProps = WithSnackbarProps & IStateProps & IDispatchProps;

class Notifier extends React.Component<TProps> {
  displayed: React.ReactText[] = [];

  storeDisplayed = (id: React.ReactText) => {
    this.displayed = [...this.displayed, id];
  };

  shouldComponentUpdate({ notifications: newSnacks = [] }: TProps) {
    if (!newSnacks.length) {
      this.displayed = [];
      return false;
    }

    const { notifications: currentSnacks } = this.props;
    let notExists = false;
    for (const newSnack of newSnacks) {
      if (newSnack.dismissed) {
        this.props.closeSnackbar(newSnack.key);
        this.props.removeNotification(newSnack.key);
      }

      if (notExists) {
        continue;
      }
      notExists = notExists || !currentSnacks.filter(({ key }) => newSnack.key === key).length;
    }
    return notExists;
  }

  componentDidUpdate() {
    const { notifications = [] } = this.props;

    notifications.forEach(({ key, message, options = {} }) => {
      // Do nothing if snackbar is already displayed
      if (this.displayed.includes(key)) {
        return;
      }
      // Display snackbar using notistack
      this.props.enqueueSnackbar(message, {
        ...options,
        onClose: (event: React.SyntheticEvent<any, Event>, reason: string) => {
          if (options.onClose) {
            options.onClose(event, reason);
          }
          // Dispatch action to remove snackbar from redux store
          this.props.removeNotification(key);
        },
      });
      // Keep track of snackbars that we've displayed
      this.storeDisplayed(key);
    });
  }

  render() {
    return null;
  }
}

const mapStateToProps = (state: IApplicationState) => ({
  notifications: state.notifier.notifications,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators({ removeNotification }, dispatch);

export default withSnackbar(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(Notifier)
);

interface IDismissButtonProps {
  notificationKey?: React.ReactText;
}

export const DismissButton: React.FC<IDismissButtonProps> = ({ notificationKey, children }) => {
  const dispatch = useDispatch();
  return (
    <Button color="inherit" onClick={() => dispatch(closeNotification(notificationKey))}>
      {children}
    </Button>
  );
};

// Helper function that allows us to render a dismiss button e.g. in sagas
export const renderDismissButton = (label: React.ReactText, key?: React.ReactText) => {
  return <DismissButton notificationKey={key}>{label}</DismissButton>;
};

import { ITenantState } from './types';

export const getTenantConfig = (state: ITenantState) => state.values;
export const getTenantFetched = (state: ITenantState) => state.didFetch;
export const getTenantError = (state: ITenantState) => state.error;
export const getTenantId = (state: ITenantState) => state.values.tenantId || null;
export const getSitewideMessage = (state: ITenantState) => state.values.sitewideMessage;
export const getBokunUUID = (state: ITenantState) => state.values.bokunChannelUUID;
export const getOnlineCheckIn = (state: ITenantState) => state.values.onlineCheckIn;
export const getGracePeriods = (state: ITenantState) => state.values.gracePeriods;
export const getPlaceProductsButtonLabel = (state: ITenantState) =>
  state.values.placeProducts ? state.values.placeProducts.buttonLabel : null;

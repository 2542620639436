import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useState, useLayoutEffect } from 'react';

interface IProps {
  updateError: (unitId: number | null, damage: any) => void;
  unitId: number | null;
  damageErrors: any[];
  addDamage: (e: any, partOfCar: string) => void;
  setIsOpen: (open: boolean) => void;
  setPartOfCar: (partOfCar: any) => void;
  blueprint: string;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    maxWidth: '100%',
    width: '100%',
  },
  mobileImage: {
    width: '390px',
    height: '207px',
  },
  webImage: {
    width: '779px',
    height: '413px',
  },
  circle: {
    opacity: 1,
    pointerEvents: 'none',
    position: 'absolute',
    width: '1000px',
    height: '1000px',
    '&:hover': {
      pointerEvents: 'click',
    },
  },
  circleChild: {
    pointerEvents: 'auto',
    '&:hover': {
      cursor: 'pointer',
      fill: 'green !important',
      [theme.breakpoints.down('xs')]: {
        r: '12',
      },
      r: '16',
    },
  },
  test22: {
    '& area': {
      cursor: 'pointer',
      '&:hover + $svg': {
        opacity: 1,
      },
    },
  },
  test: {
    opacity: 0,
    pointerEvents: 'none',
    position: 'absolute',
    width: '1000px',
    height: '1000px',
  },
  colorClass: {
    fill: theme.palette.primary.main + '80',
    stroke: theme.palette.primary.main,
    strokeWidth: 2,
    strokeDasharray: 7,
  },
}));

const BluePrintPolo = ({
  unitId,
  damageErrors,
  updateError,
  addDamage,
  setIsOpen,
  setPartOfCar,
  blueprint,
}: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const responsiveAreas: any[] = [];
  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobileScreen = width <= 768;

  const originalCoordinates = () => {
    const areas: any = document.getElementsByTagName('area');

    for (const area of areas) {
      responsiveAreas.push({ element: area, originalCords: area.coords.split(',') });
    }
  };

  const isMobile = () => {
    setWidth(window.innerWidth);

    if (isMobileScreen && responsiveAreas.length === 0) {
      originalCoordinates();
      resize();
    }
  };

  const resize = () => {
    const ratio = 0.5;
    for (const area of responsiveAreas) {
      const newCoords = [];
      for (const originalCoord of area.originalCords) {
        newCoords.push(Math.round(originalCoord * ratio));
      }
      area.element.coords = newCoords.join(',');
    }

    return true;
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', isMobile);
    window.addEventListener('load', isMobile);
    isMobile();

    return () => {
      window.removeEventListener('resize', isMobile);
      window.removeEventListener('load', isMobile);
    };
  }, []);

  return (
    <div className={classes.container}>
      <map id="map" className={classes.test22} name="image-map">
        {damageErrors.map((damage: any) => (
          <svg key={damage.id} id="circle" className={classes.circle}>
            <g>
              <circle
                id={`circle${damage.customId}`}
                className={classes.circleChild}
                onClick={() => updateError(unitId, damage)}
                cx={damage.xCoordinate}
                cy={damage.yCoordinate}
                r={xs === true ? 10 : 12}
                fill="#D2042D"
              />
              <text
                style={xs ? { fontSize: '11px' } : undefined}
                x={damage.xCoordinate}
                y={damage.yCoordinate}
                stroke="white"
                strokeWidth="1"
                textAnchor="middle"
                alignmentBaseline="middle"
              >
                {damage.customId}
              </text>
              <title>{damage.description}</title>
            </g>
          </svg>
        ))}
        <area
          alt="area"
          title="Front part"
          coords="64,125,67,155,100,159,116,171,135,168,145,158,154,159,151,96,100,105"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Front Part');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M64 125 L 67 155 L 100 159 L 116 171 L 135 168 L 145 158 L 154 159 L 151 96 L 100 105Z"
          />
        </svg>
        <area
          alt="area"
          title="Front door"
          coords="153,95,154,158,227,161,231,73,199,80"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Front door');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 153 95 L 154 158 L 227 161 L 231 73 L 199 80Z"
          />
        </svg>
        <area
          alt="area"
          title="Back door"
          coords="233,74,227,157,278,158,279,137,288,125,295,106,286,75"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Back door');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 233 74 L 227 157 L 278 158 L 279 137 L 288 125 L 295 106 L 286 75Z"
          />
        </svg>
        <area
          alt="area"
          title="Back part"
          coords="290,76,326,85,341,114,349,130,350,141,340,152,322,155,314,170,300,172,285,165,279,147,288,128,296,104"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Back part');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 290 76 L 326 85 L 341 114 L 349 130 L 350 141 L 340 152 L 322 155 L 314 170 L 300 172 L 285 165 L 279 147 L 288 128 L 296 104Z"
          />
        </svg>
        <area
          alt="area"
          title="Front view"
          coords="414,70,394,115,397,172,411,173,412,160,499,161,498,172,515,171,516,115,496,69,454,67"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Front view');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 414 70 L 394 115 L 397 172 L 411 173 L 412 160 L 499 161 L 498 172 L 515 171 L 516 115 L 496 69 L 454 67Z"
          />
        </svg>
        <area
          alt="area"
          title="Top view"
          coords="601,92,595,156,596,295,599,327,615,346,657,359,705,344,718,323,717,294,715,157,715,100,695,74,661,66,626,71"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Top view');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 601 92 L 595 156 L 596 295 L 599 327 L 615 346 L 657 359 L 705 344 L 718 323 L 717 294 L 715 157 L 715 100 L 695 74 L 661 66 L 626 71Z"
          />
        </svg>
        <area
          alt="area"
          title="Back view"
          coords="413,257,392,294,395,357,411,358,412,343,499,344,502,355,515,358,516,298,496,253,455,250"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Back view');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 413 257 L 392 294 L 395 357 L 411 358 L 412 343 L 499 344 L 502 355 L 515 358 L 516 298 L 496 253 L 455 250Z"
          />
        </svg>
        <area
          alt="area"
          title="Front part"
          coords="263,293,262,345,277,347,288,357,305,356,315,344,349,340,350,308,320,293,277,286"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Front part');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 263 293 L 262 345 L 277 347 L 288 357 L 305 356 L 315 344 L 349 340 L 350 308 L 320 293 L 277 286Z"
          />
        </svg>
        <area
          alt="area"
          title="Front door"
          coords="185,261,190,345,261,346,265,289,223,266"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Front door');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 185 261 L 190 345 L 261 346 L 265 289 L 223 266Z"
          />
        </svg>
        <area
          alt="area"
          title="Back door"
          coords="116,270,143,347,190,345,188,289,184,260,143,263"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Back door');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 116 270 L 143 347 L 190 345 L 188 289 L 184 260 L 143 263Z"
          />
        </svg>
        <area
          alt="area"
          title="Back part"
          coords="95,267,77,294,68,312,67,328,77,340,95,344,105,354,127,358,138,344,125,294,130,264"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Back part');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 95 267 L 77 294 L 68 312 L 67 328 L 77 340 L 95 344 L 105 354 L 127 358 L 138 344 L 125 294 L 130 264Z"
          />
        </svg>
      </map>

      <img
        id="img"
        className={xs ? classes.mobileImage : classes.webImage}
        src={blueprint}
        alt="mising"
        useMap="#image-map"
      />
    </div>
  );
};

export default BluePrintPolo;

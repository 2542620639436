import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';

import { get3dSecurePaymentForm } from '../store/selectors';

const BorgunSecureForm: React.FC = () => {
  const paymentForm = useSelector(get3dSecurePaymentForm);

  useEffect(() => {
    const borgunForm = document.getElementById('borgunMpi') as HTMLFormElement;
    if (borgunForm !== null) {
      borgunForm.submit();
    }
  }, [paymentForm]);

  return (
    <div style={{ display: 'none' }}>
      {paymentForm && <div dangerouslySetInnerHTML={{ __html: paymentForm }} />}{' '}
    </div>
  );
};

export default BorgunSecureForm;

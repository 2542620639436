import React, { useEffect } from 'react';

import useAuthActions from '../hooks/useAuthActions';

const DetectLogout: React.FC = () => {
  const { logout } = useAuthActions();

  useEffect(() => {
    function checkLogout(e: StorageEvent) {
      if (e.key === 'isLoggedIn' && e.oldValue && !e.newValue) {
        logout();
      }
    }

    window.addEventListener('storage', checkLogout);
    return () => {
      window.removeEventListener('storage', checkLogout);
    };
  }, [logout]);

  return null;
};

export default DetectLogout;

import { useSelector } from 'react-redux';
import { selectTenantValues } from '../store/selectors';

function useTenant() {
  const tenant = useSelector(selectTenantValues);

  return tenant;
}

export default useTenant;

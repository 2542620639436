import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import Hidden from '@material-ui/core/Hidden';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

import RouterLinkForwardRef from './RouterLinkForwardRef';
import { ITripLite } from '../services/ApiService';
import SignupDialog from '../components/SignupDialog';
import ConfirmDialog from './ConfirmDialog';

interface IProps {
  trip: ITripLite;
  isCustomerTrip: boolean;
  email?: string;
  isLoggedIn: boolean;
  addToTrips?: (templateId: string) => Promise<void>;
  deleteCustomerTrip?: (instanceId: string) => Promise<void>;
}

const useStyles = makeStyles(theme => ({
  card: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  media: {
    height: 258,
  },
  textCardContent: {
    padding: theme.spacing(1.25, 0, 0, 1.875),
  },
  dayCount: {
    [theme.breakpoints.down('sm')]: {
      height: 30,
      width: 80,
      fontSize: 11,
      margin: theme.spacing(0, 0.5),
      padding: theme.spacing(0.5, 1),
    },
    margin: theme.spacing(0, 0.7),
    borderRadius: 60,
    padding: theme.spacing(0.675, 1.25),
    color: '#585858',
    fontWeight: 'bolder',
    boxShadow: '0px 2px 5px #8888',
    height: 40,
    width: 87,
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    backgroundColor: 'rgba(252, 252, 252, 0.94)',
    whiteSpace: 'nowrap',
  },
  actionPadding: {
    flex: 1,
    padding: theme.spacing(2.5),
  },
  button: {
    backgroundColor: theme.palette.secondary.main,
    width: '80%',
    color: 'white',
    '&:hover': {
      backgroundColor: theme.palette.secondary.light,
    },
  },
  buttonRight: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  alignTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: 500,
  },
  tripInfoBubble: {
    position: 'absolute',
    top: 210,
    display: 'flex',
    flexDirection: 'row',
    pointerEvents: 'none',
  },
  hoverCard: {
    backgroundColor: 'rgba(252, 252, 252, 0)',
    opacity: 0,
    height: '100%',
    '&:hover': {
      opacity: 1,
      backgroundColor: 'rgba(252, 252, 252, 0.75)',
    },
  },
  hoverButton: {
    backgroundColor: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightMedium,
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  deleteButton: {
    color: theme.palette.error.main,
    borderColor: theme.palette.error.main,
    '&:hover': {
      borderColor: theme.palette.error.dark,
    },
  },
  mobileAddToTrips: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  addMobileButton: {
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  alignCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
  },
}));

const TripCard: React.FC<IProps> = ({
  trip,
  isCustomerTrip,
  addToTrips,
  email,
  isLoggedIn,
  deleteCustomerTrip,
}) => {
  const classes = useStyles();
  const [isSignupOpen, setSignupOpen] = useState(false);
  const [isDeleteOpen, setDeleteOpen] = useState(false);
  const openDeleteDialog = () => setDeleteOpen(true);

  return (
    <Card className={classes.card}>
      <CardMedia className={classes.media} image={trip.photoUrl} title={trip.name}>
        <div className={classes.tripInfoBubble}>
          <Typography component="p" className={classes.dayCount}>
            {trip.dayCount > 1 ? `${trip.dayCount} days` : `${trip.dayCount} day`}
          </Typography>
          <Typography component="p" className={classes.dayCount}>
            {trip.stopCount > 1 ? `${trip.stopCount} stops` : `${trip.stopCount} stop`}
          </Typography>
          <Typography component="p" className={classes.dayCount}>
            {trip.distanceKm} km
          </Typography>
        </div>
        {renderHoverDesktop()}
      </CardMedia>

      <CardActions className={classes.actionPadding} disableSpacing={false}>
        {renderCardButtons()}
      </CardActions>
      <SignupDialog open={isSignupOpen} onClose={closeSignupDialog} />
      <ConfirmDialog
        open={isDeleteOpen}
        title={`Delete ${trip.name}`}
        text="Are you sure you want to delete this trip instance?"
        onConfirm={deleteMyTrip}
        onCancel={() => setDeleteOpen(false)}
      />
    </Card>
  );

  function renderHoverDesktop() {
    if (addToTrips || deleteCustomerTrip) {
      return (
        <Hidden only={['xs', 'sm', 'md']}>
          <div className={classes.hoverCard}>
            <div className={classes.alignCenter}>
              <Button
                color="secondary"
                variant="outlined"
                className={cx(classes.hoverButton, { [classes.deleteButton]: !addToTrips })}
                onClick={addToTrips ? addToMyTrips : openDeleteDialog}
              >
                {addToTrips ? 'Add to my trips' : 'Delete trip'}
              </Button>
            </div>
          </div>
        </Hidden>
      );
    }
    return null;
  }

  function renderCardButtons() {
    if (addToTrips || deleteCustomerTrip) {
      return (
        <React.Fragment>
          <Hidden only={['xs', 'sm', 'md']}>{renderViewButton()}</Hidden>
          <Hidden only={['lg', 'xl']}>
            <Grid container direction="column">
              <Grid item xs={12}>
                <Typography variant="h6" className={classes.title}>
                  {trip.name}
                </Typography>
              </Grid>
              <Grid item xs={12} className={classes.alignCenter} style={{ minHeight: 60 }}>
                <Grid container direction="row">
                  <Grid item xs={8}>
                    <Button
                      color="secondary"
                      variant="outlined"
                      onClick={addToTrips ? addToMyTrips : openDeleteDialog}
                      className={cx(classes.addMobileButton, {
                        [classes.deleteButton]: !addToTrips,
                      })}
                    >
                      {addToTrips ? 'Add to my trips' : 'Delete trip'}
                    </Button>
                  </Grid>

                  <Grid item xs={4} className={classes.buttonRight}>
                    <Button
                      className={classes.button}
                      size="medium"
                      component={RouterLinkForwardRef}
                      to={
                        isCustomerTrip && !!trip.templateId
                          ? `/trips/${trip.templateId}/instance/${trip.id}`
                          : `/trips/${trip.id}`
                      }
                    >
                      View
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Hidden>
        </React.Fragment>
      );
    }
    return renderViewButton();
  }

  function renderViewButton() {
    return (
      <Grid container>
        <Grid item xs={8}>
          <Typography variant="h6" className={classes.title}>
            {trip.name}
          </Typography>
        </Grid>
        <Grid item xs={4} className={classes.buttonRight}>
          <Button
            className={classes.button}
            size="medium"
            component={RouterLinkForwardRef}
            to={
              isCustomerTrip && !!trip.templateId
                ? `/trips/${trip.templateId}/instance/${trip.id}`
                : `/trips/${trip.id}`
            }
          >
            View
          </Button>
        </Grid>
      </Grid>
    );
  }

  function addToMyTrips() {
    if (isLoggedIn && email && addToTrips) {
      addToTrips(trip.id);
    } else {
      openSignupDialog();
    }
  }

  function deleteMyTrip() {
    if (deleteCustomerTrip) {
      deleteCustomerTrip(trip.id);
    }
  }

  function openSignupDialog() {
    setSignupOpen(true);
  }

  function closeSignupDialog() {
    setSignupOpen(false);
  }
};

export default TripCard;

import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import cx from 'classnames';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import ArrowIcon from '@material-ui/icons/ArrowDropDown';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { IUser } from '../store/auth/types';
import { selectUserMetadata } from '../store/selectors';
import useAuthActions from '../hooks/useAuthActions';
import useTenant from '../hooks/useTenant';
import RouterLinkForwardRef from './RouterLinkForwardRef';

interface IProps {
  user: IUser | null;
}

const useStyles = makeStyles(theme => ({
  disableLinkStyle: {
    textDecoration: 'none',
    outline: 'none',
  },
  buttonText: {
    color: theme.palette.grey[700],
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(2),
  },
  profile: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing(2),
  },
  profileText: {
    margin: theme.spacing(0, 1),
    overflow: 'hidden',
    maxWidth: 200,
    textTransform: 'uppercase',
    lineHeight: 1.5,
    whiteSpace: 'nowrap',
  },
  arrow: {
    transition: 'transform 0.2s',
  },
  rotateArrow: {
    transform: 'rotate(180deg)',
  },
}));

const NavDesktop: React.FC<IProps> = ({ user }) => {
  const classes = useStyles();
  const { headerLinks, hideLogin } = useTenant();
  const { login, logout } = useAuthActions();
  const userMetadata = useSelector(selectUserMetadata);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const { firstNameLine, secondNameLine } = getNameLines();
  const isMenuOpen = Boolean(anchorEl);
  const isLoggedIn = Boolean(user);

  return (
    <React.Fragment>
      {renderNavLinks()}
      {!isLoggedIn && !hideLogin && (
        <Grid item>
          <Button data-testid="login-button" onClick={handleLogin} className={classes.buttonText}>
            Log In
          </Button>
        </Grid>
      )}
      {user && (
        <div className={classes.profile}>
          <Avatar data-testid="avatar" src={user.picture} />
          {firstNameLine && (
            <Typography variant="caption" color="textSecondary" className={classes.profileText}>
              {firstNameLine}
              {secondNameLine ? (
                <React.Fragment>
                  <br />
                  {secondNameLine}
                </React.Fragment>
              ) : null}
            </Typography>
          )}
          <IconButton
            aria-owns={isMenuOpen ? 'material-appbar' : undefined}
            aria-haspopup="true"
            onClick={e => setAnchorEl(e.currentTarget)}
            color="default"
            size="small"
            data-testid="arrow"
          >
            <ArrowIcon className={cx(classes.arrow, { [classes.rotateArrow]: isMenuOpen })} />
          </IconButton>
        </div>
      )}
      {renderMenu()}
    </React.Fragment>
  );

  function handleMenuClose() {
    setAnchorEl(null);
  }

  function handleLogin() {
    login();
  }

  function handleLogout() {
    logout();
    handleMenuClose();
  }

  function renderNavLinks() {
    return headerLinks.map(({ label, path }) => {
      const isInternal = path.startsWith('/');
      return (
        <Grid item key={label}>
          {isInternal ? (
            <Button to={path} component={RouterLinkForwardRef} className={classes.buttonText}>
              {label}
            </Button>
          ) : (
            <Button href={path} className={classes.buttonText}>
              {label}
            </Button>
          )}
        </Grid>
      );
    });
  }

  function renderMenu() {
    return (
      <Menu
        data-testid="avatar-menu"
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={isMenuOpen}
        onClose={handleMenuClose}
        getContentAnchorEl={null}
      >
        <MenuItem data-testid="logout-button" onClick={handleLogout}>
          Log Out
        </MenuItem>
      </Menu>
    );
  }

  // To split name correctly into two lines
  function getNameLines() {
    let firstLine = null;
    let secondLine = null;

    if (userMetadata && userMetadata.given_name && userMetadata.family_name) {
      firstLine = userMetadata.given_name + ' ' + userMetadata.family_name;
    } else if (user && user.name) {
      if (user.name.length <= 20) {
        firstLine = user.name;
      } else {
        const names = user.name.split(' ');
        const split = Math.ceil(names.length / 2);
        firstLine = names.slice(0, split).join(' ');
        secondLine = names.slice(split, names.length).join(' ');
      }
    }

    return { firstNameLine: firstLine, secondNameLine: secondLine };
  }
};

export default NavDesktop;

import React, { useEffect, useMemo, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Box from '@material-ui/core/Box';
import Hidden from '@material-ui/core/Hidden';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import EditIcon from '@material-ui/icons/Edit';
import moment from 'moment';
import { saveAs } from 'file-saver';
import {
  getReservationLoading,
  getLoadedReservation,
  getAvailableExtras,
  getAvailableInsurances,
  getReservationErrors,
  getLoadingStates,
  getExtrasState,
  getInsurancesState,
  selectOnlineCheckIn,
  getLoadedReservationDates,
  getLoadedUnitId,
  selectGracePeriods,
} from '../store/selectors';
import {
  fetchAvailableExtras,
  fetchAvailableInsurances,
  cancelReservation,
  addExtras,
  addInsurances,
  openExtrasDialog,
  closeExtrasDialog,
  openInsurancesDialog,
  closeInsurancesDialog,
  openExtendReservationDialog,
  updateDriver,
  uploadLicencePhotos,
  updateDrivers,
  getUnitIdByGuid,
  removeDriver,
} from '../store/reservations/actions';

import ReservationCarInfo from './ReservationCarInfo';
import ReservationDateRange from './ReservationDateRange';
import ReservationExtras from './ReservationExtras';
import ReservationPriceSummary from './ReservationPriceSummary';
import ReservationInfoHeader from './ReservationInfoHeader';
import InformationItems from './InformationItems';
import PickupInfo from './PickupInfo';
import TripList from './TripList';
import { IAddExtra, IReservationCustomer } from '../interfaces/IReservation';
import ReservationPayments from './ReservationPayments';
import useAuthState from '../hooks/useAuthState';
import useTrips from '../hooks/useTrips';
import useTenant from '../hooks/useTenant';
import AddExtrasDialog from './AddExtrasDialog';
import ExtendReservationDialog from './ExtendReservationDialog';
import SignupDialog from '../components/SignupDialog';
import ConfirmButton from './ConfirmButton';
import { WavingIcon, ExclamationIcon } from '../icons';
import { ITripLite } from '../services/ApiService';
import FindReservationForm from '../components/FindReservationForm';
import OnlineCheckInInformation from './OnlineCheckInInformation';
import { performUpdateCustomer } from '../utils/reservation';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import CircularIconButton from './CircularIconButton';
import OnlineCheckInDriverInfo from './OnlineCheckInDriverInfo';
import useApi from '../hooks/useApi';
import useNotifier from '../hooks/useNotifier';
import useMount from '../hooks/useMount';
import { Button } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  content: {
    maxWidth: theme.dimensions.maxLayoutWidth,
    margin: '0px auto',
  },
  rightColumn: {
    justifyContent: 'flex-end',
    order: 2,
    paddingLeft: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      order: 1,
    },
  },
  leftColumn: {
    order: 1,
    paddingRight: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
      order: 2,
      paddingRight: theme.spacing(0),
    },
  },
  leftColumnNarrow: {
    margin: theme.spacing(0, 4),
    [theme.breakpoints.only('md')]: {
      margin: 0,
    },
    [theme.breakpoints.only('xs')]: {
      margin: 0,
    },
  },
  itemSpacing: {
    padding: theme.spacing(2, 0),
  },
  orderSpacing: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  orderSummary: {
    textTransform: 'uppercase',
    fontWeight: 'normal',
  },
  borderBox: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(2),
    border: `1px solid ${theme.palette.primary.main}`,
  },
  uppercase: {
    textTransform: 'uppercase',
  },
  cancelButton: {
    border: `1px solid ${theme.palette.grey[400]}`,
  },
  bold: {
    fontWeight: 'bolder',
  },
  pickupInfo: {
    margin: theme.spacing(1, 2, 1, 0),
  },
  extend: {
    margin: theme.spacing(1, 0),
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  extendText: {
    fontWeight: theme.typography.fontWeightMedium,
    marginRight: theme.spacing(1),
  },
  skeletonColor: {
    color: theme.customPalette.skeleton,
  },
  tripsContainer: {
    marginTop: theme.spacing(6),
  },
  bookingCodeMargin: {
    margin: theme.spacing(1.5, 0),
  },
  greyLine: {
    height: 5,
    width: theme.dimensions.maxContentWidth,
    backgroundColor: theme.palette.grey[100],
  },
  fontSizes: {
    [theme.breakpoints.down('xs')]: {
      fontSize: 18,
    },
  },
  formContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100vh',
    minHeight: 800,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      height: 'auto',
      minHeight: 0,
    },
  },
}));

interface IProps {
  email?: string;
  bookingCode?: string;
  guid?: string;
  success?: string;
}

const ReservationInfo = ({ email, bookingCode, success }: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const api = useApi();
  const dispatch = useDispatch();
  const {
    hasDriverGuide,
    showDriversList,
    paymentOptionType,
    canCancelReservation,
    externalCancelLink,
  } = useTenant();
  const { isLoggedIn } = useAuthState();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const history = useHistory();
  const reservation = useSelector(getLoadedReservation);
  const isLoadingReservation = useSelector(getReservationLoading);
  const extrasLoading = useSelector(getLoadingStates).isFetchingAvailableExtras;
  const extrasAdding = useSelector(getLoadingStates).isAddingExtras;
  const insurancesLoading = useSelector(getLoadingStates).isFetchingAvailableInsurances;
  const insurancesAdding = useSelector(getLoadingStates).isAddingInsurances;
  const availableExtras = useSelector(getAvailableExtras);
  const availableInsurances = useSelector(getAvailableInsurances);
  const extrasDialogOpen = useSelector(getExtrasState).isDialogOpen;
  const extrasGuid = useSelector(getExtrasState).guid;
  const insurancesDialogOpen = useSelector(getInsurancesState).isDialogOpen;
  const insurancesGuid = useSelector(getInsurancesState).guid;
  const errors = useSelector(getReservationErrors);
  const onlineCheckIn = useSelector(selectOnlineCheckIn);
  const { editable, cancellation } = useSelector(selectGracePeriods) || {
    editable: 0,
    cancellation: 0,
  };
  const [countries, setCountries] = useState<any>([]);
  const { trips, isLoading: loadingTrips, error: tripsError } = useTrips(
    (reservation && reservation.days) || undefined
  );
  const [isSignupOpen, setSignupOpen] = useState(false);
  const error = errors.loadedReservation;
  const loading = isLoadingReservation || !!error;
  const unitId = useSelector(getLoadedUnitId);
  const hideSkeleton = sm && error;
  const notifierError = useNotifier({ dismissable: true }, { variant: 'error' });
  const notifierSuccess = useNotifier({ dismissable: true }, { variant: 'success' });

  const {
    guid,
    dateTo,
    dateFrom,
    extras,
    insurance,
    location,
    vehicle,
    prices,
    customer,
    days,
    payments,
    gracePeriods,
  } = reservation;

  function mapInsuranceArray(insuranceData: any[]) {
    return insuranceData.map(i => ({ ...i, quantity: 1, pricePerDay: null }));
  }

  function mapAvailableInsurancesArray(availableInsurancesData: any[]) {
    return availableInsurancesData.map(i => ({ ...i, maximumQuantity: 1 }));
  }

  // In your component:
  const mappedExtras = useMemo(() => mapInsuranceArray(insurance), [insurance]);
  const mappedAvailable = useMemo(() => mapAvailableInsurancesArray(availableInsurances), [
    availableInsurances,
  ]);

  const reservationDates = {
    from: {
      label: 'Pickup',
      date: dateFrom ? moment(dateFrom) : null,
      location: location.pickupName,
    },
    to: {
      label: 'Dropoff',
      date: dateTo ? moment(dateTo) : null,
      location: location.dropoffName,
    },
  };

  const personalInfo = createPersonalInfoFields(reservation.flightNumber, customer);
  const canEdit =
    !reservation.checkedIn && !isCancelled() && isReservationUpcoming() && editUntil();

  useEffect(() => {
    const getCountries = async () => {
      try {
        const result = await api.getCountries();
        result.unshift({ label: 'Select a country', value: 0 });
        setCountries(result);
      } catch (error) {
        setCountries([]);
        console.error(error);
      }
    };

    getCountries();
  }, [api]);

  useEffect(() => {
    if (reservation.guid !== '') {
      localStorage.setItem('reservationGuid', reservation.guid);
    }
  }, [reservation.guid]);

  useEffect(() => {
    if (reservation.drivers.length === 0 && reservation.guid !== '') {
      dispatch(
        updateDrivers(reservation.guid, [
          { id: 0, dateOfBirth: '', name: '', licenseNumber: '', images: '', mainDriver: true },
        ])
      );
    }

    const driversWithoutId = reservation.drivers.filter(driver => driver.id === null);

    if (driversWithoutId.length > 0) {
      dispatch(updateDrivers(reservation.guid, reservation.drivers));
    }
  }, [reservation.drivers, reservation.guid, dispatch]);

  useEffect(() => {
    if (reservation.guid !== '') {
      dispatch(getUnitIdByGuid(reservation.guid));
    }
  }, [reservation.guid, dispatch]);

  useMount(() => {
    if (success !== undefined) {
      if (success.includes('false')) {
        notifierError.notify('There was an error with payment');
      } else {
        notifierSuccess.notify('Payment was successful');
      }
    }
  });

  const onRemoveExtraDriver = (id: number) => {
    dispatch(removeDriver(reservation.guid, id));
  };

  return (
    <Grid>
      {!hideSkeleton && (
        <Grid container direction="column" justify="center" className={classes.content}>
          <Grid container direction="row">
            <Grid item className={classes.orderSpacing}>
              <ReservationInfoHeader
                loading={loading}
                bookingCode={reservation.bookingCode}
                vehicleClass={reservation.vehicle.class}
              />
              {onlineCheckIn !== null && !loading && (
                <OnlineCheckInInformation
                  unitId={unitId}
                  onNavigateHandler={() => {
                    let query;

                    if (email && bookingCode) {
                      query = qs.stringify({
                        email,
                        bookingCode,
                      });
                    } else if (guid) {
                      query = qs.stringify({
                        guid,
                      });
                    }

                    history.push(`/bookings/onlinecheck?${query}`);
                  }}
                  downloadRentalAgreementHandler={downloadRentalAgreement}
                  damageNavigateHandler={() => {
                    if (unitId !== null) {
                      localStorage.setItem('unitId', unitId.toString());
                    }
                    if (reservation.id !== null) {
                      localStorage.setItem('reservationId', reservation.id.toString());
                    }

                    const query = qs.stringify({
                      guid,
                    });
                    history.push(`/bookings/damageHandler?${query}`);
                  }}
                />
              )}
              <div className={classes.greyLine} />
              {!loading && isCancelled() && (
                <Grid item className={classes.itemSpacing}>
                  <Box className={classes.borderBox}>
                    <Typography variant="h6" color="inherit" className={classes.uppercase}>
                      Booking Canceled
                    </Typography>
                  </Box>
                </Grid>
              )}
            </Grid>

            <Grid item xs={12} md={6} className={classes.leftColumn}>
              <Grid container direction="column" justify="center">
                <Hidden smDown>
                  <Grid item className={classes.itemSpacing}>
                    <ReservationDateRange
                      to={reservationDates.to}
                      from={reservationDates.from}
                      loading={loading}
                    />
                    {renderBookingActions()}
                  </Grid>
                </Hidden>
                <div className={classes.leftColumnNarrow}>
                  <Grid item className={classes.itemSpacing}>
                    <ReservationExtras
                      label="Extras"
                      extras={extras}
                      loading={loading}
                      currency={prices.currency}
                      editable={canEdit}
                      onEditClick={onExtrasEdit}
                      emptyIcon={<WavingIcon alt="No Extras" />}
                      emptyLine1="Don’t forget the extras!"
                      emptyLine2="You can book them now here"
                      emptyLinkIndex={5}
                    />
                    <AddExtrasDialog
                      open={extrasDialogOpen}
                      onClose={() => dispatch(closeExtrasDialog())}
                      label="Extras"
                      extras={extras}
                      available={availableExtras}
                      loading={extrasLoading && extrasGuid !== guid}
                      error={errors.extras}
                      busy={extrasAdding}
                      currency={prices.currency}
                      days={days}
                      addExtras={onAddExtras}
                    />
                  </Grid>
                  <Grid item className={classes.itemSpacing}>
                    <ReservationExtras
                      label="Insurances"
                      extras={mappedExtras}
                      loading={loading}
                      editable={canEdit}
                      currency={prices.currency}
                      onEditClick={onInsurancesEdit}
                      emptyIcon={<ExclamationIcon alt="No Insurances" />}
                      emptyLine1="Better be safe than sorry!"
                      emptyLine2="Check out our insurances here"
                      emptyLinkIndex={4}
                    />
                    <AddExtrasDialog
                      open={insurancesDialogOpen}
                      onClose={() => dispatch(closeInsurancesDialog())}
                      label="Insurances"
                      extras={mappedExtras}
                      available={mappedAvailable}
                      loading={insurancesLoading && insurancesGuid !== guid}
                      error={errors.insurances}
                      busy={insurancesAdding}
                      currency={prices.currency}
                      days={days}
                      addExtras={onAddInsurances}
                    />
                  </Grid>
                  <Grid item className={classes.itemSpacing}>
                    <ReservationPriceSummary
                      priceInfo={prices}
                      days={days}
                      canPayBalance={prices.balance > 0}
                      loading={loading}
                      shouldBeValitor={paymentOptionType === 'valitor'}
                      isFromOnlineCheckIn={false}
                      shouldContinue={() => {
                        return;
                      }}
                    />
                  </Grid>
                </div>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} className={classes.rightColumn}>
              <Grid container direction="column" alignItems="center">
                <Hidden mdUp>
                  <Grid item style={{ width: '100%' }}>
                    <ReservationDateRange
                      to={reservationDates.to}
                      from={reservationDates.from}
                      loading={loading}
                    />
                    {renderBookingActions()}
                  </Grid>
                </Hidden>
                <Grid item>
                  <ReservationCarInfo vehicle={vehicle} loading={loading} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {payments.length > 0 && (
            <Grid item className={classes.itemSpacing}>
              <ReservationPayments payments={payments} loading={loading} />
            </Grid>
          )}
          {loading ? null : (
            <React.Fragment>
              {personalInfo && (
                <Grid item className={classes.itemSpacing}>
                  <InformationItems
                    title="Customer information"
                    editable={canEditCustomerInfo()}
                    items={personalInfo}
                    countriesItems={countries}
                    onUpdate={onCustomerUpdate}
                    onUpdatingCustomerInfo={() => {
                      return null;
                    }}
                  />
                </Grid>
              )}
              {showDriversList && reservation.drivers.length > 0 && (
                <div style={{ width: xs ? '100%' : '60%' }}>
                  <OnlineCheckInDriverInfo
                    drivers={reservation.drivers}
                    extras={reservation.extras}
                    currency={prices.currency}
                    isFromCheckIn={false}
                    days={reservation.days}
                    availableExtras={availableExtras.find(
                      extra => extra.isExtraDriverExtra === true
                    )}
                    onEdit={onDriverEdit}
                    addExtras={onAddExtras}
                    editable={canEditCustomerInfo()}
                    onUpdating={() => {
                      return null;
                    }}
                    onRemoveExtraDriver={onRemoveExtraDriver}
                  />
                </div>
              )}
              {canCancelReservation && !isCancelled() && isReservationInactive() && cancelUntil() && (
                <Grid container direction="row-reverse">
                  <Grid item>
                    {!!externalCancelLink ? (
                      <Button onClick={onCancelNavigate} variant="outlined" color="secondary">
                        Cancel Booking
                      </Button>
                    ) : (
                      <ConfirmButton
                        placeholder="Cancel Booking"
                        question="Are you sure you would like to cancel your booking?"
                        cancelPlaceholder="No"
                        confirmPlaceholder="Yes"
                        confirm={onCancelConfirm}
                      />
                    )}
                  </Grid>
                </Grid>
              )}
            </React.Fragment>
          )}
          {hasDriverGuide && (
            <TripList
              // Replace with correct day count
              trips={randomTrips(trips, 3)}
              title="Recommended trips"
              loading={loadingTrips || loading}
              error={tripsError}
              link="/trips"
              linkText="View all trips"
              emptyText="No recommended trips found for your duration"
              className={classes.tripsContainer}
              isCustomerTrips
              isLoggedIn={isLoggedIn}
            />
          )}
        </Grid>
      )}
      <SignupDialog open={isSignupOpen} onClose={closeSignupDialog} />
      {error && (
        <div className={classes.formContainer}>
          <FindReservationForm error />
        </div>
      )}
    </Grid>
  );

  function renderBookingActions() {
    return canEdit && !loading ? (
      <React.Fragment>
        <Grid
          container
          alignItems="center"
          justify="space-between"
          style={{ marginTop: theme.spacing(1) }}
        >
          <div className={classes.pickupInfo}>
            <PickupInfo
              pickupExtra={reservation.location.pickupExtra}
              bookingCode={reservation.bookingCode}
              email={reservation.customer ? reservation.customer.email : undefined}
            />
          </div>
          <div className={classes.extend}>
            <Typography className={classes.extendText}>Extend your booking</Typography>
            <CircularIconButton
              onClick={() => (!isCancelled() ? openExtensionDialog() : openSignupDialog())}
            >
              <EditIcon fontSize="small" style={{ padding: 2 }} />
            </CircularIconButton>
          </div>
        </Grid>
        <ExtendReservationDialog
          initialTo={reservationDates.to}
          initialFrom={reservationDates.from}
        />
      </React.Fragment>
    ) : null;
  }

  function onExtrasEdit() {
    if (reservation && !isCancelled()) {
      dispatch(openExtrasDialog());
      dispatch(fetchAvailableExtras(reservation.guid));
    } else {
      openSignupDialog();
    }
  }

  async function onDriverEdit(driver: any) {
    if (reservation) {
      dispatch(updateDriver(reservation.guid, driver));

      if (driver.images.length > 0) {
        dispatch(uploadLicencePhotos(reservation.guid, driver.id, driver.images));
      }
    }
  }

  function onAddExtras(addedExtras: IAddExtra[]) {
    if (reservation && !isCancelled()) {
      dispatch(addExtras(reservation.guid, addedExtras));
    }
  }

  function onAddInsurances(addedInsurances: IAddExtra[]) {
    if (reservation && !isCancelled()) {
      dispatch(addInsurances(reservation.guid, addedInsurances));
    }
  }

  function onInsurancesEdit() {
    if (reservation && !isCancelled()) {
      dispatch(openInsurancesDialog());
      dispatch(fetchAvailableInsurances(reservation.guid));
    } else {
      openSignupDialog();
    }
  }

  function onCancelConfirm() {
    if (reservation) {
      dispatch(cancelReservation(reservation.guid));
    }
  }

  function onCancelNavigate() {
    if (externalCancelLink !== null) {
      window.location.href = externalCancelLink;
    }
  }

  function openSignupDialog() {
    setSignupOpen(true);
  }

  function closeSignupDialog() {
    setSignupOpen(false);
  }

  function openExtensionDialog() {
    dispatch(openExtendReservationDialog());
  }

  function isReservationInactive() {
    return (
      reservation && !!reservation.dateFrom && moment(new Date()).isBefore(reservation.dateFrom)
    );
  }

  function isCancelled() {
    return Boolean(reservation && reservation.status === 'Cancelled');
  }

  function isReservationUpcoming() {
    return Boolean(reservation && reservation.status === 'Upcoming');
  }

  function editUntil() {
    const today = moment().utc();
    const now = moment();
    if (reservationDates.from.date) {
      const reservationDateClone = moment(reservationDates.from.date.utc());

      if (onlineCheckIn) {
        if (editable && editable > 0) {
          const openUntilDate = reservationDateClone.subtract(editable, 'h');
          // if (today.isSame(openUntilDate, 'day') || openUntilDate.isBefore(today)) {
          //   return false;
          // }
          if (
            today.isBefore(openUntilDate, 'hour') ||
            (today.isSame(openUntilDate, 'hour') && today.isBefore(openUntilDate, 'minute'))
          ) {
            return true; // Can still edit
          } else {
            return false; // Cannot edit anymore
          }
        }
      }
    }

    return true;
  }

  function cancelUntil() {
    const today = moment().utc();
    if (reservationDates.from.date) {
      const reservationDateClone = moment(reservationDates.from.date.utc());

      if (cancellation > 0) {
        const canCancelUntilDate = reservationDateClone.subtract(cancellation, 'h');
        if (today.isSame(canCancelUntilDate, 'day') || canCancelUntilDate.isBefore(today)) {
          return false;
        }
      }
    }

    return true;
  }

  function randomTrips(tripList: ITripLite[], many: number) {
    if (tripList.length > many) {
      return tripList.sort(() => 0.5 - Math.random()).splice(0, many);
    }
    return tripList;
  }

  function downloadRentalAgreement() {
    const dataForDownload = reservation.rentalAgreementSignedUrl;
    saveAs(dataForDownload, 'RentalAgreement.pdf');
  }

  function canEditCustomerInfo() {
    return !reservation.checkedIn && !isCancelled() && isReservationUpcoming() && editUntil();
  }

  async function onCustomerUpdate(values: { [name: string]: string }) {
    const updateValues = { ...values };
    updateValues.countryId = values.countryId.toString();

    await performUpdateCustomer(reservation, dispatch, updateValues);
  }
};

function createPersonalInfoFields(flightNumber: string, customer?: IReservationCustomer) {
  if (!customer) {
    return null;
  }
  return [
    {
      label: 'First name',
      value: customer.firstName,
      required: true,
      name: 'firstName',
    },
    {
      label: 'Last name',
      value: customer.lastName,
      required: true,
      name: 'lastName',
    },
    {
      label: 'Email',
      value: customer.email,
      disableEdit: true,
    },
    {
      label: 'Phone',
      value: customer.phone,
      name: 'phone',
    },
    {
      label: 'Mobile',
      value: customer.mobile,
      name: 'mobile',
      required: true,
    },
    {
      label: 'Address',
      value: customer.address,
      name: 'address',
    },
    {
      label: 'City',
      value: customer.city,
      name: 'city',
      required: true,
    },
    {
      label: 'Zip code',
      value: customer.zipCode,
      name: 'zipCode',
      required: true,
    },
    {
      label: 'Flight number',
      value: flightNumber,
      name: 'flightNumber',
    },
    {
      label: 'Country',
      value: customer.countryId || '0',
      name: 'countryId',
      required: true,
    },
  ];
}

export default ReservationInfo;

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Helmet from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Button from '@material-ui/core/Button';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import SignupDialog from './SignupDialog';
import HTMLRichText from './HTMLRichText';
import { ITrip, ITripRoute, ITripDay, ITripPlace } from '../services/ApiService';
import { DurationIcon, DistanceIcon, CameraIcon } from '../icons';
import useAuthState from '../hooks/useAuthState';
import TripItinerary from './TripItinerary';
import TripRouteMap from './TripRouteMap';
import TripDay from './TripDay';
import { updateCustomerTrip } from '../store/customerTrip/actions';
import { placeIsStop } from '../utils/trips';
import { sum, chunkByIndices } from '../utils/array';
import { metersToKm } from '../utils/distance';
import stripHtml from '../utils/stripHtml';
import { selectCustomerTripState } from '../store/selectors';

export type IChunkedRoute = Array<Array<{ lat: number; lng: number }>>;

export interface IDraggablePlace extends ITripPlace {
  instanceId: string;
}

export interface IDraggableDay extends ITripDay {
  id: string;
  places: IDraggablePlace[];
}

interface IProps {
  trip: ITrip;
  route: ITripRoute | null;
  isCustomerTrip: boolean;
  routeLoading?: boolean;
  addToTrips?: (templateId: string) => Promise<string | null>;
}

const useStyles = makeStyles(theme => ({
  container: {
    marginBottom: theme.spacing(10),
  },
  content: {
    maxWidth: theme.dimensions.maxLayoutWidth,
    margin: 'auto',
    width: 'auto',
    padding: theme.spacing(0, 3),
    boxSizing: 'content-box',
  },
  topContent: {
    maxWidth: `calc(${theme.dimensions.maxLayoutWidth} + ${theme.spacing(6)}px)`,
    margin: 'auto',
  },
  titleArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(4, 3),
    [theme.breakpoints.down('sm')]: {
      alignItems: 'center',
      order: 2,
    },
  },
  titleContainer: {
    textTransform: 'uppercase',
    marginBottom: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  title: {
    fontWeight: theme.typography.fontWeightMedium,
    lineHeight: 1.2,
  },
  subtitle: {
    marginTop: theme.spacing(1),
  },
  iconContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    marginRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(0, 4),
    },
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 1),
    },
  },
  boldText: {
    fontWeight: theme.typography.fontWeightBold,
  },
  mediumText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  descriptionContainer: {
    marginTop: theme.spacing(2),
  },
  description: {
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  addButton: {
    margin: theme.spacing(2, 0),
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  image: {
    minHeight: 450,
    maxHeight: 800,
    width: '100%',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    [theme.breakpoints.down('sm')]: {
      order: 1,
      height: 250,
      minHeight: 0,
    },
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'center',
    borderColor: theme.palette.grey[300],
    borderStyle: 'solid',
    borderTopWidth: 1,
    borderBottomWidth: 1,
  },
  tabContent: {
    marginTop: theme.spacing(5),
  },
  tabTitleContainer: {
    marginBottom: theme.spacing(3),
  },
  map: {
    marginTop: theme.spacing(8),
  },
}));

const TripDetails: React.FC<IProps> = ({
  trip,
  addToTrips,
  isCustomerTrip,
  route,
  routeLoading,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const history = useHistory();
  const dispatch = useDispatch();
  const { user, isLoggedIn } = useAuthState();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const { updateLoading, updateError } = useSelector(selectCustomerTripState);

  const [isSignupOpen, setSignupOpen] = useState(false);
  const [tabIndex, setTabIndex] = useState(0);
  const [draggableDays, setDraggableDays] = useState<IDraggableDay[]>([]);
  const [chunkedRoute, setChunkedRoute] = useState<IChunkedRoute>([]);
  const [dayDistances, setDayDistances] = useState<number[]>([]);

  const dayIndex = tabIndex - 1;
  const canEditTrip = checkCanEditTrip();

  useEffect(() => {
    const draggable = trip.days.map(day => {
      const places = day.places.map(place => ({ ...place, instanceId: uuidv4() }));
      return { ...day, id: uuidv4(), places };
    });
    setDraggableDays(draggable);
  }, [trip.days]);

  useEffect(() => {
    setChunkedRoute(chunkRouteByDays(route, draggableDays));
    setDayDistances(getDayDistances(route, draggableDays));
  }, [route, draggableDays]);

  return (
    <React.Fragment>
      <Helmet title={trip.name}>
        <meta name="description" content={stripHtml(trip.description)} />
      </Helmet>
      <div className={classes.container}>
        <Grid className={classes.topContent} container>
          <Grid item xs={12} md={6} className={classes.titleArea}>
            <div className={classes.titleContainer}>
              <Typography variant={sm ? 'h4' : 'h3'} className={classes.title}>
                {trip.name}
              </Typography>
              {isCustomerTrip && (trip.customId || trip.slug) && (
                <Typography variant="h6" color="textSecondary" className={classes.subtitle}>
                  TRIP ID: {trip.customId || trip.slug}
                </Typography>
              )}
            </div>
            <Grid container direction="row" justify={sm ? 'center' : 'flex-start'}>
              {renderIcon(
                <DurationIcon width="30" height="30" />,
                'Duration',
                `${draggableDays.length} days`
              )}
              {renderIcon(
                <DistanceIcon width="40" height="30" />,
                'Distance',
                `${getTripDistance()} km`
              )}
              {renderIcon(
                <CameraIcon width="30" height="30" />,
                'Attractions',
                `${getStopCount()} stops`
              )}
            </Grid>
            <Grid
              className={classes.descriptionContainer}
              container
              direction="column"
              alignItems={sm ? 'center' : 'flex-start'}
            >
              <div className={classes.description}>
                <HTMLRichText text={trip.description} />
              </div>
              {!isCustomerTrip && (
                <Button
                  onClick={() => addCustomerTrip(trip.id)}
                  variant="contained"
                  color="secondary"
                  className={classes.addButton}
                >
                  Add to my trips
                </Button>
              )}
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            className={classes.image}
            style={{ backgroundImage: `url(${trip.photoUrl})` }}
          />
        </Grid>
        <div className={classes.tabsContainer}>
          <Tabs
            value={tabIndex}
            onChange={handleTabChange}
            indicatorColor="secondary"
            variant="scrollable"
            scrollButtons="on"
          >
            <Tab label="Overview" />
            {draggableDays.map((day, index) => (
              <Tab key={index} label={day.name || `Day ${index + 1}`} />
            ))}
          </Tabs>
        </div>
        <div className={classes.content}>
          {renderTabContent()}
          <div className={classes.map}>
            <TripRouteMap
              days={draggableDays}
              dayIndex={dayIndex}
              route={route}
              chunkedRoute={chunkedRoute}
              height={sm ? 400 : 600}
              isCustomerTrip={isCustomerTrip}
              routeLoading={routeLoading}
            />
          </div>
        </div>
      </div>
      <SignupDialog open={isSignupOpen} onClose={closeSignupDialog} />
    </React.Fragment>
  );

  function renderIcon(icon: React.ReactNode, label: string, value: string) {
    return (
      <Grid item className={classes.iconContainer}>
        <div style={{ marginBottom: 2 }}>{icon}</div>
        <Typography variant="body1">{label}:</Typography>
        <Typography variant="body1" className={classes.boldText}>
          {value}
        </Typography>
      </Grid>
    );
  }

  function renderTabContent() {
    const day = draggableDays[dayIndex];
    const showItinerary = dayIndex < 0 || !day;

    const title = showItinerary ? 'Travel Itinerary' : day.name || `Day ${dayIndex + 1}`;
    const subtitle = showItinerary
      ? `${draggableDays.length} ${draggableDays.length === 1 ? 'day' : 'days'}`
      : `${metersToKm(dayDistances[dayIndex] || day.distance)} km`;
    const status = updateLoading ? 'Saving...' : 'Up to date';

    const content = showItinerary ? (
      <TripItinerary
        days={draggableDays}
        distances={dayDistances}
        canEditTrip={canEditTrip}
        updateDays={updateDays}
        setDayIndex={index => setTabIndex(index + 1)}
      />
    ) : (
      <TripDay
        day={draggableDays[dayIndex]}
        dayIndex={dayIndex}
        canEditTrip={canEditTrip}
        updateDay={updateDay}
      />
    );

    return (
      <div className={classes.tabContent}>
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="flex-start"
          className={classes.tabTitleContainer}
        >
          <div style={{ marginBottom: theme.spacing(1) }}>
            <Typography variant="h5" className={classes.mediumText}>
              {title}
            </Typography>
            <Typography className={classes.mediumText}>{subtitle}</Typography>
          </div>
          {canEditTrip && <Typography color="textSecondary">{updateError || status}</Typography>}
        </Grid>
        {content}
      </div>
    );
  }

  function checkCanEditTrip() {
    return (
      isCustomerTrip &&
      isLoggedIn &&
      !!user &&
      !!user.email &&
      user.email_verified &&
      user.email === trip.customerEmail
    );
  }

  async function addCustomerTrip(templateId: string) {
    if (isLoggedIn) {
      if (!isCustomerTrip && addToTrips) {
        const customerTripId = await addToTrips(templateId);
        if (customerTripId) {
          history.push(`/trips/${templateId}/instance/${customerTripId}`);
        }
      }
    } else {
      openSignupDialog();
    }
  }

  function openSignupDialog() {
    setSignupOpen(true);
  }

  function closeSignupDialog() {
    setSignupOpen(false);
  }

  function handleTabChange(event: React.ChangeEvent<{}>, newValue: number) {
    setTabIndex(newValue);
  }

  function getTripDistance() {
    const distance = sum(dayDistances);
    return distance ? metersToKm(distance) : trip.distanceKm;
  }

  function getStopCount() {
    const stopCountsPerDay = draggableDays.map(day => day.places.filter(placeIsStop).length);
    return sum(stopCountsPerDay);
  }

  function updateDays(days: IDraggableDay[]) {
    if (canEditTrip) {
      const updatedDays = days.map(day => ({
        name: day.name,
        description: day.description,
        photo: day.photo,
        places: day.places.map(place => ({
          id: place.id,
          type: place.type,
          location: place.location,
        })),
      }));

      dispatch(updateCustomerTrip(trip.id, { days: updatedDays }));
      setDraggableDays(days);
    }
  }

  function updateDay(index: number, day: IDraggableDay) {
    const updatedDays: IDraggableDay[] = draggableDays.slice();
    updatedDays[index] = day;
    updateDays(updatedDays);
  }
};

function chunkRouteByDays(route: ITripRoute | null, days: IDraggableDay[]) {
  if (!route || !route.waypoint) {
    return [];
  }

  const dayStops = days.map(day => day.places);
  const dayStopLengths = dayStops.map(stops => stops.length);
  const indices = dayStopLengths.map((len, index) => len + sum(dayStopLengths.slice(0, index)));
  const chunkedWaypoints = chunkByIndices(route.waypoint, indices);

  let previousIndex = 0;
  const lastShapeIndexOfEachDay = chunkedWaypoints.map(wp => {
    if (wp.length) {
      // Have to add 1 to the shapeIndex because the
      // chunkByIndices index is up to but not including
      previousIndex = wp[wp.length - 1].shapeIndex + 1;
    }
    return previousIndex;
  });

  return chunkByIndices(route.shape, lastShapeIndexOfEachDay);
}

function getDayDistances(route: ITripRoute | null, days: IDraggableDay[]) {
  if (!route || !route.legs) {
    return [];
  }

  // Start at -1 because the first place has no leg
  let index = -1;
  const legs = route.legs;

  return days.map(day =>
    sum(
      day.places.map(_ => {
        const leg = legs[index++];
        return leg ? leg.distance : 0;
      })
    )
  );
}

export default TripDetails;

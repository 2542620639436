import { useReducer, useEffect } from 'react';

import useApi from './useApi';
import IReservationLite from '../interfaces/IReservationLite';

interface IState {
  reservations: IReservationLite[];
  isLoading: boolean;
  error: string;
}

interface IInitAction {
  type: 'FETCH_INIT';
}

interface ISuccessAction {
  type: 'FETCH_SUCCESS';
  payload: IReservationLite[];
}

interface IErrorAction {
  type: 'FETCH_ERROR';
  payload: Error;
}

type Action = IInitAction | ISuccessAction | IErrorAction;

const initialState: IState = {
  reservations: [],
  isLoading: true,
  error: '',
};

function reducer(state: IState, action: Action) {
  switch (action.type) {
    case 'FETCH_INIT':
      return { ...state, isLoading: true };
    case 'FETCH_SUCCESS':
      return { ...state, reservations: action.payload || [], isLoading: false, error: '' };
    case 'FETCH_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload.message || 'An error occured',
      };
    default:
      return state;
  }
}

export default function useReservations(includeCancelled?: boolean) {
  const api = useApi();

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    let didCancel = false;
    const fetchData = async () => {
      dispatch({ type: 'FETCH_INIT' });
      try {
        const data = await api.fetchReservations(includeCancelled);
        if (!didCancel) {
          dispatch({ type: 'FETCH_SUCCESS', payload: data });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'FETCH_ERROR', payload: error });
        }
      }
    };

    fetchData();

    return () => {
      didCancel = true;
    };
  }, [api, includeCancelled]);

  return state;
}

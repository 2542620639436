import React, { useState } from 'react';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import MenuIcon from '@material-ui/icons/Menu';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';

import RouterLinkForwardRef from '../components/RouterLinkForwardRef';

import useTenant from '../hooks/useTenant';
import useAuthActions from '../hooks/useAuthActions';
import { IUser } from '../store/auth/types';

interface IProps {
  user: IUser | null;
}

const useStyles = makeStyles({
  root: {},
  drawerContent: {
    minWidth: 230,
  },
  listLink: {
    textDecoration: 'none',
  },
});

const MobileNav: React.FC<IProps> = ({ user }) => {
  const classes = useStyles();
  const { login, logout } = useAuthActions();
  const { headerLinks } = useTenant();
  const [isDrawerOpen, setDrawerOpen] = useState(false);

  const isLoggedIn = Boolean(user);

  return (
    <React.Fragment>
      <IconButton onClick={() => setDrawerOpen(true)} disableTouchRipple>
        <MenuIcon data-testid="menu-icon" color="primary" />
      </IconButton>
      <SwipeableDrawer
        anchor="right"
        disableBackdropTransition={!isIOS()}
        disableDiscovery={isIOS()}
        open={isDrawerOpen}
        onClose={closeDrawer}
        onOpen={openDrawer}
      >
        <div data-testid="drawer" className={classes.drawerContent}>
          <IconButton onClick={closeDrawer}>
            <ArrowForwardIcon color="primary" />
          </IconButton>
          <Divider />
          <List>
            {headerLinks.map(({ label, path }) => {
              const isInternal = path.startsWith('/');
              return isInternal ? (
                <ListItem
                  className={classes.listLink}
                  onClick={closeDrawer}
                  to={path}
                  button
                  key={label}
                  component={RouterLinkForwardRef}
                >
                  <ListItemText primary={label} />
                </ListItem>
              ) : (
                <ListItem
                  className={classes.listLink}
                  href={path}
                  onClick={closeDrawer}
                  button
                  key={label}
                  component="a"
                >
                  <ListItemText primary={label} />
                </ListItem>
              );
            })}
            {isLoggedIn && (
              <ListItem data-testid="logout-button" button onClick={onLogout}>
                <ListItemText primary="Log Out" />
              </ListItem>
            )}
            {!isLoggedIn && (
              <ListItem data-testid="login-button" button onClick={onLogin}>
                <ListItemText primary="Log In" />
              </ListItem>
            )}
          </List>
        </div>
      </SwipeableDrawer>
    </React.Fragment>
  );

  function openDrawer() {
    setDrawerOpen(true);
  }

  function closeDrawer() {
    setDrawerOpen(false);
  }

  function onLogout() {
    closeDrawer();
    logout();
  }

  function onLogin() {
    openDrawer();
    login();
  }
};

export default MobileNav;

function isIOS() {
  return /iPad|iPhone|iPod/.test(navigator.userAgent);
}

import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';

import formatPrice from '../utils/priceFormatter';

interface IProps {
  title: string;
  extras: Array<{
    id: number;
    name: string;
    quantity: number;
    price: number;
  }>;
  emptyText: string;
  currency: string;
  disabled?: boolean;
  days?: number;
}

const useStyles = makeStyles(theme => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  content: {
    padding: theme.spacing(0, 2),
    [theme.breakpoints.down('xs')]: {
      padding: 0,
    },
  },
  item: {
    padding: '5px 0px',
    borderBottom: `1px solid ${theme.palette.grey[400]}`,
  },
  total: {
    paddingTop: theme.spacing(1),
  },
  upperCase: {
    textTransform: 'uppercase',
  },
}));

const ExtrasPrice: React.FC<IProps> = ({ title, extras, emptyText, currency, disabled, days }) => {
  const classes = useStyles();
  let totalPrice = 0;
  extras.forEach(e => (totalPrice += e.price));

  return (
    <div>
      <Typography variant="h6" className={classes.title}>
        {title}
      </Typography>
      <div
        style={{ opacity: disabled || extras.length === 0 ? 0.5 : 1 }}
        className={classes.content}
      >
        {extras.length === 0 ? (
          <Typography>{emptyText}</Typography>
        ) : (
          <React.Fragment>
            {extras.map(extra => (
              <Grid
                container
                direction="row"
                justify="space-between"
                className={classes.item}
                key={extra.id}
              >
                <Typography>
                  {extra.quantity} x {extra.name}
                </Typography>
                {!!days ? (
                  <Typography>
                    {formatPrice(Math.round(extra.price / days), currency)} per day
                  </Typography>
                ) : (
                  <Typography>{formatPrice(extra.price, currency)}</Typography>
                )}
              </Grid>
            ))}
            <Grid container direction="row" justify="space-between" className={classes.total}>
              <Typography variant="h6" className={classes.upperCase}>
                Total price
              </Typography>
              <Typography variant="h6">{formatPrice(totalPrice, currency)}</Typography>
            </Grid>
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default ExtrasPrice;

import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectApiToken, selectTenantId } from '../store/selectors';
import ApiService from '../services/ApiService';

// Hook that ensures that the API Service instance always sends
// the correct API Access token and tenant ID with every call
export default function useApi() {
  const accessToken = useSelector(selectApiToken);
  const tenantId = useSelector(selectTenantId);

  const api = useMemo(() => new ApiService({ accessToken, tenantId }), [accessToken, tenantId]);

  return api;
}

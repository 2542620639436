import React, { useState } from 'react';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';

import useInterval from '../hooks/useInterval';

const RealtimeClock: React.FC = () => {
  const time = useCurrentTime('HH:mm:ss');
  return (
    <Typography color="inherit" variant="body1" display="inline">
      {time}
    </Typography>
  );
};

function useCurrentTime(format: string) {
  const [time, setTime] = useState(getTime());

  useInterval(() => {
    setTime(getTime());
  }, 1000);

  return time;

  function getTime() {
    return moment()
      .utc()
      .format(format);
  }
}

export default RealtimeClock;

import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Grid from '@material-ui/core/Grid';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Hidden from '@material-ui/core/Hidden';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import { fade } from '@material-ui/core/styles/colorManipulator';

import useAuthState from '../hooks/useAuthState';
import useRouterState from '../hooks/useRouterState';
import useTenant from '../hooks/useTenant';
import { NAVBAR_HEIGHT } from '../constants';

import MobileNav from './NavMobile';
import DesktopNav from './NavDesktop';

const useStyles = makeStyles(theme => ({
  header: {
    backgroundColor: '#fff',
    boxShadow: '0 3px 6px 0 rgba(114, 114, 114, 0.16)',
  },
  toolbar: {
    height: NAVBAR_HEIGHT,
  },
  toolbarGrid: {
    maxWidth: theme.dimensions.maxLayoutWidth,
    margin: '0 auto',
  },
  logoLink: {
    minWidth: 50,
  },
  logo: {
    height: 40,
  },
  grow: {
    flexGrow: 1,
  },
  lowerNavContainer: {
    padding: theme.spacing(2, 3),
    width: '100%',
    backgroundColor: fade(theme.palette.primary.main, 0.3),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(2),
    },
  },
  lowerNav: {
    maxWidth: theme.dimensions.maxLayoutWidth,
    margin: 'auto',
  },
  lowerLink: {
    textDecoration: 'none',
    marginRight: theme.spacing(8),
    [theme.breakpoints.down('sm')]: {
      margin: 0,
    },
  },
  lowerLinkText: {
    borderBottom: '2px solid transparent',
    '&:hover': {
      borderColor: theme.palette.primary.main,
    },
  },
  activeLinkText: {
    fontWeight: theme.typography.fontWeightBold,
    borderColor: theme.palette.primary.main,
  },
}));

const Navbar: React.FC = () => {
  const classes = useStyles();
  const tenant = useTenant();
  const theme = useTheme();
  const { user } = useAuthState();
  const { location } = useRouterState();
  const isLoggedIn = Boolean(user);
  const sm = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <React.Fragment>
      {sm && <div className={classes.toolbar} style={{ visibility: 'hidden' }} />}
      <AppBar className={classes.header} position={sm ? 'fixed' : 'static'}>
        <Toolbar className={classes.toolbar}>
          <Grid container className={classes.toolbarGrid} justify="center" alignItems="center">
            <Grid item>
              <Link aria-label="Home" className={classes.logoLink} to="/">
                <img data-testid="logo" alt="logo" src={tenant.logoUrl} className={classes.logo} />
              </Link>
            </Grid>
            <Grid item className={classes.grow} />
            <Hidden only={['xs', 'sm']}>
              <div data-testid="desktop-test" />
              <DesktopNav data-testid="desktop-nav" user={user} />
            </Hidden>
            <Hidden only={['md', 'lg', 'xl']}>
              <div data-testid="mobile-test" />
              <MobileNav data-testid="mobile-nav" user={user} />
            </Hidden>
          </Grid>
        </Toolbar>
      </AppBar>
      {isLoggedIn && (
        <div className={classes.lowerNavContainer}>
          <Grid container className={classes.lowerNav} justify={sm ? 'space-around' : 'flex-start'}>
            {renderLowerLink('Dashboard', '/dashboard')}
            {renderLowerLink('Bookings', '/bookings')}
            {tenant.hasDriverGuide && renderLowerLink('Trips', '/trips')}
            {tenant.showWidget && renderLowerLink('Widget', '/widget')}
          </Grid>
        </div>
      )}
    </React.Fragment>
  );

  function renderLowerLink(label: string, to: string) {
    const active = location.pathname.startsWith(to);
    return (
      <Link to={to} className={classes.lowerLink}>
        <Typography className={cx(classes.lowerLinkText, { [classes.activeLinkText]: active })}>
          {label}
        </Typography>
      </Link>
    );
  }
};

export default Navbar;

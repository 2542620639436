import React from 'react';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface IProps {
  title: string;
  description: string;
  // If marker has border
  padding?: number;
}

const useStyles = makeStyles(theme => ({
  container: {
    position: 'absolute',
    transform: 'translate(15px, -50%)',
  },
  title: {
    padding: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    whiteSpace: 'nowrap',
  },
}));

const InfoWindow: React.FC<IProps> = ({ title, padding = 0 }) => {
  const classes = useStyles();
  const style = { top: padding, left: padding * 2 };

  return (
    <Paper className={classes.container} style={style}>
      <Typography className={classes.title}>{title}</Typography>
    </Paper>
  );
};

export default InfoWindow;

import React, { useState } from 'react';
import {
  DialogTitle,
  DialogContent,
  Grid,
  Typography,
  TextareaAutosize,
  DialogActions,
  Button,
  useTheme,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  partOfCar: any;
  textAreaChange: (e: any) => void;
  onSubmit: (isCreating: boolean) => void;
  fileRef: any;
  fileUploadHandler: (e: any) => void;
  descriptionError: string;
  imageError: string;
  addImageInput: () => void;
  imageInputs: any[];
}

const useStyles = makeStyles(theme => ({
  arrowButton: {
    fontSize: '22px',
    cursor: 'pointer',
    backgroundColor: 'transparent',
    border: 'none',
    height: '100px',
    alignSelf: 'center',
  },
  sliderContainer: {
    display: 'flex',
  },
  sliderImage: {
    opacity: 0,
    animation: '$fadeInOut 1s',
  },
  sliderImageVisible: {
    opacity: 1,
  },
  '@keyframes fadeInOut': {
    '0%': {
      opacity: 0,
    },

    '100%': {
      opacity: 1,
    },
  },
}));

interface ImageInput {
  id: string;
  element: JSX.Element;
}

const DamageDialog = ({
  isOpen,
  partOfCar,
  textAreaChange,
  onSubmit,
  onClose,
  fileRef,
  fileUploadHandler,
  descriptionError,
  imageError,
  addImageInput,
  imageInputs,
}: IProps) => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const [activeIndex, setActiveIndex] = useState(0);
  const [isImageVisible, setIsImageVisible] = useState(false);

  const handlePrev = () => {
    setIsImageVisible(false);
    setTimeout(() => {
      setActiveIndex(prevIndex => (prevIndex === 0 ? partOfCar.images.length - 1 : prevIndex - 1));
      setIsImageVisible(true);
    }, 0);
  };

  const handleNext = () => {
    setIsImageVisible(false);
    setTimeout(() => {
      setActiveIndex(prevIndex => (prevIndex === partOfCar.images.length - 1 ? 0 : prevIndex + 1));
      setIsImageVisible(true);
    }, 0);
  };

  const goToSlide = (index: number) => {
    setTimeout(() => {
      setActiveIndex(prevIndex => index);
    }, 0);
  };

  // const generateId = () => {
  //   return `input-${new Date().getTime()}`; // Generate a unique ID based on the timestamp
  // };

  // const removeImageInput = (id: string) => {
  //   setImageInputs((prevInputs) => prevInputs.filter((input) => input.id !== id));
  // };

  // const addImageInput = () => {
  //   const id = generateId();
  //   const element = (
  //     <div key={id}>
  //       <input
  //         accept="image/jpeg, image/png"
  //         ref={fileRef}
  //         type="file"
  //         style={{ marginBottom: '5px' }}
  //         onChange={fileUploadHandler}
  //       />
  //       <button onClick={() => removeImageInput(id)} >X</button>
  //     </div>)

  //   setImageInputs((prevInputs) => [...prevInputs, { id, element }]);
  // };

  return (
    <Dialog
      open={isOpen}
      onClose={() => {
        return;
      }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>{partOfCar.name}</DialogTitle>
      <DialogContent>
        <Grid
          container
          style={{ display: 'flex', flexDirection: 'column' }}
          alignItems="flex-start"
          spacing={2}
        >
          <Grid
            item
            style={{
              display: 'flex',
              width: '100%',
              flex: 1,
              flexDirection: 'column',
            }}
          >
            <Typography variant="body1">Description</Typography>
            <TextareaAutosize
              rowsMax={5}
              rowsMin={5}
              defaultValue={partOfCar.description !== '' ? partOfCar.description : ''}
              aria-label="maximum height"
              style={{ resize: 'none', height: xs ? '50px' : '81px' }}
              onChange={(e: any) => textAreaChange(e)}
            />
            {descriptionError && (
              <Typography variant="caption" color="error">
                {descriptionError}
              </Typography>
            )}
          </Grid>
          <Grid
            item
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between',
              flex: 1,
              flexDirection: 'column',
            }}
          >
            <Typography variant="body1">Photos</Typography>
            {partOfCar.images && partOfCar.images.length > 0 ? (
              <div className="photoSLiderContainer">
                <div style={{ display: 'flex', justifyContent: 'center' }} className="photoSlider">
                  <button className={classes.arrowButton} onClick={handlePrev}>
                    &lt;
                  </button>
                  <div className={classes.sliderContainer}>
                    <img
                      key={activeIndex}
                      width="350px"
                      height="300px"
                      alt="damage"
                      className={`${classes.sliderImage} ${
                        isImageVisible ? classes.sliderImageVisible : ''
                      }`}
                      onLoad={() => {
                        setIsImageVisible(true);
                      }}
                      src={partOfCar.images[activeIndex]}
                    />
                  </div>
                  <button className={classes.arrowButton} onClick={handleNext}>
                    {' '}
                    &gt;
                  </button>
                </div>

                <div
                  className="photoCirclesContainer"
                  style={{ display: 'flex', marginTop: '10px', justifyContent: 'center' }}
                >
                  {partOfCar.images.map((image: any, index: number) => (
                    <div
                      onClick={() => goToSlide(index)}
                      className="photoCirle"
                      key={index}
                      style={
                        activeIndex === index
                          ? {
                              cursor: 'pointer',
                              backgroundColor: theme.palette.primary.main,
                              width: '11px',
                              height: '11px',
                              marginRight: '5px',
                              borderRadius: '50%',
                            }
                          : {
                              cursor: 'pointer',
                              backgroundColor: '#aeaeae',
                              width: '11px',
                              height: '11px',
                              marginRight: '5px',
                              borderRadius: '50%',
                            }
                      }
                    />
                  ))}
                </div>
              </div>
            ) : (
              <div
                style={{ display: 'flex', flexDirection: 'column', justifyContent: 'flex-start' }}
              >
                <input
                  accept="image/jpeg, image/png"
                  ref={fileRef}
                  type="file"
                  style={{ marginBottom: '5px' }}
                  onChange={e => fileUploadHandler(e)}
                />
                {imageInputs.map(imageInput => imageInput.element)}
                {imageError && (
                  <Typography variant="caption" color="error">
                    {imageError}
                  </Typography>
                )}
                <Button
                  variant="outlined"
                  style={
                    imageError !== ''
                      ? {
                          borderColor: 'gray',
                          color: 'gray',
                          marginTop: '10px',
                          width: '200px',
                          cursor: 'not-allowed',
                        }
                      : { borderColor: 'green', color: 'green', marginTop: '10px', width: '200px' }
                  }
                  onClick={addImageInput}
                  disabled={imageError !== ''}
                >
                  Add another image
                </Button>
              </div>
            )}
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            setActiveIndex(0);
            onClose();
          }}
        >
          {' '}
          Cancel
        </Button>
        <Button
          style={{ color: 'white' }}
          color="primary"
          variant="contained"
          onClick={() => {
            setActiveIndex(0);
            onSubmit(partOfCar.isCreating);
          }}
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DamageDialog;

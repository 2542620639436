import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface IProps {
  alt: string;
}
const useStyles = makeStyles(theme => ({
  background: {
    fill: theme.palette.background.default,
  },
  primary: {
    fill: theme.palette.primary.main,
  },
}));

const Waving: React.FC<IProps> = ({ alt }) => {
  const classes = useStyles();
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="91" height="88" viewBox="0 0 91 88">
      <title>{alt}</title>
      <g id="emoji_people-24px" transform="translate(0 -.742)">
        <g id="Bounding_Box" transform="translate(0 .419)">
          <path
            id="Rectangle_650"
            d="M0 0h91v88H0z"
            className={classes.background}
            data-name="Rectangle 650"
            transform="translate(0 .324)"
          />
        </g>
        <g id="Flat" transform="translate(14.802 7.417)">
          <g id="Group_207" data-name="Group 207">
            <ellipse
              id="Ellipse_54"
              cx="8"
              cy="6.5"
              className={classes.primary}
              data-name="Ellipse 54"
              rx="8"
              ry="6.5"
              transform="translate(21.198 1.325)"
            />
            <path
              id="Path_70"
              d="M48 24.61c-1.443-1.443-3.923-4.108-8.733-4.108h-9.4A18.549 18.549 0 0 1 11.4 2H4a25.991 25.991 0 0 0 18.5 24.83v49.179h7.4v-22.2h7.4v22.2h7.4v-44.22l14.622 14.617 5.218-5.218z"
              className={classes.primary}
              data-name="Path 70"
              transform="translate(-4 -2)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
};

export default Waving;

import React from 'react';
import cx from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import grey from '@material-ui/core/colors/grey';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import RouterLinkForwardRef from './RouterLinkForwardRef';
import RealtimeClock from './RealtimeClock';
import useTenant from '../hooks/useTenant';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3),
    borderTop: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: grey[200],
  },
  contentRow: {
    maxWidth: theme.dimensions.maxLayoutWidth,
    margin: `${theme.spacing(2)}px auto`,
  },
  logo: {
    width: 90,
  },
  infoItem: {
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down('sm')]: {
      margin: theme.spacing(2, 0),
      justifyContent: 'flex-start',
    },
  },
  whiteDot: {
    margin: 10,
    height: 25,
    width: 25,
    backgroundColor: '#fff',
    borderRadius: '50%',
  },
  tenantName: {
    fontWeight: 'bold',
    color: '#585858',
    marginTop: theme.spacing(1),
  },
  text: {
    lineHeight: 2,
  },
  rightText: {
    textAlign: 'right',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  divider: {
    maxWidth: theme.dimensions.maxLayoutWidth,
    margin: `${theme.spacing(2)}px auto`,
    backgroundColor: '#afafaf',
  },
}));

const StaticFooter: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { info, footerLines, footerLinks, logoUrl } = useTenant();
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <footer className={classes.root}>
      <Grid container className={classes.contentRow}>
        <Grid item>
          <Grid container>
            <Grid item>
              <img className={classes.logo} src={logoUrl} alt="logo" />
              <Typography className={classes.tenantName} color="inherit" variant="h5">
                {info.name}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid container className={classes.contentRow} alignItems="flex-start">
        <Grid item xs={12} sm={6} md={3}>
          <Grid container justify="flex-start" className={classes.infoItem}>
            <Grid item>
              {renderInfoText(info.address)}
              {renderInfoText(info.city)}
              {renderInfoText(info.country)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Grid container justify="center" className={classes.infoItem}>
            <Grid item>
              {renderInfoText(info.email)}
              {renderInfoText(info.phone)}
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Grid container justify="center" className={classes.infoItem}>
            <Grid item>
              {renderInfoText(info.hours)}
              <Typography color="inherit" variant="body1" display="inline" className={classes.text}>
                Current Local Time:{' '}
              </Typography>
              <RealtimeClock />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Grid container justify="flex-end" className={classes.infoItem}>
            <Grid item>
              {footerLines.map(line => (
                <Typography
                  color="inherit"
                  key={line}
                  variant="body1"
                  className={cx(classes.text, classes.rightText)}
                >
                  {line}
                </Typography>
              ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        className={classes.contentRow}
        style={{ marginTop: theme.spacing(3) }}
        container
        justify="center"
        alignItems="center"
      >
        {footerLinks.map(({ label, path }) => {
          const isInternal = path.startsWith('/');
          return (
            <Grid item key={label} xs="auto">
              {isInternal ? (
                <Button
                  to={path}
                  size={xs ? 'small' : sm ? 'medium' : 'large'}
                  color="inherit"
                  component={RouterLinkForwardRef}
                >
                  {label}
                </Button>
              ) : (
                <Button href={path} size={xs ? 'small' : sm ? 'medium' : 'large'} color="inherit">
                  {label}
                </Button>
              )}
            </Grid>
          );
        })}
      </Grid>
      <Divider className={classes.divider} />
      {/* <Grid className={classes.contentRow} container justify="center" alignItems="center">
        <Grid item>
          <span className={classes.whiteDot} />
        </Grid>
        <Grid item>
          <span className={classes.whiteDot} />
        </Grid>
      </Grid> */}
    </footer>
  );

  function renderInfoText(text: React.ReactNode) {
    return (
      <Typography color="inherit" variant="body1" className={classes.text}>
        {text}
      </Typography>
    );
  }
};
export default StaticFooter;

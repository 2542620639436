import React from 'react';
import moment, { Moment } from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { DatePicker as MaterialDatePicker } from '@material-ui/pickers';
import { MuiThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import DateIcon from '@material-ui/icons/CalendarToday';
import InputAdornment from '@material-ui/core/InputAdornment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import useTheme from '../hooks/useTheme';
import { DATE_FORMAT } from '../constants';

interface IProps {
  label: string;
  value: Moment | null;
  open: boolean;
  onChange: (value: Moment | null) => void;
  onOpen: () => void;
  onClose: () => void;
  minDate?: Moment;
  maxDate?: Moment;
  dateFormat?: string;
  disabled?: boolean;
  className?: string;
  showToolbar?: boolean;
}

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.grey[700],
  },
}));

const DatePicker: React.FC<IProps> = ({
  label,
  value,
  open,
  onChange,
  onOpen,
  onClose,
  className,
  minDate,
  maxDate,
  dateFormat,
  disabled,
  showToolbar,
}) => {
  const classes = useStyles();
  // Hack to change colors
  const { palette } = useTheme();
  const theme = createMuiTheme({
    palette: {
      primary: { main: palette.secondary.main },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <MaterialDatePicker
          label={label}
          value={resolveValue(value)}
          open={open}
          error={false}
          helperText={null}
          onChange={handleChange}
          onOpen={onOpen}
          onClose={onClose}
          format={dateFormat || DATE_FORMAT}
          variant="inline"
          inputVariant="outlined"
          disableToolbar={!showToolbar}
          InputProps={{
            style: { cursor: 'pointer' },
            inputProps: {
              style: { cursor: 'pointer' },
            },
            startAdornment: (
              <InputAdornment position="start">
                <DateIcon className={classes.icon} />
              </InputAdornment>
            ),
          }}
          minDate={minDate}
          maxDate={maxDate}
          disabled={disabled}
          className={className}
          // Required props
          style={{}}
          rows={0}
          rowsMax={0}
          ref={null}
          innerRef={null}
          inputRef={null}
        />
      </MuiPickersUtilsProvider>
    </MuiThemeProvider>
  );

  function resolveValue(val: Moment | null) {
    if (!val) {
      return null;
    }

    return moment.utc(val);
  }

  function handleChange(date: moment.Moment | null) {
    if (date) {
      onChange(moment.utc(date));
    }
  }
};

export default DatePicker;

import React from 'react';
import cx from 'classnames';
import Paper from '@material-ui/core/Paper';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface IProps {
  className?: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.customPalette.infoBox.default,
    padding: theme.spacing(2),
    borderRadius: 8,
  },
}));

const InfoBox: React.FC<IProps> = ({ className, children }) => {
  const classes = useStyles();
  return (
    <Paper className={cx(classes.root, className)} elevation={0}>
      {children}
    </Paper>
  );
};

export default InfoBox;

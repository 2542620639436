import React, { useCallback, useEffect, useState } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import OnlineCheckInDriverList from './OnlineCheckInDriverList';
import InfoBox from './InfoBox';
import AddIcon from '@material-ui/icons/Add';
import {
  IAddExtra,
  IExtra,
  IReservationDriver,
  IReservationExtra,
} from '../interfaces/IReservation';
import { Button, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { getLoadingStates } from '../store/selectors';
import formatPrice from '../utils/priceFormatter';

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  bold: {
    fontWeight: 'bold',
  },
  driverContainer: {
    marginBottom: theme.spacing(1),
  },
  driverLine: {
    marginBottom: theme.spacing(0.5),
  },
  addDriverContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  addDriver: {
    borderRadius: '50%',
    minWidth: 0,
    minHeight: 0,
    height: '40px',
    alignSelf: 'center',
    width: '40px',
    backgroundColor: theme.palette.primary.main,
  },
  disabled: {
    borderRadius: '50%',
    minWidth: 0,
    minHeight: 0,
    height: '40px',
    alignSelf: 'center',
    width: '40px',
    backgroundColor: 'transparent',
    cursor: 'not-allowed',
  },
}));

interface IProps {
  drivers: IReservationDriver[];
  editable?: boolean;
  onEdit: (driver: any) => Promise<any>;
  extras: IReservationExtra[];
  availableExtras: IExtra | undefined;
  days: number;
  currency: string;
  addExtras: (extras: IAddExtra[]) => void;
  onUpdating: (change: boolean) => void;
  isFromCheckIn?: boolean;
  onRemoveExtraDriver: (id: number) => void;
}

interface IExtraStatus {
  id: number;
  quantity: number;
  currentQuantity: number;
  anchor: HTMLDivElement | null;
}

const OnlineCheckInDriverInfo: React.FC<IProps> = ({
  drivers,
  onEdit,
  editable = false,
  extras,
  availableExtras,
  currency,
  addExtras,
  onUpdating,
  days,
  onRemoveExtraDriver,
  isFromCheckIn = true,
}) => {
  const classes = useStyles();
  const { isAddingExtras } = useSelector(getLoadingStates);
  const mainDriverIndex = drivers.findIndex(d => d.mainDriver);
  const mainDriver = drivers[mainDriverIndex] || null;
  const [driverStatus, setDriverStatus] = useState<{ [id: number]: IExtraStatus }>({});

  const initializeExtras = useCallback(() => {
    const status: { [id: number]: IExtraStatus } = {};
    if (availableExtras !== undefined) {
      extras.forEach(extra => {
        status[extra.id] = {
          id: extra.id,
          quantity: extra.quantity,
          currentQuantity: extra.quantity,
          anchor: null,
        };
      });

      if (status[availableExtras.id] === undefined) {
        status[availableExtras.id] = {
          id: availableExtras!.id,
          quantity: 0,
          currentQuantity: 0,
          anchor: null,
        };
      }

      setDriverStatus(status);
    }
  }, [extras, availableExtras]);

  useEffect(() => {
    initializeExtras();
  }, [initializeExtras]);

  // useEffect(() => {
  //   if (availableExtras !== undefined) {
  //     if (
  //       availableExtras.maximumPrice !== undefined &&
  //       availableExtras.price > availableExtras.maximumPrice
  //     ) {
  //       setPrice(availableExtras.maximumPrice);
  //     } else if (
  //       availableExtras.minimumPrice !== undefined &&
  //       availableExtras.price < availableExtras.minimumPrice
  //     )
  //       setPrice(availableExtras.minimumPrice);
  //     else {
  //       setPrice(availableExtras.price);
  //     }
  //   }
  // }, [availableExtras]);

  const extraDriverHandler = () => {
    const updateStatus = { ...driverStatus };

    if (availableExtras !== undefined) {
      updateStatus[availableExtras.id].quantity = updateStatus[availableExtras.id].quantity + 1;
      setDriverStatus(updateStatus);
      const validExtras = Object.values(updateStatus).filter(status => status.quantity > 0);
      addExtras(validExtras);
    }
  };

  return (
    <>
      <InfoBox className={classes.container}>
        <div style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h6">Main Driver</Typography>
          {editable && <Typography variant="body2">*Required Information</Typography>}
        </div>
        <List>
          <OnlineCheckInDriverList
            driver={mainDriver}
            editable={editable}
            onEdited={handleDriverEdited(mainDriverIndex)}
            onUpdating={onUpdating}
            onRemoveExtraDriver={onRemoveExtraDriver}
          />
        </List>
        <Typography variant="h6">Additional Drivers</Typography>
        <List>
          {drivers.map((driver, index) =>
            index !== mainDriverIndex ? (
              <OnlineCheckInDriverList
                key={driver.name + driver.licenseNumber + index}
                driver={driver}
                onEdited={handleDriverEdited(index)}
                editable={editable}
                onUpdating={onUpdating}
                onRemoveExtraDriver={onRemoveExtraDriver}
              />
            ) : null
          )}
        </List>
      </InfoBox>
      {isFromCheckIn && (
        <div className={classes.addDriverContainer}>
          <Button
            onClick={isAddingExtras ? undefined : extraDriverHandler}
            className={isAddingExtras ? classes.disabled : classes.addDriver}
          >
            {isAddingExtras ? (
              <CircularProgress size={24} style={{ color: 'black' }} />
            ) : (
              <AddIcon style={{ fill: 'white', height: '2.5rem', width: '2.5rem' }} />
            )}
          </Button>
          <Typography style={{ fontWeight: 'bold', marginTop: 10 }} variant="body1">
            Add an extra driver for{' '}
            {formatPrice(
              Math.round(availableExtras !== undefined ? availableExtras.price : 0),
              currency
            )}
            {availableExtras !== undefined ? (availableExtras.pricePerDay ? 'per day' : '') : ''}
          </Typography>
        </div>
      )}
    </>
  );

  function handleDriverEdited(index: number) {
    return async (driver: IReservationDriver) => {
      return onEdit(driver);
    };
  }
};

export default OnlineCheckInDriverInfo;

import { IAuthState, IAppMetadata, IUserMetadata } from './types';

export const isLoggedIn = (state: IAuthState) => state.isLoggedIn;
export const isReady = (state: IAuthState) => state.isReady;
export const getAccessToken = (state: IAuthState) => state.accessToken;
export const getUser = (state: IAuthState) => state.user;
export const getTokenExpiryTimestamp = (state: IAuthState) => state.expiresAt;

export const selectAppMetadata = (state: IAuthState): Maybe<IAppMetadata> => {
  const user = getUser(state);

  return user ? user.appMetadata : null;
};

export const selectUserMetadata = (state: IAuthState): Maybe<IUserMetadata> => {
  const user = getUser(state);

  return user ? user.userMetadata : null;
};

export const selectEmailVerified = (state: IAuthState) => {
  const user = getUser(state);

  return user ? user.email_verified : false;
};

export const selectUserEmail = (state: IAuthState) => {
  const user = getUser(state);

  return user ? user.email : '';
};

import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import TripList from '../components/TripList';
import ReservationList from '../components/ReservationList';
import Layout from '../components/Layout';
import securePage from '../hocs/securePage';
import useTrips from '../hooks/useTrips';
import useCustomerTrips from '../hooks/useCustomerTrips';
import useReservations from '../hooks/useReservations';
import useAuthState from '../hooks/useAuthState';
import useTenant from '../hooks/useTenant';
import LoadingPage from '../components/LoadingPage';

const useStyles = makeStyles(theme => ({
  section: {
    marginBottom: theme.spacing(8),
  },
}));

const Dashboard: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const md = useMediaQuery(theme.breakpoints.down('md'));
  const { hasDriverGuide } = useTenant();
  const {
    reservations,
    isLoading: loadingReservations,
    error: reservationsError,
  } = useReservations(false);
  const { user, isLoggedIn } = useAuthState();

  const { trips, isLoading: loadingTrips, error: tripsError } = useTrips();
  const email = (user && user.email) || undefined;
  const tripsToDisplay = md ? 2 : 3;

  const {
    trips: customerTrips,
    isLoading: customerTripsLoading,
    error: customerTripsError,
    addToTrips,
    deleteCustomerTrip,
  } = useCustomerTrips();

  const upcoming = reservations.filter(res => {
    return res.status === 'Upcoming';
  });

  if ((customerTripsLoading || loadingTrips) && xs) {
    return renderMobileLoading();
  }

  return (
    <Layout useDefaultSpacing>
      <ReservationList
        title="Upcoming car hire"
        reservations={upcoming}
        loading={loadingReservations}
        error={reservationsError}
        className={classes.section}
      />
      {hasDriverGuide && (
        <React.Fragment>
          <TripList
            title="My Trips"
            // ! What trips should be here?
            trips={customerTrips.slice(0, tripsToDisplay)}
            loading={customerTripsLoading}
            error={customerTripsError}
            link="/trips"
            linkText="View all trips"
            emptyText="No trips added yet"
            className={classes.section}
            email={email}
            isLoggedIn={isLoggedIn}
            isCustomerTrips
            deleteCustomerTrip={deleteCustomerTrip}
          />
          <TripList
            title="Trips"
            // ! What trips should be here?
            trips={trips.slice(0, tripsToDisplay)}
            loading={loadingTrips}
            error={tripsError}
            link="/trips"
            linkText="View all trips"
            addToTrips={addToTrips}
            isCustomerTrips={false}
            email={email}
            isLoggedIn={isLoggedIn}
          />
        </React.Fragment>
      )}
    </Layout>
  );

  function renderMobileLoading() {
    if (window) {
      window.scrollTo({ top: 0 });
    }
    return <LoadingPage />;
  }
};

export default securePage(Dashboard);

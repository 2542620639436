import React from 'react';
import { useSelector } from 'react-redux';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import useTheme from '@material-ui/core/styles/useTheme';

import TripList from '../components/TripList';
import Layout from '../components/Layout';
import LoadingPage from '../components/LoadingPage';
import useTrips from '../hooks/useTrips';
import useCustomerTrips from '../hooks/useCustomerTrips';
import useAuthState from '../hooks/useAuthState';
import { selectEmailVerified } from '../store/selectors';

const Trips: React.FC = () => {
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const { user, isLoggedIn } = useAuthState();
  const emailVerified = useSelector(selectEmailVerified);
  const email = (user && user.email) || undefined;
  const { trips, isLoading, error } = useTrips();

  const {
    trips: customerTrips,
    error: customerTripsError,
    isLoading: customerTripsLoading,
    addToTrips,
    deleteCustomerTrip,
  } = useCustomerTrips();

  if ((isLoading || (isLoggedIn && customerTripsLoading)) && xs) {
    return renderMobileLoading();
  }

  const customerTripsList = (
    <TripList
      title="My trips"
      loading={customerTripsLoading}
      trips={customerTrips}
      error={customerTripsError}
      emptyText="No trips added yet"
      isCustomerTrips
      email={email}
      isLoggedIn={isLoggedIn}
      deleteCustomerTrip={deleteCustomerTrip}
    />
  );

  return (
    <Layout useDefaultSpacing>
      <div style={{ marginBottom: theme.spacing(8) }}>{emailVerified && customerTripsList}</div>
      <TripList
        title="All trips"
        loading={isLoading}
        trips={trips}
        error={error}
        addToTrips={addToTrips}
        isCustomerTrips={false}
        email={email}
        isLoggedIn={isLoggedIn}
        showFilters
      />
    </Layout>
  );

  function renderMobileLoading() {
    if (window) {
      window.scrollTo({ top: 0 });
    }
    return <LoadingPage />;
  }
};

export default Trips;

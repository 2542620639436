import { createAction } from 'typesafe-actions';
import { ITrip, IUpdateCustomerTrip, ITripRoute, ILocation } from '../../services/ApiService';

export const fetchCustomerTrip = createAction(
  'customerTrip/FETCH_TRIP_REQUEST',
  resolve => (id: string) => resolve(id)
);

export const fetchCustomerTripSuccess = createAction(
  'customerTrip/FETCH_TRIP_SUCCESS',
  resolve => (trip: ITrip) => resolve(trip)
);

export const fetchCustomerTripError = createAction(
  'customerTrip/FETCH_TRIP_ERROR',
  resolve => (error: Error) => resolve(error)
);

export const updateCustomerTrip = createAction(
  'customerTrip/UPDATE_TRIP_REQUEST',
  resolve => (id: string, trip: IUpdateCustomerTrip) => resolve({ id, trip })
);

export const updateCustomerTripSuccess = createAction(
  'customerTrip/UPDATE_TRIP_SUCCESS',
  resolve => (trip: ITrip) => resolve(trip)
);

export const updateCustomerTripError = createAction(
  'customerTrip/UPDATE_TRIP_ERROR',
  resolve => (error: Error) => resolve(error)
);

export const callUpdateCustomerTrip = createAction('customerTrip/CALL_UPDATE_TRIP');

export const calculateCustomerTripRoute = createAction(
  'customerTrip/CALCULATE_TRIP_ROUTE_REQUEST',
  resolve => (waypoints: ILocation[]) => resolve(waypoints)
);

export const calculateCustomerTripRouteSuccess = createAction(
  'customerTrip/CALCULATE_TRIP_ROUTE_SUCCESS',
  resolve => (route: ITripRoute) => resolve(route)
);

export const calculateCustomerTripRouteError = createAction(
  'customerTrip/CALCULATE_TRIP_ROUTE_ERROR',
  resolve => (error: Error) => resolve(error)
);

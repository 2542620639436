import { Auth0DecodedHash, Auth0Error } from 'auth0-js';
import { createAction, ActionType } from 'typesafe-actions';

export const RENEW_SESSION_ERROR = 'auth/RENEW_SESSION_ERROR';
export const RENEW_SESSION = 'auth/RENEW_SESSION';
export const LOGIN = 'auth/LOGIN';
export const LOGOUT = 'auth/LOGOUT';
export const LOGGED_IN = 'auth/LOGGED_IN';
export const LOGGED_OUT = 'auth/LOGGED_OUT';
export const SCHEDULE_RENEWAL = 'auth/SCHEDULE_RENEWAL';

export const loggedIn = createAction(LOGGED_IN, resolve => {
  return (authResult: Auth0DecodedHash) => resolve(authResult);
});
export type LoggedInAction = ActionType<typeof loggedIn>;

export const loggedOut = createAction(LOGGED_OUT, resolve => {
  return () => resolve();
});
export type LoggedOutAction = ActionType<typeof loggedOut>;

export const renewSession = () => ({
  type: RENEW_SESSION,
});

export const renewSessionError = createAction(RENEW_SESSION_ERROR, resolve => (error: Auth0Error) =>
  resolve({ error })
);

export const login = createAction(LOGIN, resolve => (showSignup: boolean = false) =>
  resolve({ showSignup })
);

export const logout = () => ({
  type: LOGOUT,
});

export const scheduleRenewal = () => ({
  type: SCHEDULE_RENEWAL,
});

import { DEFAULT_PALETTE_PRIMARY } from '../../constants';
import ITenantConfig from '../../interfaces/ITenantConfig';

const defaultTenantConfig: ITenantConfig = {
  tenantId: '',
  baseColor: DEFAULT_PALETTE_PRIMARY,
  hostname: 'default',
  logoUrl: '',
  auth0ClientId: '',
  auth0Domain: '',
  paymentOptionType: '',
  showWidget: false,
  hideLogin: false,
  canCancelReservation: false,
  canCancelUntil: 0,
  externalCancelLink: null,
  hasDriverGuide: false,
  footerLines: [],
  heroImage: '',
  headerLinks: [],
  footerLinks: [],
  googleTagmanager: null,
  styleSheet: null,
  favicon: {
    desktop: '',
  },
  info: {
    name: '',
    email: '',
    phone: '',
    address: '',
    city: '',
    country: '',
    hours: '',
  },
  extraPickupInfo: null,
  placeProducts: null,
  showDriversList: false,
  sitewideMessage: null,
  bokunChannelUUID: null,
  onlineCheckIn: null,
  gracePeriods: null,
};

export default defaultTenantConfig;

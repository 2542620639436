const isLocal = process.env.REACT_APP_ENV === 'local';
const isDevelopment = process.env.REACT_APP_ENV === 'development';
const isProduction = process.env.REACT_APP_ENV === 'production';
const isDevelopmentBuild = process.env.NODE_ENV === 'development';
const isProductionBuild = process.env.NODE_ENV === 'production';

const env = {
  nodeEnv: process.env.NODE_ENV,
  appEnv: process.env.REACT_APP_ENV || 'none',
  isLocal,
  isDevelopment,
  isProduction,
  isDevelopmentBuild,
  isProductionBuild,
  api: {
    baseUrl: process.env.REACT_APP_API_URL,
    audience: process.env.REACT_APP_API_AUDIENCE,
  },
  googleMaps: {
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  },
  sentry: {
    dsn: process.env.REACT_APP_SENTRY_DSN,
  },
};

export default env;

import React from 'react';
import { Moment } from 'moment';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import Skeleton from 'react-skeleton-loader';

import InfoBox from './InfoBox';
import { DATE_FORMAT, TIME_FORMAT } from '../constants';

export interface IProps {
  label: string | null;
  date: Moment | null;
  location: string | null;
  alignLabel?: 'center' | 'right' | 'left';
  hideLabel?: boolean;
  loading?: boolean;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '100%',
  },
  box: {
    flex: 1,
    width: '100%',
    boxSizing: 'border-box',
  },
  label: {
    width: '100%',
    textTransform: 'uppercase',
    padding: theme.spacing(1),
    [theme.breakpoints.down('xs')]: {
      padding: theme.spacing(0.5),
    },
  },
  date: {
    fontWeight: 'bold',
    fontSize: 22,
    lineHeight: 1.4,
    textAlign: 'center',
    wordBreak: 'break-word',
  },
  location: {
    marginTop: theme.spacing(1),
    textAlign: 'center',
    wordBreak: 'break-word',
    color: theme.palette.text.secondary,
  },
  skeleton: {
    height: 150,
    width: '100%',
  },
}));

const ReservationDateCard: React.FC<IProps> = ({
  label,
  date,
  location,
  alignLabel,
  loading,
  hideLabel,
}) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <div className={classes.container}>
      {label && (
        <Typography
          variant="body1"
          className={classes.label}
          style={{
            textAlign: alignLabel || 'center',
            visibility: hideLabel ? 'hidden' : 'visible',
          }}
          color="textSecondary"
        >
          {label}
        </Typography>
      )}
      {loading ? (
        <div className={classes.skeleton}>
          <Skeleton
            height="100%"
            width="100%"
            heightRandomness={0}
            widthRandomness={0}
            color={theme.customPalette.skeleton}
          />
        </div>
      ) : (
        <InfoBox className={classes.box}>
          <Grid container justify="center" direction="column">
            <Grid item>
              <Typography variant="body1" color="inherit" className={classes.date}>
                {date ? date.utc().format(DATE_FORMAT) : 'No Date'}
              </Typography>
              <Typography variant="body1" color="inherit" className={classes.date}>
                {date ? date.utc().format(TIME_FORMAT) : ''}
              </Typography>
            </Grid>
            <Grid item>
              <Typography variant="subtitle1" color="inherit" className={classes.location}>
                {location}
              </Typography>
            </Grid>
          </Grid>
        </InfoBox>
      )}
    </div>
  );
};

export default ReservationDateCard;

import { useReducer, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import useApi from './useApi';
import { ITripLite } from '../services/ApiService';
import useAuthState from './useAuthState';
import useTenant from './useTenant';
import { selectEmailVerified } from '../store/selectors';

interface IState {
  trips: ITripLite[];
  isLoading: boolean;
  error: string;
}

interface IInitAction {
  type: 'FETCH_INIT';
}

interface ISuccessAction {
  type: 'FETCH_SUCCESS';
  payload: ITripLite[];
}

interface IErrorAction {
  type: 'FETCH_ERROR';
  payload: Error;
}

interface ICreateTripAction {
  type: 'CREATING_FROM_TEMPLATE';
}

interface ICreateTripSuccessAction {
  type: 'CREATING_FROM_TEMPLATE_SUCCESS';
}

interface ICreateTripErrorAction {
  type: 'CREATING_FROM_TEMPLATE_ERROR';
  payload: Error;
}

interface IDeleteTripAction {
  type: 'DELETE_CUSTOMER_TRIP';
}

interface IDeleteTripSuccessAction {
  type: 'DELETE_CUSTOMER_TRIP_SUCCESS';
  payload: string;
}

interface IDeleteTripErrorAction {
  type: 'DELETE_CUSTOMER_TRIP_ERROR';
  payload: Error;
}

type Action =
  | IInitAction
  | ISuccessAction
  | IErrorAction
  | ICreateTripAction
  | ICreateTripSuccessAction
  | ICreateTripErrorAction
  | IDeleteTripAction
  | IDeleteTripSuccessAction
  | IDeleteTripErrorAction;

const initialState: IState = {
  trips: [],
  isLoading: true,
  error: '',
};

function reducer(state: IState, action: Action) {
  switch (action.type) {
    case 'FETCH_INIT':
      return {
        ...state,
        isLoading: true,
      };
    case 'FETCH_SUCCESS':
      return { ...state, trips: action.payload || [], isLoading: false, error: '' };
    case 'FETCH_ERROR':
      return {
        ...state,
        isLoading: false,
        error: action.payload.message || 'An error occured',
      };
    case 'CREATING_FROM_TEMPLATE':
      return { ...state, isLoading: true };
    case 'CREATING_FROM_TEMPLATE_SUCCESS':
      return {
        ...state,
        isLoading: false,
      };
    case 'CREATING_FROM_TEMPLATE_ERROR':
      return { ...state, isLoading: false };
    case 'DELETE_CUSTOMER_TRIP':
      return { ...state, isLoading: true };
    case 'DELETE_CUSTOMER_TRIP_SUCCESS':
      return {
        ...state,
        isLoading: false,
        trips: state.trips.filter(t => t.id !== action.payload),
      };
    case 'DELETE_CUSTOMER_TRIP_ERROR':
      return { ...state, isLoading: false };
    default:
      return state;
  }
}

export default function useCustomerTrips() {
  const api = useApi();
  const { hasDriverGuide } = useTenant();
  const { isLoggedIn } = useAuthState();
  const emailVerified = useSelector(selectEmailVerified);
  const [state, dispatch] = useReducer(reducer, initialState);

  const getCustomerTrips = useCallback(async () => {
    if (isLoggedIn) {
      dispatch({ type: 'FETCH_INIT' });
      try {
        const data = await api.getCustomerTrips();
        dispatch({ type: 'FETCH_SUCCESS', payload: data });
      } catch (error) {
        dispatch({ type: 'FETCH_ERROR', payload: error });
      }
    } else {
      dispatch({ type: 'FETCH_ERROR', payload: new Error('Please login to manage your trips!') });
    }
  }, [api, isLoggedIn]);

  useEffect(() => {
    if (hasDriverGuide && emailVerified) {
      getCustomerTrips();
    }
  }, [getCustomerTrips, hasDriverGuide, emailVerified]);

  async function addToTrips(templateId: string) {
    dispatch({ type: 'CREATING_FROM_TEMPLATE' });
    try {
      await api.createFromTripTemplate(templateId);
      dispatch({ type: 'CREATING_FROM_TEMPLATE_SUCCESS' });
      getCustomerTrips();
    } catch (err) {
      dispatch({ type: 'CREATING_FROM_TEMPLATE_ERROR', payload: err });
    }
  }

  async function deleteCustomerTrip(instanceId: string) {
    dispatch({ type: 'DELETE_CUSTOMER_TRIP' });
    try {
      await api.deleteCustomerTrip(instanceId);
      dispatch({ type: 'DELETE_CUSTOMER_TRIP_SUCCESS', payload: instanceId });
    } catch (err) {
      dispatch({ type: 'DELETE_CUSTOMER_TRIP_ERROR', payload: err });
    }
  }

  return {
    trips: state.trips,
    isLoading: state.isLoading,
    error: state.error,
    addToTrips,
    deleteCustomerTrip,
  };
}

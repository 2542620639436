import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import AddIcon from '@material-ui/icons/AddCircle';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { SortableElement } from 'react-sortable-hoc';

import { IDraggablePlace } from './TripDetails';
import TripDayPlace from './TripDayPlace';

interface IProps {
  places: IDraggablePlace[];
  canEditTrip: boolean;
  isDragging: boolean;
  setDeleteIndex: (index: number) => void;
  setAddDialogOpen: (open: boolean) => void;
  setDetailsDialogPlaceId: (placeId: string) => void;
}

const useStyles = makeStyles(theme => ({
  addPlace: {
    height: '100%',
    width: '100%',
    minHeight: 245,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    '&:hover': {
      opacity: 0.7,
    },
    '-webkit-user-select': 'none',
  },
  addIcon: {
    height: 45,
    width: 45,
    marginBottom: theme.spacing(1),
  },
  addText: {
    fontWeight: theme.typography.fontWeightLight,
  },
}));

const PlaceItem = SortableElement(TripDayPlace);

const TripDayPlaces: React.FC<IProps> = ({
  places,
  canEditTrip,
  isDragging,
  setDeleteIndex,
  setAddDialogOpen,
  setDetailsDialogPlaceId,
}) => {
  const classes = useStyles();
  const [hoveringDelete, setHoveringDelete] = useState(false);

  return (
    <Grid container spacing={4} style={{ overflow: 'auto' }}>
      {places.map((place, index) => (
        <PlaceItem
          key={place.instanceId}
          index={index}
          disabled={!canEditTrip || hoveringDelete}
          place={place}
          canEditTrip={canEditTrip}
          isDragging={isDragging}
          setHoveringDelete={setHoveringDelete}
          setDeleteIndex={() => setDeleteIndex(index)}
          setDetailsDialogPlaceId={setDetailsDialogPlaceId}
        />
      ))}
      {canEditTrip && (
        <Grid item xs={12} sm={6} md={4} lg={3} onClick={() => setAddDialogOpen(true)}>
          <Card className={classes.addPlace}>
            <AddIcon fontSize="large" color="secondary" className={classes.addIcon} />
            <Typography color="secondary" variant="h6" className={classes.addText}>
              Add destination
            </Typography>
          </Card>
        </Grid>
      )}
      {!canEditTrip && !places.length && (
        <Grid item>
          <Typography>No stops on this day.</Typography>{' '}
        </Grid>
      )}
    </Grid>
  );
};

export default TripDayPlaces;

import React, { useState } from 'react';
import moment from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TimeIcon from '@material-ui/icons/QueryBuilder';

import DatePicker from './DatePicker';
import Dropdown from './Dropdown';

import { DATE_AND_TIME_FORMAT } from '../constants';

interface IProps {
  label: string;
  value: moment.Moment;
  disabled: boolean;
  onChange: (value: moment.Moment) => void;
  minDate?: moment.Moment;
  maxDate?: moment.Moment;
  timeOptions: Array<{ label: string; value: string }>;
}

const useStyles = makeStyles(theme => ({
  label: {
    textAlign: 'center',
    textTransform: 'uppercase',
    marginBottom: theme.spacing(2),
  },
  dropDown: {
    width: '100%',
  },
}));

const DateTimePicker: React.FC<IProps> = ({
  label,
  disabled,
  value = moment(),
  minDate,
  maxDate,
  onChange,
  timeOptions,
}) => {
  const classes = useStyles();
  const [timeDropdownOpen, setTimeDropdownOpen] = useState(false);
  const [calendarOpen, setCalendarOpen] = useState(false);

  const datetimeString = value.format(DATE_AND_TIME_FORMAT);

  return (
    <Grid item xs={12} sm={6}>
      <Typography className={classes.label} color="textSecondary">
        {label}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <DatePicker
            label="Date"
            value={value}
            open={calendarOpen}
            onChange={handleChange}
            onOpen={() => setCalendarOpen(true)}
            onClose={() => setCalendarOpen(false)}
            minDate={minDate}
            maxDate={maxDate}
            dateFormat="MMM DD, YYYY"
            disabled={disabled}
            className={classes.dropDown}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Dropdown
            label="Time"
            value={datetimeString}
            open={timeDropdownOpen}
            onChange={newTime => handleChange(moment.utc(newTime, DATE_AND_TIME_FORMAT))}
            onOpen={() => setTimeDropdownOpen(true)}
            onClose={() => setTimeDropdownOpen(false)}
            Icon={TimeIcon}
            items={timeOptions}
            disabled={disabled}
            className={classes.dropDown}
          />
        </Grid>
      </Grid>
    </Grid>
  );

  function handleChange(newValue: moment.Moment | null) {
    if (newValue) {
      onChange(newValue);
      setTimeDropdownOpen(false);
      setCalendarOpen(false);
    }
  }
};

export default DateTimePicker;

import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ArrowIcon from '@material-ui/icons/ArrowForwardIos';
import CircularProgress from '@material-ui/core/CircularProgress';
import InputAdornment from '@material-ui/core/InputAdornment';
import SearchIcon from '@material-ui/icons/Search';
import Hidden from '@material-ui/core/Hidden';
import cx from 'classnames';
import debounce from 'lodash.debounce';

import { ISearchPlace } from '../services/ApiService';
import HTMLRichText from './HTMLRichText';
import Input from './Input';
import { selectPlacesState } from '../store/selectors';
import { searchPlaces, getMorePlaces } from '../store/places/actions';

interface IProps {
  open: boolean;
  onClose: () => void;
  addPlace: (place: ISearchPlace) => void;
}

const useStyles = makeStyles(theme => ({
  container: {
    padding: theme.spacing(0, 4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0, 3),
    },
  },
  input: {
    width: '100%',
    padding: theme.spacing(1, 2),
  },
  searchIcon: {
    color: theme.palette.grey[500],
  },
  footer: {
    margin: theme.spacing(0, 1),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(1),
    },
  },
  cancelButton: {
    marginRight: theme.spacing(1),
  },
  listContainer: {
    width: 400,
    height: 500,
    overflowY: 'scroll',
    padding: theme.spacing(3, 0),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
    [theme.breakpoints.down('xs')]: {
      height: '100%',
    },
  },
  infoText: {
    paddingLeft: theme.spacing(1),
    color: theme.palette.text.secondary,
  },
  loading: {
    height: '100%',
    width: '100%',
  },
  loadingMore: {
    height: 40,
    marginTop: theme.spacing(1),
  },
  details: {
    flex: 1,
    borderLeft: `1px solid ${theme.palette.grey[300]}`,
    paddingLeft: theme.spacing(4),
    padding: theme.spacing(3, 0),
    height: 500,
    overflowY: 'scroll',
  },
  listItem: {
    height: 80,
    padding: theme.spacing(1),
    paddingRight: theme.spacing(2),
    width: '100%',
    cursor: 'pointer',
  },
  backgroundImage: {
    backgroundColor: theme.palette.grey[300],
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    borderRadius: 4,
  },
  listImage: {
    height: '100%',
    width: 100,
  },
  listText: {
    flex: 1,
    marginLeft: theme.spacing(2),
    fontWeight: theme.typography.fontWeightMedium,
  },
  detailsImage: {
    height: 200,
    width: '100%',
  },
  detailsTitleContainer: {
    margin: theme.spacing(2, 0),
  },
  detailsTitle: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: 20,
  },
}));

const AddTripPlaceDialog: React.FC<IProps> = ({ open, onClose, addPlace }) => {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useDispatch();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const [searchText, setSearchText] = useState('');
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const { places, placesLoading, placesError, getMoreLoading, noMoreResults } = useSelector(
    selectPlacesState
  );

  const searchPlacesDebounced = useCallback(
    debounce((q: string) => dispatch(searchPlaces(q)), 300),
    []
  );

  return (
    <Dialog
      open={open}
      onClose={onCancel}
      maxWidth="md"
      fullWidth
      fullScreen={xs}
      onScroll={e => xs && handleScroll(e)}
    >
      <DialogTitle>
        <Input
          className={classes.input}
          disableUnderline
          value={searchText}
          onChange={updateSearch}
          autoFocus
          placeholder="Search for a place..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon className={classes.searchIcon} />
            </InputAdornment>
          }
        />
      </DialogTitle>
      <DialogContent className={classes.container} dividers>
        <Grid container direction="row">
          <div className={classes.listContainer} onScroll={handleScroll}>
            {renderPlaceList()}
          </div>
          <Hidden smDown>
            <div className={classes.details}>
              {selectedIndex !== null && renderPlaceDetails(places[selectedIndex])}
            </div>
          </Hidden>
        </Grid>
      </DialogContent>
      <DialogActions className={classes.footer}>
        <Button onClick={onCancel} className={classes.cancelButton}>
          Cancel
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={selectedIndex === null}
          onClick={onConfirm}
        >
          Add place
        </Button>
      </DialogActions>
    </Dialog>
  );

  function renderPlaceList() {
    if (placesLoading) {
      return (
        <Grid container justify="center" alignItems="center" className={classes.loading}>
          <CircularProgress size={40} />
        </Grid>
      );
    }

    if (placesError) {
      return <Typography className={classes.infoText}>{placesError}</Typography>;
    }

    if (!places.length) {
      return <Typography className={classes.infoText}>No places found</Typography>;
    }

    return (
      <React.Fragment>
        {places.map(renderPlaceItem)}
        {!searchText && !noMoreResults && (
          <Grid
            container
            justify="center"
            className={classes.loadingMore}
            style={{ visibility: getMoreLoading ? 'visible' : 'hidden' }}
          >
            <CircularProgress size={30} />
          </Grid>
        )}
      </React.Fragment>
    );
  }

  function renderPlaceItem(place: ISearchPlace, index: number) {
    const backgroundColor = selectedIndex === index ? theme.palette.grey[200] : 'white';

    return (
      <Grid
        container
        direction="row"
        key={place.id}
        onClick={() => setSelectedIndex(index)}
        className={classes.listItem}
        style={{ backgroundColor }}
      >
        <div
          style={{ backgroundImage: `url(${place.photo})` }}
          className={cx(classes.backgroundImage, classes.listImage)}
        />
        <Typography className={classes.listText}>{place.name}</Typography>
        <ArrowIcon color="secondary" fontSize="small" style={{ alignSelf: 'center' }} />
      </Grid>
    );
  }

  function renderPlaceDetails(place: ISearchPlace) {
    return (
      <React.Fragment>
        <div
          style={{ backgroundImage: `url(${place.photo})` }}
          className={cx(classes.backgroundImage, classes.detailsImage)}
        />
        <div className={classes.detailsTitleContainer}>
          <Typography className={classes.detailsTitle}>{place.name}</Typography>
          <Typography variant="body2" color="textSecondary">
            {place.categories.join(', ')}
          </Typography>
        </div>
        <HTMLRichText text={place.description} type="body2" />
      </React.Fragment>
    );
  }

  function updateSearch(event: React.ChangeEvent<HTMLInputElement>) {
    setSearchText(event.target.value);
    setSelectedIndex(null);
    searchPlacesDebounced(event.target.value);
  }

  function handleScroll(e: any) {
    const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (bottom && !searchText && !noMoreResults && !placesLoading) {
      dispatch(getMorePlaces('', places.length));
    }
  }

  function onConfirm() {
    if (selectedIndex !== null && places[selectedIndex]) {
      addPlace(places[selectedIndex]);
    }
    onCancel();
  }

  function onCancel() {
    onClose();
    setSelectedIndex(null);
    setSearchText('');
  }
};

export default AddTripPlaceDialog;

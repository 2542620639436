import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useState, useLayoutEffect } from 'react';

interface IProps {
  updateError: (unitId: number | null, damage: any) => void;
  unitId: number | null;
  damageErrors: any[];
  addDamage: (e: any, partOfCar: string) => void;
  setIsOpen: (open: boolean) => void;
  setPartOfCar: (partOfCar: any) => void;
  blueprint: string;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    maxWidth: '100%',
    width: '100%',
  },
  mobileImage: {
    width: '390px',
    height: '207px',
  },
  webImage: {
    width: '779px',
    height: '413px',
  },
  circle: {
    opacity: 1,
    pointerEvents: 'none',
    position: 'absolute',
    width: '1000px',
    height: '1000px',
    '&:hover': {
      pointerEvents: 'click',
    },
  },
  circleChild: {
    pointerEvents: 'auto',
    '&:hover': {
      cursor: 'pointer',
      fill: 'green !important',
      [theme.breakpoints.down('xs')]: {
        r: '12',
      },
      r: '16',
    },
  },
  test22: {
    '& area': {
      cursor: 'pointer',
      '&:hover + $svg': {
        opacity: 1,
      },
    },
  },
  test: {
    opacity: 0,
    pointerEvents: 'none',
    position: 'absolute',
    width: '1000px',
    height: '1000px',
  },
  colorClass: {
    fill: theme.palette.primary.main + '80',
    stroke: theme.palette.primary.main,
    strokeWidth: 2,
    strokeDasharray: 7,
  },
}));

const BluePrintKangoo = ({
  unitId,
  damageErrors,
  updateError,
  addDamage,
  setIsOpen,
  setPartOfCar,
  blueprint,
}: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const responsiveAreas: any[] = [];
  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobileScreen = width <= 768;

  const originalCoordinates = () => {
    const areas: any = document.getElementsByTagName('area');

    for (const area of areas) {
      responsiveAreas.push({ element: area, originalCords: area.coords.split(',') });
    }
  };

  const isMobile = () => {
    setWidth(window.innerWidth);

    if (isMobileScreen && responsiveAreas.length === 0) {
      originalCoordinates();
      resize();
    }
  };

  const resize = () => {
    const ratio = 0.5;
    for (const area of responsiveAreas) {
      const newCoords = [];
      for (const originalCoord of area.originalCords) {
        newCoords.push(Math.round(originalCoord * ratio));
      }
      area.element.coords = newCoords.join(',');
    }

    return true;
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', isMobile);
    window.addEventListener('load', isMobile);
    isMobile();

    return () => {
      window.removeEventListener('resize', isMobile);
      window.removeEventListener('load', isMobile);
    };
  }, []);

  return (
    <div className={classes.container}>
      <map id="map" className={classes.test22} name="image-map">
        {damageErrors.map((damage: any) => (
          <svg key={damage.id} id="circle" className={classes.circle}>
            <g>
              <circle
                id={`circle${damage.customId}`}
                className={classes.circleChild}
                onClick={() => updateError(unitId, damage)}
                cx={damage.xCoordinate}
                cy={damage.yCoordinate}
                r={xs === true ? 10 : 12}
                fill="#D2042D"
              />
              <text
                style={xs ? { fontSize: '11px' } : undefined}
                x={damage.xCoordinate}
                y={damage.yCoordinate}
                stroke="white"
                strokeWidth="1"
                textAnchor="middle"
                alignmentBaseline="middle"
              >
                {damage.customId}
              </text>
              <title>{damage.description}</title>
            </g>
          </svg>
        ))}
        <area
          alt="area"
          title="Front part"
          onClick={e => {
            addDamage(e, 'Front Part');
          }}
          onTouchStart={e => {
            addDamage(e, 'Front Part');
          }}
          coords="34,119,27,133,24,167,66,175,83,186,106,183,118,172,127,177,124,93,78,108"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 34 119 L 27 133 L 24 167 L 66 175 L 83 186 L 106 183 L 118 172 L 127 177 L 124 93 L 78 108Z"
          />
        </svg>

        <area
          alt="area"
          title="Front door"
          onClick={e => {
            addDamage(e, 'Front Door');
          }}
          onTouchStart={e => {
            addDamage(e, 'Front Door');
          }}
          coords="124,94,129,174,210,177,214,65,169,68"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 124 94 L 129 174 L 210 177 L 214 65 L 169 68Z"
          />
        </svg>

        <area
          alt="area"
          title="Back door"
          onClick={e => {
            addDamage(e, 'Back Door');
          }}
          coords="214,66,281,67,280,145,263,179,210,175"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 214 66 L 281 67 L 280 145 L 263 179 L 210 175Z"
          />
        </svg>

        <area
          alt="area"
          title="Back Part"
          onClick={e => {
            addDamage(e, 'Back Part');
          }}
          coords="285,66,347,65,356,142,361,158,360,171,326,175,319,167,310,186,286,186,273,171,281,141,284,104"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 285 66 L 347 65 L 356 142 L 361 158 L 360 171 L 326 175 L 319 167 L 310 186 L 286 186 L 273 171 L 281 141 L 284 104Z"
          />
        </svg>

        <area
          alt="area"
          title="Front view"
          onClick={e => {
            addDamage(e, 'Front view');
          }}
          coords="412,57,398,113,397,186,412,186,414,172,518,176,519,189,534,189,536,142,529,103,517,56,468,53"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 412 57 398 113 397 186 412 186 414 172 518 176 519 189 534 189 536 142 529 103 517 56 468 53Z"
          />
        </svg>

        <area
          alt="area"
          title="Top view"
          onClick={e => {
            addDamage(e, 'Top View');
          }}
          coords="625,41,602,71,598,117,598,153,598,339,610,371,672,378,725,370,737,341,735,154,740,116,733,68,710,39"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 625 41 602 71 598 117 598 153 598 339 610 371 672 378 725 370 737 341 735 154 740 116 733 68 710 39Z"
          />
        </svg>

        <area
          alt="area"
          title="Back view"
          onClick={e => {
            addDamage(e, 'Back View');
          }}
          coords="415,250,396,322,398,383,412,382,415,368,516,368,519,382,533,382,538,322,518,251,465,248"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 415 250 396 322 398 383 412 382 415 368 516 368 519 382 533 382 538 322 518 251 465 248Z"
          />
        </svg>

        <area
          alt="area"
          title="Front part"
          coords="264,283,261,366,276,369,295,381,315,375,330,367,366,360,359,321,339,306,290,297"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Front Part');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 264 283 261 366 276 369 295 381 315 375 330 367 366 360 359 321 339 306 290 297Z"
          />
        </svg>
        <area
          alt=""
          title="Front door"
          coords="177,257,181,370,263,368,264,286,221,263,193,258"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Front Door');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 177 257 181 370 263 368 264 286 221 263 193 258Z"
          />
        </svg>
        <area
          alt=""
          title="Back door"
          coords="107,259,107,337,118,364,130,368,180,367,176,300,177,260"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Back Door');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 107 259 107 337 118 364 130 368 180 367 176 300 177 260Z"
          />
        </svg>
        <area
          alt=""
          title="Back Part"
          coords="42,258,36,300,29,351,30,364,63,368,70,358,80,380,106,382,118,360,107,334,103,259"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Back Part');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 42 258 36 300 29 351 30 364 63 368 70 358 80 380 106 382 118 360 107 334 103 259Z"
          />
        </svg>
      </map>

      <img
        id="img"
        className={xs ? classes.mobileImage : classes.webImage}
        src={blueprint}
        alt="mising"
        useMap="#image-map"
      />
    </div>
  );
};

export default BluePrintKangoo;

import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import DeleteIcon from '@material-ui/icons/Close';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

import BookActivityButton from './BookActivityButton/BookActivityButton';
import { IDraggablePlace } from './TripDetails';
import { placeIsStop } from '../utils/trips';
import WaypointImg from '../assets/icons/waypoint.png';

interface IProps {
  place: IDraggablePlace;
  canEditTrip: boolean;
  isDragging: boolean;
  setHoveringDelete: (hovering: boolean) => void;
  setDeleteIndex: () => void;
  setDetailsDialogPlaceId: (placeId: string) => void;
}

const useStyles = makeStyles(theme => ({
  title: {
    textTransform: 'uppercase',
    fontSize: '1rem',
  },
  image: {
    height: 180,
    backgroundColor: theme.palette.grey[300],
  },
  card: {
    position: 'relative',
    padding: theme.spacing(2),
  },
  delete: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    height: 28,
    width: 28,
    backgroundColor: 'white',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    boxShadow: theme.shadows[2],
    '&:hover': {
      backgroundColor: theme.palette.grey[100],
    },
  },
  disableSelect: {
    userSelect: 'none',
    '-webkit-user-select': 'none',
  },
  bookActivityButton: {
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(2),
  },
}));

const TripDayPlace: React.FC<IProps> = ({
  place,
  canEditTrip,
  isDragging,
  setHoveringDelete,
  setDeleteIndex,
  setDetailsDialogPlaceId,
}) => {
  const classes = useStyles();
  // To prevent click event at end of drag in Firefox
  const [clickStarted, setClickStarted] = useState(0);
  const isStop = placeIsStop(place);
  const image = isStop ? place.photo : WaypointImg;

  return (
    <Grid item xs={12} sm={6} md={4} lg={3} className={classes.card}>
      <Card
        className={cx({ [classes.disableSelect]: isDragging })}
        style={{ cursor: getCursor() }}
        onMouseDown={e => setClickStarted(e.timeStamp)}
        onClick={e => {
          const timeSinceMouseDown = e.timeStamp - clickStarted;
          if (isStop && (timeSinceMouseDown < 100 || !canEditTrip)) {
            setDetailsDialogPlaceId(place.id);
          }
        }}
      >
        <CardMedia image={image || ''} title={place.name} className={classes.image} />
        <CardContent>
          <Typography variant="h6" className={classes.title}>
            {place.name}
          </Typography>
          <Typography variant="body2" color="textSecondary">
            {place.categories.join(', ')}
          </Typography>
        </CardContent>
      </Card>
      {canEditTrip && (
        <div
          className={classes.delete}
          onClick={setDeleteIndex}
          onMouseEnter={() => setHoveringDelete(true)}
          onMouseLeave={() => setHoveringDelete(false)}
        >
          <DeleteIcon fontSize="small" style={{ pointerEvents: 'none' }} />
        </div>
      )}
      {place.externalProduct && (
        <BookActivityButton
          className={classes.bookActivityButton}
          productId={place.externalProduct.productId}
          dialogTitle={`Book Activity for ${place.name}`}
        />
      )}
    </Grid>
  );

  function getCursor() {
    if (isDragging) {
      return 'grabbing';
    }
    if (isStop) {
      return 'pointer';
    }
    return canEditTrip ? 'grab' : undefined;
  }
};

export default TripDayPlace;

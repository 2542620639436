import { Auth0DecodedHash } from 'auth0-js';
import { all, call, put, select } from 'redux-saga/effects';
import * as tenantActions from './tenant/actions';
import * as authActions from './auth/actions';

import ApiService from '../services/ApiService';
import { getTenantConfig, selectCurrentPathname } from './selectors';
import { CALLBACK_ROUTE } from './../constants';
import { parseHash, isLoggedIn } from '../utils/auth';
import { recordInfo } from '../sentry';
import authSagas from './auth/sagas';
import completeProfileSagas from './completeProfile/sagas';
import placesSagas from './places/sagas';
import customerTripSagas from './customerTrip/sagas';
import reservationsSagas from './reservations/sagas';
import navigationSaga from './navigationSaga';
import ITenantConfig from '../interfaces/ITenantConfig';

// Initializes the app by fetching the tenant configuration,
// and renewing the active user session if available.
function* init() {
  try {
    const api = new ApiService();
    const config: ITenantConfig = yield call([api, api.fetchTenantConfig]);
    if (config.bokunChannelUUID) {
      injectBokunScript(config.bokunChannelUUID);
    }
    yield put(tenantActions.resolveTenant(config));
  } catch (err) {
    yield put(tenantActions.rejectTenant(err));
    return;
  }

  // The app loading into the callback route needs to be handled separately
  // because the auth information comes from the URL hash
  const pathname: string = yield select(selectCurrentPathname);
  if (pathname === CALLBACK_ROUTE) {
    const tenantConfig: ITenantConfig = yield select(getTenantConfig);
    try {
      recordInfo('auth/rootSaga', 'rootSaga calls parseHash');
      const result: Auth0DecodedHash = yield call(parseHash, tenantConfig);
      yield put(authActions.loggedIn(result));
    } catch (err) {
      // TODO: Report error somewhere
      yield put(authActions.loggedOut());
    }
    return;
  }

  // If there is a logged in user from a previous session, we need to renew
  // the session. Otherwise, the user is not logged in.
  if (isLoggedIn()) {
    yield put(authActions.renewSession());
  } else {
    yield put(authActions.loggedOut());
  }
}

function* rootSaga() {
  yield all([
    init(),
    navigationSaga(),
    ...authSagas,
    ...completeProfileSagas,
    ...placesSagas,
    ...customerTripSagas,
    ...reservationsSagas,
  ]);
}

function injectBokunScript(channelUUID: string): void {
  const script = document.createElement('script');

  script.type = 'text/javascript';
  script.src = `https://widgets.bokun.io/assets/javascripts/apps/build/BokunWidgetsLoader.js?bookingChannelUUID=${channelUUID}`;
  script.async = true;

  document.body.appendChild(script);
}

export default rootSaga;

import React, { useRef, useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { MuiThemeProvider } from '@material-ui/core/styles';
import createMuiTheme from '@material-ui/core/styles/createMuiTheme';
import InputAdornment from '@material-ui/core/InputAdornment';
import { SvgIconProps } from '@material-ui/core/SvgIcon';

import useTheme from '../hooks/useTheme';

interface IProps {
  value?: string;
  label: string;
  items: Array<{ value: string; label: string }>;
  open: boolean;
  onChange: (value: string) => void;
  onOpen: () => void;
  onClose: () => void;
  Icon?: React.ComponentType<SvgIconProps>;
  disabled?: boolean;
  className?: string;
}

const useStyles = makeStyles(theme => ({
  icon: {
    color: theme.palette.grey[700],
  },
}));

const Dropdown: React.FC<IProps> = ({
  value,
  label,
  items,
  open,
  onChange,
  onOpen,
  onClose,
  Icon,
  disabled,
  className,
}) => {
  const classes = useStyles();
  const inputLabel = useRef<HTMLLabelElement>(null);
  const selectInput = useRef<HTMLInputElement>(null);
  const [labelWidth, setLabelWidth] = useState(0);

  useEffect(() => {
    if (inputLabel.current) {
      setLabelWidth(inputLabel.current.offsetWidth);
    }
  }, []);

  // Hack to change colors
  const { palette } = useTheme();
  const theme = createMuiTheme({
    palette: {
      primary: { main: palette.secondary.main },
    },
  });

  return (
    <MuiThemeProvider theme={theme}>
      <FormControl className={className} variant="outlined">
        {label && <InputLabel ref={inputLabel}>{label}</InputLabel>}
        <Select
          value={value}
          open={open}
          onChange={handleChange}
          onOpen={onOpen}
          onClose={closeDropdown}
          disabled={disabled}
          inputRef={selectInput}
          input={
            <OutlinedInput
              labelWidth={labelWidth}
              style={{ cursor: 'pointer' }}
              startAdornment={
                Icon && (
                  <InputAdornment position="start" onClick={onOpen}>
                    <Icon className={classes.icon} />
                  </InputAdornment>
                )
              }
            />
          }
          MenuProps={{
            style: { maxHeight: 400 },
            getContentAnchorEl: null,
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'left',
            },
          }}
        >
          {items.map(item => (
            <MenuItem key={item.value} value={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </MuiThemeProvider>
  );

  function handleChange(event: React.ChangeEvent<{ value: unknown }>) {
    onChange(event.target.value as string);
    closeDropdown();
  }

  // To fix input focus
  function closeDropdown() {
    onClose();
    if (selectInput.current) {
      selectInput.current.focus();
    }
  }
};

export default Dropdown;

import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAvailableExtras,
  getAvailableInsurances,
  getExtrasState,
  getInsurancesState,
  getLoadedReservation,
  getLoadingStates,
  getReservationErrors,
  getReservationLoading,
  getReservationsTermsAndConditionsStatus,
  selectOnlineCheckIn,
} from '../store/selectors';
import OnlinePrecheckIn from './OnlinePrecheckIn';
import OnlineCheckInDriverInfo from './OnlineCheckInDriverInfo';
import OnlineCheckInExtendReservation from './OnlineCheckInExtendReservation';
import moment from 'moment';
import ReservationCarInfo from './ReservationCarInfo';
import OnlineCheckInInsurances from './OnlineCheckInInsurances';
import InformationItems from './InformationItems';
import { IAddExtra, IReservationCustomer } from '../interfaces/IReservation';
import { performUpdateCustomer } from '../utils/reservation';
import {
  acceptRentalAgreement,
  acceptTermsAndConditions,
  addExtras,
  addInsurances,
  fetchAvailableExtras,
  fetchAvailableInsurances,
  removeDriver,
  setReservationCheckIn,
  updateDriver,
  uploadLicencePhotos,
} from '../store/reservations/actions';
import ReservationPriceSummary from './ReservationPriceSummary';
import ReservationPayments from './ReservationPayments';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { StepLabel } from '@material-ui/core';
import OnlineCheckInApproveRentalTerms from './OnlineCheckInApproveRentalTerms';
import OnlineCheckInApproveRentalAgreement from './OnlineCheckInApproveRentalAgreement';
import useApi from '../hooks/useApi';
import useNotifier from '../hooks/useNotifier';
import useMount from '../hooks/useMount';
import useTenant from '../hooks/useTenant';

const getSteps = () => {
  return [
    'Start Check-In',
    'Pick-Up Drop-Off date',
    'Vehicle Preview',
    'Insurances Selection',
    'Extras Selection',
    'Customer Information',
    'Driver Information',
    'Approve Rental terms',
    'Approve Rental agreement',
    'Payment',
    'Complete',
  ];
};

const useStyles = makeStyles(theme => ({
  content: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonContainer: {
    marginTop: 100,
    display: 'flex',
    justifyContent: 'center',
  },
  text: {
    fill: 'white',
  },
  customStepperLabel: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '0.7rem',
    },
    [theme.breakpoints.up(1820)]: {
      fontSize: '0.9rem',
    },
  },
  active: {
    fill: theme.palette.primary.main,
  },
}));

const createPersonalInfoFields = (flightNumber: string, customer?: IReservationCustomer) => {
  if (!customer) {
    return null;
  }
  return [
    {
      label: 'First name',
      value: customer.firstName,
      required: true,
      name: 'firstName',
    },
    {
      label: 'Last name',
      value: customer.lastName,
      required: true,
      name: 'lastName',
    },
    {
      label: 'Email',
      value: customer.email,
      disableEdit: true,
    },
    {
      label: 'Phone',
      value: customer.phone,
      name: 'phone',
    },
    {
      label: 'Mobile',
      value: customer.mobile,
      name: 'mobile',
      required: true,
    },
    {
      label: 'Address',
      value: customer.address,
      name: 'address',
    },
    {
      label: 'City',
      value: customer.city,
      name: 'city',
      required: true,
    },
    {
      label: 'Zip code',
      value: customer.zipCode,
      name: 'zipCode',
      required: true,
    },
    {
      label: 'Flight number',
      value: flightNumber,
      name: 'flightNumber',
    },
    {
      label: 'Country',
      value: customer.countryId || '0',
      name: 'countryId',
      required: true,
    },
  ];
};

interface IProps {
  guid: string | undefined;
  success: string | undefined;
}

const OnlineCheckInStepper = ({ guid, success }: IProps) => {
  const steps = getSteps();
  const classes = useStyles();
  const history = useHistory();
  const { checkListInformation, completedMessage, customImage } = useSelector(
    selectOnlineCheckIn
  ) || {
    checkListInformation: '',
    completedMessage: '',
    customImage: '',
  };
  const dispatch = useDispatch();
  const { paymentOptionType } = useTenant();
  const reservation = useSelector(getLoadedReservation);
  const isLoadingReservation = useSelector(getReservationLoading);
  const availableInsurances = useSelector(getAvailableInsurances);
  const insurancesLoading = useSelector(getLoadingStates).isFetchingAvailableInsurances;
  const insurancesGuid = useSelector(getInsurancesState).guid;
  const insurancesAdding = useSelector(getLoadingStates).isAddingInsurances;
  const extrasGuid = useSelector(getExtrasState).guid;
  const extrasLoading = useSelector(getLoadingStates).isFetchingAvailableExtras;
  const errors = useSelector(getReservationErrors);
  const error = errors.loadedReservation;
  const loading = isLoadingReservation || !!error;
  const personalInfo = createPersonalInfoFields(reservation.flightNumber, reservation.customer);
  const [countries, setCountries] = useState<any>([]);
  const availableExtras = useSelector(getAvailableExtras);
  const extrasAdding = useSelector(getLoadingStates).isAddingExtras;
  const notifierError = useNotifier({ dismissable: true }, { variant: 'error' });
  const notifierInfo = useNotifier({ dismissable: true }, { variant: 'info' });
  const termsAndConditions = useSelector(getReservationsTermsAndConditionsStatus);
  const [termsAndConditionsData, setTermsAndConditionsData] = useState('');
  const [termsRentalAgreementsData, setTermsRentalAgreementData] = useState('');
  const api = useApi();
  const [dontShowContinue, setDontShowContinue] = useState<number[]>([]);

  function mapInsuranceArray(insuranceData: any[]) {
    return insuranceData.map(i => ({ ...i, quantity: 1, pricePerDay: null }));
  }

  function mapAvailableInsurancesArray(availableInsurancesData: any[]) {
    return availableInsurancesData.map(i => ({ ...i, maximumQuantity: 1 }));
  }

  // In your component:
  const mappedExtras = useMemo(() => mapInsuranceArray(reservation.insurance), [
    reservation.insurance,
  ]);
  const mappedAvailable = useMemo(() => mapAvailableInsurancesArray(availableInsurances), [
    availableInsurances,
  ]);

  const fetchInsurancesAndExtras = useCallback(() => {
    dispatch(fetchAvailableInsurances(reservation.guid));
    dispatch(fetchAvailableExtras(reservation.guid));
  }, [dispatch, reservation.guid]);

  const fetchTermsAndConditions = useCallback(async () => {
    const data = await api.getTerms();
    setTermsAndConditionsData(data.text);
  }, [api]);

  const fetchRentalAgreements = useCallback(
    async (reservationGuid: string) => {
      const data = await api.getRentalAgreement(reservationGuid);
      setTermsRentalAgreementData(data.text);
    },
    [api]
  );

  const [activeStep, setActiveStep] = useState(0);
  const [showContinue, setShowContinue] = useState(true);

  useMount(() => {
    if (success !== undefined) {
      if (success.includes('false')) {
        notifierError.notify('There was an error with payment');
        setActiveStep(9);
      } else {
        setActiveStep(10);
      }
    }
    if (success === undefined && termsAndConditions) {
      setActiveStep(9);
    }
  });

  useEffect(() => {
    if (reservation.guid) {
      fetchInsurancesAndExtras();
      fetchTermsAndConditions();
      fetchRentalAgreements(reservation.guid);
    }
  }, [fetchInsurancesAndExtras, fetchTermsAndConditions, fetchRentalAgreements, reservation.guid]);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const result = await api.getCountries();
        result.unshift({ label: 'Select a country', value: 0 });
        setCountries(result);
      } catch (error) {
        setCountries([]);
        console.error(error);
      }
    };

    getCountries();
  }, [api]);

  useEffect(() => {
    if (reservation.prices.balance > 0) {
      setDontShowContinue([10, 7]);
    } else {
      setDontShowContinue([10, 7]);
    }
  }, [reservation.prices.balance]);

  const handleNext = () => {
    if (activeStep === 0) {
      notifierInfo.notify(
        'Please make sure you double check the data on every step before you continue. Once you continue you cannot go back.'
      );
    }
    setActiveStep(prev => prev + 1);
  };

  const reservationDates = {
    from: {
      label: 'Pickup',
      date: reservation.dateFrom ? moment(reservation.dateFrom) : null,
      location: reservation.location.pickupName,
    },
    to: {
      label: 'Dropoff',
      date: reservation.dateTo ? moment(reservation.dateTo) : null,
      location: reservation.location.dropoffName,
    },
  };

  const onAddExtrasInsurances = (addedInsurances: IAddExtra[]) => {
    if (reservation && !isCancelled()) {
      dispatch(addInsurances(reservation.guid, addedInsurances));
    }
  };

  const onAddExtras = (addedExtras: IAddExtra[]) => {
    if (reservation && !isCancelled()) {
      dispatch(addExtras(reservation.guid, addedExtras));
    }
  };

  const isCancelled = () => {
    return Boolean(reservation && reservation.status === 'Cancelled');
  };

  const isReservationUpcoming = () => {
    return Boolean(reservation && reservation.status === 'Upcoming');
  };

  const canEditCustomerInfo = () => {
    return !isCancelled() && isReservationUpcoming() && reservation.gracePeriods.editable;
  };

  const onCustomerUpdate = async (values: { [name: string]: string }) => {
    const updateValues = { ...values };
    updateValues.countryId = values.countryId.toString();

    await performUpdateCustomer(reservation, dispatch, updateValues);
  };

  const navigateToReservation = () => {
    const navigateRoute = localStorage.getItem('reservationGuid');
    dispatch(setReservationCheckIn(navigateRoute!));
    history.replace(`/bookings/view?guid=${navigateRoute}`);
  };

  const onDriverEdit = async (driver: any) => {
    if (reservation) {
      dispatch(updateDriver(reservation.guid, driver));

      if (driver.images.length > 0) {
        dispatch(uploadLicencePhotos(reservation.guid, driver.id, driver.images));
      }
    }
  };

  const acceptTerms = (image: string, isChecked: boolean) => {
    dispatch(acceptTermsAndConditions(reservation.guid, image));

    handleNext();
  };

  const acceptRental = (image: string, isChecked: boolean) => {
    dispatch(acceptRentalAgreement(reservation.guid, image));

    handleNext();
  };

  const showHideContinueHandler = (change: boolean) => {
    setShowContinue(change);
  };

  const onRemoveExtraDriver = (id: number) => {
    dispatch(removeDriver(reservation.guid, id));
  };

  const getStepContent = (step: number) => {
    switch (step) {
      case 0:
        return (
          <OnlinePrecheckIn
            checkListInformation={checkListInformation}
            displayCompleteBtn={false}
            bookingCode={reservation.bookingCode}
            vehicleName={reservation.vehicle.class}
            showImages={[true, false, true]}
            customImage={''}
          />
        );
      case 1:
        return (
          <div style={{ width: '50%' }}>
            <OnlineCheckInExtendReservation
              initialTo={reservationDates.to}
              initialPickup={reservation.selfService.selfServicePickup}
              initialDropOff={reservation.selfService.selfServiceDropOff}
              initialFrom={reservationDates.from}
              onUpdating={showHideContinueHandler}
            />
          </div>
        );
      case 2:
        return <ReservationCarInfo vehicle={reservation.vehicle} loading={loading} />;
      case 3:
        return (
          <OnlineCheckInInsurances
            label="Insurances"
            extras={mappedExtras}
            available={mappedAvailable}
            loading={insurancesLoading && insurancesGuid !== reservation.guid}
            error={errors.insurances}
            busy={insurancesAdding}
            currency={reservation.prices.currency}
            days={reservation.days}
            addExtras={onAddExtrasInsurances}
            onUpdating={showHideContinueHandler}
          />
        );
      case 4:
        return (
          <OnlineCheckInInsurances
            label="Extras"
            extras={reservation.extras}
            available={availableExtras}
            loading={extrasLoading && extrasGuid !== reservation.guid}
            error={errors.extras}
            busy={extrasAdding}
            currency={reservation.prices.currency}
            days={reservation.days}
            addExtras={onAddExtras}
            onUpdating={showHideContinueHandler}
          />
        );
      case 5:
        return (
          <>
            {personalInfo && (
              <InformationItems
                title="Customer information"
                items={personalInfo}
                countriesItems={countries}
                isFromCheckIn
                editable={canEditCustomerInfo()}
                onUpdate={onCustomerUpdate}
                onUpdatingCustomerInfo={showHideContinueHandler}
              />
            )}
          </>
        );
      case 6:
        return (
          <div style={{ width: '60%' }}>
            <OnlineCheckInDriverInfo
              drivers={reservation.drivers}
              days={reservation.days}
              currency={reservation.prices.currency}
              extras={reservation.extras}
              availableExtras={availableExtras.find(extra => extra.isExtraDriverExtra === true)}
              onEdit={onDriverEdit}
              addExtras={onAddExtras}
              onRemoveExtraDriver={onRemoveExtraDriver}
              editable
              onUpdating={showHideContinueHandler}
            />
          </div>
        );
      case 7:
        return (
          <OnlineCheckInApproveRentalTerms
            acceptTerms={acceptTerms}
            data={termsAndConditionsData}
            label="terms and conditions"
          />
        );
      case 8:
        return (
          <OnlineCheckInApproveRentalAgreement
            acceptTerms={acceptRental}
            data={termsRentalAgreementsData}
            selfService={reservation.selfService}
            onUpdating={showHideContinueHandler}
            label="agreement"
          />
        );
      case 9:
        return (
          <div style={{ width: '60%' }}>
            <ReservationPriceSummary
              priceInfo={reservation.prices}
              days={reservation.days}
              canPayBalance={reservation.prices.balance > 0}
              loading={loading}
              shouldBeValitor={paymentOptionType === 'valitor'}
              isFromOnlineCheckIn
              shouldContinue={showHideContinueHandler}
            />
            {reservation.payments.length > 0 && (
              <ReservationPayments payments={reservation.payments} loading={loading} />
            )}
          </div>
        );
      case 10:
        return (
          <OnlinePrecheckIn
            checkListInformation={completedMessage}
            vehicleName={reservation.vehicle.class}
            bookingCode={reservation.bookingCode}
            displayCompleteBtn
            navigate={navigateToReservation}
            showImages={[false, false, false]}
            customImage={customImage}
          />
        );
      default:
        return <h2>Something happend please refresh browser</h2>;
    }
  };

  return (
    <div>
      {activeStep !== steps.length - 1 && (
        <Stepper style={{ marginBottom: '50px' }} activeStep={activeStep}>
          {steps.map(step => (
            <Step key={step}>
              <StepLabel
                classes={{ label: classes.customStepperLabel }}
                StepIconProps={{
                  classes: {
                    active: classes.active,
                    text: classes.text,
                  },
                }}
              >
                {step}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      <div className={classes.content}>{getStepContent(activeStep)}</div>
      <div className={classes.buttonContainer}>
        {showContinue &&
          !extrasAdding &&
          !insurancesAdding &&
          !dontShowContinue.includes(activeStep) && (
            <Button
              color="primary"
              style={{ color: 'white' }}
              variant="contained"
              onClick={handleNext}
            >
              {activeStep === 0 ? 'Start Check-In' : 'Continue'}
            </Button>
          )}
      </div>
    </div>
  );
};

export default OnlineCheckInStepper;

import { getType, ActionType } from 'typesafe-actions';
import { combineReducers, ActionCreator } from 'redux';
import createReducer from '../createReducer';
import * as A from './actions';
import { IReservationsState } from './types';

const initialState: IReservationsState = {
  loadedReservation: {
    id: 0,
    guid: '',
    status: '',
    bookingCode: '',
    checkedIn: false,
    flightNumber: '',
    termsAndConditions: false,
    rentalAgreementSigned: false,
    rentalAgreementSignedUrl: '',
    days: 0,
    dateFrom: new Date(),
    dateTo: new Date(),
    prices: {
      price: 0,
      totalPrice: 0,
      discountPrice: 0,
      confirmationPrice: 0,
      paymentsAmount: 0,
      extrasTotalPrice: 0,
      extrasDiscountPrice: 0,
      insurancesTotalPrice: 0,
      insurancesDiscountPrice: 0,
      vehicleDiscountPrice: 0,
      balance: 0,
      currency: '',
    },
    currency: 'ISK',
    vehicle: {
      class: '',
      summary: null,
      doors: 0,
      seats: 0,
      beds: 0,
      image: null,
      transmission: null,
      fuel: null,
      drive: null,
      smallBags: 0,
      largeBags: 0,
    },
    customer: {
      firstName: '',
      lastName: '',
      email: '',
      phone: null,
      mobile: null,
      passport: null,
      address: null,
      city: null,
      zipCode: null,
      countryName: null,
      countryId: null,
    },
    rental: {
      name: '',
      contactName: null,
      email: null,
      phone: null,
      address: null,
      logo: null,
    },
    location: {
      pickupName: '',
      dropoffName: '',
      pickupExtra: null,
      dropoffExtra: null,
    },
    selfService: {
      selfServicePickup: false,
      selfServiceDropOff: false,
    },
    extras: [],
    insurance: [],
    payments: [],
    drivers: [],
    gracePeriods: {
      cancelable: false,
      editable: false,
      editableUntil: '',
      cancelableUntil: '',
    },
  },
  list: {},
  payment: {
    guid: null,
    isPerformingPayment: false,
    wasSuccessful: false,
    error: null,
    _3dsForm: null,
    securePaymentPending: false,
  },
  extension: {
    guid: null,
    isLoading: false,
    isExtending: false,
    isExtendedSuccess: false,
    isDialogOpen: false,
    fromDate: null,
    toDate: null,
    currency: 'ISK',
    totalExtensionPrice: 0,
    errorMessage: null,
  },
  errors: {
    extras: null,
    insurances: null,
    loadedReservation: null,
  },
  extras: {
    guid: null,
    available: [],
    isFetching: false,
    isAdding: false,
    isDialogOpen: false,
  },
  insurances: {
    guid: null,
    available: [],
    isFetching: false,
    isAdding: false,
    isDialogOpen: false,
  },
  unit: {
    unitId: null,
    isUnitReady: false,
    selfServiceStatusId: null,
    selfServiceStatus: null,
  },
  isFetching: false,
  isFetchingList: false,
  isUpdatingCustomer: false,
  isCancelling: false,
  isUpdatingDrivers: false,
};

const createLoadingReducer = (
  requestAction: ActionCreator<any>,
  successAction: ActionCreator<any>,
  errorAction: ActionCreator<any>
) =>
  createReducer(false, {
    [getType(requestAction)]: () => true,
    [getType(successAction)]: () => false,
    [getType(errorAction)]: () => false,
  });

const loadedReservation = createReducer(initialState.loadedReservation, {
  [getType(A.fetchSuccess)]: (state, { payload }: ActionType<typeof A.fetchSuccess>) =>
    payload.reservation,
  [getType(A.fetchAvailableExtrasSuccess)]: (
    state,
    { payload }: ActionType<typeof A.fetchAvailableExtrasSuccess>
  ) => {
    return state !== null
      ? {
          ...state,
          availableExtras: payload.extras,
        }
      : state;
  },
  [getType(A.patchDriver)]: (state, { payload }: ActionType<typeof A.patchDriver>) => {
    return state !== null
      ? {
          ...state,
          drivers: state.drivers.map(driver => {
            if (driver.id === payload.driver.id) {
              return payload.driver;
            }
            return driver;
          }),
        }
      : state;
  },
  [getType(A.fetchAvailableInsurancesSuccess)]: (
    state,
    { payload }: ActionType<typeof A.fetchAvailableInsurancesSuccess>
  ) => {
    return state !== null
      ? {
          ...state,
          availableInsurances: payload.insurances,
        }
      : state;
  },
  [getType(A.patchLocalReservation)]: (
    state,
    { payload }: ActionType<typeof A.patchLocalReservation>
  ) => {
    return state !== null
      ? {
          ...state,
          ...payload.reservation,
        }
      : state;
  },
});

const list = createReducer(initialState.list, {
  [getType(A.fetchListSuccess)]: (state, { payload }: ActionType<typeof A.fetchListSuccess>) => {
    const reservations: typeof initialState.list = {};

    for (const reservation of payload.reservations) {
      reservations[reservation.guid] = reservation;
    }

    return Object.assign({}, state, reservations);
  },
});

const payment = createReducer(initialState.payment, {
  [getType(A.payBalance)]: (state, { payload }: ActionType<typeof A.payBalance>) => ({
    guid: payload.guid,
    isPerformingPayment: true,
    wasSuccessful: false,
    error: null,
    securePaymentPending: false,
    _3dsForm: null,
  }),
  [getType(A.receiveSecurePaymentForm)]: (
    state,
    { payload }: ActionType<typeof A.receiveSecurePaymentForm>
  ) => ({
    guid: payload.guid,
    isPerformingPayment: true,
    wasSuccessful: false,
    error: null,
    _3dsForm: payload.form,
    securePaymentPending: true,
  }),
  [getType(A.payBalanceSuccess)]: state => ({
    ...state,
    isPerformingPayment: false,
    wasSuccessful: true,
    error: null,
  }),
  [getType(A.payBalanceError)]: (state, { payload }: ActionType<typeof A.payBalanceError>) => ({
    ...state,
    isPerformingPayment: false,
    wasSuccessful: false,
    error: payload.reason,
  }),
  [getType(A.resetPaymentState)]: () => ({ ...initialState.payment }),
});

const extras = createReducer(initialState.extras, {
  [getType(A.fetchAvailableExtras)]: state => ({ ...state, isFetching: true }),
  [getType(A.fetchAvailableExtrasSuccess)]: (
    state,
    { payload }: ActionType<typeof A.fetchAvailableExtrasSuccess>
  ) => ({
    ...state,
    isFetching: false,
    guid: payload.guid,
    available: payload.extras,
  }),
  [getType(A.fetchAvailableExtrasError)]: state => ({ ...state, isFetching: false }),
  [getType(A.addExtras)]: state => ({ ...state, isAdding: true }),
  [getType(A.addExtrasSuccess)]: state => ({ ...state, isAdding: false }),
  [getType(A.addExtrasError)]: state => ({ ...state, isAdding: false }),
  [getType(A.openExtrasDialog)]: state => ({ ...state, isDialogOpen: true }),
  [getType(A.closeExtrasDialog)]: state => ({ ...state, isDialogOpen: false }),
});

const insurances = createReducer(initialState.insurances, {
  [getType(A.fetchAvailableInsurances)]: state => ({ ...state, isFetching: true }),
  [getType(A.fetchAvailableInsurancesSuccess)]: (
    state,
    { payload }: ActionType<typeof A.fetchAvailableInsurancesSuccess>
  ) => ({
    ...state,
    isFetching: false,
    guid: payload.guid,
    available: payload.insurances,
  }),
  [getType(A.fetchAvailableInsurancesError)]: state => ({ ...state, isFetching: false }),
  [getType(A.addInsurances)]: state => ({ ...state, isAdding: true }),
  [getType(A.addInsurancesSuccess)]: state => ({ ...state, isAdding: false }),
  [getType(A.addInsurancesError)]: state => ({ ...state, isAdding: false }),
  [getType(A.openInsurancesDialog)]: state => ({ ...state, isDialogOpen: true }),
  [getType(A.closeInsurancesDialog)]: state => ({ ...state, isDialogOpen: false }),
});

// TODO: FIX THIS TO ADD ON SUCCESS UNIT PROPERTY IN REDUX
const unit = createReducer(initialState.unit, {
  [getType(A.getUnitIdByGuid)]: (state, { payload }: ActionType<typeof A.getUnitIdByGuid>) => ({
    ...state,
  }),
  [getType(A.getUnitIdByGuidSuccess)]: (
    state,
    { payload }: ActionType<typeof A.getUnitIdByGuidSuccess>
  ) => ({
    ...state,
    unitId: payload.unit.unitId,
    isUnitReady: payload.unit.isUnitIsReady,
    selfServiceStatus: payload.unit.selfServiceStatus,
    selfServiceStatusId: payload.unit.selfServiceStatusId,
  }),
});

const isFetching = createReducer(initialState.isFetching, {
  [getType(A.fetchReservationByEmail)]: () => true,
  [getType(A.fetchReservationByGuid)]: () => true,
  [getType(A.fetchReservationError)]: () => false,
  [getType(A.fetchSuccess)]: () => false,
});

const isFetchingList = createLoadingReducer(
  A.fetchReservationsList,
  A.fetchListSuccess,
  A.fetchListError
);

const extension = createReducer(initialState.extension, {
  [getType(A.openExtendReservationDialog)]: state => ({ ...state, isDialogOpen: true }),
  [getType(A.closeExtendReservationDialog)]: state => ({ ...state, isDialogOpen: false }),
  [getType(A.fetchExtensionPrice)]: (
    state,
    { payload }: ActionType<typeof A.fetchExtensionPrice>
  ) => ({
    ...state,
    guid: payload.guid,
    fromDate: payload.from,
    toDate: payload.to,
    isLoading: true,
  }),
  [getType(A.fetchExtensionPriceSuccess)]: (
    state,
    { payload }: ActionType<typeof A.fetchExtensionPriceSuccess>
  ) => ({
    ...state,
    ...payload,
    isLoading: false,
    errorMessage: null,
  }),
  [getType(A.fetchExtensionPriceError)]: (
    state,
    { payload }: ActionType<typeof A.fetchExtensionPriceError>
  ) => ({ ...state, isLoading: false, errorMessage: payload.reason }),
  [getType(A.extendReservation)]: state => ({
    ...state,
    isExtending: true,
  }),
  [getType(A.updateSelfService)]: state => ({ ...state, isExtending: true }),
  [getType(A.extendReservationSuccess)]: state => ({
    ...state,
    isExtending: false,
    errorMessage: null,
    isExtendedSuccess: true,
  }),
  [getType(A.extendReservationRemoveSuccess)]: state => ({ ...state, isExtendedSuccess: false }),
  [getType(A.extendReservationError)]: (
    state,
    { payload }: ActionType<typeof A.extendReservationError>
  ) => ({
    ...state,
    isExtending: false,
    errorMessage: payload.reason,
    isExtendedSuccess: false,
  }),
  [getType(A.clearExtensionPrice)]: state => ({
    ...initialState.extension,
    isDialogOpen: state.isDialogOpen,
  }),
});

const errors = createReducer(initialState.errors, {
  [getType(A.fetchAvailableExtrasSuccess)]: state => ({ ...state, extras: null }),
  [getType(A.fetchAvailableExtrasError)]: (
    state,
    { payload }: ActionType<typeof A.fetchAvailableExtrasError>
  ) => ({ ...state, extras: payload.reason }),
  [getType(A.fetchAvailableInsurancesSuccess)]: state => ({ ...state, insurances: null }),
  [getType(A.fetchAvailableInsurancesError)]: (
    state,
    { payload }: ActionType<typeof A.fetchAvailableInsurancesError>
  ) => ({ ...state, insurances: payload.reason }),
  [getType(A.fetchReservationError)]: (
    state,
    { payload }: ActionType<typeof A.fetchReservationError>
  ) => ({
    ...state,
    loadedReservation: payload.message,
  }),
  [getType(A.fetchSuccess)]: state => ({ ...state, loadedReservation: null }),
});

const isUpdatingCustomer = createLoadingReducer(
  A.updateCustomer,
  A.updateCustomerSuccess,
  A.updateCustomerError
);

const isCancelling = createLoadingReducer(
  A.cancelReservation,
  A.cancelReservationSuccess,
  A.cancelReservationError
);

const isUpdatingDrivers = createLoadingReducer(
  A.updateDriver,
  A.updateDriversSuccess,
  A.updateDriversError
);

const isRemovingDriver = createLoadingReducer(
  A.removeDriver,
  A.removeDriverSuccess,
  A.removeDriverError
);

export default combineReducers({
  loadedReservation,
  list,
  extension,
  payment,
  extras,
  insurances,
  unit,
  errors,
  isFetching,
  isFetchingList,
  isUpdatingCustomer,
  isCancelling,
  isUpdatingDrivers,
});

import React from 'react';
interface IProps {
  lat: number;
  lng: number;
}

const Anchor: React.FC<IProps> = ({ children }) => {
  return <div>{children}</div>;
};

export default Anchor;

import React from 'react';
import cx from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 3,
    borderRadius: '50%',
    border: 'none',
    outline: 'inherit',
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.5,
    },
  },
  disabled: {
    backgroundColor: theme.palette.grey[400],
    cursor: 'default',
    '&:hover': {
      opacity: 1,
    },
  },
}));

interface IProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
  className?: string;
}

const CircularIconButton: React.FC<IProps> = ({ onClick, children, disabled, className }) => {
  const classes = useStyles();

  return (
    <button
      onClick={!disabled ? onClick : undefined}
      className={cx(classes.container, { [classes.disabled]: disabled }, className)}
    >
      {children}
    </button>
  );
};

export default CircularIconButton;

export function convertToLatLngShape(shape: string[]) {
  const latLngShape = shape.map(shapeStr => {
    const latLngArr = shapeStr.split(';');
    return {
      lat: parseFloat(latLngArr[0]),
      lng: parseFloat(latLngArr[1]),
    };
  });
  return latLngShape;
}

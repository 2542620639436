// Given an array and a list of indices, split the array at the indices
export function chunkByIndices<T>(arr: T[], indices: number[]) {
  let sliceFromIndex = 0;
  const result = [];
  for (const index of indices) {
    if (index >= arr.length) {
      break;
    }
    const sliceToIndex = index;
    const slice = arr.slice(sliceFromIndex, sliceToIndex);
    result.push(slice);
    sliceFromIndex = sliceToIndex;
  }
  result.push(arr.slice(sliceFromIndex));
  return result;
}

// Given an array of numbers, return the sum of those numbers
export function sum(arr: number[]) {
  return arr.reduce((acc, val) => acc + val, 0);
}

// Returns a new array with the item at the specified index removed
export function removeAtIndex<T>(arr: T[], index: number): T[] {
  return index < 0 ? arr.slice() : [...arr.slice(0, index), ...arr.slice(index + 1)];
}

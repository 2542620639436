import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';

interface IProps {
  alt: string;
}
const useStyles = makeStyles(theme => ({
  primary: {
    fill: theme.palette.primary.main,
  },
  background: {
    fill: theme.palette.background.default,
  },
}));

const Exclamation: React.FC<IProps> = ({ alt }) => {
  const classes = useStyles();
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="priority_high-24px"
      width="81"
      height="81"
      viewBox="0 0 81 81"
    >
      <title>{alt}</title>
      <path id="Path_72" d="M0 0h81v81H0z" className={classes.background} data-name="Path 72" />
      <circle
        id="Ellipse_55"
        cx="6"
        cy="6"
        r="6"
        className={classes.primary}
        data-name="Ellipse 55"
        transform="translate(35 59)"
      />
      <path
        id="Path_71"
        d="M10 3h13.5v40.5H10z"
        className={classes.primary}
        data-name="Path 71"
        transform="translate(23.75 7.125)"
      />
    </svg>
  );
};

export default Exclamation;

import React from 'react';
import moment from 'moment';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Hidden from '@material-ui/core/Hidden';
import Grid from '@material-ui/core/Grid';
import cx from 'classnames';

import { IReservationPayments } from '../interfaces/IReservation';
import formatPrice from '../utils/priceFormatter';

interface IProps {
  payments: IReservationPayments[];
  loading: boolean;
}

const useStyles = makeStyles(TableCelleme => ({
  tableHead: {
    padding: TableCelleme.spacing(1),
    backgroundColor: TableCelleme.palette.grey[200],
  },
  tableHeadLeft: {
    borderTopLeftRadius: 8,
    borderBottomLeftRadius: 8,
  },
  tableHeadRight: {
    borderTopRightRadius: 8,
    borderBottomRightRadius: 8,
  },
  background: {
    padding: TableCelleme.spacing(1),
    backgroundColor: TableCelleme.palette.grey[200],
  },
  removeBorder: {
    borderBottom: 0,
  },
  bolder: {
    fontWeight: 'bold',
  },
  noBorderBottom: {
    borderBottom: 0,
  },
  headerText: {
    marginBottom: 16,
  },
  cardMargin: {
    margin: '10px 5px',
  },
}));

const ReservationPayments: React.FC<IProps> = props => {
  const { payments, loading } = props;
  const classes = useStyles();

  // TODO: Maybe show "No Payments Made" if there are no payments, instead of nothing at all?
  if (!payments || !payments.length || loading) {
    return null;
  }

  return (
    <React.Fragment>
      <Typography variant="h5" className={classes.headerText}>
        Payments
      </Typography>
      <Hidden xsDown>
        <Table>
          <TableHead className={classes.noBorderBottom}>
            <TableRow>
              <TableCell className={classes.removeBorder} padding="none">
                <div className={cx(classes.tableHead, classes.tableHeadLeft)}>
                  <Typography className={classes.bolder} variant="body1">
                    Date
                  </Typography>
                </div>
              </TableCell>
              <TableCell className={classes.removeBorder} padding="none">
                <div className={classes.background}>
                  <Typography className={classes.bolder} variant="body1">
                    Card
                  </Typography>
                </div>
              </TableCell>
              <TableCell className={classes.removeBorder} padding="none">
                <div className={classes.background}>
                  <Typography className={classes.bolder} variant="body1">
                    Reference
                  </Typography>
                </div>
              </TableCell>
              <TableCell className={classes.removeBorder} padding="none">
                <div className={classes.background}>
                  <Typography className={classes.bolder} variant="body1">
                    Total paid
                  </Typography>
                </div>
              </TableCell>
              <TableCell className={classes.removeBorder} padding="none">
                <div className={cx(classes.tableHead, classes.tableHeadRight)}>
                  <Typography className={classes.bolder} variant="body1">
                    Type
                  </Typography>
                </div>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.map((payment, i) => (
              <TableRow key={i + moment(payment.transactionDate).valueOf()}>
                <TableCell>{moment(payment.transactionDate).format('DD/MM/YY HH:mm')}</TableCell>
                <TableCell>
                  {payment.cardNumber ? `**** **** **** ${payment.cardNumber}` : ''}
                </TableCell>
                <TableCell>{payment.referenceNumber ? payment.referenceNumber : ''}</TableCell>
                <TableCell>{`${formatPrice(payment.amount, payment.currency)}`}</TableCell>
                <TableCell>{payment.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Hidden>
      <Hidden smUp>
        {payments.map(payment => (
          <Card
            key={payment.amount + payment.transactionDate.toString()}
            className={classes.cardMargin}
          >
            <CardContent>
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <Typography variant="h6" className={classes.bolder}>
                    {moment(payment.transactionDate).format('DD/MM/YY')}
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="h6" className={classes.bolder}>
                    {moment(payment.transactionDate).format('HH:mm')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <Typography variant="body1" className={classes.bolder}>
                    Paid
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body2" className={classes.bolder}>{`${formatPrice(
                    payment.amount,
                    payment.currency
                  )}`}</Typography>
                </Grid>
              </Grid>
            </CardContent>
            <CardContent>
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <Typography variant="body1">Card</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">{`**** **** **** ${payment.cardNumber}`}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <Typography variant="body1">Ref</Typography>
                </Grid>
                <Grid item>
                  <Typography variant="body1">{payment.referenceNumber}</Typography>
                </Grid>
              </Grid>
              <Grid container direction="row" justify="space-between">
                <Grid item>
                  <Typography variant="body1">Type</Typography>
                </Grid>

                <Grid item>
                  <Typography variant="body1">{payment.type}</Typography>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        ))}
      </Hidden>
    </React.Fragment>
  );
};

export default ReservationPayments;

import React, { useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { Button } from '@material-ui/core';

interface IProps {
  url: string;
  title: string;
  closeHandler: () => void;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.common.white,
    width: 'calc(100% - 64px)',
    maxWidth: 1200,
    height: 'calc(100% - 64px)',
    position: 'fixed',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    overflowY: 'hidden',
    zIndex: 2001,
  },
  iframeContainer: {
    flex: 1,
    height: 600,
    overflowY: 'auto',
  },
  btnContainer: {
    display: 'flex',
    flexDirection: 'column',
    borderTop: `1px solid ${theme.palette.grey[300]}`,
    height: 50,
    alignItems: 'flex-end',
    justifyContent: 'center',

    '& button': {
      alignSelf: 'flex-end',
      color: theme.palette.secondary.main,

      '&:hover': {
        backgroundColor: '#0067c50a',
      },
    },
  },
  disabledBackground: {
    position: 'fixed',
    top: 0,
    left: 0,
    minHeight: '100vh',
    width: '100%',
    overflow: 'hidden',
    zIndex: 2000,
    backgroundColor: '#000000',
    opacity: 0.5,
  },
  bodyWithoutScroll: {
    overflow: 'hidden',
  },
  header: {
    '& h3': {
      margin: 0,
    },

    borderBottom: `1px solid ${theme.palette.grey[300]}`,
    padding: '16px 24px',
  },
}));

const BokunWidgetDialog = ({ url, closeHandler, title }: IProps) => {
  const theme = useStyles();

  useEffect(() => {
    document.body.classList.add(theme.bodyWithoutScroll);

    return () => document.body.classList.remove(theme.bodyWithoutScroll);
  }, [theme.bodyWithoutScroll]);

  return (
    <>
      <div className={theme.disabledBackground} />
      <div className={theme.container}>
        <div className={theme.header}>
          <h3>{title}</h3>
        </div>
        <div className={theme.iframeContainer}>
          <div className="bokunWidget" data-src={url} />
        </div>
        <div className={theme.btnContainer}>
          <Button onClick={closeHandler}>Close</Button>
        </div>
      </div>
    </>
  );
};

export default BokunWidgetDialog;

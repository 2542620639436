import React, { useState } from 'react';
import cx from 'classnames';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DragIcon from '@material-ui/icons/MoreVert';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Close';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';
import { v4 as uuidv4 } from 'uuid';

import { IDraggableDay } from './TripDetails';
import { metersToKm } from '../utils/distance';
import CircularIconButton from './CircularIconButton';
import ConfirmDialog from './ConfirmDialog';
import Input from './Input';
import { placeIsStop } from '../utils/trips';

interface IProps {
  days: IDraggableDay[];
  distances: number[];
  canEditTrip: boolean;
  updateDays: (days: IDraggableDay[]) => void;
  setDayIndex: (index: number) => void;
}

const useStyles = makeStyles(theme => ({
  days: {
    borderTop: `2px dotted ${theme.palette.grey[400]}`,
  },
  dayContainer: {
    backgroundColor: 'white',
    borderBottom: `2px dotted ${theme.palette.grey[400]}`,
    padding: theme.spacing(2, 0),
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  dayNoEdit: {
    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2, 1),
    },
  },
  dayTitle: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: '1.5rem',
  },
  mediumText: {
    fontWeight: theme.typography.fontWeightMedium,
  },
  dragIcon: {
    transform: 'translateX(-10px) scale(1.2)',
    color: theme.palette.grey[300],
  },
  info: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(3),
    },
  },
  infoText: {
    whiteSpace: 'nowrap',
    fontWeight: theme.typography.fontWeightMedium,
  },
  input: {
    marginBottom: theme.spacing(0.5),
  },
  deleteButton: {
    backgroundColor: theme.palette.grey[300],
    marginLeft: theme.spacing(5),
    [theme.breakpoints.down('xs')]: {
      marginLeft: theme.spacing(3),
    },
  },
  deleteIcon: {
    color: theme.palette.grey[700],
  },
  addButton: {
    fontWeight: theme.typography.fontWeightMedium,
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(-2),
  },
  addIcon: {
    marginLeft: theme.spacing(1),
    marginBottom: 2,
  },
}));

const TripItinerary: React.FC<IProps> = ({
  days,
  distances,
  canEditTrip,
  updateDays,
  setDayIndex,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editText, setEditText] = useState('');
  const [deleteIndex, setDeleteIndex] = useState<number | null>(null);
  const sm = useMediaQuery(theme.breakpoints.down('sm'));
  const xs = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div>
      {renderContent()}
      {canEditTrip && (
        <Grid justify="center" container>
          <Button color="secondary" size="large" className={classes.addButton} onClick={addDay}>
            Add a day
            <AddIcon color="secondary" className={classes.addIcon} />
          </Button>
        </Grid>
      )}
      {deleteIndex !== null && (
        <ConfirmDialog
          open={deleteIndex !== null}
          title={`Delete ${getDayName(deleteIndex)}`}
          text="Are you sure you want to permanently delete this day?"
          onConfirm={() => deleteDay(deleteIndex)}
          onCancel={() => setDeleteIndex(null)}
        />
      )}
    </div>
  );

  function renderContent() {
    if (!days.length) {
      return <Typography>Nothing on the itinerary.</Typography>;
    }

    return (
      <Grid container direction="column" className={classes.days}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="days">
            {provided => (
              <Grid {...provided.droppableProps} innerRef={provided.innerRef}>
                {days.map(renderDay)}
                {provided.placeholder}
              </Grid>
            )}
          </Droppable>
        </DragDropContext>
      </Grid>
    );
  }

  function renderDay(day: IDraggableDay, index: number) {
    const stops = day.places.filter(placeIsStop);
    const stopsList = sm && stops.length > 3 ? stops.slice(0, 3) : stops;
    const stopsStr = stopsList.length
      ? `${stopsList.map(s => s.name).join(' - ')} ${stops > stopsList ? '...' : ''}`
      : 'No stops';

    return (
      <React.Fragment key={day.id}>
        <Draggable draggableId={day.id} index={index} isDragDisabled={!canEditTrip}>
          {provided => (
            <Grid
              item
              className={cx(classes.dayContainer, { [classes.dayNoEdit]: !canEditTrip })}
              {...provided.draggableProps}
              innerRef={provided.innerRef}
            >
              {canEditTrip && (
                <div {...provided.dragHandleProps}>
                  <DragIcon fontSize="large" className={classes.dragIcon} />
                </div>
              )}
              <Grid
                container
                direction="column"
                alignItems="flex-start"
                style={{ flex: 1, cursor: 'pointer' }}
                onClick={() => setDayIndex(index)}
              >
                {editIndex === index && canEditTrip ? (
                  <Input
                    className={classes.input}
                    disableUnderline
                    value={editText}
                    onChange={e => setEditText(e.target.value)}
                    onBlur={() => endEdit(index)}
                    onKeyDown={e => e.key === 'Enter' && endEdit(index)}
                    autoFocus
                  />
                ) : (
                  <Typography
                    variant="h6"
                    className={classes.dayTitle}
                    onClick={e => onEditClick(e, index)}
                    style={{ cursor: canEditTrip ? 'text' : 'pointer' }}
                  >
                    {getDayName(index)}
                  </Typography>
                )}
                {!xs && <Typography className={classes.mediumText}>{stopsStr}</Typography>}
              </Grid>
              {!xs && (
                <div style={{ display: 'flex' }}>
                  <div className={classes.info}>
                    <Typography>Distance</Typography>
                    <Typography className={classes.infoText}>
                      {metersToKm(distances[index] || day.distance)} km
                    </Typography>
                  </div>
                  <div className={classes.info}>
                    <Typography>Attractions</Typography>
                    <Typography className={classes.infoText}>{stops.length} stops</Typography>
                  </div>
                </div>
              )}
              {canEditTrip && (
                <CircularIconButton
                  className={classes.deleteButton}
                  onClick={() => setDeleteIndex(index)}
                >
                  <DeleteIcon fontSize="small" className={classes.deleteIcon} />
                </CircularIconButton>
              )}
            </Grid>
          )}
        </Draggable>
      </React.Fragment>
    );
  }

  function getDayName(index: number) {
    return days[index].name || `Day ${index + 1}`;
  }

  function onEditClick(event: React.MouseEvent<HTMLElement, MouseEvent>, index: number) {
    if (canEditTrip) {
      event.stopPropagation();
      startEdit(index);
    }
  }

  function startEdit(index: number) {
    setEditIndex(index);
    setEditText(days[index].name || '');
  }

  function endEdit(index: number) {
    const updatedDays: IDraggableDay[] = days.slice();
    updatedDays[index].name = editText;

    updateDays(updatedDays);
    setEditIndex(null);
    setEditText('');
  }

  function onDragEnd(result: DropResult) {
    const { source, destination } = result;

    if (!destination || source.index === destination.index) {
      return;
    }

    const updatedDays: IDraggableDay[] = days.slice();
    const draggedDay = days[source.index];
    updatedDays.splice(source.index, 1);
    updatedDays.splice(destination.index, 0, draggedDay);

    updateDays(updatedDays);
  }

  function addDay() {
    const updatedDays: IDraggableDay[] = days.slice();
    updatedDays.push({
      id: uuidv4(),
      name: '',
      description: '',
      distance: 0,
      photo: '',
      places: [],
    });
    updateDays(updatedDays);
  }

  function deleteDay(index: number) {
    const updatedDays: IDraggableDay[] = days.slice();
    updatedDays.splice(index, 1);
    updateDays(updatedDays);
    setDeleteIndex(null);
  }
};

export default TripItinerary;

import React from 'react';
import { ConnectedRouter } from 'connected-react-router';
import { Route } from 'react-router-dom';
import { QueryParamProvider } from 'use-query-params';
import Helmet from 'react-helmet';
import Favicon from 'react-favicon';
import { MuiThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { SnackbarProvider } from 'notistack';

import { history } from './store/configureStore';
import useTheme from './hooks/useTheme';
import useTenant from './hooks/useTenant';
import Routes from './Routes';
import Notifier from './components/Notifier';
import ScrollToTop from './components/ScrollToTop';
import DetectLogout from './components/DetectLogout';
import Gtag from './components/Gtag';
import SitewideBanner from './components/SitewideBanner';
import ErrorBoundary from './components/ErrorBoundary';

const App: React.FC = () => {
  const tenant = useTenant();
  const title = (tenant.info && tenant.info.name) || '';
  const gtmId = tenant.googleTagmanager;
  const favicon = (tenant.favicon && tenant.favicon.desktop) || 'favicon.ico';

  return (
    <MuiThemeProvider theme={useTheme()}>
      <CssBaseline />
      <SnackbarProvider maxSnack={1} anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Helmet defaultTitle={title} titleTemplate={`%s - ${title}`}>
          {tenant.styleSheet && <link rel="stylesheet" href={tenant.styleSheet} />}
        </Helmet>
        <Favicon url={favicon} />
        <Notifier />
        <DetectLogout />
        <ConnectedRouter history={history}>
          <ErrorBoundary history={history}>
            <ScrollToTop />
            <QueryParamProvider ReactRouterRoute={Route}>
              <Gtag gtmId={gtmId} />
              <SitewideBanner />
              <Routes />
            </QueryParamProvider>
          </ErrorBoundary>
        </ConnectedRouter>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LinearProgress from '@material-ui/core/LinearProgress';

const useStyles = makeStyles(theme => ({
  root: { flexGrow: 1 },
}));

const FullPageLoader: React.FC = () => {
  const classes = useStyles();
  const [shouldDisplayLoader, setDisplayLoader] = useState(false);

  useEffect(() => {
    const t = setTimeout(() => {
      setDisplayLoader(true);
    }, 1000);

    return () => clearTimeout(t);
  }, []);

  return <div className={classes.root}>{shouldDisplayLoader && <LinearProgress />}</div>;
};

export default FullPageLoader;

import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';

import BasicPage from '../components/BasicPage';
import useAuthActions from '../hooks/useAuthActions';
import useAuthState from '../hooks/useAuthState';

const useStyles = makeStyles(theme => ({
  button: {
    marginTop: theme.spacing(4),
  },
}));

const EmailVerificationComplete: React.FC = () => {
  const classes = useStyles();
  const { login } = useAuthActions();
  const { user } = useAuthState();

  return (
    <BasicPage
      title="Email verified successfully"
      text="Thank you for verifying your email. Now you can securely access all your bookings and edit them."
    >
      {!user && (
        <Button onClick={login} color="secondary" variant="contained" className={classes.button}>
          Log In
        </Button>
      )}
    </BasicPage>
  );
};

export default EmailVerificationComplete;

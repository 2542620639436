import { useReducer, useEffect } from 'react';
import useApi from '../hooks/useApi';
import { ITrip, ITripRoute } from '../services/ApiService';

interface IState {
  trip: ITrip | null;
  route: ITripRoute | null;
  tripLoading: boolean;
  routeLoading: boolean;
  tripError: string;
  routeError: string;
  addingToMyTrips: boolean;
}

interface IFetchTrip {
  type: 'FETCH_TRIP';
}

interface ITripSuccessAction {
  type: 'FETCH_TRIP_SUCCESS';
  payload: ITrip;
}
interface ITripErrorAction {
  type: 'FETCH_TRIP_ERROR';
  payload: Error;
}

interface IFetchRoute {
  type: 'FETCH_ROUTE';
}

interface IRouteSuccessAction {
  type: 'FETCH_ROUTE_SUCCESS';
  payload: ITripRoute;
}

interface IRouteErrorAction {
  type: 'FETCH_ROUTE_ERROR';
  payload: Error;
}

interface ICreateTripAction {
  type: 'CREATING_FROM_TEMPLATE';
}

interface ICreateTripSuccessAction {
  type: 'CREATING_FROM_TEMPLATE_SUCCESS';
}

interface ICreateTripErrorAction {
  type: 'CREATING_FROM_TEMPLATE_ERROR';
  payload: Error;
}

type Action =
  | IFetchTrip
  | ITripSuccessAction
  | ITripErrorAction
  | IFetchRoute
  | IRouteSuccessAction
  | IRouteErrorAction
  | ICreateTripAction
  | ICreateTripSuccessAction
  | ICreateTripErrorAction;

const initialState: IState = {
  trip: null,
  route: null,
  tripLoading: true,
  routeLoading: true,
  tripError: '',
  routeError: '',
  addingToMyTrips: false,
};

function reducer(state: IState, action: Action) {
  switch (action.type) {
    case 'FETCH_TRIP':
      return { ...state, isLoading: true };
    case 'FETCH_TRIP_SUCCESS':
      return { ...state, trip: action.payload || null, tripLoading: false, tripError: '' };
    case 'FETCH_TRIP_ERROR':
      return {
        ...state,
        tripLoading: false,
        tripError: action.payload.message || 'An error occured',
      };
    case 'FETCH_ROUTE':
      return { ...state, routeLoading: true, routeError: '' };
    case 'FETCH_ROUTE_SUCCESS':
      return { ...state, route: action.payload || null, routeLoading: false, routeError: '' };
    case 'FETCH_ROUTE_ERROR':
      return {
        ...state,
        routeLoading: false,
        routeError: action.payload.message || 'An error occured',
      };
    case 'CREATING_FROM_TEMPLATE':
      return { ...state, addingToMyTrips: true };
    case 'CREATING_FROM_TEMPLATE_SUCCESS':
      return {
        ...state,
        addingToMyTrips: false,
        tripError: '',
      };
    case 'CREATING_FROM_TEMPLATE_ERROR':
      return {
        ...state,
        addingToMyTrips: false,
        tripError: action.payload.message || 'An error occured',
      };
    default:
      return state;
  }
}

export default function useTripDetails(tripId: string) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const api = useApi();

  useEffect(() => {
    let didCancel = false;
    const fetchTripDetails = async () => {
      try {
        const data = await api.fetchTripById(tripId);

        if (!didCancel) {
          dispatch({ type: 'FETCH_TRIP_SUCCESS', payload: data });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'FETCH_TRIP_ERROR', payload: error });
        }
      }
    };

    const fetchTripRoute = async () => {
      try {
        const data = await api.fetchTripRoute(tripId);
        if (!didCancel) {
          dispatch({ type: 'FETCH_ROUTE_SUCCESS', payload: data });
        }
      } catch (error) {
        if (!didCancel) {
          dispatch({ type: 'FETCH_ROUTE_ERROR', payload: error });
        }
      }
    };

    dispatch({ type: 'FETCH_TRIP' });
    dispatch({ type: 'FETCH_ROUTE' });
    fetchTripDetails();
    fetchTripRoute();
    return () => {
      didCancel = true;
    };
  }, [api, tripId]);

  async function addToTrips(templateId: string): Promise<string | null> {
    dispatch({ type: 'CREATING_FROM_TEMPLATE' });
    try {
      const data = await api.createFromTripTemplate(templateId);
      dispatch({ type: 'CREATING_FROM_TEMPLATE_SUCCESS' });
      return data.id;
    } catch (err) {
      dispatch({ type: 'CREATING_FROM_TEMPLATE_ERROR', payload: err });
      return null;
    }
  }

  return {
    trip: state.trip,
    route: state.route,
    tripLoading: state.tripLoading,
    routeLoading: state.routeLoading,
    tripError: state.tripError,
    routeError: state.routeError,
    addingToMyTrips: state.addingToMyTrips,
    addToTrips,
  };
}

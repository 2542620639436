import React from 'react';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AddIcon from '@material-ui/icons/Add';
import { fade } from '@material-ui/core/styles/colorManipulator';
import useTheme from '@material-ui/core/styles/useTheme';
import Skeleton from 'react-skeleton-loader';

import CircularIconButton from './CircularIconButton';
import { IReservationExtra } from '../interfaces/IReservation';
import formatPrice from '../utils/priceFormatter';

interface IProps {
  label: string;
  extras: IReservationExtra[];
  editable?: boolean;
  loading: boolean;
  currency: string;
  onEditClick?: () => void;
  emptyIcon: JSX.Element;
  emptyLine1: string;
  emptyLine2: string;
  emptyLinkIndex?: number;
}

const useStyles = makeStyles(theme => ({
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
  },
  addButton: {
    margin: theme.spacing(0, 1),
  },
  text: {
    fontWeight: 500,
  },
  extraItem: {
    padding: theme.spacing(1, 0),
  },
  empty: {
    marginBottom: theme.spacing(2),
    border: `2px solid ${fade(theme.palette.primary.main, 0.3)}`,
    padding: theme.spacing(2),
    borderRadius: 8,
    boxShadow: '0 3px 6px 0 rgba(144, 144, 144, 0.2)',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
      flexDirection: 'column',
    },
  },
  emptyIcon: {
    height: 70,
    marginRight: theme.spacing(2),
  },
  emptyNotEditable: {
    marginBottom: theme.spacing(2),
  },
  skeletonLabel: {
    opacity: 0.5,
  },
}));

const ReservationExtras: React.FC<IProps> = ({
  label,
  extras,
  editable = false,
  loading,
  onEditClick,
  currency,
  emptyIcon,
  emptyLine1,
  emptyLine2,
  emptyLinkIndex,
}) => {
  const classes = useStyles();

  if (loading) {
    return <ReservationExtrasLoading label={label} />;
  }

  return (
    <div>
      <div className={classes.titleContainer}>
        <Typography variant="h5">{label}</Typography>
        {editable && (
          <CircularIconButton onClick={onEditClick} className={classes.addButton}>
            <AddIcon fontSize="small" />
          </CircularIconButton>
        )}
      </div>
      {renderExtras()}
    </div>
  );

  function renderExtras() {
    if (!extras.length) {
      if (editable) {
        return (
          <Grid className={classes.empty} container alignItems="center" justify="center">
            {emptyIcon}
            <div>
              <Typography className={classes.text}>{emptyLine1}</Typography>
              <Typography className={classes.text}>
                {emptyLinkIndex === undefined
                  ? emptyLine2
                  : getEmptyLineWithLink(emptyLine2, emptyLinkIndex)}
              </Typography>
            </div>
          </Grid>
        );
      }
      return (
        <Typography className={classes.emptyNotEditable}>
          No {label.toLowerCase()} booked
        </Typography>
      );
    }

    return (
      <Grid>
        {extras.map(extra => (
          <Grid container className={classes.extraItem} key={extra.id} justify="space-between">
            <Typography variant="body1" className={classes.text}>
              {extra.quantity ? `${extra.quantity} x ` : null}
              {extra.name}
            </Typography>
            <Typography variant="body1" className={classes.text}>
              {extra.price > 0 ? `${formatPrice(extra.price, currency)}` : 'FREE'}
            </Typography>
          </Grid>
        ))}
      </Grid>
    );
  }

  function getEmptyLineWithLink(text: string, index: number) {
    const words = text.split(' ');

    if (index >= words.length || index < 0) {
      return text;
    }

    return (
      <React.Fragment>
        {`${words.slice(0, index).join(' ')} `}
        <Link
          component="button"
          color="secondary"
          variant="body1"
          onClick={onEditClick}
          className={classes.text}
        >
          {words[index]}
        </Link>
        {` ${words.slice(index + 1, words.length).join(' ')}`}
      </React.Fragment>
    );
  }
};

const ReservationExtrasLoading: React.FC<{ label: string }> = ({ label }) => {
  const theme = useTheme();
  const classes = useStyles();
  const extras = [];

  for (let i = 0; i <= 3; i++) {
    extras.push(
      <Grid container justify="space-between" className={classes.extraItem} key={i}>
        <Skeleton color={theme.customPalette.skeleton} width="250px" />
        <Skeleton color={theme.customPalette.skeleton} width="100px" />
      </Grid>
    );
  }

  return (
    <div>
      <div className={classes.titleContainer}>
        <Typography variant="h5" className={classes.skeletonLabel}>
          {label}
        </Typography>
      </div>
      <Grid container direction="row">
        {extras}
      </Grid>
    </div>
  );
};

export default ReservationExtras;

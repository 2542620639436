import { getType } from 'typesafe-actions';
import createReducer from '../createReducer';

import * as A from './actions';
import { ICustomerTripState } from './types';

const initialState: ICustomerTripState = {
  trip: null,
  tripLoading: false,
  tripError: '',
  updateLoading: false,
  updateError: '',
  route: null,
  routeLoading: false,
  routeError: '',
};

const customerTripReducer = createReducer(initialState, {
  [getType(A.fetchCustomerTrip)]: state => ({
    ...state,
    tripLoading: true,
  }),
  [getType(A.fetchCustomerTripSuccess)]: (state, { payload }) => ({
    ...state,
    trip: payload || null,
    tripLoading: false,
    tripError: '',
  }),
  [getType(A.fetchCustomerTripError)]: (state, { payload }) => ({
    ...state,
    tripLoading: false,
    tripError: payload.message || 'An error occured',
  }),
  [getType(A.callUpdateCustomerTrip)]: state => ({
    ...state,
    updateLoading: true,
  }),
  [getType(A.updateCustomerTripSuccess)]: state => ({
    ...state,
    updateLoading: false,
    updateError: '',
  }),
  [getType(A.updateCustomerTripError)]: (state, { payload }) => ({
    ...state,
    updateLoading: false,
    updateError: payload.message || 'An error occured',
  }),
  [getType(A.calculateCustomerTripRoute)]: state => ({
    ...state,
    routeLoading: true,
  }),
  [getType(A.calculateCustomerTripRouteSuccess)]: (state, { payload }) => ({
    ...state,
    route: payload,
    routeLoading: false,
  }),
  [getType(A.calculateCustomerTripRouteError)]: (state, { payload }) => ({
    ...state,
    routeLoading: false,
    routeError: payload.message || 'An error occured',
  }),
});

export default customerTripReducer;

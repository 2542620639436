import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQueryParam, StringParam } from 'use-query-params';
import makeStyles from '@material-ui/core/styles/makeStyles';
import cx from 'classnames';

import { getReservationErrors } from '../store/selectors';
import { fetchReservationByEmail, fetchReservationByGuid } from '../store/reservations/actions';
import ReservationInfo from '../components/ReservationInfo';
import Layout from '../components/Layout';
import useNotifier from '../hooks/useNotifier';
import useMount from '../hooks/useMount';
import { captureException } from '../sentry';

const useStyles = makeStyles(theme => ({
  container: {
    marginTop: theme.spacing(4),
  },
  error: {
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      padding: 0,
    },
  },
}));

const ViewReservation: React.FC = () => {
  const classes = useStyles();
  const [bookingCode] = useQueryParam('bookingCode', StringParam);
  const [email] = useQueryParam('email', StringParam);
  const [guid] = useQueryParam('guid', StringParam);
  const [errorMessage] = useQueryParam('errorMessage', StringParam);
  const [errorCode] = useQueryParam('errorCode', StringParam);
  const [success] = useQueryParam('success', StringParam);
  const dispatch = useDispatch();
  const errors = useSelector(getReservationErrors);
  const notifier = useNotifier({ dismissable: true }, { variant: 'error', persist: true });
  const reservationError = errors.loadedReservation;

  useMount(() => {
    if (errorMessage) {
      notifier.notify(errorMessage);
      captureException(new Error('3d Secure Payment redirected with error'), {
        errorCode,
        errorMessage,
      });
    }
  });

  useEffect(() => {
    if (email && bookingCode) {
      dispatch(fetchReservationByEmail(email, bookingCode));
    } else if (guid) {
      dispatch(fetchReservationByGuid(guid));
    }
  }, [dispatch, email, bookingCode, guid]);

  return (
    <Layout
      useDefaultSpacing
      contentClassName={cx(classes.container, { [classes.error]: reservationError })}
    >
      <ReservationInfo email={email} bookingCode={bookingCode} guid={guid} success={success} />
    </Layout>
  );
};

export default ViewReservation;

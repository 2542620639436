import React from 'react';
import { TransitionProps } from '@material-ui/core/transitions/transition';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import makeStyles from '@material-ui/core/styles/makeStyles';
import useTheme from '@material-ui/core/styles/useTheme';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

import useAuthActions from '../hooks/useAuthActions';

interface IProps {
  open: boolean;
  onClose?: () => void;
}

const Transition = React.forwardRef((props: TransitionProps, ref) => (
  <Slide direction="down" {...props} ref={ref} />
));

const useStyles = makeStyles(theme => ({
  dialogContent: {
    maxWidth: 768,
    [theme.breakpoints.up('md')]: {
      width: 768,
    },
    display: 'flex',
    justifyContent: 'center',
  },
  formWrapper: {
    maxWidth: 650,
  },
  dialogTitle: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
  center: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  heading: {
    marginBottom: theme.spacing(2),
    textTransform: 'uppercase',
  },
  subHeading: {
    textAlign: 'center',
  },
  login: {
    margin: theme.spacing(2),
    opacity: 0.7,
  },
  signupButton: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(8),
    width: '70%',
    whiteSpace: 'nowrap',
  },
}));

const SignupDialog: React.FC<IProps> = ({ open, onClose }) => {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const { login, signup } = useAuthActions();

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      maxWidth="lg"
      fullScreen={xs}
      onClose={onClose}
    >
      <div className={classes.dialogTitle}>
        <IconButton aria-label="close" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </div>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.formWrapper}>
          <Paper elevation={0} className={classes.center}>
            <Typography variant="h4" className={classes.heading}>
              Sign Up Required
            </Typography>
            <Typography variant="body1" className={classes.subHeading}>
              In order to view and edit sensitive information, you must confirm your identity by
              creating a verified account.
            </Typography>
            <Button
              color="secondary"
              variant="outlined"
              href="#"
              onClick={signup}
              className={classes.signupButton}
            >
              Sign up
            </Button>
            <Button variant="text" color="secondary" className={classes.login} onClick={login}>
              Already have an account? Log In
            </Button>
          </Paper>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default SignupDialog;

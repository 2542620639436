import { makeStyles, useMediaQuery, useTheme } from '@material-ui/core';
import React, { useState, useLayoutEffect } from 'react';

interface IProps {
  updateError: (unitId: number | null, damage: any) => void;
  unitId: number | null;
  damageErrors: any[];
  addDamage: (e: any, partOfCar: string) => void;
  setIsOpen: (open: boolean) => void;
  setPartOfCar: (partOfCar: any) => void;
  blueprint: string;
}

const useStyles = makeStyles(theme => ({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
    maxWidth: '100%',
    width: '100%',
  },
  mobileImage: {
    width: '417px',
    height: '207px',
  },
  webImage: {
    width: '834px',
    height: '413px',
  },
  circle: {
    opacity: 1,
    pointerEvents: 'none',
    position: 'absolute',
    width: '1000px',
    height: '1000px',
    '&:hover': {
      pointerEvents: 'click',
    },
  },
  circleChild: {
    pointerEvents: 'auto',
    '&:hover': {
      cursor: 'pointer',
      fill: 'green !important',
      [theme.breakpoints.down('xs')]: {
        r: '12',
      },
      r: '16',
    },
  },
  test22: {
    '& area': {
      cursor: 'pointer',
      '&:hover + $svg': {
        opacity: 1,
      },
    },
  },
  test: {
    opacity: 0,
    pointerEvents: 'none',
    position: 'absolute',
    width: '1000px',
    height: '1000px',
  },
  colorClass: {
    fill: theme.palette.primary.main + '80',
    stroke: theme.palette.primary.main,
    strokeWidth: 2,
    strokeDasharray: 7,
  },
}));

const BluePrintXl = ({
  unitId,
  damageErrors,
  updateError,
  addDamage,
  setIsOpen,
  setPartOfCar,
  blueprint,
}: IProps) => {
  const classes = useStyles();
  const theme = useTheme();
  const xs = useMediaQuery(theme.breakpoints.down('xs'));
  const responsiveAreas: any[] = [];
  const [width, setWidth] = useState<number>(window.innerWidth);
  const isMobileScreen = width <= 768;

  const originalCoordinates = () => {
    const areas: any = document.getElementsByTagName('area');

    for (const area of areas) {
      responsiveAreas.push({ element: area, originalCords: area.coords.split(',') });
    }
  };

  const isMobile = () => {
    setWidth(window.innerWidth);

    if (isMobileScreen && responsiveAreas.length === 0) {
      originalCoordinates();
      resize();
    }
  };

  const resize = () => {
    const ratio = 0.5;
    for (const area of responsiveAreas) {
      const newCoords = [];
      for (const originalCoord of area.originalCords) {
        newCoords.push(Math.round(originalCoord * ratio));
      }
      area.element.coords = newCoords.join(',');
    }

    return true;
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', isMobile);
    window.addEventListener('load', isMobile);
    isMobile();

    return () => {
      window.removeEventListener('resize', isMobile);
      window.removeEventListener('load', isMobile);
    };
  }, []);

  return (
    <div className={classes.container}>
      <map id="map" className={classes.test22} name="image-map">
        {damageErrors.map((damage: any) => (
          <svg key={damage.id} id="circle" className={classes.circle}>
            <g>
              <circle
                id={`circle${damage.customId}`}
                className={classes.circleChild}
                onClick={() => updateError(unitId, damage)}
                cx={damage.xCoordinate}
                cy={damage.yCoordinate}
                r={xs === true ? 10 : 12}
                fill="#D2042D"
              />
              <text
                style={xs ? { fontSize: '11px' } : undefined}
                x={damage.xCoordinate}
                y={damage.yCoordinate}
                stroke="white"
                strokeWidth="1"
                textAnchor="middle"
                alignmentBaseline="middle"
              >
                {damage.customId}
              </text>
              <title>{damage.description}</title>
            </g>
          </svg>
        ))}
        <area
          alt="area"
          title="Front part"
          onClick={e => {
            addDamage(e, 'Front Part');
          }}
          coords="59,107,39,124,36,165,54,178,80,178,91,190,105,199,123,197,135,183,138,173,130,154,122,143,118,87"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            id="test"
            className={classes.colorClass}
            d="M 59 107 L 39 124 L 36 165 L 54 178 L 80 178 L 91 190 L 105 199 L 123 197 L 135 183 L 138 173 L 130 154 L 122 143 L 118 87Z"
          />
        </svg>

        <area
          alt="area"
          title="Front door"
          onClick={e => {
            addDamage(e, 'Front Door');
          }}
          coords="207,50,207,176,139,175,131,153,124,144,118,87,158,52"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 207 50 207 176 139 175 131 153 124 144 118 87 158 52Z"
          />
        </svg>

        <area
          alt="area"
          title="Back door"
          onClick={e => {
            addDamage(e, 'Back Door');
          }}
          coords="209,48,301,50,300,122,294,179,208,177"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path className={classes.colorClass} d="M 209 48 301 50 300 122 294 179 208 177Z" />
        </svg>

        <area
          alt="area"
          title="Back Part"
          onClick={e => {
            addDamage(e, 'Back Part');
          }}
          coords="301,51,422,54,430,101,428,161,434,165,430,175,389,176,383,183,372,195,355,199,336,190,325,176,294,177"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 301 51 422 54 430 101 428 161 434 165 430 175 389 176 383 183 372 195 355 199 336 190 325 176 294 177Z"
          />
        </svg>

        <area
          alt="area"
          title="Front view"
          onClick={e => {
            addDamage(e, 'Front view');
          }}
          coords="496,26,473,103,477,197,493,198,495,181,601,184,606,198,618,197,623,151,623,104,603,26,544,23"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 496 26 473 103 477 197 493 198 495 181 601 184 606 198 618 197 623 151 623 104 603 26 544 23Z"
          />
        </svg>

        <area
          alt="area"
          title="Top view"
          onClick={e => {
            addDamage(e, 'Top View');
          }}
          coords="690,14,665,54,668,382,691,404,745,404,787,403,810,382,809,50,782,12,735,6"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 690 14 665 54 668 382 691 404 745 404 787 403 810 382 809 50 782 12 735 6Z"
          />
        </svg>

        <area
          alt="area"
          title="Back view"
          onClick={e => {
            addDamage(e, 'Back View');
          }}
          coords="495,222,474,294,474,391,492,393,493,375,603,378,603,393,622,393,625,295,604,219,550,215"
          shape="poly"
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 495 222 474 294 474 391 492 393 493 375 603 378 603 393 622 393 625 295 604 219 550 215Z"
          />
        </svg>

        <area
          alt="area"
          title="Front part"
          coords="352,279,342,348,334,367,346,386,364,392,384,386,388,371,421,368,438,354,435,326,416,300"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Front Part');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 352 279 342 348 334 367 346 386 364 392 384 386 388 371 421 368 438 354 435 326 416 300Z"
          />
        </svg>
        <area
          alt=""
          title="Front door"
          coords="263,242,264,370,330,368,346,338,348,275,315,244"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Front Door');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 263 242 264 370 330 368 346 338 348 275 315 244Z"
          />
        </svg>
        <area
          alt=""
          title="Back door"
          coords="171,244,170,312,178,369,263,371,265,244"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Back Door');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path className={classes.colorClass} d="M 171 244 170 312 178 369 263 371 265 244Z" />
        </svg>
        <area
          alt=""
          title="Back Part"
          coords="49,247,41,307,44,355,42,366,81,369,89,368,99,387,115,393,133,389,143,369,179,369,169,309,169,244"
          shape="poly"
          onClick={e => {
            addDamage(e, 'Back Part');
          }}
        />
        <svg className={classes.test} viewBox={xs === true ? '0 0 2000 2000' : '0 0 1000 1000'}>
          <path
            className={classes.colorClass}
            d="M 49 247 41 307 44 355 42 366 81 369 89 368 99 387 115 393 133 389 143 369 179 369 169 309 169 244Z"
          />
        </svg>
      </map>

      <img
        id="img"
        className={xs ? classes.mobileImage : undefined}
        src={blueprint}
        alt="mising"
        useMap="#image-map"
      />
    </div>
  );
};

export default BluePrintXl;

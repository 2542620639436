import React from 'react';
import cx from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';

import InfoIcon from '@material-ui/icons/Info';

interface IProps {
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string;
}

const useStyles = makeStyles(theme => ({
  container: {
    cursor: 'pointer',
    '&:hover': {
      opacity: 0.6,
    },
  },
}));

const InformationButton: React.FC<IProps> = ({ onClick, className }) => {
  const classes = useStyles();

  return (
    <div className={cx(classes.container, className)} onClick={onClick}>
      <InfoIcon color="primary" style={{ width: 20, height: 20 }} />
    </div>
  );
};

export default InformationButton;

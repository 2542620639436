import { createAction } from 'typesafe-actions';
import { ISearchPlace } from '../../services/ApiService';

export const searchPlaces = createAction(
  'places/SEARCH_PLACES_REQUEST',
  resolve => (query: string) => resolve(query)
);

export const searchPlacesSuccess = createAction(
  'places/SEARCH_PLACES_SUCCESS',
  resolve => (places: ISearchPlace[]) => resolve(places)
);

export const searchPlacesError = createAction(
  'places/SEARCH_PLACES_ERROR',
  resolve => (error: Error) => resolve(error)
);

export const getMorePlaces = createAction(
  'places/GET_MORE_PLACES_REQUEST',
  resolve => (query: string, from: number) => resolve({ query, from })
);

export const getMorePlacesSuccess = createAction(
  'places/GET_MORE_PLACES_SUCCESS',
  resolve => (places: ISearchPlace[]) => resolve(places)
);

export const getMorePlacesError = createAction(
  'places/GET_MORE_PLACES_ERROR',
  resolve => (error: Error) => resolve(error)
);

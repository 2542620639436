import React, { useEffect } from 'react';
import { RouteComponentProps } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import Layout from '../components/Layout';
import BasicPage from '../components/BasicPage';
import LoadingPage from '../components/LoadingPage';
import TripDetails from '../components/TripDetails';
import { selectCustomerTripState } from '../store/selectors';
import { fetchCustomerTrip } from '../store/customerTrip/actions';

const CustomerTripDetails: React.FC<RouteComponentProps<{ instanceId: string }>> = ({ match }) => {
  const dispatch = useDispatch();
  const instanceId = match.params.instanceId;
  const { trip, tripLoading, tripError, route, routeLoading } = useSelector(
    selectCustomerTripState
  );

  useEffect(() => {
    dispatch(fetchCustomerTrip(instanceId));
  }, [dispatch, instanceId]);

  if (tripLoading) {
    return <LoadingPage />;
  }

  if (!trip || tripError) {
    return <BasicPage title={tripError ? `Error: ${tripError}` : 'Error loading trips'} />;
  }

  return (
    <Layout>
      <TripDetails trip={trip} route={route} isCustomerTrip routeLoading={routeLoading} />
    </Layout>
  );
};

export default CustomerTripDetails;

import { createAction } from 'typesafe-actions';
import IReservation, {
  IExtra,
  IInsurance,
  ICardInfo,
  IAddExtra,
  IUpdateCustomer,
  IReservationDriver,
} from '../../interfaces/IReservation';
import IReservationLite from '../../interfaces/IReservationLite';
import { IFetchExtensionPriceSuccessPayload } from './types';

export const fetchReservationByGuid = createAction(
  'reservations/FETCH_BY_GUID',
  resolve => (guid: string) => resolve({ guid })
);

export const getUnitIdByGuid = createAction(
  'reservations/FETCH_UNIT_ID_BY_GUID',
  resolve => (guid: string) => resolve({ guid })
);

export const updateDamage = createAction(
  'reservations/UPDATE_DAMAGE',
  resolve => (
    unitId: number,
    damageId: string,
    damagePhotoUrl: string,
    description: string,
    xCoordinate: string,
    yCoordinate: string
  ) => resolve({ unitId, damageId, damagePhotoUrl, description, xCoordinate, yCoordinate })
);

export const getUnitIdByGuidSuccess = createAction(
  'reservations/FETCH_UNIT_ID_BY_GUID_SUCCESS',
  resolve => (unit: any) => resolve({ unit })
);

export const fetchReservationByEmail = createAction(
  'reservations/FETCH_BY_EMAIL',
  resolve => (email: string, bookingCode: string) => resolve({ email, bookingCode })
);

export const fetchSuccess = createAction(
  'reservations/FETCH_SUCCESS',
  resolve => (reservation: IReservation) => resolve({ reservation })
);

export const fetchReservationError = createAction(
  'reservations/FETCH_ERROR',
  resolve => (message: string, notify?: boolean) => resolve({ message, shouldNotify: notify })
);

export const patchLocalReservation = createAction(
  'reservations/PATCH_LOCAL',
  resolve => (reservation: Partial<IReservation>) => resolve({ reservation })
);

export const patchDriver = createAction(
  'reservations/PATCH_DRIVER',
  resolve => (driver: IReservationDriver) => resolve({ driver })
);

export const acceptTermsAndConditions = createAction(
  'reservations/ACCEPT_TERMS_AND_CONDITIONS',
  resolve => (guid: string, signaturePhoto: any) => resolve({ guid, signaturePhoto })
);

export const acceptRentalAgreement = createAction(
  'reservations/ACCEPT_RENTAL_AGREEMENT',
  resolve => (guid: string, signaturePhoto: any) => resolve({ guid, signaturePhoto })
);

export const setReservationCheckIn = createAction(
  'reservation/SET_RESERVATION_CHECKIN',
  resolve => (guid: string) => resolve({ guid })
);

export const fetchReservationsList = createAction('reservations/FETCH_LIST');

export const fetchListSuccess = createAction(
  'reservations/FETCH_LIST_SUCCESS',
  resolve => (reservations: IReservationLite[]) => resolve({ reservations })
);

export const fetchListError = createAction(
  'reservations/FETCH_LIST_ERROR',
  resolve => (reason: string) => resolve({ reason })
);

export const fetchAvailableExtras = createAction(
  'reservations/FETCH_EXTRAS',
  resolve => (guid: string) => resolve({ guid })
);

export const fetchAvailableExtrasSuccess = createAction(
  'reservations_FETCH_EXTRAS_SUCCESS',
  resolve => (guid: string, extras: IExtra[]) => resolve({ guid, extras })
);

export const fetchAvailableExtrasError = createAction(
  'reservations_FETCH_EXTRAS_ERROR',
  resolve => (reason: string) => resolve({ reason })
);

export const fetchAvailableInsurances = createAction(
  'reservations/FETCH_INSURANCES',
  resolve => (guid: string) => resolve({ guid })
);

export const fetchAvailableInsurancesSuccess = createAction(
  'reservations/FETCH_INSURANCES_SUCCESS',
  resolve => (guid: string, insurances: IInsurance[]) => resolve({ guid, insurances })
);

export const fetchAvailableInsurancesError = createAction(
  'reservations/FETCH_EXTRAS_ERROR',
  resolve => (reason: string) => resolve({ reason })
);

export const fetchExtensionPrice = createAction(
  'reservations/FETCH_EXTENSION_PRICE',
  resolve => (guid: string, from: string, to: string) => resolve({ guid, from, to })
);

export const fetchExtensionPriceSuccess = createAction(
  'reservations/FETCH_EXTENSION_PRICE_SUCCESS',
  resolve => (data: IFetchExtensionPriceSuccessPayload) => resolve(data)
);

export const fetchExtensionPriceError = createAction(
  'reservations/FETCH_EXTENSION_PRICE_ERROR',
  resolve => (reason: string) => resolve({ reason })
);

export const clearExtensionPrice = createAction('reservations/CLEAR_EXTENSION_PRICE');

export const openExtendReservationDialog = createAction('reservations/EXTEND_DIALOG_OPEN');
export const closeExtendReservationDialog = createAction('reservations/EXTEND_DIALOG_CLOSE');

export const extendReservation = createAction(
  'reservations/EXTEND',
  resolve => (guid: string, from: string, to: string) => resolve({ guid, from, to })
);

export const extendReservationSuccess = createAction(
  'reservations/EXTEND_SUCCESS',
  resolve => (guid: string) => resolve({ guid })
);

export const extendReservationRemoveSuccess = createAction(
  'reservations/REMOVE_EXTEND_SUCCESS',
  resolve => (guid: string) => resolve({ guid })
);

export const extendReservationError = createAction(
  'reservations/EXTEND_ERROR',
  resolve => (reason: string) => resolve({ reason })
);

export const addExtras = createAction(
  'reservations/ADD_EXTRAS',
  resolve => (guid: string, extras: IAddExtra[]) => resolve({ guid, extras })
);

export const addExtrasSuccess = createAction(
  'reservations/ADD_EXTRAS_SUCCESS',
  resolve => (guid: string) => resolve({ guid })
);

export const addExtrasError = createAction(
  'reservations/ADD_EXTRAS_ERROR',
  resolve => (reason: string) => resolve({ reason })
);

export const addInsurances = createAction(
  'reservations/ADD_INSURANCES',
  resolve => (guid: string, insuranceIds: any[]) => resolve({ guid, insuranceIds })
);

export const addInsurancesSuccess = createAction(
  'reservations/ADD_INSURANCES_SUCCESS',
  resolve => (guid: string) => resolve({ guid })
);

export const addInsurancesError = createAction(
  'reservations/ADD_INSURANCES_ERROR',
  resolve => (reason: string) => resolve({ reason })
);

export const openExtrasDialog = createAction('reservations/EXTRAS_DIALOG_OPEN');
export const closeExtrasDialog = createAction('reservations/EXTRAS_DIALOG_CLOSE');
export const openInsurancesDialog = createAction('reservations/INSURANCES_DIALOG_OPEN');
export const closeInsurancesDialog = createAction('reservations/INSURANCES_DIALOG_CLOSE');

export const updateCustomer = createAction(
  'reservations/UPDATE_CUSTOMER',
  resolve => (guid: string, customer: IUpdateCustomer) => resolve({ guid, customer })
);

export const updateCustomerSuccess = createAction(
  'reservations/UPDATE_CUSTOMER_SUCCESS',
  resolve => (guid: string) => resolve({ guid })
);

export const updateDrivers = createAction(
  'reservations/UPDATE_DRIVERS',
  resolve => (guid: string, drivers: IReservationDriver[]) => resolve({ guid, drivers })
);

export const updateDriver = createAction(
  'reservations/UPDATE_DRIVER',
  resolve => (guid: string, driver: any) => resolve({ guid, driver })
);

export const removeDriver = createAction(
  'reservations/REMOVE_DRIVER',
  resolve => (guid: string, id: number) => resolve({ guid, id })
);

export const updateSelfService = createAction(
  'reservations/SELF_SERVICE',
  resolve => (
    guid: string,
    selfServicePickup: boolean,
    selfServiceDropoff: boolean,
    shouldFinishReservation: boolean
  ) => resolve({ guid, selfServicePickup, selfServiceDropoff, shouldFinishReservation })
);

export const updateDriversSuccess = createAction(
  'reservations/UPDATE_DRIVERS_SUCCESS',
  resolve => (guid: string) => resolve({ guid })
);

export const removeDriverSuccess = createAction(
  'reservations/REMOVE_DRIVER_SUCCESS',
  resolve => (guid: string) => resolve({ guid })
);

export const uploadLicencePhotos = createAction(
  'reservations/UPLOAD_LICENCE_PHOTOS',
  resolve => (guid: string, driverId: number, licensePhotos: any) =>
    resolve({ guid, driverId, licensePhotos })
);

export const updateCustomerError = createAction(
  'reservations/UPDATE_CUSTOMER_ERROR',
  resolve => (reason: string) => resolve({ reason })
);

export const updateDriversError = createAction(
  'reservations/UPDATE_DRIVERS_ERROR',
  resolve => (reason: string) => resolve({ reason })
);

export const removeDriverError = createAction(
  'reservations/REMOVE_DRIVER_ERROR',
  resolve => (reason: string) => resolve({ reason })
);

export const cancelReservation = createAction('reservations/CANCEL', resolve => (guid: string) =>
  resolve({ guid })
);

export const cancelReservationSuccess = createAction(
  'reservations/CANCEL_SUCCESS',
  resolve => (guid: string) => resolve({ guid })
);

export const cancelReservationError = createAction(
  'reservations/CANCEL_ERROR',
  resolve => (reason: string) => resolve({ reason })
);

export const payBalance = createAction(
  'reservations/PAY_BALANCE',
  resolve => (guid: string, cardInfo: ICardInfo, successUrl: string, cancelUrl: string) =>
    resolve({ guid, cardInfo, successUrl, cancelUrl })
);

export const payBalanceSuccess = createAction(
  'reservations/PAY_BALANCE_SUCCESS',
  resolve => (guid: string) => resolve({ guid })
);
export const receiveSecurePaymentForm = createAction(
  'reservations/PAY_BALANCE_SUBMIT_FORM',
  resolve => (guid: string, form: string) => resolve({ guid, form })
);

export const payBalanceError = createAction(
  'reservations/PAY_BALANCE_ERROR',
  resolve => (reason: string) => resolve({ reason })
);

export const resetPaymentState = createAction('reservations/PAYMENT_RESET');

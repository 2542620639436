import { isString } from '../utils/string';

interface IApiErrorBody {
  error?: string;
  message: string;
  statusCode: number;
}

class ApiError extends Error {
  public statusCode: number;
  public name: string;

  constructor(body: IApiErrorBody) {
    const name = body.error || 'Error';
    const message = isString(body.message) ? body.message : `${body.statusCode} ${name}`;

    super(message);
    this.statusCode = body.statusCode;
    this.name = name;
  }
}

export default ApiError;
